export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Nadchodzące',
  finished: 'Skończone',
  prizePool: 'Pula nagród',
  joinNow: 'Dołącz teraz',
  joined: 'Dołączył',
  startsIn: 'Zaczyna się w',
  endsIn: 'Kończy się w',
  ended: 'Turniej zakończony',
  started: 'Turniej rozpoczęty',
  seeMore: 'Zobacz więcej',
  winPrizes: 'Wygraj ekscytujące nagrody',
  leaderBoard: 'Tablica Liderów',
  prize: 'Nagroda',
  aboutTournament: 'O Turnieju',
  congratulationsMessage: 'Brawo!',
  payments: 'Depozyt',
  tournaments: 'Turnieje',
  shop: 'Sklep',
  blog: 'Blog',
  referral: 'Polecenie',
  contacts: 'Kontakty',
  retry: 'Spróbować ponownie',
  incorrectCredsMessage: 'Twoja nazwa użytkownika lub hasło są nieprawidłowe.',
  bonuses: 'Bonus',
  depositBonus: 'Bonus do depozytu',
  voucher: 'Kupon',
  gamlings: 'Hazard',
  transactionHistory: 'Historia transakcji',
  offers: 'Oferty',
  editProfile: 'Edytuj profil',
  continue: 'Kontynuuj',
  apply: 'Zastosuj',
  male: 'Mężczyzna',
  female: 'Kobieta',
  register: 'Zarejestruj się',
  login: 'Logowanie',
  grand: 'Grand',
  major: 'Major',
  minor: 'Minor',
  mini: 'Mini',
  crashGameTitle: 'Crash',
  betAmount: 'Kwota zakładu',
  profit: 'Zysk',
  payout: 'Wypłata',
  chance: 'Szansa na wygraną',
  betBtn: 'Zakład',
  autoCashout: 'automatyczna wypłata',
  btnHalf: '½',
  btnDouble: 'Podwójny zakład',
  btnMax: 'Maksymalny zakład',
  wagered: 'Obrócone',
  nothingFound: 'Nic nie znaleziono',
  lost: 'Zaginiony',
  won: 'Wygrał',
  streak: 'passa wygranych',
  highestWin: 'Najwyższa wygrana',
  promotions: 'Promocje',
  latestPromotions: 'Najnowsze promocje',
  sponsorships: 'Sponsoring',
  highestLost: 'Najwyższa przegrana',
  highestBet: 'Najwyższy zakład',
  maxStreak: 'Maksymalna passa',
  maxProfit: 'Maksymalny zysk:',
  bal: 'Bal:',
  messagesNoBetHistory: 'Twoja historia zakładów jest pusta',
  messagesCopyNotSupported: 'Twoja przeglądarka nie obsługuje automatycznego kopiowania do schowka.',
  messagesCopied: 'Adres skopiowany.',
  deposit: 'Depozyt',
  statReset: 'Zresetuj statystyki',
  rollOver: 'Postaw powyżej',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Z powrotem',
  inputFieldBtnMax: 'maks',
  buttonTextPlaceBet: 'Postaw zakład',
  buttonTextCancelBet: 'Anuluj zakład',
  buttonTextEscapeBet: 'Wypłata',
  buttonTextStartAutoBet: 'Uruchom Autobet',
  buttonTextCancelAutoBet: 'Anuluj zakład automatyczny',
  buttonTextLoading: 'Ładowanie',
  tabsMyBets: 'Moje zakłady',
  tabsDeposit: 'Depozyt',
  tabsCashout: 'Wypłaty',
  tabsAllbets: 'Wszystkie zakłady',
  tabsTopbets: 'Najlepsze zakłady',
  tabsBonus: 'Bonusy',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Historia zakładów',
  timerTextCurrentPayout: 'Aktualna wypłata',
  timerTextStarting: 'Rozpoczęcie w',
  timerTextSec: 'S',
  timerTextFlewAway: 'Rozbił się',
  timerTextWait: 'Proszę czekać..',
  timerTextPreparingRound: 'Runda przygotowawcza',
  timerTextLoadingRound: 'Ładowanie rundy',
  comingSoon: 'Wkrótce',
  labelsProfitOnWin: 'Zysk na wygranej',
  inputsNoOfBets: 'Liczba zakładów',
  inputsAuto: 'Automatyczny',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'start',
  inputsCancel: 'Anulować',
  buttonsMin: 'Min',
  buttonsMax: 'Maks',
  prediction: 'Prognoza',
  luckyNumber: 'Szczęśliwa liczba',
  multiplier: 'Mnożnik',
  chanceToWin: 'Szansa by wygrać',
  bets: 'Zakłady',
  depositBtn: 'Depozyt',
  rollUnder: 'Postaw niżej',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Historia',
  navBarMyBets: 'Moje zakłady',
  navBarAllBets: 'Wszystkie zakłady',
  navBarHighRollers: 'Wysokie zakłady',
  navBarRoundHistory: 'Historia rundy',
  navBarTotal: 'Łączna liczba zakładów',
  topHeaderBetId: 'Identyfikator zakładu',
  topHeaderUser: 'Użytkownik',
  topHeaderPayout: 'Wypłata',
  topHeaderAmount: 'Kwota',
  topHeaderTime: 'Czas',
  topHeaderProfit: 'Zysk',
  topHeaderRoundCrashed: 'Rozbił się',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'hash',
  topHeaderRoundBet: 'Zakład',
  topHeaderRoundProfit: 'Zysk',
  topHeaderRoundSignature: 'Podpis',
  roundHistoryTitle: 'INFORMACJE O GRZE',
  roundHistoryDescGame: 'Gra',
  roundHistoryDescCrashAt: 'Rozbił się w:',
  roundHistoryDescDate: 'Data :',
  roundHistoryDescBetHeader: 'Zakład',
  roundHistoryTableTitlePlayer: 'GRACZ',
  roundHistoryTableTitleBet: 'ZAKŁAD',
  roundHistoryTableTitleCashedOut: 'WYPŁATA',
  roundHistoryTableTitleProfit: 'ZYSK',
  roundHistoryTableTitleBetId: 'Identyfikator zakładu',
  roundHistoryMessagesNoBetHistory: 'Twoja historia zakładów jest pusta',
  placedBetsTabPlaced: 'Postawiony zakład',
  placedBetsTabPrev: 'Poprzedni zakład',
  placedBetsTitleUser: 'Użytkownik',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Zakład',
  placedBetsTitleProfit: 'Zysk',
  placedBetsTitleCashout: 'Wypłata',
  placedBetsNoPlacedBet: 'Brak postawionych zakładów.',
  provablyFairContentCheckBtn: 'Sprawdź uczciwość',
  provablyFairContentTitle: 'Uczciwość',
  provablyFairContentDesc: 'Wynik czterech stron prowadzących można udowodnić jako sprawiedliwy, tutaj możesz sprawdzić grę i obliczyć wyniki.',
  provablyFairContentInputFieldsCrashRateTitle: 'Częstotliwość awarii',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Częstotliwość awarii bez x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hash rundy',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'np.',
  provablyFairContentInputFieldsSubmitBtn: 'Wygeneruj podpis',
  provablyFairContentSignature: 'Podpis rundy',
  provablyFairContentErrorsCrashRate: 'proszę wpisać numer w polu częstości awarii',
  resetPasswordTitle: 'Zresetuj hasło',
  resetPasswordNewPasswordTitle: 'Nowe hasło',
  resetPasswordNewPasswordErrorsRequired: '*Wymagane jest hasło.',
  resetPasswordNewPasswordErrorsMinLength: 'Dozwolone minimum 8 znaków.',
  resetPasswordNewPasswordErrorsMaxLength: 'Dozwolone maksymalnie 16 znaków.',
  resetPasswordNewPasswordErrorsPattern: '*Hasło powinno zawierać od 8 do 16 znaków alfanumerycznych i specjalnych.',
  resetPasswordConfirmPasswordTitle: 'Potwierdź hasło',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Hasła nie pasują do siebie.',
  resetPasswordResetBtn: 'Resetowanie',
  passwordResetSuccess: 'Hasło zostało pomyślnie zmienione. Zaloguj się i ciesz się grą!',
  resetPasswordWentWrong: 'Coś poszło nie tak!',
  levelGrand: 'grand',
  levelMajor: 'major',
  levelMinor: 'minor',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Wygrywaj codziennie 1000 $',
  bannerContentContest: 'Teraz konkursy',
  bannerContentjoin: 'Dołącz do naszego codziennego konkursu i zostań Bohaterem!',
  bannerContentPlayNow: 'Zagraj teraz',
  bannerContentTitle: 'Wygraj Jackpot',
  bannerContentContent: 'Im więcej grasz tym masz większą szanse na wygranie jackpota',
  bannerContentGameName: 'CRASH GAME',
  bannerContentGameDesc: 'Graj więcej, aby wygrywać więcej.',
  partners: 'Partnerzy',
  gamesGoldenDragon: 'Złoty Smok',
  gamesCrashGame: 'Crash game',
  gamesDice: 'Dice',
  gamesHilo: 'Hilo',
  allBetsTitle: 'Wszystkie zakłady',
  allBetsBetId: 'Identyfikator zakładu',
  allBetsUser: 'Użytkownik',
  allBetsPayout: 'Wypłata',
  allBetsAmount: 'Kwota',
  allBetsTime: 'Czas',
  allBetsProfit: 'Zysk',
  LoginwithOtp: 'Zaloguj się przez Otp',
  EnterLoginOtp: 'Wprowadź login Otp',
  submit: 'wyślij',
  footerAboutUs: 'O nas',
  footerFaq: 'Często zadawane pytania',
  footerSitePolicy: 'Polityka witryny',
  footerResponsibleGambling: 'Odpowiedzialna gra',
  footerTerms: 'Regulamin',
  signinTitle: 'Zaloguj sie',
  signinUsername: 'Nazwa użytkownika / e-mail',
  signinPassword: 'Hasło',
  signinLoginBtn: 'Zaloguj sie',
  signinSignup: 'Zarejestruj się!',
  signinForgetPassword: 'Zapomniałeś hasła?',
  forgotPasswordMsg: 'Wpisz adres e-mail powiązany z Twoim kontem, a my wyślemy Ci link umożliwiający zresetowanie hasła.',
  emailLinkMsg: 'Link do resetowania hasła został wysłany na Twój e-mail rejestracyjny. Kliknij link e-mail i ustaw nowe hasło.',
  resetPasswardConfirm: 'Hasło zostało pomyślnie zresetowane',
  signinDontHaveAccount: 'Nie masz konta?',
  signinCreateAccount: 'Utwórz konto',
  signinErrorsUserNameRequired: '*Proszę wpisać nazwę użytkownika/adres e-mail.',
  signinErrorsUserNameMinLength: 'Dozwolone minimum 4 znaki.',
  signinErrorsUserNameMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  signinErrorsUserNamePattern: 'Proszę wprowadzić poprawną nazwę użytkownika. Dozwolone są tylko litery i cyfry.',
  signinErrorsPasswordRequired: '*Wymagane jest hasło.',
  signinErrorsPasswordMinLength: 'Dozwolone minimum 8 znaków.',
  signinErrorsPasswordMaxLength: 'Dozwolone maksymalnie 16 znaków.',
  signinErrorsPasswordPattern: '*Hasło powinno zawierać od 8 do 16 znaków alfanumerycznych i specjalnych.',
  signinSuccessfullyLoggedIn: 'Zalogowano się pomyślnie.',
  signinResetMailSent: 'Zresetuj hasło wysłane e-mailem.',
  signinTryAgain: 'Coś poszło nie tak. Proszę spróbuj ponownie.',
  signinResetPasswordUsernameErr: 'Wprowadź nazwę użytkownika/adres e-mail, aby zresetować hasło.',
  signinResetMailSentSuccessContent: 'Wysłaliśmy Ci link do resetowania hasła na Twój e-mail.',
  signupTitle: 'rejestracja',
  signupUsername: 'Nazwa użytkownika',
  signupEmail: 'E-mail',
  signupTermsConditions: 'Warunki',
  signupWalletConnect: 'Zaloguj się za pomocą Portfela',
  signupPassword: 'Hasło',
  signupEighteenPlus: 'Mam ukończone 18 lat i zgadzam się z Regulaminem',
  signupRegisterBtn: 'Zapisać się',
  signupCreateNewAccount: 'Stwórz nowe konto',
  signupFullName: 'Pełne imię i nazwisko',
  signupReferralCode: 'kod polecającego',
  signupConfirmPassword: 'Potwierdź hasło',
  signupUseSocialAccount: 'Zaloguj się poprzez sieci społecznościowe',
  signupFacebook: 'Facebook',
  signupGoogle: 'Google',
  signupHaveAccount: 'Masz konto?',
  signupLoginBtn: 'Zaloguj sie',
  signupErrorsUserNameRequired: '*Proszę wpisać nazwę użytkownika.',
  signupErrorsUserNameMinLength: 'Dozwolone minimum 4 znaki.',
  signupErrorsUserNameMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  signupErrorsUserNamePattern: 'Proszę wprowadzić poprawną nazwę użytkownika. Dozwolone są tylko litery i cyfry.',
  signupErrorsFullNameRequired: '*Proszę wpisać pełne imię i nazwisko.',
  signupErrorsFullNamePattern: 'Proszę podać prawidłowe imię i nazwisko. Dozwolone są tylko litery i cyfry.',
  signupErrorsEmailRequired: '*Proszę wpisać e-mail.',
  signupErrorsEmailMinLength: 'Dozwolone minimum 4 znaki.',
  signupErrorsEmailMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  signupErrorsEmailPattern: 'Proszę podać poprawny adres e-mail.',
  signupErrorsPasswordRequired: '*Wymagane jest hasło.',
  signupErrorsPasswordMinLength: 'Dozwolone minimum 8 znaków.',
  signupErrorsPasswordMaxLength: 'Maksymalny depozyt dozwolony 16 znaków.',
  signupErrorsPasswordPattern: '*Hasło powinno składać się z 8 do 16 cyfr alfanumerycznych (wielkie i małe litery) oraz znaków specjalnych.',
  signupErrorsConfirmPasswordMatch: 'Hasło musi pasować.',
  signupPlaceholderUserName: 'Min. 4 cyfry lub litery',
  signupPlaceholderEmail: 'np.',
  signupPlaceholderPassword: 'Min. 8 liczb lub cyfr',
  signupSomethingWentWrong: 'Coś poszło nie tak!',
  signupSignupGoogle: 'Pomyślnie zarejestruj się w Google.',
  signupRegistered: 'Zarejestrowano pomyślnie.',
  signupConfirmAge: 'Potwierdź, że masz 18 lat.',
  signupSignupFacebook: 'Pomyślnie zarejestruj się za pomocą Facebooka.',
  signupCookiesConsent: 'Zezwól na korzystanie z plików cookie stron trzecich w ustawieniach przeglądarki, aby móc korzystać z logowania społecznościowego.',
  signupRegisterEmailPopupContent: 'Wysłaliśmy do Ciebie e-mail weryfikacyjny.',
  accountMenuDeposit: 'Depozyt',
  accountMenuCashout: 'Wypłata',
  accountMenujackpotWinners: 'Zwycięzcy jackpota',
  accountMenuTransactions: 'Transakcje',
  accountMenuPromotions: 'Promocje',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Wyloguj',
  accountMenuSound: 'Dźwięk',
  accountMenuMusic: 'Muzyka',
  accountMenuWithdraw: 'Wycofać',
  pageNotFound: 'Nie możemy znaleźć strony, której szukasz!',
  error: 'Błąd',
  somethingWentWrong: 'Coś poszło nie tak!',
  verifyEmailThankYou: 'Dziękuję!',
  verifyEmailEmailIsVerified: 'Twój e-mail został zweryfikowany.',
  verifyEmailGoToLogin: 'Przejdź do strony logowania',
  verifyEmailWentWrong: 'Coś poszło nie tak!',
  verifyEmailTokenExpired: 'Adres e-mail został już zweryfikowany lub link wygasł. Wyślij ponownie link weryfikacyjny e-mailem, podając poniżej swój zarejestrowany adres e-mail.',
  verifyEmailGoToHome: 'Wróć do strony głównej',
  verifyEmailSuccessMsg: 'Adres e-mail został pomyślnie zweryfikowany.',
  bonusSectionSpanText: 'przedstawia najbardziej hojne i różnorodne',
  bonusSectionBonuses: 'Bonusy',
  bonusSectionSubtitleText: 'Graj z przyjemnością i zdobywaj dodatkowe nagrody w trakcie gry.',
  JoiningBonusTitle: 'Bonus za dołączenie',
  JoiningBonusButton: 'Odbierz teraz',
  JoiningBonusDesc: 'Takie bonusy zachęcają graczy kasyna online do hazardu za pomocą Bitcoinów lub innych kryptowalut.',
  depositBonusTitle: 'Bonus depozytowy',
  depositBonusButton: 'Depozyt',
  depositBonusDesc: 'Takie bonusy zachęcają graczy kasyna online do hazardu za pomocą Bitcoinów lub innych kryptowalut.',
  refferBonusTitle: 'Bonus za polecenie',
  refferBonusButton: 'Depozyt',
  refferBonusDesc: 'Takie bonusy zachęcają graczy kasyna online do hazardu za pomocą Bitcoinów lub innych kryptowalut.',
  aboutSectionSpanText2: 'W kasynie online możesz grać w swoje ulubione gry za darmo lub za kryptowalutę.',
  aboutSectionSpanText3: 'W tym wirtualnym świecie hazardu Bitcoin możesz cieszyć się wieloma możliwościami — grać w swoje ulubione gry kasynowe, otrzymywać bonusy, brać udział w konkursach i promocjach.',
  verifiedSectionTitle: 'Zweryfikowane i licencjonowane oprogramowanie',
  verifiedSectionSpanText1: 'Nie kopiujemy ani nie adaptujemy oprogramowania z innych stron internetowych.',
  faqSectionTitle: 'Często zadawane pytania od graczy',
  faqSectionForgetPasswordTitle: 'Zapomniałem hasła.',
  faqSectionForgetPasswordDesc: 'Nie podałeś pomocniczego adresu e-mail dla swojego konta.',
  faqSectionMyDepositCreditedTitle: 'Mój depozyt nie został zaksięgowany.',
  faqSectionMyDepositCreditedDesc: 'Treść zastępcza dla tego akordeonu, która ma na celu zademonstrowanie klasy.',
  SupportedCurrencyTitle: 'Obsługiwana waluta',
  SupportedCurrencySpanText1: 'Nie kopiujemy ani nie adaptujemy oprogramowania z innych stron internetowych.',
  CarouselSectionTitle: 'WYPADK HODL-u',
  CarouselSectionSubTitle: 'Graj więcej, więcej gier i zdobądź szansę na wygraną.',
  WalletSettingTitle: 'Ustawienie portfela',
  WalletSettingHideZero: 'Ukryj salda zerowe',
  WalletSettingHideZeroInfo: 'Twoje saldo zerowe nie pojawi się w Twoim portfelu',
  WalletSettingDisplayFiat: 'Wyświetl kryptowaluty w fiat',
  WalletSettingDisplayFiatInfo: 'Wszystkie zakłady',
  WalletSettingNoteForApprox: 'Należy pamiętać, że są to przybliżenia walutowe.',
  WalletSettingSave: 'zapisać',
  WalletSettingNoWalletFound: 'Nie znaleziono portfela',
  jackpotWinnersNoWinnerAvailable: 'Brak dostępnych zwycięzców',
  jackpotWinnersNew: 'Nowy',
  jackpotWinnersHeader: 'Zwycięzca jackpota',
  jackpotDetailsHeader: 'Szczegóły jackpota',
  jackpotDetailsCampaignPeriod: 'Okres kampanii',
  jackpotDetailsFrom: 'Z',
  jackpotDetailsTo: 'Do',
  jackpotDetailsBettingRule: 'Zasada zakładów',
  jackpotDetailsMinBetAmount: 'Minimalna kwota zakładu',
  jackpotDetailsMaxBetAmount: 'Maksymalna kwota zakładu',
  jackpotDetailsBetPercentage: 'Procent zakładu',
  jackpotDetailsWinningRuleHeader: 'Zwycięska zasada',
  jackpotDetailsWinningRule: 'Obstawianie zakładów o wyższej kwocie zakładu zwiększy szanse na wygraną.',
  jackpotDetailsProfitRule: 'Jeśli kwota zakładu mieści się w przedziale od minBetAmount do maxBetAmount, wówczas do kwoty jackpota zostanie dodany betPercentage% kwoty zakładu.',
  profileTitle: 'Dane osobowe',
  profileFName: 'Imię',
  profileLname: 'Nazwisko',
  profileUserName: 'Nazwa użytkownika',
  profileDob: 'Data urodzenia',
  profileEmail: 'Adres e-mail',
  profilePhone: 'Numer telefonu',
  profileChange: 'Zmiana',
  profileSave: 'Zapisz zmianę',
  profilePassword: 'Hasło',
  profileSubmit: 'Wyślij',
  profileUploadProfile: 'Prześlij profil',
  profileCurrentPassword: 'Aktualne hasło',
  profileNewPassword: 'nowe hasło',
  profileConfirmPassword: 'Potwierdź hasło',
  profileScanQRCode: 'Zeskanuj qr kod',
  profileEnterSecretCode: 'Wprowadź tajny kod',
  profileErrorsFirstNameRequired: 'Pierwsze imię jest wymagane.',
  profileErrorsFirstNamePattern: 'Proszę wprowadzić prawidłową nazwę.',
  profileErrorsFirstNameMinLength: 'Dozwolone minimum 3 znaki.',
  profileErrorsFirstNameMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  profileErrorsLastNameRequired: 'Nazwisko jest wymagane.',
  profileErrorsLastNamePattern: 'Proszę wprowadzić prawidłową nazwę.',
  profileErrorsLastNameMinLength: 'Dozwolone minimum 3 znaki.',
  profileErrorsLastNameMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  profileErrorsChangePasswordOldRequired: 'Wymagane jest stare hasło.',
  profileErrorsChangePasswordOldPattern: 'Proszę wprowadzić prawidłowe hasło.',
  profileErrorsChangePasswordOldMinLength: 'Dozwolone minimum 8 znaków.',
  profileErrorsChangePasswordOldMaxLength: 'Dozwolone maksymalnie 16 znaków.',
  profileErrorsChangePasswordNewRequired: 'Wymagane jest nowe hasło.',
  profileErrorsChangePasswordNewPattern: 'Proszę wprowadzić prawidłowe hasło.',
  profileErrorsChangePasswordNewMinLength: 'Dozwolone minimum 8 znaków.',
  profileErrorsChangePasswordNewMaxLength: 'Dozwolone maksymalnie 16 znaków.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Hasła nie pasują do siebie.',
  profileErrorsChangePasswordNewPasswordMatched: 'Nowe hasło nie może być takie samo jak obecne hasło.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Potwierdź, że wymagane jest hasło.',
  profileErrorsUserNameRequired: 'Wymagana jest nazwa użytkownika.',
  profileErrorsUserNamePattern: 'Proszę wprowadzić prawidłową nazwę.',
  profileErrorsUserNameMinLength: 'Dozwolone minimum 3 znaki.',
  profileErrorsUserNameMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  profileErrorsDobRequired: 'Wymagana jest data urodzenia.',
  profileErrorsEmailRequired: 'Email jest wymagany.',
  profileErrorsPostcodeRequired: 'Kod pocztowy jest wymagany.',
  profileErrorsCityRequired: 'Miasto jest wymagane.',
  profileErrorsCurrencyRequired: 'Wymagana jest waluta.',
  profileErrorsAddressRequired: 'Adres jest wymagany.',
  profileErrorsCountryRequired: 'Kraj jest wymagany.',
  profileErrorsEmailPattern: 'Proszę podać poprawny adres e-mail.',
  profileErrorsEmailMinLength: 'Dozwolone minimum 3 znaki.',
  profileErrorsEmailMaxLength: 'Dozwolone maksymalnie 20 znaków.',
  profileMessagesProfileUpdate: 'Profil zaktualizowany pomyślnie.',
  profileMessagesChangePassword: 'Hasło zostało zmienione.',
  profileProfilePicProfilePicUpload: 'Prześlij zdjęcie profilowe',
  profileNoNewChanges: 'Nie udało się zaktualizować profilu!',
  profileLoggedInWithSocial: 'Zalogowałeś się za pomocą loginu społecznościowego.',
  profileSideNavigatorSound: 'Dźwięk',
  profileSideNavigatorMusic: 'Muzyka',
  profileSideNavigatorProfile: 'Mój profil',
  profileSideNavigatorBetHistory: 'Historia zakładów',
  profileSideNavigatorTransactions: 'Transakcje',
  profileSideNavigatorDeposit: 'Depozyt',
  profileSideNavigatorWithdraw: 'Wypłata',
  profileSideNavigatorGameLimits: 'Limity gry',
  profileSideNavigatorReferral: 'Polecenie',
  profileSideNavigatorChangePassword: 'Zmień hasło',
  profileSideNavigatorTermsConditions: 'Warunki',
  profileSideNavigatorLogOut: 'Wyloguj',
  profileSideNavigatorProvablyFair: 'Prawdopodobieństwo uczciwości',
  WalletInfoSectionNotAvailable: 'Niedostępne',
  WalletInfoSectionRealBalance: 'Prawdziwe pieniądzę',
  GameLimitsMinimumBet: 'Minimalny zakład',
  GameLimitsMaximumBet: 'Maksymalny zakład',
  GameLimitsMaxWinFor1Bet: 'Maksymalna wygrana przy jednym zakładzie',
  ProvablyFairSubTitle: 'Ta gra wykorzystuje technologię Provably Fair do ustalenia wyniku gry.',
  ProvablyFairMaximumBet: 'Maksymalny zakład:',
  ProvablyFairMaxWinFor1Bet: 'Maksymalna wygrana przy jednym zakładzie',
  promotionsTitle: 'Promocje',
  promotionsNoLosingData: 'Przepraszamy, nie mam dostępnych promocji',
  promotionsNoDepositData: 'Przepraszamy, nie są dostępne promocje bonusowe bez depozytu',
  promotionsCurrency: 'Waluta.',
  promotionsViewBtn: 'Podgląd',
  promotionsClaimSuccess: 'Pomyślnie otrzymałeś',
  promotionsAvailability: 'Dostępność:',
  promotionsAvailabilityTabLosing: 'Utrata bonusu',
  promotionsAvailabilityTabDeposit: 'Bonus depozytowy',
  promotionsDepositBonusTableCode: 'Kod',
  promotionsDepositBonusTableMinDeposit: 'min. depozyt',
  promotionsDepositBonusTablePercentage: 'Odsetek',
  promotionsDepositBonusTableMaxBonus: 'Maksymalna premia',
  promotionsDepositBonusTableRolloverMultipler: 'Mnożnik rolowania',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Maksymalny obrót na zakład',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Czas osiągnąć cel przeniesienia',
  promotionsBonusCode: 'KOD BONUSOWY:',
  promotionsLossesClaim: 'Straty można zgłaszać za (w okresie kampanii):',
  promotionsLossesClaimLosingBonusTablePercentage: 'Odsetek',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. kwota straty',
  promotionsBonusStatus: 'Status bonusowy:',
  promotionsBonusAmount: 'Kwota bonusu:',
  promotionsRolloverTarget: 'Cel przeniesienia:',
  promotionsRolloverAchieved: 'Osiągnięto najazd:',
  promotionsActivatebtn: 'AKTYWUJ',
  promotionsClaimBtn: 'Odbierz',
  promotionsCancelBtn: 'Anuluj',
  promotionsBackBtn: 'Z POWROTEM',
  promotionsBonus: 'Premia',
  promotionsTermAndConditions: 'Warunki',
  promotionsClaimDepositBonusTitle: 'Kroki, aby ubiegać się o bonus od depozytu',
  promotionsClaimDepositBonusFirst: 'Aktywuj kod bonusowy',
  promotionsClaimDepositBonusSecond: 'Wpłać kwotę do portfela',
  promotionsClaimDepositBonusThird: 'Osiągnij cel obstawiania, aby ubiegać się o premię',
  promotionsClaimLosingBonusTitle: 'Kroki, aby ubiegać się o bonus za utratę',
  promotionsClaimLosingBonusFirst: 'Aktywuj kod bonusowy',
  promotionsClaimLosingBonusSecond: 'Graj w swoje ulubione gry',
  promotionsClaimLosingBonusThird: 'Kliknij „Zgłoś straty”, aby zgłosić straty w okresie kampanii',
  promotionsWentWrong: 'Coś poszło nie tak!',
  transactionTitle: 'Transakcje',
  transactionBetId: 'Identyfikator zakładu',
  transactionUser: 'Użytkownik',
  transactionPayout: 'Wypłata',
  transactionAmount: 'Kwota',
  transactionProfit: 'Zysk',
  transactionCashout: 'Wypłata',
  transactionBet: 'Zakład',
  transactionMultix: 'Multi.x',
  transactionWin: 'Wygrać',
  transactionFairness: 'Uczciwość',
  transactionReferFriend: 'Poleć znajomemu',
  transactionTotal: 'Łączna liczba zakładów',
  transactionWins: 'Wygrane',
  transactionRefresh: 'Odświeżać',
  transactionFilterTopMultipliers: 'Najlepsze mnożniki',
  transactionFilterHugeWins: 'Ogromne wygrane',
  transactionFilterBiggestWins: 'Największe wygrane',
  transactionFilterMultipliers: 'Mnożniki',
  transactionRealBalance: 'Prawdziwy balans',
  depositWithdrawTXDate: 'Data',
  depositWithdrawTXAmount: 'Kwota',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Typ transakcji',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Brak dostępnych danych.',
  depositTitle: 'Depozyt',
  depositHistory: 'Historia',
  depositSubTitle: 'Użytkownik nie może wpłacać ani wypłacać waluty pieniężnej',
  depositCopyNotSupported: 'Twoja przeglądarka nie obsługuje automatycznego kopiowania do schowka.',
  depositCopied: 'Adres skopiowany.',
  depositAddressCreated: 'Adres został utworzony pomyślnie.',
  depositGenerateAddress: 'Wygeneruj adres',
  depositWarningText: 'Proszę NIE dokonywać wpłat za pośrednictwem przelewu między łańcuchami',
  withdrawTitle: 'wypłata',
  withdrawSubTitle: 'Użytkownik nie może wpłacać ani wypłacać waluty pieniężnej.',
  withdrawNoteDesc: 'Upewnij się, że NIE wprowadzasz adresu BEP2, BEP20 (BSC) jako portfela adresu wypłaty LTC',
  withdrawNoteSublist: 'Proszę NIE dokonywać wypłat w ramach przelewu międzyłańcuchowego',
  withdrawHistory: 'Historia',
  withdrawWithdrawBtn: 'zgłoszenie wypłaty',
  withdrawFees: 'Opłata za wypłatę {{fees}} {{token}}',
  withdrawWithdrawPending: 'Twoja prośba o wypłatę oczekuje na realizację.',
  withdrawEnterFields: 'Wprowadź kwotę wypłaty i adres swojego portfela.',
  withdrawSelectWallet: 'Wybierz portfel do wypłaty.',
  withdrawAmountFieldTitle: 'Kwota (Min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: 0,
  withdrawAddressFieldTitle: 'Twój {{wallet.currency.code}} adres',
  withdrawAddressFieldPlaceholder: 'Wpisz swój adres',
  withdrawNoBalance: 'Za mało pieniędzy',
  withdrawMinAmount: 'Proszę wpisać kwotę większą lub równą {{min}} .',
  fiatCurrencyInfo: 'Użytkownik nie może wpłacać ani wypłacać waluty pieniężnej',
  paginationNextBtn: 'Następny',
  paginationPrevBtn: 'Poprzedni',
  wentWrong: 'Coś poszło nie tak.',
  referralTitle: 'Polecenie',
  referralSubTitle: 'Poleć swoich znajomych',
  referralEarn: 'Zarób 150 INR za każdą',
  referralReferAndEarn: 'Polecaj i zarabiaj premię za każde polecenie',
  referralReferPara: 'Jeśli polecisz ten kod dowolnemu użytkownikowi i on się z niego zaloguje, dostaniesz bonus 100% od depozytu',
  referralReferLink: 'link polecający',
  referralCopyReferralCode: 'Skopiuj kod polecający',
  referralTotalRewards: 'Całkowita nagroda',
  TwoWayAuthentication: 'Uwierzytelnianie dwukierunkowe',
  LoginRequired: 'Logowanie wymagane!',
  BetPlacedSuccessfully: 'Zakład postawiony pomyślnie',
  BetAddedInQueueSuccessfully: 'Zakład dodany do kolejki pomyślnie',
  BetRemovedFromQueueSuccessfully: 'Zakład pomyślnie usunięty z kolejki',
  BetCancelledSuccessfully: 'Zakład anulowany pomyślnie',
  PlayerEscapedSuccessfully: 'Graczowi udało się uciec',
  CopiedSuccessfull: 'Skopiowano pomyślnie',
  NotEnoughBalance: 'Za mało pieniędzy',
  AutoBetStarted: 'Rozpoczęto zakład automatyczny',
  AutoBetFinished: 'Zakład automatyczny zakończony',
  IdCopied: 'Skopiowałem identyfikator',
  usersConsent: 'Wchodząc na tę stronę, potwierdzam, że mam 18 lat i zapoznałem się z regulaminem.',
  termsOfService: 'Warunki usługi',
  email: 'E-mail',
  playNow: 'Zagraj teraz',
  orContinueWith: 'Lub Kontynuuj za pomocą',
  metamaskExtensionNotAvailable: 'Zainstaluj rozszerzenie metaMask i odśwież stronę.',
  cashier: 'KASJER',
  account: 'KONTO',
  hello: 'Cześć',
  balance: 'Stan konta',
  referrals: 'Polecenia',
  settings: 'Ustawienia',
  withdrawals: 'Wypłaty',
  demoWallet: 'Portfel demonstracyjny',
  myAccount: 'Moje konto',
  wallet: 'Portfel',
  user: 'Użytkownik',
  USD: 'USD',
  amountIsRequired: 'Kwota jest wymagana',
  minDepositAmount: 'Kwota depozytu musi być większa niż {{min}}.',
  maxDepositAmount: 'Kwota depozytu musi być mniejsza niż {{max}}.',
  minWithdrawAmount: 'Kwota wypłaty musi być większa niż {{min}}',
  maxWithdrawAmount: 'Kwota wypłaty musi być mniejsza niż {{max}}',
  withdrawMustLessThanWallet: 'Kwota wypłaty musi być mniejsza niż kwota Twojego portfela',
  accountTypeIsRequired: 'Typ konta jest wymagany.',
  mobileNumberLength: 'Numer telefonu komórkowego musi być z {{length}} cyfry',
  phoneIsRequired: 'Wymagany jest telefon',
  cpfError: 'CPF musi być wyłączony {{length}} postacie',
  cpfRequiredError: 'Wymagane jest CPF',
  cnpjError: 'CNPJ musi być {{length}} postacie',
  mainMenu: 'MENU GŁÓWNE',
  casino: 'Kasyno',
  sports: 'Sporty',
  crypto: 'Krypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'ZDOBYWAĆ NAGRODY',
  rankUp: 'Wyższa ranga',
  inviteFriends: 'Zaprosić przyjaciół',
  partnerProgram: 'Program partnerski',
  home: 'Dom',
  sportsbook: 'Zakłady sportowe',
  menu: 'Menu',
  liveBets: 'ZAKŁADY NA ŻYWO',
  allBets: 'Wszystkie zakłady',
  highRollers: 'wysokie zakłady',
  luckyBets: 'Szczęśliwe zakłady',
  trade: 'Handel',
  game: 'GRA',
  player: 'GRACZ',
  time: 'CZAS',
  wager: 'ZAKŁAD',
  multiplierBan: 'MNOŻNIK',
  payoutText: 'WYPŁATA',
  bannerHeadOne: 'Doświadcz prawdziwej innowacji na najwyższym poziomie',
  bannerHeadTwo: 'program nagród w branży.',
  registerNow: 'Zarejestruj się teraz',
  liveWins: 'wygrane na żywo',
  monthly: 'Miesięczny',
  weekly: 'Tygodniowy',
  daily: 'Codziennie',
  winner1: 'Zwycięzca 1',
  winner2: 'Zwycięzca 2',
  winner3: 'Zwycięzca 3',
  spin: 'Obrót',
  spinsWon: 'Obracane wygrane',
  joinedOn: 'Dołączono',
  wageringAmount: 'Kwota zakładu',
  name: 'Nazwa',
  allGames: 'Wszystkie gry',
  allSports: 'Wszystkie sporty',
  cryptoFutures: 'kryptowalutowe kontrakty terminowe',
  tradeNow: 'Handluj teraz',
  allTables: 'Wszystkie tabele',
  nft: 'NFT',
  viewAllGames: 'Zobacz wszystkie gry',
  viewAll: 'Widzieć wszystko',
  rewards: 'NAGRODY',
  profile: 'Profil',
  totalBets: 'Łączna liczba zakładów',
  totalWagered: 'Całkowita stawka',
  joinDate: 'Data dołączenia',
  displaySocialLinks: 'Wyświetl połączone konta społecznościowe',
  privateProfile: 'Profil prywatny',
  rank: 'Ranga',
  winner: 'Zwycięzcy',
  nextRank: 'Następna ranga',
  changeUsername: 'Zmień nazwę użytkownika',
  newPassword: 'Nowe hasło',
  changeName: 'Zmień nazwę',
  linkSocialAccount: 'Połącz konto społecznościowe',
  privateProfileTooltip: 'Po ustawieniu Twojego profilu na prywatny, inni użytkownicy nie będą widzieć Twojej nazwy użytkownika obok zakładów, wyścigów itp.',
  socialLinksTooltip: 'Treść niedostępna',
  rankTooltip: 'Treść niedostępna',
  balances: 'Salda',
  createReferralBalance: 'Utwórz saldo poleceń',
  total: 'Całkowity',
  switchBalance: 'Zmień balans',
  mainAccount: 'Główne konto',
  tips: 'Porady',
  receivedAmount: 'Otrzymana ilość',
  dateOrTime: 'Data/godzina',
  view: 'Pogląd',
  allChains: 'Wszystkie łańcuchy',
  creditAmount: 'Kwota kredytu',
  setCustomReferralCode: 'Ustaw niestandardowy kod polecający',
  changeEmail: 'Zmień adres e-mail',
  newEmail: 'Nowy e-mail',
  createPassword: 'Stwórz hasło',
  newUsername: 'Nowa nazwa użytkownika',
  username: 'Nazwa użytkownika',
  changePassword: 'Zmień hasło',
  updateProfileMsg: 'Profil zaktualizowany pomyślnie',
  twoFactorAuthentication: 'uwierzytelnianie dwuskładnikowe',
  twoFactorAuthenticationTitle: 'Zdecydowanie zalecamy korzystanie z uwierzytelniania dwuskładnikowego, ponieważ chroni ono Twoje konto zarówno za pomocą hasła, jak i telefonu.',
  twoFactorAuthenticationSubTitle: 'Aby aktywować 2FA, należy ustawić adres e-mail i hasło.',
  enable2fa: 'WŁĄCZ 2FA',
  verifyIdentityByKyc: 'Zweryfikuj swoją tożsamość (kyc)',
  oldPassword: 'Stare hasło',
  live: 'Na żywo',
  soccer: 'Piłka nożna',
  tennis: 'Tenis',
  fifa: 'FIFA',
  basketball: 'Koszykówka',
  iceHockey: 'Hokej na lodzie',
  volleyball: 'Siatkówka',
  tableTennis: 'Tenis stołowy',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boks',
  myBets: 'Moje zakłady',
  favourites: 'Ulubione',
  americanFootball: 'futbol amerykański',
  buyCrypto: 'Kup kryptowalutę',
  oldEmail: 'Stary email',
  loggedInSuccess: 'zalogowano pomyślnie',
  loggedOutSuccess: 'Wylogowano pomyślnie',
  wagerLimit: 'Limit zakładów został ustawiony pomyślnie',
  lossLimit: 'Limit strat został ustawiony pomyślnie',
  accountDisable: 'Konto zostało pomyślnie wyłączone',
  sessionTimeout: 'Pomyślnie ustawiono limit czasu sesji',
  signup: 'Zarejestruj się pomyślnie',
  depositLimit: 'Limit depozytu ustawiony pomyślnie',
  tokenVerify: 'Token został wysłany na Twój adres e-mail, sprawdź',
  emailVerify: 'E-mail został zweryfikowany',
  emailChangedSuccess: 'Adres e-mail został pomyślnie zmieniony',
  profileUpdatedSuccess: 'Profil zaktualizowany',
  otpVerified: 'Zweryfikowano OTP',
  twoFADeactivated: 'Uwierzytelnianie 2FA wyłączone',
  internalServerError: 'Wewnętrzny błąd serwera',
  unAuthorized: 'Nieautoryzowany',
  dataNotFound: 'Dane nieodnalezione',
  setReferralCode: 'Ustaw kod polecający...',
  ultimate: 'OSTATECZNY',
  notFound: 'Nie znaleziono',
  casinoTransactions: 'Transakcje w kasynie',
  sportsbookTransactions: 'Transakcje w zakładach sportowych',
  status: 'Status',
  success: 'Powodzenie',
  failed: 'Przegrany',
  pending: 'Aż do',
  date: 'Data',
  to: 'Do',
  gameName: 'Nazwa gry',
  transactionId: 'Identyfikator transakcji',
  roundId: 'Okrągły identyfikator',
  bet: 'Zakład',
  win: 'Wygrać',
  transactionType: 'typ transakcji',
  at: 'Na',
  downloadAll: 'Ściągnij wszystko',
  gameText: 'Gra',
  betId: 'Identyfikator zakładu',
  gameId: 'ID gry',
  actionType: 'Rodzaj działania',
  searchGames: 'Wyszukaj gry',
  provider: 'Dostawcy',
  search: 'Szukaj',
  sortBy: 'Sortuj według',
  popular: 'Popularny',
  challengePool: 'Pula wyzwań',
  loadMore: 'Załaduj więcej',
  bitcoin: 'Bitcoina',
  chat: 'Czat',
  trades: 'Handel',
  battles: 'Bitwy',
  loginToChat: 'Zaloguj się, aby porozmawiać',
  rules: 'Zasady',
  chatRule1: 'Nie nękaj i nie obrażaj innych użytkowników',
  chatRule2: 'Nie proś, proś o pożyczki, ciekawostki i wskazówki',
  chatRule5: 'Nie reklamuj żadnych form handlu, kupna lub sprzedaży usług',
  chatRule6: 'Nie udostępniaj ani nie reklamuj swojego kodu polecającego',
  chatRule7: 'Nie proś o zostanie członkiem personelu',
  chatRule8: 'tylko angielski',
  chatRule9: 'Szanuj modów, administratorów i innych użytkowników',
  send: 'Wysłać',
  expand: 'Zwiększać',
  signIn: 'Zalogować się',
  cross: 'Przechodzić',
  collapse: 'Zawalić się',
  emailPlaceholder: 'twóje-mail@domena.com',
  removeFromFavourite: 'Usuń z ulubionych',
  addToFavourite: 'Dodaj do ulubionych',
  footerAboutSite: `Porozmawiajmy trochę o nauce? Cash Machine Casino jest częścią ciebie, ponieważ
wszyscy składają się z tych samych atomów. Twoja interakcja z nią potwierdza to
połączenie.`,
  footerRightsReserved: 'Сash Machine Casino wszelkie prawa zastrzeżone.',
  signupFirstName: 'Imię',
  signupAddress: 'Adres',
  signupCity: 'Miasto',
  signupPostcode: 'Kod pocztowy',
  signupCounty: 'Kraj',
  signupCurrency: 'Waluta',
  signupGender: 'Płeć',
  signupMan: 'Człowiek',
  signupWomen: 'Kobiety',
  signupOther: 'Inny',
  signupLoginDetails: 'Zaloguj się Szczegóły',
  signupPersonalDetails: 'Dane osobowe',
  signupConfirm: 'Potwierdź',
  signupPrivacyPolicy: 'Przeczytałem i akceptuję Politykę prywatności',
  signupTermAndConditions: 'Wyrażam zgodę na przetwarzanie moich danych osobowych.',
  signupNewsLetter: 'Chcę okresowo otrzymywać premie, promocje, aktualności i inne istotne informacje za pośrednictwem News Letter.',
  signupSms: 'Chcę okresowo otrzymywać SMS-y z bonusami, promocjami, aktualnościami i innymi istotnymi informacjami.',
  initialHeaderContent: 'Zdobądź niesamowite 100%',
  secoundaryHeaderContent: 'Bonus powitalny do 100 $',
  playNowButton: 'Zagraj teraz',
  registerButtonContent: 'Rejestr',
  results: 'Wyniki',
  refresh: 'Odśwież',
  cancel: 'Anuluj',
  select: 'Wybierz',
  current: 'Aktualny',
  set: 'Ustaw',
  remove: 'Usuń',
  save: 'Zapisz',
  not: 'nie',
  before: 'Zanim',
  after: 'Po',
  action: 'Działanie',
  withdrawal: 'Wypłata',
  requested: 'Wymagany',
  withdraw: 'Wypłata',
  areYouSure: 'Jesteś pewny',
  yes: 'Tak',
  no: 'NIE',
  cancelled: 'Anulowany',
  approved: 'Zatwierdzony',
  refunded: 'Zwrócono pieniądze',
  chargeback: 'Obciążenie zwrotne',
  processedOn: 'Przetworzone włączone',
  transaction: 'Transakcja',
  dateTime: 'Data/godzina',
  more: 'Więcej',
  fromDate: 'Od daty',
  toDate: 'do daty',
  percentage: 'Odsetek',
  info: 'Informacje',
  min: 'Min',
  max: 'Maks',
  change: 'Zmiana',
  continueToSite: 'Przejdź do strony',
  choose: 'Wybierz',
  enterWithdrawalAmount: 'Wybierz lub wprowadź kwotę wypłaty',
  capDeposit: 'DEPOZYT',
  capWithdrawal: 'wypłata',
  capAmount: 'KWOTA',
  minimum: 'Minimum',
  required: 'Wymagany',
  of: 'z',
  isRequired: 'jest wymagane',
  isAllowed: 'jest dozwolone',
  method: 'Metoda',
  capWithdraw: 'Wypłata',
  showLess: 'Pokaż mniej',
  clearFilter: 'wyczyść filtry',
  quantity: 'Ilość',
  reset: 'Reset',
  incorrect: 'Błędny',
  load: 'załaduj',
  applied: 'wysłany',
  ok: 'OK',
  list: 'Lista',
  all: 'Wszystko',
  favorite: 'Ulubiony',
  endPointNotFound: 'Coś jest nie tak z połączeniem sieciowym',
  category: 'Kategoria',
  emailUserNameRequired: 'Wymagany adres e-mail/nazwa użytkownika',
  emailUserNamePlaceHolder: 'Wpisz swój adres e-mail lub nazwę użytkownika',
  passwordRequired: 'Wymagane hasło',
  accountFrozen: 'Twoje konto jest zamrożone do',
  resendEmail: 'Ponownie wysłać wiadomość e-mail',
  resendLink: 'WYŚLIJ LINK PONOWNIE',
  userLoggedOut: 'Wylogowano pomyślnie',
  emailAlreadyRegistered: 'Ten email jest już zarejestrowany',
  userNameAlreadyTaken: 'Ta nazwa użytkownika jest już zajęta',
  fillAllFields: 'Wypełnij wszystkie pola przed przejściem do następnego kroku',
  pleaseAccept: 'Proszę przyjąć',
  acceptPrivacyPolicy: 'Polityka prywatności',
  acceptTerms: 'Zasady i warunki',
  modeOfComm: 'i sposób komunikacji',
  beforeNextStep: 'przed przejściem do następnego kroku',
  userNamePlaceholder: 'Wpisz nazwę użytkownika',
  min8Characters: 'Min. 8 znaków',
  min1Number: 'Min. 1 liczba',
  min1LowerCase: 'Min. 1 mała litera',
  min1UpperCase: 'Min. 1 wielka litera',
  min1SpecialChar: 'Min. 1 znak specjalny',
  passwordTip: 'Hasło musi składać się z co najmniej ośmiu znaków, w tym co najmniej jednej dużej litery, jednej małej litery, jednej cyfry i jednego znaku specjalnego',
  passwordPlaceholder: 'Wprowadź hasło',
  confirmPasswordPlaceholder: 'Wprowadź Potwierdź hasło',
  acceptAll: 'Akceptuj wszystkie',
  firstNamePlaceholder: 'Wprowadź imię',
  lastNamePlaceholder: 'Wpisz nazwisko',
  addressPlaceholder: 'Podaj adres',
  cityPlaceholder: 'Wpisz Miasto',
  postCodePlaceholder: 'Wprowadź kod pocztowy',
  phonePlaceholder: 'Wpisz numer telefonu',
  invalidEmail: 'Niepoprawny email',
  emailRequired: 'Email (wymagany',
  confirmPasswordRequired: 'Potwierdź hasło Wymagane',
  firstNameRequired: 'Imię (wymagane',
  lastNameRequired: 'Wymagane nazwisko',
  phoneRequired: 'Wymagany telefon',
  dateOfBirthRequired: 'Wymagana data urodzenia',
  genderRequired: 'Wymagana płeć',
  userNameRequired: 'Nazwa użytkownika (wymagana',
  addressRequired: 'Adres wymagany',
  cityRequired: 'Miasto wymagane',
  postCodeRequired: 'Wymagany kod pocztowy',
  currencyCodeRequired: 'Wymagany kod waluty',
  countryRequired: 'Kraj wymagany',
  matchPassword: 'Hasło musi pasować',
  fName3Chars: 'Imię musi składać się z co najmniej 3 znaków',
  onlyAlphabet: 'Dozwolone są tylko alfabety',
  lName3Chars: 'Nazwisko musi mieć co najmniej 3 znaki',
  mustBe18: 'Aby się zarejestrować, musisz mieć ukończone 18 lat',
  mustBeUnder200: 'Aby się zarejestrować, musisz mieć mniej niż 200 lat',
  validDOB: 'Prosze wpisać właściwą datę urodzenia',
  max20Characters: 'Dozwolone maksymalnie 20 znaków',
  min3Characters: 'Wymagane minimum 3 znaki',
  max100Characters: 'Dozwolone maksymalnie 100 znaków',
  max50Characters: 'Dozwolone maksymalnie 50 znaków',
  invalidNumber: 'Nieprawidłowy numer',
  preferredLanguageRequired: 'Wymagany preferowany język',
  invalidPass: 'nieprawidłowe hasło',
  prevBtn: 'Poprzedni',
  dayRequired: 'Dzień jest wymagany',
  monthRequired: 'Wymagany jest miesiąc',
  yearRequired: 'Rok jest wymagany',
  uploadImage: 'Załaduj obrazek',
  removeImage: 'Usuń obraz',
  preferredLanguage: 'preferowany język',
  wantToChangePass: 'Czy chcesz zmienić hasło?',
  selectCountryCode: 'Wybierz Kod kraju',
  selectPreferredLanguage: 'Wybierz Preferowany język',
  fName50Chars: 'Imię musi mieć maksymalnie 50 znaków',
  lName50Chars: 'Nazwisko musi mieć maksymalnie 50 znaków',
  invalidDate: 'Nieprawidłowa data',
  DOBEarlyThanToday: 'Data urodzenia musi być wcześniejsza niż dzisiejsza',
  enterValidPhone: 'Proszę wprowadzić prawidłowy numer telefonu',
  accountStatus: 'Status Konta',
  kycVerified: 'Twój KYC został pomyślnie zweryfikowany.',
  denied: 'odrzucony',
  chooseFile: 'Wybierz plik',
  upload: 'Wgraj',
  countryCantChange: 'Zaraz rozpoczniesz weryfikację KYC. potwierdź swój kraj, po rozpoczęciu kyc, nie będziesz mógł zmienić kraju.',
  proceed: 'Przystępować',
  update: '',
  history: 'Historia',
  type: 'Typ',
  amount: 'Kwota',
  forfeited: 'Utracone',
  expired: 'Wygasły',
  completed: 'Zakończony',
  zeroedOut: 'Wyzerowany',
  active: 'Aktywny',
  lapsed: 'Wygasło',
  yourActiveBonus: 'Twoje aktywne bonusy',
  currentActiveBonus: 'Aktualne aktywne bonusy',
  bonusStatus: 'Status',
  bonusWager: 'Zakład',
  bonusActivate: 'Aktywuj',
  bonusForfeit: 'Utrata bonusu',
  notUsableBonus: 'Jeśli tak, nie będziesz mógł ponownie skorzystać z tego bonusu.',
  bonusLapsed: 'Nie poniosłeś wystarczających strat, aby otrzymać zwrot pieniędzy.',
  inProcess: 'W trakcie',
  claiming: 'Twierdzenie',
  loyaltyPopover: 'Te punkty lojalnościowe można wykorzystać do uzyskania zwrotu gotówki.',
  loyaltyPage: 'Strona lojalnościowa',
  loyaltyPerCurrency: 'Lojalność według waluty',
  maxLevelReached: 'Maksymalny poziom osiągnięty',
  pointsToReach: 'punkty do osiągnięcia',
  loyaltyBannerHeading: 'Zasługujesz na naszą zupełnie nową lojalność',
  loyaltyBannerDesc: '100% bonusu powitalnego aż do 200 $',
  loyaltyHeadingOne: 'Zawsze jesteś nagradzany w CashMachine',
  loyaltyHeadingOneDesc: 'W CashMachine przenosimy lojalność na zupełnie nowy poziom emocji, obsypując Cię nagrodami za każdym zakrętem!',
  loyaltySubHeadOne: 'Zdobywanie punktów lojalnościowych',
  loyaltySubHeadOneDesc: 'Za każde 10 € w zakładach gotówkowych postawionych w naszym kasynie otrzymasz 1 punkt lojalnościowy.',
  loyaltySubHeadTwo: 'Wspinaczka po drabinie lojalności',
  loyaltySubHeadTwoDesc: 'Im więcej punktów zbierzesz, tym wyżej wejdziesz na poziomy lojalnościowe, odblokowując po drodze jeszcze więcej fantastycznych nagród.',
  loyaltySubHeadThree: 'Awansuj, zdobywaj nagrody',
  loyaltySubHeadThreeDesc: 'Podnieś swoje wrażenia z gry, przechodząc przez nasze kuszące poziomy nagród, gdzie każdy wyższy poziom odblokowuje ekscytujący zestaw darmowych spinów, aby zmaksymalizować Twoje szanse na wygraną.',
  loyaltyTestimonialHeadOne: 'W KASYNIE CashMachine DBAMY O TO',
  loyaltyTestimonialHeadTwo: 'Liczy się każdy obrót',
  loyaltyTestimonialDesc: 'Każdy obrót na automatach nie służy tylko zabawie, ale także przybliża Cię do naprawdę słodkich nagród!',
  loyaltyPoints: 'punkty',
  headerLevel: 'poziom',
  loyaltyCashback: 'Zwrot gotówki',
  loyaltyBannerBtn: 'Sprawdź postęp',
  loyaltyHeadingTwo: 'Odblokuj najlepsze wrażenia z gry: Program VIP CashMachine',
  loyaltyHeadingTwoDesc: 'Gdy osiągniesz poziom 6 w naszym programie lojalnościowym, odblokujesz świat ekskluzywnych przywilejów jako członek prestiżowego programu VIP CashMachine.',
  loyaltySubHeadFour: 'Wyższe limity wpłat i wypłat',
  loyaltySubHeadFourDesc: 'Ciesz się elastycznością zwiększonych limitów wpłat i wypłat jako członek VIP.',
  loyaltySubHeadFive: 'Ekskluzywne bonusy i promocje',
  loyaltySubHeadFiveDesc: 'Uzyskaj dostęp do specjalnych bonusów i promocji dostosowanych do potrzeb naszych graczy VIP',
  loyaltySubHeadSix: 'Menedżer konta osobistego',
  loyaltySubHeadSixDesc: 'Uzyskaj dedykowane wsparcie i spersonalizowaną pomoc od swojego menedżera konta.',
  loyaltySubHeadSeven: 'Szybsze czasy wypłat',
  loyaltySubHeadSevenDesc: 'Jako VIP będziesz cieszyć się szybszym czasem wypłaty, dzięki czemu będziesz mieć jeszcze szybszy dostęp do swoich wygranych.',
  loyaltySubHeadEight: 'Comiesięczne nagrody za zwrot gotówki',
  loyaltySubHeadEightDesc: 'Zwiększ swój bankroll dzięki hojnym miesięcznym nagrodom typu cashback wyłącznie dla naszych członków VIP.',
  loyaltySubHeadNine: 'Zaproszenia na ekskluzywne wydarzenia i turnieje',
  loyaltySubHeadNineDesc: 'Zdobądź upragnione zaproszenia na ekskluzywne wydarzenia i turnieje, podczas których możesz spotkać się z innymi VIP-ami i rywalizować o niesamowite nagrody.',
  loyaltySubHeadTen: 'Bonusy urodzinowe',
  loyaltySubHeadTenDesc: 'Świętuj swój wyjątkowy dzień w wielkim stylu dzięki wspaniałemu bonusowi urodzinowemu specjalnie dla Ciebie!',
  loyaltySubHeadEleven: 'Luksusowe prezenty',
  loyaltySubHeadElevenDesc: 'Zanurz się w wyjątkowym wyborze luksusowych prezentów, starannie dobranych, aby nagrodzić naszych najbardziej cenionych graczy.',
  loyaltyTableHeading: 'Przegląd poziomów i nagród',
  loyaltyTableHeaderOne: 'Poziom lojalności',
  loyaltyTableHeaderTwo: 'Punkty lojalnościowe',
  loyaltyTableHeaderThree: 'Codzienny zwrot gotówki',
  promReadMore: 'Czytaj więcej',
  currentPortalBlock: 'jeśli chcesz zablokować swoje konto tylko dla tego portalu.',
  allPortalBlock: 'aby zablokować Twoje konto na wszystkich portalach.',
  limit24Reset: 'Po ustawieniu limitów zakładów, strat i depozytów będzie można je edytować i usunąć po 24 godzinach, ale limity można natychmiast zmniejszyć.',
  enterAmount: 'Wprowadź ilość',
  limit: 'Limit',
  loss: 'Strata',
  takeABreak: 'Zrób sobie przerwę',
  session: 'Sesja',
  selfExclusion: 'Samowykluczenie',
  used: 'Używany',
  edit: 'Edytować',
  updatedAt: 'Zaktualizowano o godz',
  cannotBeRemoved: 'Nie można usunąć wcześniej',
  timePeriod: 'Okres czasu',
  custom: 'Zwyczaj',
  hours: 'godziny',
  days: 'Dni',
  timePeriodPlaceholder: 'Liczba dni',
  months: 'Miesiące',
  permanent: 'Stały',
  onlyNumbers: 'Dozwolone są tylko liczby',
  notNumbers: 'Liczby niedozwolone',
  userNameAllowed: 'Trzeba zacząć od alfabetu.',
  timePeriodRequired: 'Wymagany okres',
  cannotBeIncreased: 'Nie można wcześniej zwiększyć',
  amountGTZero: 'Kwota powinna być większa niż 0',
  amountEqualOrLess: 'Kwota powinna być równa lub mniejsza niż tygodniowa i miesięczna',
  amountInBetween: 'Kwota powinna mieścić się pomiędzy dzienną a miesięczną (włącznie)',
  amountEqualOIrGreater: 'Kwota powinna być równa lub większa niż dzienna i miesięczna',
  limitRemoved: 'Twój następujący limit zostanie usunięty.',
  limitSet24Hrs: 'Zamierzasz ustawić następujący limit na swoim koncie.',
  takeABreakInfo: 'Zamierzasz zablokować dostęp do swojego konta na określony czas.',
  sessionLimitInfo: 'Zamierzasz ustawić następujący limit na swoim koncie.',
  limitCantSetBefore: 'Limitu sesji nie można wcześniej ustawić',
  selfExclusionInfo: 'Zamierzasz zablokować dostęp do swojego konta na określony czas.',
  quickSearch: 'Szybkie wyszukiwanie',
  cash: 'Gotówka',
  nonCash: 'Bezgotówkowe',
  userDetail: 'Szczegóły użytkownika',
  gameIdentifier: 'Identyfikator gry',
  rollback: 'zwrot',
  rollbackBeforeBetWin: 'zrób zwrot przed wygraną zakładu',
  freeSpins: 'Darmowe spiny',
  betInternal: 'Zakład wewnętrzny',
  winInternal: 'Wygraj wewnętrznie',
  addMoney: 'Dodaj pieniądze',
  removeMoney: 'Usuń pieniądze',
  addMoneyInternal: 'Dodaj pieniądze wewnętrzne',
  removeMoneyInternal: 'Usuń pieniądze wewnętrzne',
  depositInternal: 'Depozyt wewnętrzny',
  withdrawInternal: 'Wycofaj wewnętrzne',
  promotionTitle: 'Tytuł promocji',
  cancelWithdrawRequest: 'Chcesz anulować tę prośbę',
  rowsPerPage: 'Wiersze na stronę',
  availedBonus: 'Masz już aktywny bonus.',
  capForfeit: 'UTRACIĆ',
  itFirst: 'to najpierw.',
  selectYourBonus: 'Wybierz swój bonus',
  skip: 'POMINĄĆ',
  with: 'z',
  without: 'bez',
  selectPaymentMethod: 'Wybierz metodę płatności',
  volatility: 'Zmienność',
  paylines: 'Linie wygrywające',
  theme: 'Temat',
  tryForFree: 'Wypróbuj za darmo',
  resultsFound: 'Znalezione rezultaty',
  games: 'Gry',
  in: 'W',
  low: 'Niski',
  medium: 'Średni',
  high: 'Wysoki',
  'medium-high': 'Średni wzrost',
  'very-high': 'Bardzo wysoko',
  'low-medium': 'Średnio zaawansowany',
  fantasy: 'Fantazja',
  ancient_civilizations: 'Starożytne cywilizacje',
  fruits: 'Owoce',
  africa: 'Afryka',
  military: 'Wojskowy',
  joker: 'Żartowniś',
  asia: 'Azja',
  luxurylife: 'Luksusowe życie',
  underwater_world: 'Podwodny świat',
  book_off: 'Rezerwacja',
  western: 'Zachodni',
  retro: 'Retro',
  egypt: 'Egipt',
  party: 'Impreza',
  st_patrick_day: 'Dzień Świętego Patryka',
  space: 'Przestrzeń',
  easter: 'Wielkanoc',
  girls: 'Dziewczyny',
  branded: 'Markowe',
  x_mas_and_new_year: 'Święta Bożego Narodzenia i Nowy Rok',
  horrors: 'Horrory',
  other: 'Inny',
  loginFirst: 'Najpierw zaloguj się, aby dodać grę do ulubionych',
  loginFirstPlay: 'Aby zagrać w tę grę, zaloguj się najpierw',
  sport: 'Sport',
  pirates: 'Piraci',
  sweets: 'Słodycze',
  luxury_life: 'Luksusowe życie',
  st_valentines_day: 'Walentynki',
  halloween: 'Halloween',
  food: 'Żywność',
  lobby: 'lobby',
  favourite: 'Ulubiony',
  playNGO: 'Zagraj w N Go',
  loyaltySystem: 'Systemu lojalnościowego',
  progressionSystem: 'System progresji',
  startPoint: 'Punkt startu',
  endPoint: 'Punkt końcowy',
  loyaltyBonus: 'Bonus lojalnościowy',
  pageRemoved: 'Ta strona nie istnieje lub została usunięta',
  suggestToBackHome: 'Sugerujemy powrót do domu',
  backToHome: 'Wrócić do domu',
  goHome: 'Idź do domu',
  oopps: 'Ups',
  somethingNotWorking: 'Przepraszamy, coś tu nie działa!',
  tournament: 'Turniej',
  none: 'Nic',
  validOnDays: 'Obowiązuje w dni',
  validTill: 'Obowiązuje do',
  yourRequest: 'Twoja prośba',
  withdrawalRequestPending: 'Masz jedną prośbę o wypłatę w stanie oczekiwania. ',
  realBalance: 'Prawdziwa równowaga',
  bonusInCasino: 'Bonus w kasynie',
  bonusSelected: 'Bonus wybrany',
  payWith: 'Zapłacić',
  reached: 'Osiągnięty',
  receiptOfYour: 'Odbiór Twojego',
  capEmail: 'E-MAIL',
  capCreditCard: 'NUMER KARTY KREDYTOWEJ',
  capExpiry: 'WYGAŚNIĘCIE',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY ma nieprawidłowy format',
  min13Numbers: 'Wymagane minimum 13 numerów',
  max19Numbers: 'Dozwolone maksymalnie 19 numerów',
  min3Numbers: 'Wymagane minimum 3 cyfry',
  max4Numbers: 'Dozwolone maksymalnie 4 numery',
  Other: 'Inny',
  forfeit: 'Utracić',
  withdrawRequestCreated: 'Żądanie wypłaty zostało utworzone pomyślnie',
  noBetHistory: 'Nie znaleziono historii zakładów',
  noPromotions: 'Nie znaleziono żadnych promocji',
  noBonuses: 'Nie znaleziono żadnych bonusów',
  noHistory: 'Nie znaleziono historii',
  noWithdrawalHistory: 'Nie znaleziono historii wypłat',
  noCMS: 'Nie znaleziono CMS-a',
  noProvidersFound: 'Nie znaleziono dostawców',
  noDataFound: 'Nie znaleziono danych',
  countryBlocked: 'KRAJ ZABLOKOWANY',
  forbidden: 'ZABRONIONY',
  notAcceptingVisitors: 'Bardzo nam przykro z tego powodu, ale witryna Shinywilds.com nie jest dostępna w Twoim kraju.',
  contactUsAt: 'Jeśli uważasz, że zostało to pokazane przez pomyłkę, skontaktuj się z naszym działem pomocy e-mailem pod adresem',
  siteUnderMaintenance: 'Strona w trakcie konserwacji',
  weWillBeRightBack: 'Zaraz wracamy!',
  serviceUnavailable: 'Nasz serwis jest chwilowo niedostępny. ',
  tryAgainLater: 'Spróbuj ponownie później.',
  checkYourEmail: 'Sprawdź swój email',
  sentAVerification: 'Wysłaliśmy link weryfikacyjny',
  toYourEmail: 'na Twój e-mail.',
  backToLogin: 'Powrót do logowania',
  goToLogin: 'Przejdź do logowania',
  accountVerified: 'Konto zweryfikowane',
  yourAccountVerified: 'Twoje konto zostało zweryfikowane',
  successfully: 'Z powodzeniem',
  verificationFailed: 'Weryfikacja nie powiodła się',
  emailFailedContact: 'Weryfikacja nie powiodła się. Jeśli problem się powtarza, skontaktuj się z nami ',
  setNewPass: 'Ustaw nowe hasło',
  passResetLink: 'Wysłaliśmy link do resetowania hasła do',
  toYourAccount: 'Na Twoje konto',
  sendPasswordResetLink: 'Prośba o zresetowanie hasła',
  gamePlayWagerComplete: 'Gratulacje, pomyślnie zakończyłeś zakłady, a wygrane pieniądze bonusowe są większe niż maksymalna dozwolona kwota wygranej. ',
  real: 'Prawdziwy',
  demo: 'Próbny',
  money: 'Pieniądze',
  formatRquired: 'Wymagany format .png, .jpg, .jpeg',
  chooseImage: 'Proszę wybrać obraz',
  imageSize3MB: 'Wprowadź obraz o rozmiarze mniejszym niż 3 MB',
  noProfileImage: 'Brak obrazu profilowego do usunięcia',
  limitSetSuccess: 'Limit ustawiony pomyślnie',
  accountDisabled: 'Konto zostało pomyślnie wyłączone',
  loginSuccess: 'Logowanie zakończone sukcesem',
  signupSuccess: 'Rejestracja pomyślna',
  linkExpired: 'Link wygasł',
  linkSent: 'Link wysłany',
  accAlreadyVerified: 'Konto już zweryfikowane. ',
  profileImage: 'Zdjęcie profilowe',
  profileImageRemoved: 'Zdjęcie profilowe zostało pomyślnie usunięte',
  docs: 'Dokumenty',
  updatedSuccess: 'Aktualizacja zakończona sukcesem',
  MATCH: 'MECZ',
  BALANCE: 'BALANSOWAĆ',
  FREESPINS: 'DARMOWE SPINY',
  MATCH_1: 'MECZ',
  DEPOSIT: 'DEPOZYT',
  WAGERING: 'ZAKŁADY',
  PROMOTION: 'AWANS',
  CREDITCARD: 'KARTA KREDYTOWA',
  WALLET: 'PORTFEL',
  CRYPTO: 'KRYPTO',
  OTHER: 'INNY',
  INSTANTBANKING: 'BANKOWOŚĆ NATYCHMIASTOWA',
  cookieTitle: 'Ciasteczka!',
  declineButton: 'Spadek',
  acceptButton: 'Zaakceptować',
  cookieDeclinedMessage: 'Aby móc nadal korzystać z tej witryny, należy zaakceptować pliki cookie. ',
  cookieDescription: 'Witamy na Shinywilds.com! ',
  forfeitMessageFirst: 'Aktualnie posiadasz następujący aktywny bonus:',
  forfeitMessageSecond: 'Musisz zrezygnować z tego bonusu przed ubieganiem się o nowy.',
  forfeitButton: 'Utracić',
  pleaseVerifyFirstText: 'Aby móc poprosić o wypłatę, musimy najpierw zweryfikować Twój adres e-mail. ',
  clickHere: 'Kliknij tutaj',
  pleaseVerifyLastText: 'abyśmy mogli ponownie wysłać Ci e-mail weryfikacyjny i kontynuować.',
  pleseWait: 'Proszę czekać na',
  toResend: 'aby ponownie wysłać link',
  notVerified: 'Aby wypłacić pieniądze, musisz zweryfikować swój adres e-mail',
  verification: 'Weryfikacja',
  UPLOAD_IMG_MSG: 'Przeciągnij i upuść pliki tutaj lub kliknij, aby wybrać pliki',
  affiliate: 'Przyłączać',
  affiliateTitle: 'Program partnerski',
  affiliateDescription: ' Polecaj naszą markę i zarabiaj pieniądze uczestnicząc w Programie Partnerskim Cash Machine! ',
  refferalTitle: 'Program polecający',
  refferalDescription: 'Możesz udostępnić ten link polecający innym osobom i zyskać premię, jeśli użytkownik zarejestruje się przy użyciu podanego przez Ciebie linku',
  affiliateButtonText: 'Zostań partnerem',
  affiliateLink: 'Link partnerski',
  affiliateStatus: 'Twoja prośba o partnerstwo została pomyślnie wysłana',
  currentStatus: 'a aktualny status Partnera to {{status}}',
  referralLink: 'link referencyjny',
  copyTitleMessage: 'Skopiuj ten link',
  copiedMessage: 'Link skopiowany',
  searchedGame: 'Wyszukiwana gra',
  favoriteGame: 'Ulubione gry',
  randomGames: 'Losowe gry',
  poolPrize: 'Pula nagród',
  chooseYourWelcomeBonus: 'Wybierz swój bonus powitalny',
  useNow: 'Użyć teraz',
  readTheTerms: 'Przeczytaj warunki',
  proceedWithoutWelcomeBonus: 'Kontynuuj bez bonusu powitalnego',
  doYouAlreadyHaveAccount: 'Czy masz już konto? ',
  registerViaSocialNetworks: 'Zarejestruj się za pośrednictwem sieci społecznościowych',
  iconfirmIm18YearsOlder: 'Potwierdzam, że mam ukończone 18 lat',
  iAgreeWithTermsAndConditions: 'Zgadzam się na przedstawione warunki',
  thankYouSuccessfullyRegisteringCashMachine: 'Dziękujemy za pomyślną rejestrację w Cash Machine.',
  verifyEmailMessage: 'Prosimy o zweryfikowanie Twojego adresu e-mail poprzez kliknięcie linku weryfikacyjnego przesłanego na Twój adres e-mail w celu pomyślnego zalogowania się na stronie.',
  gotIt: 'Rozumiem',
  phonecodeRequired: 'Wymagany jest kod telefonu',
  cpNotAllow: 'kopiowanie/wklejanie jest niedozwolone',
  playFun: 'Baw się dobrze',
  playReal: 'Graj naprawdę',
  yourRealBalance: 'Twoje prawdziwe saldo to:',
  chooseYourPaymentMethod: 'Wybierz metodę płatności',
  balanceHistory: 'Historia salda',
  liveChat: 'Czat na żywo',
  mail: 'Poczta',
  chooseEnterYourDepositAmounts: 'Wybierz lub wprowadź kwotę depozytu',
  noBonusDataAvailable: 'Brak dostępnych danych o bonusach',
  nowTryPlayForRealBets: 'Teraz spróbuj zagrać o prawdziwe zakłady',
  registerAndPlayForRealBets: 'Zarejestruj się i graj o prawdziwe zakłady',
  tournamentDataNotAvailable: 'Dane dotyczące turnieju niedostępne',
  startDate: 'Data rozpoczęcia',
  endDate: 'Data końcowa',
  merchant: 'Kupiec',
  seachHere: 'Szukaj tutaj',
  show: 'Pokazywać',
  betResults: 'Wyniki zakładów:',
  noTransactionsFoundForselectedDate: 'Nie znaleziono transakcji dla wybranej daty',
  loginError: 'Błąd logowania',
  errorMessageIncorrectlyAuthorization: 'Niepoprawnie wpisany adres e-mail / login lub hasło Błąd autoryzacji.',
  profileSaved: 'Profil został zapisany',
  addInfo: 'Dodaj informacje',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Przeczytałem i zgadzam się z ograniczeniami systemów płatności*',
  paymentOptions: 'Opcje płatności',
  language: 'Język',
  betTime: 'Czas zakładu',
  noActiveBonusFound: 'Nie znaleziono aktywnego bonusu',
  bonusType: 'Typ bonusu',
  requiredWageringAmount: 'Wymagana kwota zakładu',
  currentWageringAmount: 'Aktualna kwota zakładu',
  validUpto: 'Obowiązuje do',
  bonusCode: 'Kod bonusowy',
  wageringProgressBar: 'Pasek postępu obstawiania',
  noGamesFound: 'Nie znaleziono żadnych gier',
  noteYouMayOptfreeSpin: 'Uwaga: możesz zdecydować się na FREESPIN w dowolnej z tych gier',
  viewOfferDetails: 'Zobacz szczegóły oferty',
  viewFreespinGames: 'Zobacz gry Freespin',
  depositBonusPercentage: 'Procent bonusu depozytowego: ',
  validity: 'Ważność',
  activated: 'Aktywowany',
  bonusName: 'Nazwa bonusowa',
  freeRounds: 'Darmowe rundy',
  wagering: 'Zakłady',
  ended2: 'Zakończone',
  searchBonusTitle: 'Szukaj według tytułu bonusowego',
  claimed: 'Przejęte',
  noBonusHistoryFoundselectedDates: 'Nie znaleziono historii bonusów dla wybranych dat',
  noBonusDetailsFound: 'Nie znaleziono szczegółów bonusu',
  enterCode: 'Wprowadź kod',
  transactionTime: 'Czas transakcji',
  credit: 'Kredyt',
  debit: 'Obciążyć',
  // bonusInfoOfHeading: 'Premie to dodatkowe płatności nagradzające Twoje wyniki',
  bonusInfoOfHeading: 'Bony to dodatkowe nagrody, które doceniają Twoją aktywność i osiągnięcia.',
  onlineCasino: 'kasyno online',
  isTheTop: ' jest szczyt ',
  bonusInfoPara0: 'Szukasz najlepszych gier kasynowych online? ',
  tournamentsHistory: 'Historia turniejów',

  loginWelcomeBonusInfo: `Cash Machine wita nowych graczy bonusem powitalnym - 100%
   depozyt + 100 darmowych spinów w grze „Pat’s Heroes” (Platipus).
   Minimalna kwota depozytu wynosi 20 EUR / 900 ARS / 30 AUD / 500 CZK
   / 150 DKK / 200 NOK / 30 NZD / 80 zł / 20 USD / 300 ZAR / 70 ŻEL
   / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,
  loginDepositBonusInfo: `Maksymalna kwota bonusu: 100% kwoty depozytu, ale nie więcej
   niż 100 EUR / 4000 ARS / 150 AUD / 2500 CZK / 750 DKK / 1000 NOK
   / 150 NZD / 400 zł / 100 USD / 1500 ZAR / 350 GEL / 45 000 KZT /
   TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `Darmowe spiny zostaną przyznane w ciągu 5 dni od aktywacji bonusu,
   20 darmowych spinów dziennie. Wszystkie środki wygrane w darmowych spinach są dopisywane
   konto bonusowe i są dostępne do gry/obstawiania
   premia. Wypłata dostępna po obstawieniu X40. Czas obstawiania bonusu
   wynosi 7 dni.`,
  loginMaximumBetLimitInfo: `Maksymalny limit zakładu podczas obstawiania: 1 EUR / 45 ARS / 1,5 AUD / CZK
   25 / 7 DKK / 10 NOK / 1,5 NZD / 5 zł / 1 USD / 15 ZAR / 3,5 ŻELU /
   KZT 450 / TJS 10 / UZS 10 000 / TRY 6`,

  bonusInfoPara1: ' , z szeroką gamą fantastycznych gier i olśniewającymi ofertami specjalnymi. Nasze automaty do gier są dostępne w szerokiej gamie tematów, a automaty z progresywnym jackpotem oferują graczom lukratywną szansę na zdobycie dużej wygranej. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane∂ i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się — możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się grać na serio.',
  bonusInfoPara2: 'Nasze automaty do gier mają szeroką gamę tematów, a automaty z progresywnym jackpotem oferują lukratywną szansę na zdobycie dużej wygranej. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się — możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się na grę w realu. Nasze automaty do gry są dostępne w szerokiej gamie tematów, a automaty z progresywnym jackpotem oferują graczom lukratywną szansę na zdobycie punktów wielkie zwycięstwo. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się — możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się grać na serio.',
  bonusInfoPara3: 'Nasze automaty do gier mają szeroką gamę tematów, a automaty z progresywnym jackpotem oferują graczom lukratywną szansę na zdobycie dużej wygranej. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się — możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się grać na serio.',
  bonusInfoPara4: 'Nasze automaty do gier mają szeroką gamę tematów, a automaty z progresywnym jackpotem oferują graczom lukratywną szansę na zdobycie dużej wygranej. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się — możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się na grę w realu. Nasze automaty do gry są dostępne w szerokiej gamie tematów, a automaty z progresywnym jackpotem oferują graczom lukratywną szansę na zdobycie punktów wielkie zwycięstwo. Oferujemy także ekscytujące gry z krupierem na żywo, w których możesz zobaczyć, jak Twoje karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się – możesz najpierw poćwiczyć w trybie demonstracyjnym, aby poznać każdą grę, zanim zdecydujesz się grać na serio.',
  cashMachineFooterPara1: 'Szukasz najlepszego kasyna online? Witaj w ',
  cashMachineFooterPara2: ` gdzie łączymy ekscytującą
rozgrywkę, hojne bonusy i ekskluzywne turnieje, aby dostarczyć Ci najlepszą rozrywkę
online. Chcesz wygrać jackpot na naszych progresywnych automatach, walczyć o nagrody
w turniejach lub spróbować szczęścia w grach z krupierami na żywo? Mamy wszystko! Co
nas wyróżnia? W `,
  cashMachineFooterPara3: ` możesz wygrać nie tylko nagrody pieniężne, ale także
prawdziwe fizyczne nagrody! Z dumą stajemy się wyborem graczy na całym świecie.
Gotowy, aby odkryć więcej? Graj w dowolną grę dostępną w Twoim regionie i ciesz się
niezrównaną ekscytacją. Doświadcz najlepszego, graj z najlepszymi — tylko w `,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: 'Turnieje w kasynie na żywo, automaty i turnieje kasynowe z nagrodami',
  tournamentDescTitle: 'Turnieje to doskonała okazja, aby rywalizować z innymi graczami i wygrać hojne nagrody.',
  tournamentPara1: 'Szukasz najlepszych gier kasynowych online? Trafiłeś we właściwe miejsce.',
  tournamentPara2: 'z szerokim wyborem fantastycznych gier i olśniewającymi ofertami specjalnymi. Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze.',
  tournamentPara3: 'Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze. Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze.',
  tournamentPara4: 'Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze.',
  tournamentPara5: 'Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze. Nasze gry slotowe występują w szerokiej gamie tematów, a gry slotowe z progresywnym jackpotem dają graczom lukratywną szansę na duży wygrany. Oferujemy również ekscytujące gry z żywymi krupierami, gdzie możesz zobaczyć, jak karty są tasowane i rozdawane w czasie rzeczywistym! Nie wiesz, która gra jest dla Ciebie odpowiednia? Nie martw się - możesz najpierw ćwiczyć w trybie demonstracyjnym, aby poczuć każdą grę, zanim zdecydujesz się grać na prawdziwe pieniądze.',
  'New Games': 'Nowe Gry',
  'Popular Games': 'Popularne Gry',
  'Games Of The Month': 'Gry Miesiąca',
  Exclusive: 'Ekskluzywne',
  'Bonus Buy': 'Zakup Bonusowy',
  'Jackpot Games': 'Jackpot Gry',
  lightMode: 'Tryb jasny',
  darkMode: 'Tryb ciemny',
  searchProvider: 'Dostawca wyszukiwania',
  tournamentHistoryDataNotAvailable: 'Dane historii turniejów niedostępne',
  viewMore: 'Wyświetl więcej',
  description: 'Opis',
  codeCopied: 'Kod skopiowany',
  tournamentGames: 'Gry turniejowe',
  freeSpinGames: 'Darmowe gry spinowe',
  promoCode: 'Kod promocyjny',
  paymentNote: 'Uwaga: Proszę nie zamykać ani odświeżać okna podczas przetwarzania transakcji',
  liveGames: 'Gry na Żywo',
  play: 'GRAĆ',
  demo1: 'DEMO',
  registration: 'REJESTRACJA',
  confirmation: 'Potwierdzenie',
  forfeitBonusMessage: 'Jeśli aktywujesz ten bonus, wszystkie stare aktywne bonusy zostaną utracone.',
  bonusConfirmationMessage: 'Czy na pewno chcesz aktywować ten bonus?',

  forfeitRewardMessage: 'Jeśli aktywujesz tę nagrodę, wszelkie stare aktywne bonusy zostaną utracone.',
  rewardConfirmationMessage: 'Czy na pewno chcesz aktywować tę nagrodę?',
  reward: 'Nagroda',
  purchasedReward: 'Zakupiona nagroda',
  noActiveRewardFound: 'Nie znaleziono aktywnej nagrody',
  noRewardDetailsFound: 'Nie znaleziono nagrody',
  betDate: 'Data zakładu',
  shopDes: 'Sklep to miejsce, gdzie możesz wymieniać swoje zgromadzone punkty na różne nagrody, bony i nagrody.',
  vipInfoHeading: 'Bony VIP to ekskluzywne oferty dla naszych najbardziej lojalnych graczy.',
  updatefooter: 'świadczy usługi eGaming i posiada oficjalną licencję wydaną przez Offshore Finance Authority stanu Anjouan zgodnie z ustawą Computer Gaming Licensing Act 007 z 2005 roku. Ta strona internetowa jest własnością i jest zarządzana przez KeyPartnership B.V. (numer firmy: 166291), zarejestrowaną i regulowaną w Curaçao, z adresem: Abraham de Veerstraat 1, Willemstad, Curaçao',
  loginFirstForRandom: 'Aby zobaczyć losowe gry, zaloguj się najpierw'
}
