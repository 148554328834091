import * as Yup from 'yup'
import moment from 'moment'

export const userLoginSchema = (t) => Yup.object().shape({
  email: Yup.string().required(t('emailUserNameRequired')),
  password: Yup.string().required(t('passwordRequired'))
})

export const verifyResetPasswordSchema = (t) => Yup.object().shape({
  password:
  Yup.string()
    .min(8, t('min8Characters'))
    .max(50, t('max50Characters'))
    .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
      const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
      return !commonWords.includes(value?.toLowerCase())
    })
    .test('no-spaces', t('noSpaceAllow'), (value) => {
      // Check if the password does not contain spaces
      return !/\s/.test(value)
    })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      t('passwordTip')
    )
    .required(t('passwordRequired')),

  confirmPassword:
    Yup.string()
      .oneOf([Yup.ref('password'), null], t('matchPassword'))
      .required(t('confirmPasswordRequired'))
})

export const changePasswordSchema = (t) => Yup.object().shape({
  password:
  Yup.string()
    .min(8, t('min8Characters'))
    .max(50, t('max50Characters'))
    .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
      const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
      return !commonWords.includes(value?.toLowerCase())
    })
    .test('no-spaces', t('noSpaceAllow'), (value) => {
      // Check if the password does not contain spaces
      return !/\s/.test(value)
    })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      t('passwordTip')
    )
    .required(t('passwordRequired')),
  newPassword:
  Yup.string()
    .min(8, t('min8Characters'))
    .max(50, t('max50Characters'))
    .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
      const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
      return !commonWords.includes(value?.toLowerCase())
    })
    .test('no-spaces', t('noSpaceAllow'), (value) => {
      // Check if the password does not contain spaces
      return !/\s/.test(value)
    })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      t('passwordTip')
    )
    .required(t('passwordRequired')),

  confirmPassword:
    Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('matchPassword'))
      .required(t('confirmPasswordRequired'))
})

export const verifyEmailSchema = (t) => Yup.object().shape({
  email: Yup.string().email(t('invalidEmail')).max(50, t('max50Characters')).required(t('emailRequired'))
})
// Schema for EN with Extra Validations
export const userSigninSchemaEN = (regFields, t) =>
  Yup.object().shape({
    email:
      regFields.email &&
      Yup.string().email(t('invalidEmail')).max(50, t('max50Characters')).required(t('emailRequired')),
    password:
      regFields.password &&
      Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
          t('invalidPass')
        )
        .max(50, t('max50Characters'))
        .required(t('passwordRequired')),
    confirmPassword:
      regFields.confirmPassword &&
      Yup.string()
        .max(50, t('max50Characters'))
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),
    firstName:
      regFields.firstName &&
      Yup.string()
        .min(3, t('fName3Chars'))
        .matches(/^([^0-9]*)$/, t('notNumbers'))
        .max(50).required(t('firstNameRequired')),
    lastName:
      regFields.lastName === 2
        ? Yup.string()
            .matches(/^([^0-9]*)$/, t('notNumbers'))
            .min(3, t('lName3Chars')).max(50).required(t('lastNameRequired'))
        : Yup.string()
          .matches(/^([^0-9]*)$/, t('notNumbers'))
          .min(3, t('lName3Chars')).max(50).nullable(),
    phone: regFields.phone === 2
      ? Yup.string().required(t('phoneRequired')).nullable()
      : regFields.phone !== 0 &&
      Yup.string().nullable(),
    dateOfBirth:
      regFields.dateOfBirth === 2
        ? Yup.date()
            .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
            .typeError(t('validDOB'))
            .required(t('dateOfBirthRequired'))
        : regFields.dateOfBirth !== 0 && Yup.date()
          .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
          .typeError(t('validDOB')).nullable(),
    gender: regFields.gender === 2
      ? Yup.string().required(t('genderRequired'))
      : regFields.gender !== 0 && Yup.string().nullable(),
    username:
      regFields.username === 2
        ? Yup.string().max(20, t('max20Characters'))
            .min(3, t('min3Characters'))
            .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
            .required(t('userNameRequired'))
        : regFields.username !== 0 && Yup.string().max(20, t('max20Characters'))
          .min(3, t('min3Characters'))
          .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
          .nullable(),

    address:
      regFields.address === 2
        ? Yup.string().max(100, t('max100Characters'))
            .required(t('addressRequired'))
            .nullable()
        : regFields.address !== 0 && Yup.string().max(100, t('max100Characters'))
          .nullable(),
    city:
      regFields.address === 2
        ? Yup.string().max(50, t('max50Characters'))
            .required(t('cityRequired')).nullable()
        : regFields.address !== 0 && Yup.string()
          .max(50, t('max50Characters')).nullable(),
    zipCode:
      regFields.address === 2
        ? Yup.string()
            .required(t('postCodeRequired')).nullable()
        : regFields.address !== 0 && Yup.string()
          .nullable(),
    currencyCode:
      regFields.currencyCode &&
      Yup.string().required(t('currencyCodeRequired')),
    countryCode: regFields.countryCode === 2
      ? Yup.string().required(t('countryRequired'))
      : regFields.countryCode !== 0 && Yup.string().nullable(),
    preferredLanguage: regFields.preferredLanguage === 2
      ? Yup.string().required(t('preferredLanguageRequired'))
      : regFields.preferredLanguage !== 0 && Yup.string().nullable()
  })

// Schema for Other Languages
export const userSigninSchema = (regFields, t) =>
  regFields &&
  Yup.object().shape({
    email:
      regFields.email &&
      Yup.string().email(t('invalidEmail')).max(50).required(t('emailRequired')),
    password:
      regFields.password &&
      Yup.string()
        .min(8, t('min8Characters'))
        .max(50, t('max50Characters'))
        .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
          const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
          return !commonWords.includes(value?.toLowerCase())
        })
        .test('no-spaces', t('noSpaceAllow'), (value) => {
          // Check if the password does not contain spaces
          return !/\s/.test(value)
        })
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          t('passwordTip')
        )
        .required(t('passwordRequired')),

    confirmPassword:
      regFields.confirmPassword &&
      Yup.string()
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),

    username:
      regFields.username === 2
        ? Yup.string().max(20, t('max20Characters'))
            .min(3, t('min3Characters'))
            .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
            .required(t('userNameRequired'))
        : regFields.username !== 0 && Yup.string().max(20, t('max20Characters'))
          .min(3, t('min3Characters'))
          .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
          .nullable(),

    dateOfBirth: Yup.string()
      .required(t('dateOfBirthRequired'))
      .test('is-valid-date', t('invalidDate'), function (value) {
        const dateOfBirth = moment(value, 'MM/DD/YYYY', true)
        return dateOfBirth.isValid()
      })
      .test('is-over-18', t('mustBe18'), function (value) {
        const dateOfBirth = moment(value, 'MM/DD/YYYY', true)
        const eighteenYearsAgo = moment().subtract(18, 'years')
        return dateOfBirth.isBefore(eighteenYearsAgo)
      }),
    countryCode: regFields.countryCode === 2
      ? Yup.string().required(t('countryRequired'))
      : regFields.countryCode !== 0 && Yup.string().nullable()

    // firstName:
    //   regFields.firstName &&
    //   Yup.string()
    //     .min(3, t('fName3Chars')).matches(/^([^0-9]*)$/, t('notNumbers'))
    //     .max(50, t('max50Characters')).required(t('firstNameRequired')),
    // lastName:
    //   regFields.lastName === 2
    //     ? Yup.string()
    //         .min(3, t('lName3Chars')).max(50, t('max50Characters')).matches(/^([^0-9]*)$/, t('notNumbers')).required(t('lastNameRequired'))
    //     : Yup.string()
    //       .min(3, t('lName3Chars')).max(50, t('max50Characters')).matches(/^([^0-9]*)$/, t('notNumbers')).nullable(),
    // phone: regFields.phone === 2
    //   ? Yup.string().required(t('phoneRequired')).nullable()
    //   : regFields.phone !== 0 &&
    //   Yup.string().nullable(),
    // dateOfBirth:
    //   regFields.dateOfBirth === 2
    //     ? Yup.date()
    //         .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
    //         .typeError(t('validDOB'))
    //         .required(t('dateOfBirthRequired'))
    //     : regFields.dateOfBirth !== 0 && Yup.date()
    //       .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
    //       .typeError(t('validDOB')).nullable(),
    // gender: regFields.gender === 2
    //   ? Yup.string().required(t('genderRequired'))
    //   : regFields.gender !== 0 && Yup.string().nullable(),

    // address:
    //   regFields.address === 2
    //     ? Yup.string().max(100, t('max100Characters'))
    //         .required(t('addressRequired'))
    //         .nullable()
    //     : regFields.address !== 0 && Yup.string().max(100, t('max100Characters'))
    //       .nullable(),
    // city:
    //   regFields.address === 2
    //     ? Yup.string().max(50, t('max50Characters'))
    //         .required(t('cityRequired')).nullable()
    //     : regFields.address !== 0 && Yup.string()
    //       .max(50, t('max50Characters')).nullable(),
    // zipCode:
    //   regFields.address === 2
    //     ? Yup.string()
    //         .required(t('postCodeRequired')).nullable()
    //     : regFields.address !== 0 && Yup.string()
    //       .nullable(),
    // currencyCode:
    //   regFields.currencyCode &&
    //   Yup.string().required(t('currencyCodeRequired')),

    // preferredLanguage: regFields.preferredLanguage === 2
    //   ? Yup.string().required(t('preferredLanguageRequired'))
    //   : regFields.preferredLanguage !== 0 && Yup.string().nullable()
  })

// Schema for Sign Up
export const userSignupSchema = (t) =>
  Yup.object().shape({
    email:
      Yup.string().email(t('invalidEmail')).max(50).required(t('emailRequired')),
    password:
      Yup.string()
        .min(8, t('min8Characters'))
        .max(50, t('max50Characters'))
        .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
          const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
          return !commonWords.includes(value?.toLowerCase())
        })
        .test('no-spaces', t('noSpaceAllow'), (value) => {
          // Check if the password does not contain spaces
          return !/\s/.test(value)
        })
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          t('passwordTip')
        )
        .required(t('passwordRequired')),
    countryCode: Yup.string().required(t('countryRequired')),
    phone: Yup.string().min(7).max(15).required(t('phoneRequired')).nullable(),

    confirmPassword:
      Yup.string()
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),

    username:
         Yup.string().max(20, t('max20Characters'))
           .min(3, t('min3Characters'))
           .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
           .required(t('userNameRequired')),

    dateOfBirth: Yup.string()
      .required(t('dateOfBirthRequired'))
      .test('is-valid-date', t('invalidDate'), function (value) {
        const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
        return dateOfBirth.isValid()
      })
      .test('is-over-18', t('mustBe18'), function (value) {
        const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
        const eighteenYearsAgo = moment().subtract(18, 'years')
        return dateOfBirth.isBefore(eighteenYearsAgo)
      })
      .test('is-under-200', t('invalidDate'), function (value) {
        const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
        const twoHundredYearsAgo = moment().subtract(200, 'years')
        return dateOfBirth.isAfter(twoHundredYearsAgo)
      }),
    firstName: Yup
      .string()
      .label(t('firstName'))
      .matches(/^[A-Za-z]*$/, t('profileErrorsFirstNamePattern'))
      .min(3, (props) =>
        t('fName3Chars', { ...props, minNumber: 3 })
      )
      .max(50, (props) =>
        t('fName3Chars', { ...props, maxNumber: 50 })
      )
      .required((props) => t('firstNameRequired', props)),
    lastName: Yup
      .string()
      .label(t('lastName'))
      .matches(/^[A-Za-z]*$/, t('profileErrorsLastNamePattern'))
      .min(3, (props) =>
        t('fName3Chars', { ...props, minNumber: 3 })
      )
      .max(50, (props) =>
        t('fName3Chars', { ...props, maxNumber: 50 })
      )
      .required((props) => t('lastNameRequired', props)),
    promoCode: Yup.string()
      .min(4, t('signinErrorsUserNameMinLength')),
    currency: Yup.string().required(t('currencyRequired'))
    // : Yup.string()
    //   .min(3, t('lName3Chars')).max(50, t('max50Characters')).matches(/^([^0-9]*)$/, t('notNumbers')).nullable()
    // phone: regFields.phone === 2
    //   ? Yup.string().required(t('phoneRequired')).nullable()
    //   : regFields.phone !== 0 &&
    //   Yup.string().nullable(),
    // dateOfBirth:
    //   regFields.dateOfBirth === 2
    //     ? Yup.date()
    //         .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
    //         .typeError(t('validDOB'))
    //         .required(t('dateOfBirthRequired'))
    //     : regFields.dateOfBirth !== 0 && Yup.date()
    //       .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
    //       .typeError(t('validDOB')).nullable(),
    // gender: regFields.gender === 2
    //   ? Yup.string().required(t('genderRequired'))
    //   : regFields.gender !== 0 && Yup.string().nullable(),

    // address:
    //   regFields.address === 2
    //     ? Yup.string().max(100, t('max100Characters'))
    //         .required(t('addressRequired'))
    //         .nullable()
    //     : regFields.address !== 0 && Yup.string().max(100, t('max100Characters'))
    //       .nullable(),
    // city:
    //   regFields.address === 2
    //     ? Yup.string().max(50, t('max50Characters'))
    //         .required(t('cityRequired')).nullable()
    //     : regFields.address !== 0 && Yup.string()
    //       .max(50, t('max50Characters')).nullable(),
    // zipCode:
    //   regFields.address === 2
    //     ? Yup.string()
    //         .required(t('postCodeRequired')).nullable()
    //     : regFields.address !== 0 && Yup.string()
    //       .nullable(),
    // currencyCode:
    //   regFields.currencyCode &&
    //   Yup.string().required(t('currencyCodeRequired')),

    // preferredLanguage: regFields.preferredLanguage === 2
    //   ? Yup.string().required(t('preferredLanguageRequired'))
    //   : regFields.preferredLanguage !== 0 && Yup.string().nullable()
  })

export const SocialLoginFormSchema = (t) => Yup.object().shape({
  dateOfBirth: Yup.string()
    .required(t('dateOfBirthRequired'))
    .test('is-valid-date', t('invalidDate'), function (value) {
      const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
      return dateOfBirth.isValid()
    })
    .test('is-over-18', t('mustBe18'), function (value) {
      const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
      const eighteenYearsAgo = moment().subtract(18, 'years')
      return dateOfBirth.isBefore(eighteenYearsAgo)
    })
    .test('is-under-200', t('invalidDate'), function (value) {
      const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
      const twoHundredYearsAgo = moment().subtract(200, 'years')
      return dateOfBirth.isAfter(twoHundredYearsAgo)
    }),
  currency: Yup.string().required(t('currencyRequired')),
  country: Yup.string().required(t('countryRequired')),
  mobile: Yup.string().min(7).max(15).required(t('phoneRequired')).nullable()
})

export const editProfileSchema = (t) => Yup.object().shape({
  email: Yup.string().email(t('invalidEmail')).max(50).required(t('emailRequired')),
  firstName: Yup
    .string()
    .label(t('firstName'))
    .matches(/^[A-Za-z]*$/, t('profileErrorsFirstNamePattern'))
    .min(3, (props) =>
      t('fName3Chars', { ...props, minNumber: 3 })
    )
    .max(50, (props) =>
      t('fName3Chars', { ...props, maxNumber: 50 })
    )
    .required((props) => t('firstNameRequired', props)),
  lastName: Yup
    .string()
    .label(t('lastName'))
    .matches(/^[A-Za-z]*$/, t('profileErrorsLastNamePattern'))
    .min(3, (props) =>
      t('fName3Chars', { ...props, minNumber: 3 })
    )
    .max(50, (props) =>
      t('fName3Chars', { ...props, maxNumber: 50 })
    )
    .required((props) => t('lastNameRequired', props)),
  // phoneCode: Yup.string().required(t('phonecodeRequired')),
  // phone: Yup.string().min(10).max(10).required(t('phoneRequired')).nullable(),
  gender: Yup.string().required(t('genderRequired')),
  address: Yup.string().max(100, t('max100Characters'))
    .required(t('addressRequired'))
    .nullable(),
  city: Yup.string().max(50, t('max50Characters'))
    .required(t('cityRequired')).nullable()
    .max(50, t('max50Characters')).nullable(),
  zipCode: Yup.string()
    .min(6).max(6).matches(/^\d+$/, t('onlyNumbers')).required(t('postCodeRequired')).nullable(),
  countryCode:
      Yup.string().required(t('currencyCodeRequired')),
  dateOfBirth: Yup.string()
    .required(t('dateOfBirthRequired'))
    .test('is-valid-date', t('invalidDate'), function (value) {
      const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
      return dateOfBirth.isValid()
    })
    .test('is-over-18', t('mustBe18'), function (value) {
      const dateOfBirth = moment(new Date(value), 'MM/DD/YYYY', true)
      const eighteenYearsAgo = moment().subtract(18, 'years')
      return dateOfBirth.isBefore(eighteenYearsAgo)
    })
})
