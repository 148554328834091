import PropTypes from 'prop-types'

const Button = (props) => {
  const { buttonlabel, active = false, className = '' } = props
  return (
    <button
      {...props}
      className={`${className} ${active && 'active'}`}
    >{buttonlabel || props.children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.objectOf(PropTypes.any),
  haslabel: PropTypes.bool
}

export default Button
