import { Box, styled } from '@mui/material'

export const AuthModalWrapper = styled(Box)(({ theme }) => {
  return {
    // height: '100%',
    '& .form-title': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.textActive,
      fontSize: theme.spacing(1.625),
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1.375)
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.typographyText.text_01,
      fontWeight: '700'
    },
    '& .MuiInputBase-root': {
      '&:hover, &.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.border.border_02} !important`
        }
      }
    },
    '& .aut-btn-box': {
      width: '100%',
      maxWidth: theme.spacing(15),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(0.75),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .MuiButtonBase-root': {
        width: '100%',
        '& .inner-box': {
          whiteSpace: 'normal'
        }
      }
    },
    '& .MuiTypography-caption': {
      color: theme.palette.typographyText.text_01,
      fontSize: theme.spacing(1),
      fontWeight: '700'
    },
    '& .MuiTypography-button': {
      color: theme.palette.typographyText.text_01,
      fontSize: theme.spacing(1),
      fontWeight: '700',
      textTransform: 'inherit',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .social-auth-wrap': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(0.625),
      marginBottom: theme.spacing(2.25),
      '& .MuiTypography-caption': {
        color: theme.palette.typographyText.text_01,
        fontSize: theme.spacing(1),
        fontWeight: '700'
      },
      '& .social-auth-box': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(0.938),
        '& svg': {
          width: theme.spacing(2),
          height: theme.spacing(2),
          cursor: 'pointer',
          transition: '300ms all',
          '&:hover': {
            transform: 'scale(1.1)'
          }
        }
      },
      [theme.breakpoints.up('md')]: {
        position:'sticky',
        top:'0',
        index:'1'
      }
    },
    '& .welcome-text-box': {
      '& .pera-text': {
        color: theme.palette.typographyText.text_01,
        fontSize: theme.spacing(1),
        fontWeight: '700',
        textTransform: 'inherit',
        marginBottom: theme.spacing(1)
      },
      '& .MuiButtonBase-root': {
        maxWidth: theme.spacing(15),
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    '& .terms-privacy-paper': {
      backgroundColor: theme.palette.solidColor.solidColor_04,
      borderRadius: theme.spacing(0.313),
      boxShadow: theme.shadows[4],
      padding: theme.spacing(1),
      '& .terms-privacy-box': {
        height: theme.spacing(14.375),
        overflowY: 'auto'
      }
    },
    '& .MuiAccordion-root': {
      backgroundColor: theme.palette.lightShade.main,
      backgroundImage: 'none',
      boxShadow: theme.shadows[0],
      '&::before': {
        display: 'none'
      },
      '& .MuiAccordionSummary-root': {
        padding: theme.spacing(0, 1),
        '&.Mui-expanded': {
          minHeight: 'unset'
        },
        '& .MuiAccordionSummary-content': {
          color: theme.palette.typographyText.text_01,
          fontSize: theme.spacing(1),
          fontWeight: 'bold',
          lineHeight: '1.4',
          margin: theme.spacing(1, 0),
          '&.Mui-expanded': {
            margin: theme.spacing(1, 0)
          }
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          color: theme.palette.typographyText.text_01,
          '& svg': {
            width: theme.spacing(1),
            height: theme.spacing(1)
          },
          '&.Mui-expanded': {
            transform: 'rotate(90deg)'
          }
        }
      },
      '& .MuiAccordionDetails-root': {
        overflowY: 'auto',
        padding: theme.spacing(0, 1, 1.5),
        '& .MuiTypography-root': {
          color: theme.palette.typographyText.text_01,
          fontSize: theme.spacing(0.75),
          fontWeight: '500',
          lineHeight: 'normal',
          '&:not(:last-child)': {
            marginBottom: theme.spacing(0.75)
          }
        }
      },
      '&:first-of-type, &:last-of-type': {
        borderRadius: theme.spacing(0)
      },
      '&.Mui-expanded': {
        margin: theme.spacing(0, 0)
      }
    },
    '& .MuiFormGroup-root.checkbox-wrap': {
      marginTop: theme.spacing(2.25),
      '& .MuiFormControlLabel-root': {
        color: theme.palette.typographyText.text_01
      }
    },
    '& .error-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: 'red',
      fontSize: '12px',
      fontWeight: '500',
      margin: '2px 0px'
    },
    [theme.breakpoints.down('md')]: {
      '& .form-title': {
        fontSize: theme.spacing(1.25),
        position: 'absolute',
        top: theme.spacing(1.375),
        left: theme.spacing(1.5),
        marginBottom: theme.spacing(0)
      }
    }
  }
})

export const LoginFormWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      '& .form-wrap': {
        maxWidth: theme.spacing(31.25),
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    '& .facebook-btn': {
      background: 'transparent',
      border: 'none',
      outline: 'none'
    },
  }
})

export const SignupFormWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    '& .form-wrap-1': {
      '& .social-auth-wrap': {
        alignItems: 'flex-start',
        margin: theme.spacing(4, 0, 0),
        '& .social-auth-box': {
          justifyContent: 'flex-start'
        }
      },
      '& .aut-btn-box': {
        maxWidth: 'unset',
        marginTop: theme.spacing(2)
      }
    },
    '& .facebook-btn': {
      background: 'transparent',
      border: 'none',
      outline: 'none'
    },
    '& .form-wrap-3': {
      alignSelf: 'center'
    },
    '& .welcome-bonus-card': {
      backgroundColor: theme.palette.solidColor.solidColor_01,
      backgroundImage: 'none',
      borderRadius: theme.spacing(0.625),
      boxShadow: theme.shadows[0],
      '& .MuiCardContent-root': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(1, 1, 1.25),
        minHeight: theme.spacing(12),
        '& .MuiTypography-h4': {
          minHeight: '50px',
          color: theme.palette.typographyText.textWhite,
          fontSize: theme.spacing(1.5),
          fontWeight: 'bold',
          lineHeight: '1.1',
          letterSpacing: theme.spacing(0.25),
          textTransform: 'uppercase',
          textAlign: 'center',
          marginBottom: theme.spacing(2)
        },
        '& .MuiButtonBase-root': {
          width: '100%',
          maxWidth: theme.spacing(7.813),
          background: theme.palette.success.dark,
          border: `1px solid ${theme.palette.lightShade.main}`,
          borderRadius: theme.spacing(1.25),
          color: theme.palette.typographyText.textWhite,
          fontSize: theme.spacing(1),
          fontWeight: '700',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          padding: theme.spacing(0.5),
          margin: 'auto'
        },
        '& .MuiTypography-button': {
          color: theme.palette.typographyText.textWhite,
          fontSize: theme.spacing(0.75),
          fontWeight: '400',
          lineHeight: 'normal',
          textAlign: 'center',
          marginTop: theme.spacing(0.625),
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .form-wrap-2': {
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      '& .social-auth-wrap': {
        margin: `${theme.spacing(2, 0, 1)} !important`
      }
    }
  }
})

export const ForgotPasswordFormWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      '& .form-wrap': {
        maxWidth: theme.spacing(31.25),
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }
  }
})

export const ResetPasswordFormWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%'
  }
})
