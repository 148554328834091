import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useTranslation } from 'react-i18next'
import { LoginFormWrapper } from './style'
import {
  CustomFormLabel,
  CustomTextField
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import {
  SocialArrobaIcon,
  SocialFacebookIcon,
  SocialGooglePlusIcon,
  SocialOdnoklassnikiIcon,
  SocialVKIcon,
  SpinnerSVG
} from 'assets/icons/index'
import {
  CustomMainButton,
  CustomPrimaryButton
} from 'components/common-ui/CustomButton/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { facebookSignup, googleSingup, userLogin } from 'redux-thunk/thunk/auth.thunk'
import { Form, Formik } from 'formik'
import { userLoginSchema } from './schema'
import ErrorMessage from '../ErrorMessage/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { Buffer } from 'buffer'
import { getDeviceType } from 'utils/getDeviceType'
import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index'
import { defaultCurrency } from 'utils/constant'
import { playGame } from 'redux-thunk/thunk/game.thunk'
import { setAuthModal, setUserModal } from 'redux-thunk/redux/slices/auth.slice'
import ReactFacebookLogin from 'react-facebook-login'

const LoginForm = ({ setShowLoginForm, handleClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  )
  const langCode = useSelector((state) => state.language.selectedLanguage?.code?.toUpperCase())

  const [showPassword, setShowPassword] = useState(false)
  const [showFormWrap, setShowFormWrap] = useState(true)
  // const [showWelcomeTextBox, setShowWelcomeTextBox] = useState(false)
  const [showLoginError, setShowLoginError] = useState(false)
  const { showRandomGames } = useSelector((state) => state.gameSetting)
  const { randomGames } = useSelector((state) => state.games)
  const userData = useSelector((state) => state.user?.user)
  const navigate = useNavigate()
  const { REACT_APP_FACEBOOK_APP_ID } = process.env

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const onLoginSuccess = () => {
    setShowFormWrap(false)
    dispatch(setUserModal('deposit'))
    // setShowWelcomeTextBox(true)
    handleClose()
    if (showRandomGames) {
      const openGame = (gameCode, url) => {
        handleClose()
        navigate(`/casino/play-game/${gameCode}`, { state: { gameURL: url } })
      }
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: randomGames[0]?.casinoGameId,
        funMode: false,
        device: getDeviceType()
      }
      dispatch(playGame({ data, openGame, fromRandomGames: true }))
      navigate('/casino/play-game/random-game')
    }
  }
  const handleGoogleLoginSuccess = () => {
    setShowFormWrap(false)
    dispatch(setUserModal('deposit'))
    //dispatch(setAuthModal(''))
  }

  const handleFacebookLoginSuccess = () => {
    setShowFormWrap(false)
    dispatch(setUserModal('deposit'))
  }

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      dispatch(googleSingup({ code: tokenResponse.access_token, handleGoogleLoginSuccess, isLogin: true }))
    }
  })

  const handleFacebookCallback = (response) => {
      dispatch(facebookSignup({ code: response.accessToken, handleFacebookLoginSuccess, isLogin: true }))
    }

  const onLoginError = (errorMsg) => {
    setShowFormWrap(false)
    setShowLoginError(errorMsg)
  }

  const handleRetry = () => {
    setShowLoginError(false)
    setShowFormWrap(true)
  }

  const handleLoginSubmit = async ({ email, password }) => {
    dispatch(
      userLogin({
        userNameOrEmail: email,
        password: Buffer.from(password).toString('base64'),
        deviceType: getDeviceType(),
        onLoginSuccess,
        onLoginError
      })
    )
  }

  return (
    <>
      <LoginFormWrapper theme={theme}>
        {showFormWrap && (
          <Box className='form-wrap'>
            <Typography variant='h3' className='form-title'>
              {t('login')}
            </Typography>

            <Formik
              enableReinitialize
              initialValues={{ email: '', password: '' }}
              validationSchema={userLoginSchema(t)}
              onSubmit={({ email, password }) =>
                handleLoginSubmit({ email, password })}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => {
                return (
                  <Form>
                    <Box component='form' noValidate>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12} md={6}>
                          <Grid
                            container
                            rowSpacing={1.25}
                            columnSpacing={0.625}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <CustomFormLabel>
                                {t('signinUsername')} *
                              </CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type='email'
                                name='email'
                                placeholder={t('signinUsername')}
                                value={values.email}
                                onChange={(e) => {
                                  e.target.value = e.target.value.trim()
                                  handleChange(e)
                                }}
                              />
                              <ErrorMessage
                                component='p'
                                name='email'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <CustomFormLabel>
                                {t('signinPassword')} *
                              </CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                showPassword={showPassword}
                                // handleClickShowPassword={handleClickShowPassword}
                                // handleMouseDownPassword={handleMouseDownPassword}
                                placeholder={t('signinPassword')}
                                value={values.password}
                                onChange={(e) => {
                                  e.target.value = e.target.value.trim()
                                  handleChange(e)
                                }}
                                onBlur={handleBlur}
                                maxLength={50}
                                minLength={8}
                                haslabel='yes'
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={handleTogglePassword}
                                        edge='end'
                                      >
                                        {showPassword
                                          ? (
                                            <VisibilityIcon />
                                            )
                                          : (
                                            <VisibilityOffIcon />
                                            )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  autoComplete: 'new-password'
                                }}
                              />
                              <ErrorMessage
                                component='p'
                                name='password'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Typography
                                variant='button'
                                onClick={() => setShowLoginForm('forgot-pass')}
                              >
                                {t('signinForgetPassword')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box className='social-auth-wrap'>
                            <Typography variant='caption'>
                              {t('signupUseSocialAccount')}
                            </Typography>
                            <Box className='social-auth-box'>
                              {/* <SocialArrobaIcon />
                              <SocialOdnoklassnikiIcon /> */}
                              <SocialVKIcon />
                              <ReactFacebookLogin
                                  appId={REACT_APP_FACEBOOK_APP_ID} // we need to get this from facebook developer console by setting the app.
                                  autoLoad={false}
                                  // cssClass="my-facebook-button-class"
                                  // cssClass="my-facebook-button-class"
                                  cssClass='facebook-btn'
                                  textButton=''
                                  fields='name,email,picture'
                                  icon={<SocialFacebookIcon />}
                                  callback={handleFacebookCallback}
                                />
                              <SocialGooglePlusIcon onClick={() => login()} />
                            </Box>
                          </Box>
                          <Box className='aut-btn-box'>
                            <CustomPrimaryButton
                              fullWidth
                              type='submit'
                              onClick={handleSubmit}
                              disabled={loading}
                              fontSize={['DE', 'TR', 'FR'].includes(langCode) ? 'smd' : ['FI', 'RU'].includes(langCode) ? 'sm' : ''}
                            >
                              {!loading
                                ? (
                                  <>{t('login')}</>
                                  )
                                : (
                                  <SpinnerSVG style={{ fill: 'white' }} />
                                  )}
                            </CustomPrimaryButton>
                            <CustomMainButton
                              fullWidth
                              onClick={() => setShowLoginForm('signup')}
                              disabled={loading}
                              // fontSize={['FI', 'UK', 'DE'].includes(langCode) ? 'smd' : ''}
                              fontSize={['FI', 'PL', 'DE', 'TR', 'EN', 'ENAU', 'ENCA', 'ENUS', 'DA', 'UK', 'RU'].includes(langCode) ? 'smd' : ''}
                            >
                              {t('registration')}
                            </CustomMainButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        )}

        {/* {showWelcomeTextBox && (
          <Box className='welcome-text-box'>
            <Typography variant='body1' className='pera-text'>
              {t('loginWelcomeBonusInfo')}
            </Typography>
            <Typography variant='body1' className='pera-text'>
              {t('loginDepositBonusInfo')}
            </Typography>
            <Typography variant='body1' className='pera-text'>
              {t('loginFreespinBonusInfo')}
            </Typography>
            <Typography variant='body1' className='pera-text'>
              {t('loginMaximumBetLimitInfo')}
            </Typography>
            <CustomPrimaryButton fullWidth onClick={handleClose}>
              {t('gotIt')}
            </CustomPrimaryButton>
          </Box>
        )} */}
        {!!showLoginError && (
          <Box className='welcome-text-box'>
            <Typography variant='h3' className='form-title'>
              {t('incorrect')} {t('login')}
            </Typography>
            <Typography variant='body1' className='pera-text'>
              {showLoginError || t('incorrectCredsMessage')}
              <CustomPrimaryButton fullWidth onClick={handleRetry}>
                {t('retry')}
              </CustomPrimaryButton>
            </Typography>
          </Box>
        )}
      </LoginFormWrapper>
    </>
  )
}

export default React.memo(LoginForm)
