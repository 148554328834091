export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Bevorstehende',
  finished: 'Fertig',
  prizePool: 'Preis Pool',
  joinNow: 'Jetzt beitreten',
  joined: 'Beigetreten',
  startsIn: 'Beginnt in',
  endsIn: 'Endet in',
  ended: 'Turnier beendet',
  started: 'Turnier gestartet',
  seeMore: 'Mehr sehen',
  winPrizes: 'Gewinnen Sie spannende Preise',
  leaderBoard: 'Bestenliste',
  prize: 'Preis',
  aboutTournament: 'Über das Turnier',
  congratulationsMessage: 'Bravo! ',
  payments: 'Zahlung',
  tournaments: 'Turniere',
  shop: 'Geschäft',
  blog: 'Blog',
  referral: 'Verweisung',
  contacts: 'Kontakte',
  retry: 'Wiederholen',
  incorrectCredsMessage: 'Ihr Benutzername oder Passwort ist falsch. ',
  bonuses: 'Bonus',
  depositBonus: 'Einzahlungsbonus',
  voucher: 'Gutschein',
  gamlings: 'Glücksspiele',
  transactionHistory: 'Transaktionsverlauf',
  offers: 'Bietet an',
  editProfile: 'Profil bearbeiten',
  continue: 'Weitermachen',
  apply: 'Anwenden',
  male: 'Männlich',
  female: 'Weiblich',
  register: 'Registrieren',
  login: 'Anmeldung',
  grand: 'Großartig',
  major: 'Wesentlich',
  minor: 'Unerheblich',
  mini: 'Mini',
  crashGameTitle: 'Absturz',
  betAmount: 'Wettbetrag',
  profit: 'Profitieren',
  payout: 'Zielauszahlung',
  chance: 'Chance gewinnen',
  betBtn: 'Wette',
  autoCashout: 'Automatische Auszahlung',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Gewettet',
  nothingFound: 'Nichts gefunden',
  lost: 'Verloren',
  won: 'Gewonnen',
  streak: 'Strähne',
  highestWin: 'Höchster Gewinn',
  promotions: 'Werbeaktionen',
  latestPromotions: 'Neueste Aktionen',
  sponsorships: 'Patenschaften',
  highestLost: 'Höchster Verlust',
  highestBet: 'Höchster Einsatz',
  maxStreak: 'Max Streak',
  maxProfit: 'Maximaler Gewinn: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Ihr Wettverlauf ist leer',
  messagesCopyNotSupported: 'Ihr Browser unterstützt das automatische Kopieren in die Zwischenablage nicht. Bitte versuchen Sie, den Code manuell zu kopieren.',
  messagesCopied: 'Adresse kopiert.',
  deposit: 'Kaution',
  statReset: 'Statistiken zurücksetzen',
  rollOver: 'Sich umdrehen',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Zurück',
  inputFieldBtnMax: 'max',
  buttonTextPlaceBet: 'Wetteinsatz festlegen',
  buttonTextCancelBet: 'Wette stornieren',
  buttonTextEscapeBet: 'Auszahlen',
  buttonTextStartAutoBet: 'Starten Sie Autobet',
  buttonTextCancelAutoBet: 'Autobet abbrechen',
  buttonTextLoading: 'Wird geladen',
  tabsMyBets: 'Meine Wetten',
  tabsDeposit: 'Kaution',
  tabsCashout: 'Auszahlungen',
  tabsAllbets: 'Alle Wetten',
  tabsTopbets: 'Top-Wetten',
  tabsBonus: 'Boni',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Wettverlauf',
  timerTextCurrentPayout: 'Aktuelle Auszahlung',
  timerTextStarting: 'Beginnend in',
  timerTextSec: 'S',
  timerTextFlewAway: 'Abgestürzt',
  timerTextWait: 'Bitte warten..',
  timerTextPreparingRound: 'Vorbereitungsrunde',
  timerTextLoadingRound: 'Laderunde',
  comingSoon: 'Demnächst',
  labelsProfitOnWin: 'Profitieren Sie vom Sieg',
  inputsNoOfBets: 'Anzahl der Wetten',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Stornieren',
  buttonsMin: 'Mindest',
  buttonsMax: 'Max',
  prediction: 'Vorhersage',
  luckyNumber: 'Glückszahl',
  multiplier: 'Multiplikator',
  chanceToWin: 'Chance zu gewinnen',
  bets: 'Wetten',
  depositBtn: 'Kaution',
  rollUnder: 'Unterrollen',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Geschichte',
  navBarMyBets: 'Meine Wetten',
  navBarAllBets: 'Alle Wetten',
  navBarHighRollers: 'High Roller',
  navBarRoundHistory: 'Runde Geschichte',
  navBarTotal: 'Gesamteinsätze',
  topHeaderBetId: 'Wett-ID',
  topHeaderUser: 'Benutzer',
  topHeaderPayout: 'Auszahlung',
  topHeaderAmount: 'Menge',
  topHeaderTime: 'Zeit',
  topHeaderProfit: 'Profitieren',
  topHeaderRoundCrashed: 'Abgestürzt',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Wette',
  topHeaderRoundProfit: 'Profitieren',
  topHeaderRoundSignature: 'Unterschrift',
  roundHistoryTitle: 'SPIELINFORMATIONEN',
  roundHistoryDescGame: 'Spiel',
  roundHistoryDescCrashAt: 'Absturz am:',
  roundHistoryDescDate: 'Datum :',
  roundHistoryDescBetHeader: 'Wette',
  roundHistoryTableTitlePlayer: 'SPIELER',
  roundHistoryTableTitleBet: 'WETTE',
  roundHistoryTableTitleCashedOut: 'AUSGEZAHLT',
  roundHistoryTableTitleProfit: 'PROFITIEREN',
  roundHistoryTableTitleBetId: 'Wett-ID',
  roundHistoryMessagesNoBetHistory: 'Ihr Wettverlauf ist leer',
  placedBetsTabPlaced: 'Platzierte Wette',
  placedBetsTabPrev: 'Vorherige Wette',
  placedBetsTitleUser: 'Benutzer',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Wette',
  placedBetsTitleProfit: 'Profitieren',
  placedBetsTitleCashout: 'Auszahlen',
  placedBetsNoPlacedBet: 'Keine platzierten Wetten.',
  provablyFairContentCheckBtn: 'Überprüfen Sie die nachweisbare Fairness',
  provablyFairContentTitle: 'Nachweislich fair',
  provablyFairContentDesc: 'Das Ergebnis der Vier-Seiten-Führung kann als fair erwiesen werden. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Absturzrate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Absturzrate ohne x',
  provablyFairContentInputFieldsRoundHashTitle: 'Runder Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'z.B. ',
  provablyFairContentInputFieldsSubmitBtn: 'Signatur generieren',
  provablyFairContentSignature: 'Runde Signatur',
  provablyFairContentErrorsCrashRate: 'Bitte geben Sie die Nummer in das Feld „Absturzrate“ ein',
  resetPasswordTitle: 'Passwort zurücksetzen',
  resetPasswordNewPasswordTitle: 'Neues Kennwort',
  resetPasswordNewPasswordErrorsRequired: '*Passwort wird benötigt.',
  resetPasswordNewPasswordErrorsMinLength: 'Mindestens 8 Zeichen zulässig.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximal 16 Zeichen zulässig.',
  resetPasswordNewPasswordErrorsPattern: '*Das Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
  resetPasswordConfirmPasswordTitle: 'Bestätige das Passwort',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwörter stimmen nicht überein.',
  resetPasswordResetBtn: 'Zurücksetzen',
  passwordResetSuccess: 'Passwort erfolgreich geändert, bitte melden Sie sich an, um das Spielen zu genießen!',
  resetPasswordWentWrong: 'Etwas ist schief gelaufen!',
  levelGrand: 'GROSSARTIG',
  levelMajor: 'WESENTLICH',
  levelMinor: 'UNERHEBLICH',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Gewinnen Sie täglich 1000 $ ',
  bannerContentContest: 'Wettbewerbe jetzt ',
  bannerContentjoin: 'Nehmen Sie an unserem täglichen Wettwettbewerb teil und werden Sie zum Helden!  ',
  bannerContentPlayNow: 'jetzt spielen',
  bannerContentTitle: 'Jackpot gewinnen',
  bannerContentContent: 'Spiel mehr',
  bannerContentGameName: 'CRASH-SPIEL',
  bannerContentGameDesc: 'Spiel mehr',
  partners: 'Partner',
  gamesGoldenDragon: 'goldener Drache',
  gamesCrashGame: 'Crash-Spiel',
  gamesDice: 'Würfel',
  gamesHilo: 'Hilo',
  allBetsTitle: 'Alle Wetten',
  allBetsBetId: 'Wett-ID',
  allBetsUser: 'Benutzer',
  allBetsPayout: 'Auszahlung',
  allBetsAmount: 'Menge',
  allBetsTime: 'Zeit',
  allBetsProfit: 'Profitieren',
  LoginwithOtp: 'Melden Sie sich mit OTP an',
  EnterLoginOtp: 'Geben Sie Login Otp ein',
  submit: 'Einreichen',
  footerAboutUs: 'Über uns',
  footerFaq: 'FAQs',
  footerSitePolicy: 'Website-Richtlinie',
  footerResponsibleGambling: 'Verantwortungsvolles Glücksspiel',
  footerTerms: 'Geschäftsbedingungen',
  signinTitle: 'Anmeldung',
  signinUsername: 'Benutzername / E-Mail',
  signinPassword: 'Passwort',
  signinLoginBtn: 'Anmeldung',
  signinSignup: 'Melden Sie sich an!',
  signinForgetPassword: 'Haben Sie Ihr Passwort vergessen?',
  forgotPasswordMsg: 'Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
  emailLinkMsg: 'Der Link zum Zurücksetzen des Passworts wurde an Ihre Registrierungs-E-Mail-Adresse gesendet. Klicken Sie bitte auf den E-Mail-Link und legen Sie Ihr neues Passwort fest.',
  resetPasswardConfirm: 'Das Passwort wurde erfolgreich zurückgesetzt',
  signinDontHaveAccount: 'Sie haben noch kein Konto? ',
  signinCreateAccount: 'Benutzerkonto erstellen',
  signinErrorsUserNameRequired: '*Bitte geben Sie Benutzernamen/E-Mail ein.',
  signinErrorsUserNameMinLength: 'Mindestens 4 Zeichen zulässig.',
  signinErrorsUserNameMaxLength: 'Maximal 20 Zeichen zulässig.',
  signinErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Benutzernamen ein. Es sind nur Buchstaben und Zahlen zulässig.',
  signinErrorsPasswordRequired: '*Passwort wird benötigt.',
  signinErrorsPasswordMinLength: 'Mindestens 8 Zeichen zulässig.',
  signinErrorsPasswordMaxLength: 'Maximal 16 Zeichen zulässig.',
  signinErrorsPasswordPattern: '*Das Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
  signinSuccessfullyLoggedIn: 'Erfolgreich angemeldet.',
  signinResetMailSent: 'E-Mail zum Zurücksetzen des Passworts gesendet.',
  signinTryAgain: 'Etwas ist schief gelaufen. Bitte versuche es erneut.',
  signinResetPasswordUsernameErr: 'Bitte geben Sie Benutzernamen/E-Mail-Adresse ein, um das Passwort zurückzusetzen.',
  signinResetMailSentSuccessContent: 'Wir haben Ihnen per E-Mail einen Link zum Zurücksetzen des Passworts gesendet. ',
  signupTitle: 'Registrieren',
  signupUsername: 'Nutzername',
  signupEmail: 'Email',
  signupTermsConditions: 'Bedingungen',
  signupWalletConnect: 'Melden Sie sich mit Wallet an ',
  signupPassword: 'Passwort',
  signupEighteenPlus: 'Ich bin über 18 Jahre alt und stimme den Nutzungsbedingungen zu',
  signupRegisterBtn: 'Melden Sie sich an',
  signupCreateNewAccount: 'Neuen Account erstellen',
  signupFullName: 'Vollständiger Name',
  signupReferralCode: 'Referenzcode',
  signupConfirmPassword: 'Bestätige das Passwort',
  signupUseSocialAccount: 'Melden Sie sich über soziale Netzwerke an',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Ein Konto haben? ',
  signupLoginBtn: 'Anmeldung',
  signupErrorsUserNameRequired: '*Bitte geben sie einen Benutzernamen ein.',
  signupErrorsUserNameMinLength: 'Mindestens 4 Zeichen zulässig.',
  signupErrorsUserNameMaxLength: 'Maximal 20 Zeichen zulässig.',
  signupErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Benutzernamen ein. Es sind nur Buchstaben und Zahlen zulässig.',
  signupErrorsFullNameRequired: '*Bitte geben Sie den vollständigen Namen ein.',
  signupErrorsFullNamePattern: 'Bitte geben Sie einen gültigen vollständigen Namen ein. Es sind nur Buchstaben und Zahlen zulässig.',
  signupErrorsEmailRequired: '*Bitte geben Sie Ihre E-Mail-Adresse ein.',
  signupErrorsEmailMinLength: 'Mindestens 4 Zeichen zulässig.',
  signupErrorsEmailMaxLength: 'Maximal 20 Zeichen zulässig.',
  signupErrorsEmailPattern: 'Bitte eine gültige E-Mail-Adresse eintragen.',
  signupErrorsPasswordRequired: '*Passwort wird benötigt.',
  signupErrorsPasswordMinLength: 'Mindestens 8 Zeichen zulässig.',
  signupErrorsPasswordMaxLength: 'Maximale Einzahlung: 16 Zeichen erlaubt.',
  signupErrorsPasswordPattern: '*Das Passwort sollte aus 8 bis 16 Ziffern, alphanumerischen Zeichen (Groß- und Kleinbuchstaben) und Sonderzeichen bestehen. ',
  signupErrorsConfirmPasswordMatch: 'Passwort muss übereinstimmen.',
  signupPlaceholderUserName: 'Mindest. ',
  signupPlaceholderEmail: 'z.B. ',
  signupPlaceholderPassword: 'Mindest. ',
  signupSomethingWentWrong: 'Etwas ist schief gelaufen!',
  signupSignupGoogle: 'Melden Sie sich erfolgreich bei Google an.',
  signupRegistered: 'Erfolgreich registriert.',
  signupConfirmAge: 'Bitte bestätigen Sie, dass Sie 18 Jahre alt sind.',
  signupSignupFacebook: 'Melden Sie sich erfolgreich bei Facebook an.',
  signupCookiesConsent: 'Bitte erlauben Sie in den Einstellungen Ihres Browsers Cookies von Drittanbietern, um Social Login zu verwenden.',
  signupRegisterEmailPopupContent: 'Wir haben Ihnen eine Bestätigungs-E-Mail gesendet. ',
  accountMenuDeposit: 'Kaution',
  accountMenuCashout: 'Auszahlen',
  accountMenujackpotWinners: 'Jackpot-Gewinner',
  accountMenuTransactions: 'Transaktionen',
  accountMenuPromotions: 'Werbeaktionen',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Ausloggen',
  accountMenuSound: 'Klang',
  accountMenuMusic: 'Musik',
  accountMenuWithdraw: 'Zurückziehen',
  pageNotFound: 'Wir können die von Ihnen gesuchte Seite nicht finden!',
  error: 'Fehler',
  somethingWentWrong: 'Etwas ist schief gelaufen!',
  verifyEmailThankYou: 'Danke schön!',
  verifyEmailEmailIsVerified: 'Ihre E-Mail-Adresse ist bestätigt. ',
  verifyEmailGoToLogin: 'Gehen Sie zur Anmeldeseite',
  verifyEmailWentWrong: 'Etwas ist schief gelaufen!',
  verifyEmailTokenExpired: 'E-Mail bereits bestätigt oder Link abgelaufen. Bitte senden Sie den E-Mail-Bestätigungslink erneut, indem Sie unten Ihre registrierte E-Mail-Adresse angeben. ',
  verifyEmailGoToHome: 'Gehen Sie zur Startseite',
  verifyEmailSuccessMsg: 'E-Mail erfolgreich bestätigt.',
  bonusSectionSpanText: 'präsentiert das Großzügigste und Vielfältigste',
  bonusSectionBonuses: 'Boni',
  bonusSectionSubtitleText: 'Spielen Sie mit Vergnügen und erhalten Sie während des Spiels zusätzliche Belohnungen. ',
  JoiningBonusTitle: 'Beitrittsbonus',
  JoiningBonusButton: 'Beanspruche jetzt',
  JoiningBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  depositBonusTitle: 'Einzahlungsbonus',
  depositBonusButton: 'Kaution',
  depositBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  refferBonusTitle: 'Empfehlungsbonus',
  refferBonusButton: 'Kaution',
  refferBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  aboutSectionSpanText2: 'Im Online-Casino können Sie Ihre Lieblingsspiele entweder kostenlos oder gegen Kryptowährung spielen. ',
  aboutSectionSpanText3: ' In dieser virtuellen Welt des Bitcoin-Glücksspiels können Sie viele Möglichkeiten genießen – Ihre Lieblingscasinospiele spielen, Boni erhalten, an Wettbewerben und Werbeaktionen teilnehmen.',
  verifiedSectionTitle: 'Verifizierte und lizenzierte Software',
  verifiedSectionSpanText1: 'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
  faqSectionTitle: 'FAQ von Spielern',
  faqSectionForgetPasswordTitle: 'Ich habe mein Passwort vergessen.',
  faqSectionForgetPasswordDesc: 'Sie haben für Ihr Konto keine Wiederherstellungs-E-Mail-Adresse angegeben. ',
  faqSectionMyDepositCreditedTitle: 'Meine Anzahlung wurde nicht gutgeschrieben.',
  faqSectionMyDepositCreditedDesc: ' Platzhalterinhalt für dieses Akkordeon, der die Klasse demonstrieren soll. ',
  SupportedCurrencyTitle: 'Unterstützte Währung',
  SupportedCurrencySpanText1: 'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
  CarouselSectionTitle: 'HODL-ABSTURZ',
  CarouselSectionSubTitle: ' Spielen Sie mehr Spiele und erhalten Sie die Chance zu gewinnen. ',
  WalletSettingTitle: 'Wallet-Einstellung',
  WalletSettingHideZero: 'Nullsalden ausblenden',
  WalletSettingHideZeroInfo: 'Ihr Nullsaldo wird nicht in Ihrer Brieftasche angezeigt',
  WalletSettingDisplayFiat: 'Krypto in Fiat anzeigen',
  WalletSettingDisplayFiatInfo: 'Alle Wetten',
  WalletSettingNoteForApprox: 'Bitte beachten Sie, dass es sich hierbei um Währungsnäherungen handelt.',
  WalletSettingSave: 'Speichern',
  WalletSettingNoWalletFound: 'Keine Wallet gefunden',
  jackpotWinnersNoWinnerAvailable: 'Keine Gewinner verfügbar',
  jackpotWinnersNew: 'Neu',
  jackpotWinnersHeader: 'Jackpot-Gewinner',
  jackpotDetailsHeader: 'Jackpot-Detail',
  jackpotDetailsCampaignPeriod: 'Kampagnenzeitraum',
  jackpotDetailsFrom: 'Aus',
  jackpotDetailsTo: 'Zu',
  jackpotDetailsBettingRule: 'Wettregel',
  jackpotDetailsMinBetAmount: 'Mindesteinsatzbetrag',
  jackpotDetailsMaxBetAmount: 'Maximaler Einsatzbetrag',
  jackpotDetailsBetPercentage: 'Einsatzprozentsatz',
  jackpotDetailsWinningRuleHeader: 'Gewinnregel',
  jackpotDetailsWinningRule: 'Das Platzieren von Wetten mit einem höheren Einsatzbetrag erhöht die Gewinnchancen.',
  jackpotDetailsProfitRule: 'Wenn der Einsatzbetrag zwischen minBetAmount und maxBetAmount liegt, wird betPercentage % des Einsatzbetrags zum Jackpot-Betrag addiert.',
  profileTitle: 'Persönliche Daten',
  profileFName: 'Vorname',
  profileLname: 'Familienname, Nachname',
  profileUserName: 'Nutzername',
  profileDob: 'Geburtsdatum',
  profileEmail: 'E-Mail-Adresse',
  profilePhone: 'Telefonnummer',
  profileChange: 'Ändern',
  profileSave: 'Änderungen speichern',
  profilePassword: 'Passwort',
  profileSubmit: 'Einreichen',
  profileUploadProfile: 'Profil hochladen',
  profileCurrentPassword: 'Aktuelles Passwort',
  profileNewPassword: 'Neues Kennwort',
  profileConfirmPassword: 'Bestätige das Passwort',
  profileScanQRCode: 'QR-Code scannen',
  profileEnterSecretCode: 'Geben Sie den Geheimcode ein',
  profileErrorsFirstNameRequired: 'Vorname ist erforderlich.',
  profileErrorsFirstNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsFirstNameMinLength: 'Mindestens 3 Zeichen zulässig.',
  profileErrorsFirstNameMaxLength: 'Maximal 20 Zeichen zulässig.',
  profileErrorsLastNameRequired: 'Nachname ist erforderlich.',
  profileErrorsLastNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsLastNameMinLength: 'Mindestens 3 Zeichen zulässig.',
  profileErrorsLastNameMaxLength: 'Maximal 20 Zeichen zulässig.',
  profileErrorsChangePasswordOldRequired: 'Altes Passwort ist erforderlich.',
  profileErrorsChangePasswordOldPattern: 'Bitte geben Sie ein gültiges Passwort ein.',
  profileErrorsChangePasswordOldMinLength: 'Mindestens 8 Zeichen zulässig.',
  profileErrorsChangePasswordOldMaxLength: 'Maximal 16 Zeichen zulässig.',
  profileErrorsChangePasswordNewRequired: 'Neues Passwort ist erforderlich.',
  profileErrorsChangePasswordNewPattern: 'Bitte geben Sie ein gültiges Passwort ein.',
  profileErrorsChangePasswordNewMinLength: 'Mindestens 8 Zeichen zulässig.',
  profileErrorsChangePasswordNewMaxLength: 'Maximal 16 Zeichen zulässig.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Passwörter stimmen nicht überein.',
  profileErrorsChangePasswordNewPasswordMatched: 'Das neue Passwort darf nicht mit dem aktuellen Passwort identisch sein.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Zur Bestätigung ist ein Passwort erforderlich.',
  profileErrorsUserNameRequired: 'Benutzername wird benötigt.',
  profileErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsUserNameMinLength: 'Mindestens 3 Zeichen zulässig.',
  profileErrorsUserNameMaxLength: 'Maximal 20 Zeichen zulässig.',
  profileErrorsDobRequired: 'Geburtsdatum ist erforderlich.',
  profileErrorsEmailRequired: 'E-Mail ist erforderlich.',
  profileErrorsPostcodeRequired: 'Postleitzahl ist erforderlich.',
  profileErrorsCityRequired: 'Stadt ist erforderlich.',
  profileErrorsCurrencyRequired: 'Währung ist erforderlich.',
  profileErrorsAddressRequired: 'Adresse ist erforderlich.',
  profileErrorsCountryRequired: 'Land ist erforderlich.',
  profileErrorsEmailPattern: 'Bitte eine gültige E-Mail-Adresse eintragen.',
  profileErrorsEmailMinLength: 'Mindestens 3 Zeichen zulässig.',
  profileErrorsEmailMaxLength: 'Maximal 20 Zeichen zulässig.',
  profileMessagesProfileUpdate: 'Profil erfolgreich aktualisiert.',
  profileMessagesChangePassword: 'Passwort erfolgreich aktualisiert.',
  profileProfilePicProfilePicUpload: 'Profilbild hochladen',
  profileNoNewChanges: 'Profil konnte nicht aktualisiert werden! ',
  profileLoggedInWithSocial: 'Sie haben sich mit Social Login angemeldet.',
  profileSideNavigatorSound: 'Klang',
  profileSideNavigatorMusic: 'Musik',
  profileSideNavigatorProfile: 'Mein Profil',
  profileSideNavigatorBetHistory: 'Wettverlauf',
  profileSideNavigatorTransactions: 'Transaktionen',
  profileSideNavigatorDeposit: 'Kaution',
  profileSideNavigatorWithdraw: 'Zurückziehen',
  profileSideNavigatorGameLimits: 'Spiellimits',
  profileSideNavigatorReferral: 'Verweisung',
  profileSideNavigatorChangePassword: 'Kennwort ändern',
  profileSideNavigatorTermsConditions: 'Bedingungen',
  profileSideNavigatorLogOut: 'Ausloggen',
  profileSideNavigatorProvablyFair: 'Nachweislich fair',
  WalletInfoSectionNotAvailable: 'Nicht verfügbar',
  WalletInfoSectionRealBalance: 'Echtes Gleichgewicht',
  GameLimitsMinimumBet: 'Mindesteinsatz',
  GameLimitsMaximumBet: 'Maximaler Einsatz',
  GameLimitsMaxWinFor1Bet: 'Maximaler Gewinn bei einer Wette',
  ProvablyFairSubTitle: 'Dieses Spiel verwendet Provably Fair-Technologie, um das Spielergebnis zu bestimmen. ',
  ProvablyFairMaximumBet: 'Maximaler Einsatz:',
  ProvablyFairMaxWinFor1Bet: 'Maximaler Gewinn bei einer Wette',
  promotionsTitle: 'Werbeaktionen',
  promotionsNoLosingData: 'Es sind leider keine Verlustbonus-Aktionen verfügbar ',
  promotionsNoDepositData: 'Leider sind Bonusaktionen ohne Einzahlung verfügbar für ',
  promotionsCurrency: ' Währung.',
  promotionsViewBtn: 'Sicht',
  promotionsClaimSuccess: 'Sie haben Ihren Anspruch erfolgreich geltend gemacht',
  promotionsAvailability: 'Verfügbarkeit: ',
  promotionsAvailabilityTabLosing: 'Verlustbonus',
  promotionsAvailabilityTabDeposit: 'Einzahlungsbonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Mindest. ',
  promotionsDepositBonusTablePercentage: 'Prozentsatz',
  promotionsDepositBonusTableMaxBonus: 'Maximaler Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover-Multiplikator',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Maximaler Rollover pro Wette',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Zeit, das Rollover-Ziel zu erreichen',
  promotionsBonusCode: 'BONUS-CODE: ',
  promotionsLossesClaim: 'Verluste können (innerhalb des Aktionszeitraums) geltend gemacht werden für: ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Prozentsatz',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Mindest. ',
  promotionsBonusStatus: 'Bonusstatus: ',
  promotionsBonusAmount: 'Bonusbetrag: ',
  promotionsRolloverTarget: 'Rollover-Ziel: ',
  promotionsRolloverAchieved: 'Rollover erreicht: ',
  promotionsActivatebtn: 'AKTIVIEREN SIE',
  promotionsClaimBtn: 'BEANSPRUCHEN',
  promotionsCancelBtn: 'STORNIEREN',
  promotionsBackBtn: 'ZURÜCK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Bedingungen',
  promotionsClaimDepositBonusTitle: 'Schritte zur Inanspruchnahme des Einzahlungsbonus',
  promotionsClaimDepositBonusFirst: 'Bonuscode aktivieren',
  promotionsClaimDepositBonusSecond: 'Zahlen Sie den Betrag auf das Wallet ein',
  promotionsClaimDepositBonusThird: 'Erreichen Sie das Rollover-Ziel für die Inanspruchnahme des Bonus',
  promotionsClaimLosingBonusTitle: 'Schritte zur Inanspruchnahme des Verlustbonus',
  promotionsClaimLosingBonusFirst: 'Bonuscode aktivieren',
  promotionsClaimLosingBonusSecond: 'Spielen Sie Ihre Lieblingsspiele',
  promotionsClaimLosingBonusThird: 'Klicken Sie auf „Verluste geltend machen“, um Verluste innerhalb des Kampagnenzeitraums geltend zu machen',
  promotionsWentWrong: 'Etwas ist schief gelaufen!',
  transactionTitle: 'Transaktionen',
  transactionBetId: 'Wett-ID',
  transactionUser: 'Benutzer',
  transactionPayout: 'Auszahlung',
  transactionAmount: 'Menge',
  transactionProfit: 'Profitieren',
  transactionCashout: 'Auszahlen',
  transactionBet: 'Wette',
  transactionMultix: 'Multi.x',
  transactionWin: 'Gewinnen',
  transactionFairness: 'Gerechtigkeit',
  transactionReferFriend: 'Empfehlen Sie einen Freund',
  transactionTotal: 'Gesamteinsätze',
  transactionWins: 'Gewinnt',
  transactionRefresh: 'Aktualisierung',
  transactionFilterTopMultipliers: 'Top-Multiplikatoren',
  transactionFilterHugeWins: 'Riesige Siege',
  transactionFilterBiggestWins: 'Größte Siege',
  transactionFilterMultipliers: 'Multiplikatoren',
  transactionRealBalance: 'Echtes Gleichgewicht',
  depositWithdrawTXDate: 'Datum',
  depositWithdrawTXAmount: 'Menge',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Art der Transaktion',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Keine Daten verfügbar.',
  depositTitle: 'Kaution',
  depositHistory: 'Geschichte',
  depositSubTitle: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  depositCopyNotSupported: 'Ihr Browser unterstützt das automatische Kopieren in die Zwischenablage nicht. Bitte versuchen Sie, den Code manuell zu kopieren.',
  depositCopied: 'Adresse kopiert.',
  depositAddressCreated: 'Adresse erfolgreich erstellt.',
  depositGenerateAddress: 'Adresse generieren',
  depositWarningText: 'Bitte zahlen Sie NICHT per Cross-Chain-Überweisung ein',
  withdrawTitle: 'Zurückziehen',
  withdrawSubTitle: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  withdrawNoteDesc: 'Bitte stellen Sie sicher, dass Sie KEINE BEP2- oder BEP20-(BSC)-Adresse als LTC-Abhebungsadressen-Wallets eingeben',
  withdrawNoteSublist: ' Bitte ziehen Sie NICHT als Cross-Chain-Transfer ab',
  withdrawHistory: 'Geschichte',
  withdrawWithdrawBtn: 'Antrag zurückziehen',
  withdrawFees: 'Abhebegebühr {{fees}} {{token}}',
  withdrawWithdrawPending: 'Ihr Auszahlungsantrag steht noch aus. ',
  withdrawEnterFields: 'Geben Sie den Auszahlungsbetrag und Ihre Wallet-Adresse ein.',
  withdrawSelectWallet: 'Bitte wählen Sie die Wallet zum Abheben aus.',
  withdrawAmountFieldTitle: 'Betrag (Min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Dein {{wallet.currency.code}} Adresse',
  withdrawAddressFieldPlaceholder: 'Geben Sie Ihre Adresse ein',
  withdrawNoBalance: 'Nicht genug Gleichgewicht.',
  withdrawMinAmount: 'Bitte geben Sie einen Betrag ein, der größer oder gleich ist {{min}} .',
  fiatCurrencyInfo: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  paginationNextBtn: 'Nächste',
  paginationPrevBtn: 'Vorherige',
  wentWrong: 'Etwas ist schief gelaufen.',
  referralTitle: 'Verweisung',
  referralSubTitle: 'Empfehlen Sie Ihre Freunde',
  referralEarn: ' Verdienen Sie jeweils ₹150',
  referralReferAndEarn: 'Empfehlen Sie und verdienen Sie einen Bonus für jede Empfehlung',
  referralReferPara: ' Wenn Sie diesen Code an einen Benutzer weitergeben',
  referralReferLink: 'Siehe Link',
  referralCopyReferralCode: 'Empfehlungscode kopieren',
  referralTotalRewards: 'Gesamtbelohnung',
  TwoWayAuthentication: 'Zwei-Wege-Authentifizierung',
  LoginRequired: 'Anmeldung erforderlich!',
  BetPlacedSuccessfully: 'Wette erfolgreich platziert',
  BetAddedInQueueSuccessfully: 'Wette erfolgreich zur Warteschlange hinzugefügt',
  BetRemovedFromQueueSuccessfully: 'Die Wette wurde erfolgreich aus der Warteschlange entfernt',
  BetCancelledSuccessfully: 'Wette erfolgreich abgebrochen',
  PlayerEscapedSuccessfully: 'Der Spieler ist erfolgreich entkommen',
  CopiedSuccessfull: 'Erfolgreich kopiert',
  NotEnoughBalance: 'Nicht genug Gleichgewicht',
  AutoBetStarted: 'Automatische Wette gestartet ',
  AutoBetFinished: 'Automatische Wette beendet ',
  IdCopied: 'Ich habe es kopiert',
  usersConsent: 'Durch den Zugriff auf diese Website bestätige ich, dass ich 18 Jahre alt bin und sie gelesen, verstanden und akzeptiert habe',
  termsOfService: 'Nutzungsbedingungen',
  email: 'Email',
  playNow: 'jetzt spielen',
  orContinueWith: 'Oder fahren Sie fort mit',
  metamaskExtensionNotAvailable: 'Bitte installieren Sie die MetaMask-Erweiterung und aktualisieren Sie die Seite.',
  cashier: 'KASSIERER',
  account: 'KONTO',
  hello: 'Hallo',
  balance: 'Gleichgewicht',
  referrals: 'Empfehlungen',
  settings: 'Einstellungen',
  withdrawals: 'Abhebungen',
  demoWallet: 'Demo-Wallet',
  myAccount: 'Mein Konto',
  wallet: 'Geldbörse',
  user: 'Benutzer',
  USD: 'US Dollar',
  amountIsRequired: 'Betrag ist erforderlich',
  minDepositAmount: 'Der Einzahlungsbetrag muss größer sein als {{min}}.',
  maxDepositAmount: 'Der Einzahlungsbetrag muss geringer sein als {{max}}.',
  minWithdrawAmount: 'Der Auszahlungsbetrag muss größer sein als {{min}}',
  maxWithdrawAmount: 'Der Abhebungsbetrag muss kleiner sein als {{max}}',
  withdrawMustLessThanWallet: 'Der Auszahlungsbetrag muss geringer sein als Ihr Wallet-Betrag',
  accountTypeIsRequired: 'Kontotyp ist erforderlich.',
  mobileNumberLength: 'Die Handynummer muss lauten {{length}} Ziffern',
  phoneIsRequired: 'Telefon ist erforderlich',
  cpfError: 'CPF muss sein {{length}} Figuren',
  cpfRequiredError: 'CPF ist erforderlich',
  cnpjError: 'CNPJ muss von sein {{length}} Figuren',
  mainMenu: 'HAUPTMENÜ',
  casino: 'Kasino',
  sports: 'Sport',
  crypto: 'Krypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'VERDIENEN SIE BELOHNUNGEN',
  rankUp: 'Rangaufstieg',
  inviteFriends: 'Freunde einladen',
  partnerProgram: 'Partnerprogramm',
  home: 'Heim',
  sportsbook: 'Sportwetten',
  menu: 'Speisekarte',
  liveBets: 'LIVE-WETTEN',
  allBets: 'Alle Wetten',
  highRollers: 'High Roller',
  luckyBets: 'Glückswetten',
  trade: 'Handel',
  game: 'SPIEL',
  player: 'SPIELER',
  time: 'ZEIT',
  wager: 'WETTE',
  multiplierBan: 'MULTIPLIKATOR',
  payoutText: 'AUSZAHLUNG',
  bannerHeadOne: 'Erleben Sie echte Innovation auf höchstem Niveau',
  bannerHeadTwo: 'Prämienprogramm innerhalb der Branche.',
  registerNow: 'Jetzt registrieren',
  liveWins: 'LIVE GEWINNT',
  monthly: 'Monatlich',
  weekly: 'Wöchentlich',
  daily: 'Täglich',
  winner1: 'Gewinner 1',
  winner2: 'Gewinner 2',
  winner3: 'Gewinner 3',
  spin: 'Drehung',
  spinsWon: 'Gewonnene Drehungen',
  joinedOn: 'Beigetreten am',
  wageringAmount: 'Einsatzhöhe',
  name: 'Name',
  allGames: 'Alle Spiele',
  allSports: 'Alle Sportarten',
  cryptoFutures: 'Krypto-Futures',
  tradeNow: 'Handel jetzt',
  allTables: 'Alle Tische',
  nft: 'NFT',
  viewAllGames: 'Alle Spiele anzeigen',
  viewAll: 'Alles sehen',
  rewards: 'BELOHNUNG',
  profile: 'Profil',
  totalBets: 'Gesamteinsätze',
  totalWagered: 'Gesamteinsatz',
  joinDate: 'Beitrittsdatum',
  displaySocialLinks: 'Verknüpfte soziale Konten anzeigen',
  privateProfile: 'Privates Profil',
  rank: 'Rang',
  winner: 'Gewinner',
  nextRank: 'Nächster Rang',
  changeUsername: 'Benutzernamen ändern',
  newPassword: 'Neues Kennwort',
  changeName: 'Namen ändern',
  linkSocialAccount: 'Soziales Konto verknüpfen',
  privateProfileTooltip: 'Nachdem Sie Ihr Profil privat gemacht haben, können andere Benutzer Ihren Benutzernamen neben Wetten, Rennen usw. nicht sehen.',
  socialLinksTooltip: 'Inhalt nicht verfügbar',
  rankTooltip: 'Inhalt nicht verfügbar',
  balances: 'Salden',
  createReferralBalance: 'Empfehlungsguthaben erstellen',
  total: 'Gesamt',
  switchBalance: 'Balance wechseln',
  mainAccount: 'Hauptaccount',
  tips: 'Tipps',
  receivedAmount: 'Erhaltener Betrag',
  dateOrTime: 'Terminzeit',
  view: 'Sicht',
  allChains: 'Alle Ketten',
  creditAmount: 'Kreditbetrag',
  setCustomReferralCode: 'Legen Sie einen benutzerdefinierten Empfehlungscode fest',
  changeEmail: 'Ändern Sie die E-Mail',
  newEmail: 'Neue e-mail',
  createPassword: 'Passwort erstellen',
  newUsername: 'Neuer Benutzername',
  username: 'Nutzername',
  changePassword: 'Kennwort ändern',
  updateProfileMsg: 'Profil erfolgreich aktualisiert',
  twoFactorAuthentication: 'Zwei-Faktor-Authentifizierung',
  twoFactorAuthenticationTitle: 'Die Verwendung der Zwei-Faktor-Authentifizierung wird dringend empfohlen, da sie Ihr Konto sowohl mit Ihrem Passwort als auch mit Ihrem Telefon schützt.',
  twoFactorAuthenticationSubTitle: 'Zur Aktivierung von 2FA müssen eine E-Mail-Adresse und ein Passwort festgelegt werden. ',
  enable2fa: 'AKTIVIEREN SIE 2FA',
  verifyIdentityByKyc: 'Verifizieren Sie Ihre Identität (kyc)',
  oldPassword: 'Altes Kennwort',
  live: 'Live',
  soccer: 'Fußball',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ice Hokey',
  volleyball: 'Volleyball',
  tableTennis: 'Tischtennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boxen',
  myBets: 'Meine Wetten',
  favourites: 'Favoriten',
  americanFootball: 'American Football',
  buyCrypto: 'Krypto kaufen',
  oldEmail: 'Alte Email',
  loggedInSuccess: 'Erfolgreich eingeloggt',
  loggedOutSuccess: 'Erfolgreich abgemeldet',
  wagerLimit: 'Einsatzlimit erfolgreich festgelegt',
  lossLimit: 'Verlustlimit erfolgreich gesetzt',
  accountDisable: 'Konto erfolgreich deaktiviert',
  sessionTimeout: 'Sitzungszeitlimit erfolgreich festgelegt',
  signup: 'Anmeldung erfolgreich',
  depositLimit: 'Einzahlungslimit erfolgreich festgelegt',
  tokenVerify: 'Das Token wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie es',
  emailVerify: 'E-Mail wurde verifiziert',
  emailChangedSuccess: 'E-Mail wurde erfolgreich geändert',
  profileUpdatedSuccess: 'Profil aktualisiert',
  otpVerified: 'OTP verifiziert',
  twoFADeactivated: '2FA-Authentifizierung deaktiviert',
  internalServerError: 'Interner Serverfehler',
  unAuthorized: 'Nicht autorisiert',
  dataNotFound: 'Daten nicht gefunden',
  setReferralCode: 'Empfehlungscode festlegen...',
  ultimate: 'ULTIMATIV',
  notFound: 'Nicht gefunden',
  casinoTransactions: 'Casino-Transaktionen',
  sportsbookTransactions: 'Sportwetten-Transaktionen',
  status: 'Status',
  success: 'Erfolg',
  failed: 'Fehlgeschlagen',
  pending: 'Ausstehend',
  date: 'Datum',
  to: 'Zu',
  gameName: 'Spielname',
  transactionId: 'Transaktions-ID',
  roundId: 'Runde ID',
  bet: 'Wette',
  win: 'Gewinnen',
  transactionType: 'Art der Transaktion',
  at: 'bei',
  downloadAll: 'Alles herunterladen',
  gameText: 'Spiel',
  betId: 'Wett-ID',
  gameId: 'Spiel-ID',
  actionType: 'Aktionstyp',
  searchGames: 'Suchspiele',
  provider: 'Anbieter',
  search: 'Suchen',
  sortBy: 'Sortiere nach',
  popular: 'Beliebt',
  challengePool: 'Challenge-Pool',
  loadMore: 'Mehr laden',
  bitcoin: 'Bitcoin',
  chat: 'Plaudern',
  trades: 'Gewerbe',
  battles: 'Kämpfe',
  loginToChat: 'Melden Sie sich an, um zu chatten',
  rules: 'Regeln',
  chatRule1: 'Belästigen oder beleidigen Sie andere Benutzer nicht',
  chatRule2: 'Bitten Sie nicht um Kredite, Wissenswertes oder Trinkgeld',
  chatRule5: 'Machen Sie keine Werbung für irgendeine Form des Handels, Kaufs oder Verkaufs von Dienstleistungen',
  chatRule6: 'Teilen oder bewerben Sie Ihren Empfehlungscode nicht',
  chatRule7: 'Bitten Sie nicht darum, Mitarbeiter zu werden',
  chatRule8: 'Nur Englisch',
  chatRule9: 'Respektiere Mods, Admins und andere Benutzer',
  send: 'Schicken',
  expand: 'Expandieren',
  signIn: 'Anmelden',
  cross: 'Kreuzen',
  collapse: 'Zusammenbruch',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'Von den Favoriten entfernen',
  addToFavourite: 'Zu Favoriten hinzufügen',
  footerAboutSite: `Lassen Sie uns ein wenig über Wissenschaft sprechen? Die Website von Cash Machine
Casino ist ein Teil von Ihnen, da wir alle aus den gleichen Atomen bestehen. Ihre Interaktion
damit bestätigt diese Verbindung.`,
  footerRightsReserved: ' Сash Machine Casino alle Rechte vorbehalten.',
  signupFirstName: 'Vorname',
  signupAddress: 'Adresse',
  signupCity: 'Stadt',
  signupPostcode: 'Postleitzahl ',
  signupCounty: 'Land',
  signupCurrency: 'Währung',
  signupGender: 'Geschlecht',
  signupMan: 'Mann',
  signupWomen: 'Frauen',
  signupOther: 'Andere',
  signupLoginDetails: 'Anmelden unter Details',
  signupPersonalDetails: 'Persönliche Daten',
  signupConfirm: 'Bestätigen',
  signupPrivacyPolicy: 'Ich habe die Datenschutzerklärung gelesen und akzeptiert',
  signupTermAndConditions: 'Ich bin mit der Verarbeitung meiner personenbezogenen Daten einverstanden. ',
  signupNewsLetter: 'Ich möchte regelmäßig Boni, Werbeaktionen, Neuigkeiten oder andere relevante Informationen per Newsletter erhalten.',
  signupSms: 'Ich möchte regelmäßig Boni, Werbeaktionen, Neuigkeiten oder andere relevante Informationen per SMS erhalten.',
  initialHeaderContent: 'Erhalten Sie erstaunliche 100 %',
  secoundaryHeaderContent: 'Willkommensbonus bis zu 100 $',
  playNowButton: 'jetzt spielen',
  registerButtonContent: 'Registrieren',
  results: 'Ergebnisse',
  refresh: 'Aktualisierung',
  cancel: 'Stornieren',
  select: 'Wählen',
  current: 'Aktuell',
  set: 'Satz',
  remove: 'Entfernen',
  save: 'Speichern',
  not: 'nicht',
  before: 'Vor',
  after: 'Nach',
  action: 'Aktion',
  withdrawal: 'Rückzug',
  requested: 'Angefordert',
  withdraw: 'Zurückziehen',
  areYouSure: 'Bist du sicher',
  yes: 'Ja',
  no: 'NEIN',
  cancelled: 'Abgesagt',
  approved: 'Genehmigt',
  refunded: 'Erstattet',
  chargeback: 'Rückbuchung',
  processedOn: 'Verarbeitet am',
  transaction: 'Transaktion',
  dateTime: 'Terminzeit',
  more: 'Mehr',
  fromDate: 'Ab Datum',
  toDate: 'Miteinander ausgehen',
  percentage: 'Prozentsatz',
  info: 'Die Info',
  min: 'Mindest',
  max: 'Max',
  change: 'Ändern',
  continueToSite: 'Weiter zur Website',
  choose: 'Wählen',
  enterWithdrawalAmount: 'Wählen Sie Ihren Auszahlungsbetrag oder geben Sie ihn ein',
  capDeposit: 'KAUTION',
  capWithdrawal: 'RÜCKZUG',
  capAmount: 'MENGE',
  minimum: 'Minimum',
  required: 'Erforderlich',
  of: 'von',
  isRequired: 'ist erforderlich',
  isAllowed: 'ist erlaubt',
  method: 'Methode',
  capWithdraw: 'ZURÜCKZIEHEN',
  showLess: 'Zeige weniger',
  clearFilter: 'Filter löschen',
  quantity: 'Menge',
  reset: 'Zurücksetzen',
  incorrect: 'Falsch',
  load: 'Belastung',
  applied: 'Angewandt',
  ok: 'OK',
  list: 'Aufführen',
  all: 'Alle',
  favorite: 'Favorit',
  endPointNotFound: 'Mit der Netzwerkverbindung stimmt etwas nicht',
  category: 'Kategorie',
  emailUserNameRequired: 'E-Mail/Benutzername erforderlich',
  emailUserNamePlaceHolder: 'Geben Sie Ihre E-Mail-Adresse oder Ihren Benutzernamen ein',
  passwordRequired: 'Passwort erforderlich',
  accountFrozen: 'Ihr Konto ist bis dahin gesperrt',
  resendEmail: 'E-Mail zurücksenden',
  resendLink: 'LINK ERNEUT SENDEN',
  userLoggedOut: 'Erfolgreich abgemeldet',
  emailAlreadyRegistered: 'Diese E-Mail ist bereits registriert',
  userNameAlreadyTaken: 'Dieser Benutzername ist bereits vergeben',
  fillAllFields: 'Füllen Sie alle Felder aus, bevor Sie mit dem nächsten Schritt fortfahren',
  pleaseAccept: 'Bitte akzeptiere',
  acceptPrivacyPolicy: 'Datenschutzrichtlinie',
  acceptTerms: 'Geschäftsbedingung',
  modeOfComm: ' und Art der Kommunikation',
  beforeNextStep: 'bevor Sie mit dem nächsten Schritt fortfahren',
  userNamePlaceholder: 'Geben Sie den Benutzernamen ein',
  min8Characters: 'Mindestens 8 Zeichen',
  min1Number: 'Mindestens 1 Zahl',
  min1LowerCase: 'Mindestens 1 Kleinbuchstabe',
  min1UpperCase: 'Mindestens 1 Großbuchstabe',
  min1SpecialChar: 'Mindestens 1 Sonderzeichen',
  passwordTip: 'Das Passwort muss mindestens acht Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
  passwordPlaceholder: 'Passwort eingeben',
  confirmPasswordPlaceholder: 'Geben Sie das Bestätigungskennwort ein',
  acceptAll: 'Akzeptiere alle',
  firstNamePlaceholder: 'Bitte Vornamen eingeben',
  lastNamePlaceholder: 'Nachnamen eingeben',
  addressPlaceholder: 'Adresse eingeben',
  cityPlaceholder: 'Stadt betreten',
  postCodePlaceholder: 'Geben Sie die Postleitzahl ein',
  phonePlaceholder: 'Telefonnummer eingeben',
  invalidEmail: 'Ungültige E-Mail',
  emailRequired: 'Email (erforderlich',
  confirmPasswordRequired: 'Passwort bestätigen erforderlich',
  firstNameRequired: 'Vorname (erforderlich',
  lastNameRequired: 'Nachname erforderlich',
  phoneRequired: 'Telefon erforderlich',
  dateOfBirthRequired: 'Geburtsdatum erforderlich',
  genderRequired: 'Geschlecht erforderlich',
  userNameRequired: 'Benutzername erforderlich',
  addressRequired: 'Adresse erforderlich',
  cityRequired: 'Stadt erforderlich',
  postCodeRequired: 'Postleitzahl erforderlich',
  currencyCodeRequired: 'Währungscode erforderlich',
  countryRequired: 'Land erforderlich',
  matchPassword: 'Passwort muss übereinstimmen',
  fName3Chars: 'Der Vorname muss mindestens 3 Zeichen lang sein',
  onlyAlphabet: 'Nur Alphabete erlaubt',
  lName3Chars: 'Der Nachname muss mindestens 3 Zeichen lang sein',
  mustBe18: 'Sie müssen 18 Jahre alt sein, um sich registrieren zu lassen',
  mustBeUnder200: 'Sie müssen unter 200 Jahre alt sein, um registriert zu werden',
  validDOB: 'Bitte geben Sie ein gültiges Geburtsdatum ein',
  max20Characters: 'Maximal 20 Zeichen erlaubt',
  min3Characters: 'Mindestens 3 Zeichen erforderlich',
  max100Characters: 'Maximal 100 Zeichen erlaubt',
  max50Characters: 'Maximal 50 Zeichen erlaubt',
  invalidNumber: 'Ungültige Nummer',
  preferredLanguageRequired: 'Bevorzugte Sprache erforderlich',
  invalidPass: 'Ungültiges Passwort',
  prevBtn: 'Vorherige',
  dayRequired: 'Tag ist erforderlich',
  monthRequired: 'Monat ist erforderlich',
  yearRequired: 'Jahr ist erforderlich',
  uploadImage: 'Bild hochladen',
  removeImage: 'Entferne Bild',
  preferredLanguage: 'Bevorzugte Sprache',
  wantToChangePass: 'Möchten Sie das Passwort ändern?',
  selectCountryCode: 'Wählen Sie den Ländercode aus',
  selectPreferredLanguage: 'Wählen Sie Bevorzugte Sprache',
  fName50Chars: 'Der Vorname muss maximal 50 Zeichen lang sein',
  lName50Chars: 'Der Nachname darf höchstens 50 Zeichen lang sein',
  invalidDate: 'Ungültiges Datum',
  DOBEarlyThanToday: 'Das Geburtsdatum muss vor dem heutigen Tag liegen',
  enterValidPhone: 'Bitte geben Sie eine gültige Telefonnummer ein',
  accountStatus: 'Kontostatus',
  kycVerified: 'Ihr KYC wurde erfolgreich verifiziert.',
  denied: 'Bestritten',
  chooseFile: 'Datei wählen',
  upload: 'Hochladen',
  countryCantChange: 'Sie sind dabei, die KYC-Verifizierung einzuleiten. ',
  proceed: 'Fortfahren',
  update: ' ',
  history: 'Geschichte',
  type: 'Typ',
  amount: 'Menge',
  forfeited: 'Verfallen',
  expired: 'Abgelaufen',
  completed: 'Vollendet',
  zeroedOut: 'Auf Null gesetzt',
  active: 'Aktiv',
  lapsed: 'Verfallen',
  yourActiveBonus: 'Ihre aktiven Boni',
  currentActiveBonus: 'Aktuelle aktive Boni',
  bonusStatus: 'Status',
  bonusWager: 'Wette',
  bonusActivate: 'aktivieren Sie',
  bonusForfeit: 'Bonus verfällt',
  notUsableBonus: 'Wenn ja, können Sie diesen Bonus nicht erneut nutzen.',
  bonusLapsed: 'Sie hatten nicht genug Verluste, um Cashback zu erhalten. ',
  inProcess: 'In Bearbeitung',
  claiming: 'Anspruch',
  loyaltyPopover: 'Diese Treuepunkte können für Cashback verwendet werden. ',
  loyaltyPage: 'Treueseite',
  loyaltyPerCurrency: 'Loyalität pro Währung',
  maxLevelReached: 'Maximales Level erreicht',
  pointsToReach: 'Punkte zu erreichen ',
  loyaltyBannerHeading: 'Sie verdienen unsere brandneue Treue',
  loyaltyBannerDesc: '100 % Willkommensbonus bis zu 200 $',
  loyaltyHeadingOne: 'Bei CashMachine werden Sie immer belohnt',
  loyaltyHeadingOneDesc: 'Bei CashMachine bringen wir die Treue auf ein ganz neues Level und überschütten Sie auf Schritt und Tritt mit Belohnungen! ',
  loyaltySubHeadOne: 'Treuepunkte sammeln',
  loyaltySubHeadOneDesc: 'Für jeweils 10 € an Bargeldwetten, die Sie in unserem Casino platzieren, erhalten Sie 1 Treuepunkt. ',
  loyaltySubHeadTwo: 'Die Loyalitätsleiter erklimmen',
  loyaltySubHeadTwoDesc: 'Je mehr Punkte Sie sammeln, desto höher steigen Sie in den Treuestufen auf und schalten dabei noch mehr fantastische Prämien frei. ',
  loyaltySubHeadThree: 'Steige auf, werde belohnt ',
  loyaltySubHeadThreeDesc: 'Steigern Sie Ihr Spielerlebnis, indem Sie unsere verlockenden Prämienstufen durchlaufen, bei denen mit jedem Levelaufstieg eine aufregende Reihe von Freispielen freigeschaltet wird, um Ihre Gewinnchancen zu maximieren. ',
  loyaltyTestimonialHeadOne: 'BEI CashMachine CASINO SORGEN WIR DAFÜR',
  loyaltyTestimonialHeadTwo: 'Jeder Spin zählt',
  loyaltyTestimonialDesc: 'Jeder Dreh, den Sie an den Spielautomaten machen, dient nicht nur dem Spaß, sondern bringt Sie auch einigen wirklich süßen Belohnungen näher! ',
  loyaltyPoints: 'Punkte',
  headerLevel: 'Ebene',
  loyaltyCashback: 'Cashback',
  loyaltyBannerBtn: 'Überprüfen Sie den Fortschritt',
  loyaltyHeadingTwo: 'Schalten Sie das ultimative Spielerlebnis frei: CashMachine VIP-Programm',
  loyaltyHeadingTwoDesc: 'Sobald Sie Level 6 in unserem Treueprogramm erreichen, erschließen Sie sich als Mitglied des prestigeträchtigen CashMachine VIP-Programms eine Welt voller exklusiver Privilegien. ',
  loyaltySubHeadFour: 'Höhere Ein- und Auszahlungslimits',
  loyaltySubHeadFourDesc: 'Genießen Sie als VIP-Mitglied die Flexibilität erhöhter Ein- und Auszahlungslimits.',
  loyaltySubHeadFive: 'Exklusive Boni und Werbeaktionen',
  loyaltySubHeadFiveDesc: 'Erhalten Sie Zugang zu speziellen Boni und Werbeaktionen, die speziell auf unsere VIP-Spieler zugeschnitten sind',
  loyaltySubHeadSix: 'Persönlicher Account Manager',
  loyaltySubHeadSixDesc: 'Erhalten Sie engagierten Support und persönliche Unterstützung von Ihrem Account Manager.',
  loyaltySubHeadSeven: 'Schnellere Auszahlungszeiten',
  loyaltySubHeadSevenDesc: 'Als VIP genießen Sie verkürzte Auszahlungszeiten, sodass Sie noch schneller auf Ihre Gewinne zugreifen können.',
  loyaltySubHeadEight: 'Monatliche Cashback-Prämien',
  loyaltySubHeadEightDesc: 'Steigern Sie Ihr Guthaben mit großzügigen monatlichen Cashback-Prämien exklusiv für unsere VIP-Mitglieder.',
  loyaltySubHeadNine: 'Einladungen zu exklusiven Events und Turnieren',
  loyaltySubHeadNineDesc: 'Sichern Sie sich begehrte Einladungen zu exklusiven Events und Turnieren, bei denen Sie sich mit anderen VIPs austauschen und um unglaubliche Preise kämpfen können.',
  loyaltySubHeadTen: 'Geburtstagsboni',
  loyaltySubHeadTenDesc: 'Feiern Sie Ihren besonderen Tag stilvoll mit einem herrlichen Geburtstagsbonus nur für Sie!',
  loyaltySubHeadEleven: 'Luxusgeschenke',
  loyaltySubHeadElevenDesc: 'Gönnen Sie sich eine exquisite Auswahl an handverlesenen Luxusgeschenken, um unsere wertvollsten Spieler zu belohnen.',
  loyaltyTableHeading: 'Übersicht über Level und Belohnungen',
  loyaltyTableHeaderOne: 'Treuestufe',
  loyaltyTableHeaderTwo: 'Treuepunkte',
  loyaltyTableHeaderThree: 'Tägliches Cashback',
  promReadMore: 'Mehr lesen',
  currentPortalBlock: 'wenn Sie Ihr Konto nur für dieses Portal sperren möchten.',
  allPortalBlock: 'um Ihr Konto für alle Portale zu sperren.',
  limit24Reset: 'Sobald Sie Einsatz-, Verlust- und Einzahlungslimits festgelegt haben, können diese nach 24 Stunden bearbeitet und entfernt werden, die Limits können jedoch sofort gesenkt werden.',
  enterAmount: 'Menge eingeben',
  limit: 'Grenze',
  loss: 'Verlust',
  takeABreak: 'Machen Sie eine Pause',
  session: 'Sitzung',
  selfExclusion: 'Selbstausschluss',
  used: 'Gebraucht',
  edit: 'Bearbeiten',
  updatedAt: 'Aktualisiert am',
  cannotBeRemoved: 'Kann vorher nicht entfernt werden',
  timePeriod: 'Zeitraum',
  custom: 'Brauch',
  hours: 'Std',
  days: 'Tage',
  timePeriodPlaceholder: 'Anzahl der Tage',
  months: 'Monate',
  permanent: 'Dauerhaft',
  onlyNumbers: 'Nur Zahlen erlaubt',
  notNumbers: 'Nummern nicht zulässig',
  userNameAllowed: 'Muss mit Alphabet beginnen. ',
  timePeriodRequired: 'Zeitraum erforderlich',
  cannotBeIncreased: 'Kann vorher nicht erhöht werden',
  amountGTZero: 'Der Betrag sollte größer als 0 sein',
  amountEqualOrLess: 'Der Betrag sollte gleich oder kleiner als wöchentlich und monatlich sein',
  amountInBetween: 'Der Betrag sollte zwischen täglich und monatlich (einschließlich) liegen.',
  amountEqualOIrGreater: 'Der Betrag sollte gleich oder größer als täglich und monatlich sein',
  limitRemoved: 'Ihr folgendes Limit wird entfernt.',
  limitSet24Hrs: 'Sie sind dabei, das folgende Limit für Ihr Konto festzulegen. ',
  takeABreakInfo: 'Sie sind dabei, den Zugriff auf Ihr Konto für einen voreingestellten Zeitraum zu sperren. ',
  sessionLimitInfo: 'Sie sind dabei, das folgende Limit für Ihr Konto festzulegen. ',
  limitCantSetBefore: 'Ihr Sitzungslimit kann vorher nicht festgelegt werden',
  selfExclusionInfo: 'Sie sind dabei, den Zugriff auf Ihr Konto für einen voreingestellten Zeitraum zu sperren. ',
  quickSearch: 'Schnelle Suche',
  cash: 'Kasse',
  nonCash: 'Bargeldlos',
  userDetail: 'Benutzerdetails',
  gameIdentifier: 'Spiel-ID',
  rollback: 'Rollback',
  rollbackBeforeBetWin: 'Rollback vor Wettgewinn',
  freeSpins: 'Freispiele',
  betInternal: 'Interne Wette',
  winInternal: 'Intern gewinnen',
  addMoney: 'Geld hinzufügen',
  removeMoney: 'Geld entfernen',
  addMoneyInternal: 'Internes Geld hinzufügen',
  removeMoneyInternal: 'Internes Geld entfernen',
  depositInternal: 'Interne Einzahlung',
  withdrawInternal: 'Intern zurückziehen',
  promotionTitle: 'Promotion-Titel',
  cancelWithdrawRequest: 'Sie möchten diese Anfrage abbrechen',
  rowsPerPage: 'Zeilen pro Seite',
  availedBonus: 'Sie haben bereits einen aktiven Bonus. ',
  capForfeit: 'VERLIEREN',
  itFirst: 'es zuerst.',
  selectYourBonus: 'Wählen Sie Ihren Bonus',
  skip: 'ÜBERSPRINGEN',
  with: 'mit',
  without: 'ohne',
  selectPaymentMethod: 'Bitte wählen Sie Ihre Zahlungsmethode',
  volatility: 'Volatilität',
  paylines: 'Gewinnlinien',
  theme: 'Thema',
  tryForFree: 'Kostenlos testen',
  resultsFound: 'Gefundene Ergebnisse',
  games: 'Spiele',
  in: 'In',
  low: 'Niedrig',
  medium: 'Mittel',
  high: 'Hoch',
  'medium-high': 'Mittelhoch',
  'very-high': 'Sehr hoch',
  'low-medium': 'Niedrig Mittel',
  fantasy: 'Fantasie',
  ancient_civilizations: 'Alte Zivilisationen',
  fruits: 'Früchte',
  africa: 'Afrika',
  military: 'Militär',
  joker: 'Joker',
  asia: 'Asien',
  luxurylife: 'Luxusleben',
  underwater_world: 'Unterwasserwelt',
  book_off: 'Bookoff',
  western: 'Western',
  retro: 'Retro',
  egypt: 'Ägypten',
  party: 'Party',
  st_patrick_day: 'St. Patrick Tag',
  space: 'Raum',
  easter: 'Ostern',
  girls: 'Mädchen',
  branded: 'Gebrandmarkt',
  x_mas_and_new_year: 'Weihnachten und Neujahr',
  horrors: 'Horror',
  other: 'Andere',
  loginFirst: 'Bitte melden Sie sich zuerst an, um das Spiel zu Ihren Favoriten hinzuzufügen',
  loginFirstPlay: 'Bitte melden Sie sich zuerst an, um dieses Spiel zu spielen',
  sport: 'Sport',
  pirates: 'Piraten',
  sweets: 'Süßigkeiten',
  luxury_life: 'Luxusleben',
  st_valentines_day: 'Valentinstag',
  halloween: 'Halloween',
  food: 'Essen',
  lobby: 'Empfangshalle',
  favourite: 'Favorit',
  playNGO: 'Play N Go',
  loyaltySystem: 'Treuesystem',
  progressionSystem: 'Fortschrittssystem',
  startPoint: 'Startpunkt',
  endPoint: 'Endpunkt',
  loyaltyBonus: 'Treuebonus',
  pageRemoved: 'Diese Seite existiert nicht oder wurde entfernt',
  suggestToBackHome: 'Wir empfehlen Ihnen, nach Hause zurückzukehren',
  backToHome: 'Zurück nach Hause',
  goHome: 'Nach Hause gehen',
  oopps: 'Hoppla',
  somethingNotWorking: 'Es tut uns leid, hier funktioniert etwas nicht!',
  tournament: 'Turnier',
  none: 'Keiner',
  validOnDays: 'Gültig an Tagen',
  validTill: 'Gültig bis',
  yourRequest: 'Ihre Anfrage',
  withdrawalRequestPending: 'Sie haben eine Auszahlungsanfrage im Status „Ausstehend“. ',
  realBalance: 'Echtes Gleichgewicht',
  bonusInCasino: 'Bonus im Casino',
  bonusSelected: 'Bonus ausgewählt',
  payWith: 'Bezahlen mit',
  reached: 'Erreicht',
  receiptOfYour: 'Erhalt Ihrer',
  capEmail: 'EMAIL',
  capCreditCard: 'KREDITKARTENNUMMER',
  capExpiry: 'ABLAUF',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY hat nicht das richtige Format',
  min13Numbers: 'Mindestens 13 Nummern erforderlich',
  max19Numbers: 'Maximal 19 Nummern erlaubt',
  min3Numbers: 'Mindestens 3 Nummern erforderlich',
  max4Numbers: 'Maximal 4 Nummern erlaubt',
  Other: 'Andere',
  forfeit: 'Verlieren',
  withdrawRequestCreated: 'Auszahlungsanforderung erfolgreich erstellt',
  noBetHistory: 'Kein Wettverlauf gefunden',
  noPromotions: 'Keine Werbeaktionen gefunden',
  noBonuses: 'Keine Boni gefunden',
  noHistory: 'Kein Verlauf gefunden',
  noWithdrawalHistory: 'Keine Auszahlungshistorie gefunden',
  noCMS: 'Kein CMS gefunden',
  noProvidersFound: 'Keine Anbieter gefunden',
  noDataFound: 'Keine Daten gefunden',
  countryBlocked: 'LAND GESPERRT',
  forbidden: 'VERBOTEN',
  notAcceptingVisitors: 'Es tut uns sehr leid, aber Shinywilds.com ist in Ihrem Land nicht verfügbar.',
  contactUsAt: 'Wenn Sie der Meinung sind, dass Ihnen dies fälschlicherweise angezeigt wurde, wenden Sie sich bitte per E-Mail an unseren Support',
  siteUnderMaintenance: 'Seite wird gewartet',
  weWillBeRightBack: 'Wir sind gleich wieder zurück!',
  serviceUnavailable: 'Unser Service ist vorübergehend nicht verfügbar. ',
  tryAgainLater: 'Bitte versuchen Sie es später noch einmal.',
  checkYourEmail: 'Check deine E-Mails',
  sentAVerification: 'Wir haben einen Bestätigungslink gesendet',
  toYourEmail: 'an Ihre E-Mail.',
  backToLogin: 'Zurück zur Anmeldung',
  goToLogin: 'Gehen Sie zu „Anmelden“.',
  accountVerified: 'Konto bestätigt',
  yourAccountVerified: 'Dein Account wurde verifiziert',
  successfully: 'Erfolgreich',
  verificationFailed: 'Verifizierung fehlgeschlagen',
  emailFailedContact: 'Die Überprüfung ist fehlgeschlagen. Wenn dies weiterhin auftritt, wenden Sie sich bitte an ',
  setNewPass: 'Legen Sie ein neues Passwort fest',
  passResetLink: 'Wir haben einen Link zum Zurücksetzen des Passworts an gesendet',
  toYourAccount: 'Zu deinem Konto',
  sendPasswordResetLink: 'Anfrage zum Zurücksetzen des Passworts',
  gamePlayWagerComplete: 'Herzlichen Glückwunsch, Sie haben den Wetteinsatz erfolgreich abgeschlossen und das gewonnene Bonusgeld ist höher als der maximal zulässige Gewinnbetrag. ',
  real: 'Real',
  demo: 'Demo',
  money: 'Geld',
  formatRquired: '.png-, .jpg-, .jpeg-Format erforderlich',
  chooseImage: 'Bitte wählen Sie ein Bild aus',
  imageSize3MB: 'Geben Sie ein Bild mit einer Größe von weniger als 3 MB ein',
  noProfileImage: 'Kein Profilbild zum Entfernen verfügbar',
  limitSetSuccess: 'Limit erfolgreich gesetzt',
  accountDisabled: 'Konto erfolgreich deaktiviert',
  loginSuccess: 'Anmeldung erfolgreich',
  signupSuccess: 'Registrierung erfolgreich',
  linkExpired: 'Link abgelaufen',
  linkSent: 'Link gesendet',
  accAlreadyVerified: 'Konto bereits bestätigt. ',
  profileImage: 'Profilbild',
  profileImageRemoved: 'Profilbild erfolgreich entfernt',
  docs: 'Unterlagen',
  updatedSuccess: 'Erfolgreich geupdated',
  MATCH: 'ÜBEREINSTIMMEN',
  BALANCE: 'GLEICHGEWICHT',
  FREESPINS: 'FREESPINS',
  MATCH_1: 'ÜBEREINSTIMMEN',
  DEPOSIT: 'KAUTION',
  WAGERING: 'WETTEN',
  PROMOTION: 'FÖRDERUNG',
  CREDITCARD: 'KREDITKARTE',
  WALLET: 'GELDBÖRSE',
  CRYPTO: 'KRYPTO',
  OTHER: 'ANDERE',
  INSTANTBANKING: 'SOFORT-BANKING',
  cookieTitle: 'Kekse!',
  declineButton: 'Abfall',
  acceptButton: 'Akzeptieren',
  cookieDeclinedMessage: 'Um das bestmögliche Erlebnis zu gewährleisten, müssen Cookies akzeptiert werden, um diese Website weiterhin nutzen zu können. ',
  cookieDescription: 'Willkommen bei Shinywilds.com! ',
  forfeitMessageFirst: 'Sie haben derzeit folgenden aktiven Bonus:',
  forfeitMessageSecond: 'Sie müssen auf diesen Bonus verzichten, bevor Sie einen neuen beanspruchen können.',
  forfeitButton: 'Verlieren',
  pleaseVerifyFirstText: 'Um eine Auszahlung beantragen zu können, müssen wir zunächst Ihre E-Mail-Adresse verifizieren. ',
  clickHere: 'Klicken Sie hier',
  pleaseVerifyLastText: 'Damit wir Ihnen die Bestätigungs-E-Mail erneut senden und fortfahren können.',
  pleseWait: 'Bitte warte auf',
  toResend: 'um den Link erneut zu senden',
  notVerified: 'Sie müssen Ihre E-Mail-Adresse bestätigen, um Geld abzuheben',
  verification: 'Überprüfung',
  UPLOAD_IMG_MSG: 'Ziehen Sie Dateien per Drag-and-Drop hierher oder klicken Sie, um Dateien auszuwählen',
  affiliate: 'Affiliate',
  affiliateDescription: ' Empfehlen Sie unsere Marke und verdienen Sie Geld durch die Teilnahme am Cash Machine-Partnerprogramm! ',
  refferalTitle: 'Empfehlungsprogramm',
  refferalDescription: 'Sie können diesen Empfehlungslink an andere weitergeben und einen Bonus verdienen, wenn sich der Benutzer über den angegebenen Link registriert',
  affiliateButtonText: 'Werden Sie Partner',
  affiliateLink: 'Affiliate-Link',
  affiliateStatus: 'Ihre Affiliate-Anfrage wurde erfolgreich gesendet',
  currentStatus: 'und aktueller Affiliate-Status ist {{status}}',
  referralLink: 'Empfehlungslink',
  copyTitleMessage: 'Bitte kopieren Sie diesen Link',
  copiedMessage: 'Link kopiert',
  searchedGame: 'Gesuchtes Spiel',
  favoriteGame: 'Lieblingsspiele',
  randomGames: 'Zufällige Spiele',
  poolPrize: 'Preis Pool',
  chooseYourWelcomeBonus: 'Wählen Sie Ihren Willkommensbonus',
  useNow: 'Jetzt benutzen',
  readTheTerms: 'Lesen Sie die Bedingungen',
  proceedWithoutWelcomeBonus: 'Fahren Sie ohne Willkommensbonus fort',
  doYouAlreadyHaveAccount: 'Haben Sie bereits ein Konto? ',
  registerViaSocialNetworks: 'Registrieren Sie sich über soziale Netzwerke',
  iconfirmIm18YearsOlder: 'Ich bestätige, dass ich mindestens 18 Jahre alt bin',
  iAgreeWithTermsAndConditions: 'Ich akzeptiere die Nutzungsbedingungen',
  thankYouSuccessfullyRegisteringCashMachine: 'Vielen Dank für die erfolgreiche Registrierung bei Cash Machine.',
  verifyEmailMessage: 'Für eine erfolgreiche Anmeldung auf der Website bitten wir Sie, Ihre E-Mail-Adresse zu bestätigen, indem Sie auf den Bestätigungslink klicken, der an Ihre E-Mail-Adresse gesendet wurde.',
  gotIt: 'Habe es',
  phonecodeRequired: 'Telefoncode ist erforderlich',
  cpNotAllow: 'Kopieren/Einfügen ist nicht zulässig',
  playFun: 'Spielspaß',
  playReal: 'Spielen Sie echt',
  yourRealBalance: 'Ihr tatsächlicher Kontostand ist:',
  chooseYourPaymentMethod: 'Wähle deine Zahlungsmethode',
  balanceHistory: 'Bilanzverlauf',
  liveChat: 'Live-Chat',
  mail: 'Post',
  chooseEnterYourDepositAmounts: 'Wählen Sie Ihre Einzahlungsbeträge aus oder geben Sie sie ein',
  noBonusDataAvailable: 'Keine Bonusdaten verfügbar',
  nowTryPlayForRealBets: 'Versuchen Sie nun, um echte Einsätze zu spielen',
  registerAndPlayForRealBets: 'Registrieren Sie sich und spielen Sie um echte Wetten',
  tournamentDataNotAvailable: 'Turnierdaten nicht verfügbar',
  startDate: 'Startdatum',
  endDate: 'Endtermin',
  merchant: 'Händler',
  seachHere: 'Suche hier',
  show: 'Zeigen',
  betResults: 'Wettergebnisse:',
  noTransactionsFoundForselectedDate: 'Für das ausgewählte Datum wurden keine Transaktionen gefunden',
  loginError: 'Login Fehler',
  errorMessageIncorrectlyAuthorization: 'E-Mail/Login oder Passwort falsch eingegeben Autorisierungsfehler.',
  profileSaved: 'Profil gespeichert',
  addInfo: 'Zus. Info',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Ich habe die Einschränkungen des Zahlungssystems gelesen und bin damit einverstanden*',
  paymentOptions: 'Zahlungsmöglichkeiten',
  language: 'Sprache',
  betTime: 'Wettzeit',
  noActiveBonusFound: 'Kein aktiver Bonus gefunden',
  bonusType: 'Bonustyp',
  requiredWageringAmount: 'Erforderlicher Wettbetrag',
  currentWageringAmount: 'Aktueller Einsatzbetrag',
  validUpto: 'Gültig bis',
  bonusCode: 'Bonus-Code',
  wageringProgressBar: 'Wettfortschrittsbalken',
  noGamesFound: 'Keine Spiele gefunden',
  noteYouMayOptfreeSpin: 'Hinweis: Sie können sich für FREESPIN bei jedem dieser Spiele entscheiden',
  viewOfferDetails: 'Angebotsdetails anzeigen',
  viewFreespinGames: 'Freespin-Spiele ansehen',
  depositBonusPercentage: 'Einzahlungsbonus-Prozentsatz: ',
  validity: 'Gültigkeit',
  activated: 'Aktiviert',
  bonusName: 'Bonusname',
  freeRounds: 'Kostenlose Runden',
  wagering: 'Wetten',
  ended2: 'Beendet',
  searchBonusTitle: 'Suche nach Bonustitel',
  claimed: 'Behauptet',
  noBonusHistoryFoundselectedDates: 'Für ausgewählte Daten wurde kein Bonusverlauf gefunden',
  noBonusDetailsFound: 'Keine Bonusdetails gefunden',
  enterCode: 'Code eingeben',
  transactionTime: 'Transaktionszeit',
  credit: 'Kredit',
  debit: 'Lastschrift',
  // bonusInfoOfHeading: 'Boni sind zusätzliche Zahlungen, die Ihre Leistung belohnen',
  bonusInfoOfHeading: 'Boni sind zusätzliche Belohnungen, die Ihre Aktivität und Erfolge anerkennen.',
  onlineCasino: 'online Casino',
  isTheTop: ' ist die Spitze ',
  bonusInfoPara0: 'Suchen Sie nach den allerbesten Online-Casinospielen? ',
  tournamentsHistory: 'Turniergeschichte',

  loginWelcomeBonusInfo: `Cash Machine begrüßt neue Spieler mit einem Willkommensbonus – 100 %
  die Einzahlung + 100 Freispiele im Spiel „Pat’s Heroes“ (Platipus).
  Der Mindesteinzahlungsbetrag beträgt 20 EUR / 900 ARS / 30 AUD / 500 CZK
  / 150 DKK / 200 NOK / 30 NZD / 80 PLN / 20 USD / 300 ZAR / 70 GEL
  / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,
  loginDepositBonusInfo: `Maximaler Bonusbetrag: 100 % des Einzahlungsbetrags, jedoch nicht mehr
  als 100 EUR / 4.000 ARS / 150 AUD / 2.500 CZK / 750 DKK / 1.000 NOK
  / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45 000 /
  TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `Freispiele werden innerhalb von 5 Tagen nach der Bonusaktivierung ausgegeben.
  20 Freispiele täglich. Alle bei Freispielen gewonnenen Gelder werden gutgeschrieben
  Bonuskonto und stehen zum Spielen/Wetten zur Verfügung
  Bonus. Eine Auszahlung ist möglich, nachdem X40 eingesetzt wurde. Bonus-Einsatzzeit
  beträgt 7 Tage.`,
  loginMaximumBetLimitInfo: `Maximales Einsatzlimit beim Wetten: 1 EUR / 45 ARS / 1,5 AUD / CZK
  25 / 7 DKK / 10 NOK / 1,5 NZD / 5 PLN / 1 USD / 15 ZAR / GEL 3,5 /
  KZT 450 / TJS 10 / UZS 10 000 / TRY 6`,

  bonusInfoPara1: ', mit einer großen Auswahl an fantastischen Spielen und tollen Sonderangeboten. Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot den Spielern eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie sehen können, wie Ihre Karten in Echtzeit gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen.',
  bonusInfoPara2: 'Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie in Echtzeit zusehen können, wie Ihre Karten gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen. Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot den Spielern eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie in Echtzeit zusehen können, wie Ihre Karten gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen.',
  bonusInfoPara3: 'Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot den Spielern eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie in Echtzeit zusehen können, wie Ihre Karten gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen.',
  bonusInfoPara4: 'Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot den Spielern eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie in Echtzeit zusehen können, wie Ihre Karten gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen. Unsere Spielautomaten sind in einer Vielzahl von Themen erhältlich, wobei Spielautomaten mit progressivem Jackpot den Spielern eine lukrative Chance auf einen großen Gewinn bieten. Wir bieten auch spannende Live-Dealer-Spiele an, bei denen Sie in Echtzeit zusehen können, wie Ihre Karten gemischt und ausgeteilt werden! Sie sind sich nicht sicher, welches Spiel das Richtige für Sie ist? Machen Sie sich keine Sorgen – Sie können zunächst im Demomodus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, mit echtem Geld zu spielen.',
  cashMachineFooterPara1: 'Suchen Sie das beste Online-Casino? Willkommen bei',
  cashMachineFooterPara2: ` wo wir
aufregendes Gameplay, großzügige Boni und exklusive Turniere kombinieren, um Ihnen das
Beste an Online-Unterhaltung zu bieten. Möchten Sie den Jackpot bei unseren progressiven
Slots knacken, um Preise in Turnieren kämpfen oder Ihr Glück bei Live-Dealer-Spielen
versuchen? Wir haben alles! Was macht uns einzigartig? Bei`,
  cashMachineFooterPara3: `können Sie
nicht nur Geldpreise gewinnen, sondern auch echte physische Belohnungen! Wir sind stolz
darauf, die Wahl der Spieler weltweit zu sein. Bereit, mehr zu entdecken? Spielen Sie jedes
Spiel, das in Ihrer Region verfügbar ist, und genießen Sie unvergleichliche Spannung.
Erleben Sie das Beste, spielen Sie mit den Besten – nur bei`,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: 'Live Casino, Slots und Tropfen und gewinnen Casino-Turniere',
  tournamentDescTitle: 'Turniere sind eine großartige Gelegenheit, um gegen andere Spieler anzutreten und großzügige Preise zu gewinnen.',
  tournamentPara1: 'Suchen Sie nach den besten Casinospielen online? Dann sind Sie hier genau richtig.',
  tournamentPara2: 'mit einer großen Auswahl an fantastischen Spielen und beeindruckenden Sonderangeboten. Unsere Slot-Spiele kommen in einer breiten Vielfalt von Themen, mit progressiven Jackpot-Slot-Spielen, die Spielern eine lukrative Chance bieten, einen großen Gewinn zu erzielen. Wir bieten auch aufregende Live-Dealer-Spiele, bei denen Sie Ihre Karten in Echtzeit mischen und austeilen sehen können! Sind Sie sich nicht sicher, welches Spiel das richtige für Sie ist? Keine Sorge - Sie können zuerst im Demo-Modus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, um echtes Geld zu spielen.',
  tournamentPara3: 'Unsere Slot-Spiele kommen in einer breiten Vielfalt von Themen, mit progressiven Jackpot-Slot-Spielen, die Spielern eine lukrative Chance bieten, einen großen Gewinn zu erzielen. Wir bieten auch aufregende Live-Dealer-Spiele, bei denen Sie Ihre Karten in Echtzeit mischen und austeilen sehen können! Sind Sie sich nicht sicher, welches Spiel das richtige für Sie ist? Keine Sorge - Sie können zuerst im Demo-Modus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, um echtes Geld zu spielen. Unsere Slot-Spiele kommen in einer breiten Vielfalt von Themen, mit progressiven Jackpot-Slot-Spielen, die Spielern eine lukrative Chance bieten, einen großen Gewinn zu erzielen. Wir bieten auch aufregende Live-Dealer-Spiele, bei denen Sie Ihre Karten in Echtzeit mischen und austeilen sehen können! Sind Sie sich nicht sicher, welches Spiel das richtige für Sie ist? Keine Sorge - Sie können zuerst im Demo-Modus üben, um ein Gefühl für jedes Spiel zu bekommen, bevor Sie sich entscheiden, um echtes Geld zu spielen.',
  'New Games': 'Neue Spiele',
  'Popular Games': 'Beliebte Spiele',
  'Games Of The Month': 'Spiele des Monats',
  Exclusive: 'Exklusiv',
  'Bonus Buy': 'Bonuskauf',
  'Jackpot Games': 'Jackpot-Spiele',
  lightMode: 'Lichtmodus',
  darkMode: 'Dunkler Modus',
  searchProvider: 'Suchanbieter',
  tournamentHistoryDataNotAvailable: 'Daten zum Turnierverlauf sind nicht verfügbar',
  viewMore: 'Mehr sehen',
  description: 'Beschreibung',
  codeCopied: 'Code kopiert',
  tournamentGames: 'Turnierspiele',
  freeSpinGames: 'Kostenlose Spin-Spiele',
  promoCode: 'Aktionscode',
  paymentNote: 'Hinweis: Bitte schließen oder aktualisieren Sie das Fenster nicht, während die Transaktion verarbeitet wird',
  liveGames: 'Live-Spiele',
  play: 'SPIELEN',
  demo1: 'DEMO',
  registration: 'REGISTRIERUNG',
  confirmation: 'Bestätigung',
  forfeitBonusMessage: 'Wenn du diesen Bonus aktivierst, wird jeder alte aktive Bonus verfällt.',

  bonusConfirmationMessage: 'Sind Sie sicher, dass Sie diesen Bonus aktivieren möchten?',
  forfeitRewardMessage: 'Wenn Sie diese Belohnung aktivieren, verfällt der eventuelle alte aktive Bonus.',
  rewardConfirmationMessage: 'Sind Sie sicher, dass Sie diese Belohnung aktivieren möchten?',
  reward: 'Belohnen',
  purchasedReward: 'Gekaufte Belohnung',
  noActiveRewardFound: 'Keine aktive Belohnung gefunden',
  noRewardDetailsFound: 'Keine Belohnung gefunden',
  betDate: 'Wettdatum',
  shopDes: 'Im Shop können Sie Ihre gesammelten Punkte gegen verschiedene Belohnungen, Boni und Preise eintauschen.',
  vipInfoHeading: 'VIP-Boni sind exklusive Angebote für unsere loyalsten Spieler.',
  updatefooter:' bietet eGaming-Dienste an und ist offiziell von der Offshore Finance Authority des Staates Anjouan gemäß dem Computer Gaming Licensing Act 007 von 2005 lizenziert. Diese Website ist Eigentum von KeyPartnership B.V. (Firmennummer: 166291) und wird von dieser betrieben, registriert und reguliert in Curaçao. mit der Adresse Abraham de Veerstraat 1, Willemstad, Curaçao‘',
  loginFirstForRandom: 'Meld u eerst aan om willekeurige spellen te zien',
  affiliateTitle:'Partnerprogramma'
}
