export default {
  404: '404',
  vip: 'Vip',
  upcoming: 'Kommende',
  finished: 'Færdig',
  prizePool: 'Præmiepulje',
  joinNow: 'Deltag nu',
  joined: 'Tiltrådte',
  startsIn: 'Starter ind',
  endsIn: 'Ender i',
  ended: 'Turneringen afsluttet',
  started: 'Turneringen er startet',
  seeMore: 'Se mere',
  winPrizes: 'Vind spændende præmier',
  leaderBoard: 'Leaderboard',
  prize: 'Præmie',
  aboutTournament: 'Om turnering',
  congratulationsMessage: 'Bravo! ',
  payments: 'Betaling',
  tournaments: 'Turneringer',
  shop: 'Butik',
  blog: 'Blog',
  referral: 'Henvisning',
  contacts: 'Kontaktpersoner',
  retry: 'Prøve igen',
  incorrectCredsMessage: 'Dit brugernavn eller din adgangskode er forkert. ',
  bonuses: 'Bonus',
  depositBonus: 'Indbetalingsbonus',
  voucher: 'Rabatkupon',
  gamlings: 'Hasardspil',
  transactionHistory: 'Transaktionshistorik',
  offers: 'Tilbud',
  editProfile: 'Rediger profil',
  continue: 'Blive ved',
  apply: 'ansøge',
  male: 'Han',
  female: 'Kvinde',
  register: 'Tilmeld',
  login: 'Log på',
  grand: 'Grand',
  major: 'Major',
  minor: 'Mindre',
  mini: 'Mini',
  crashGameTitle: 'Krak',
  betAmount: 'Indsatsbeløb',
  profit: 'Profit',
  payout: 'Måludbetaling',
  chance: 'Vind chance',
  betBtn: 'Vædde',
  autoCashout: 'Automatisk udbetaling',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Maks',
  wagered: 'satsede',
  nothingFound: 'Intet fundet',
  lost: 'Faret vild',
  won: 'Vandt',
  streak: 'Streak',
  highestWin: 'Højeste gevinst',
  promotions: 'Kampagner',
  latestPromotions: 'Seneste kampagner',
  sponsorships: 'Sponsorater',
  highestLost: 'Højeste tabt',
  highestBet: 'Højeste indsats',
  maxStreak: 'Max Streak',
  maxProfit: 'Maks fortjeneste: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Din væddemålshistorik er tom',
  messagesCopyNotSupported: 'Din browser understøtter ikke automatisk Kopier til udklipsholder. Prøv venligst at kopiere koden manuelt.',
  messagesCopied: 'Adressen er kopieret.',
  deposit: 'Depositum',
  statReset: 'Nulstil statistik',
  rollOver: 'Rul rundt',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Tilbage',
  inputFieldBtnMax: 'max',
  buttonTextPlaceBet: 'Placer indsats',
  buttonTextCancelBet: 'Annuller væddemål',
  buttonTextEscapeBet: 'Udbetale',
  buttonTextStartAutoBet: 'Start Autobet',
  buttonTextCancelAutoBet: 'Annuller Autobet',
  buttonTextLoading: 'Indlæser',
  tabsMyBets: 'Mine væddemål',
  tabsDeposit: 'Depositum',
  tabsCashout: 'Udbetalinger',
  tabsAllbets: 'Alle væddemål',
  tabsTopbets: 'Top væddemål',
  tabsBonus: 'Bonusser',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Væddemålshistorie',
  timerTextCurrentPayout: 'Nuværende udbetaling',
  timerTextStarting: 'Starter i',
  timerTextSec: 's',
  timerTextFlewAway: 'Kørt galt',
  timerTextWait: 'Vent venligst..',
  timerTextPreparingRound: 'Forberedende runde',
  timerTextLoadingRound: 'Indlæsningsrunde',
  comingSoon: 'Kommer snart',
  labelsProfitOnWin: 'Fortjeneste på sejr',
  inputsNoOfBets: 'Antal væddemål',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Afbestille',
  buttonsMin: 'Min',
  buttonsMax: 'Maks',
  prediction: 'Forudsigelse',
  luckyNumber: 'Lykketal',
  multiplier: 'Multiplikator',
  chanceToWin: 'Chance for at vinde',
  bets: 'Væddemål',
  depositBtn: 'Depositum',
  rollUnder: 'Rul under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'Historie',
  navBarMyBets: 'Mine væddemål',
  navBarAllBets: 'Alle væddemål',
  navBarHighRollers: 'High Rollers',
  navBarRoundHistory: 'Runde Historie',
  navBarTotal: 'Samlede indsatser',
  topHeaderBetId: 'Bet ID',
  topHeaderUser: 'Bruger',
  topHeaderPayout: 'Udbetaling',
  topHeaderAmount: 'Beløb',
  topHeaderTime: 'Tid',
  topHeaderProfit: 'Profit',
  topHeaderRoundCrashed: 'Kørt galt',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Vædde',
  topHeaderRoundProfit: 'Profit',
  topHeaderRoundSignature: 'Underskrift',
  roundHistoryTitle: 'SPILINFORMATION',
  roundHistoryDescGame: 'Spil',
  roundHistoryDescCrashAt: 'Nedstyrtede ved:',
  roundHistoryDescDate: 'Dato:',
  roundHistoryDescBetHeader: 'Vædde',
  roundHistoryTableTitlePlayer: 'SPILLER',
  roundHistoryTableTitleBet: 'VÆDDE',
  roundHistoryTableTitleCashedOut: 'Udbetalt',
  roundHistoryTableTitleProfit: 'PROFIT',
  roundHistoryTableTitleBetId: 'INDSATS ID',
  roundHistoryMessagesNoBetHistory: 'Din væddemålshistorik er tom',
  placedBetsTabPlaced: 'Placeret indsats',
  placedBetsTabPrev: 'Forrige bet',
  placedBetsTitleUser: 'Bruger',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Vædde',
  placedBetsTitleProfit: 'Profit',
  placedBetsTitleCashout: 'Udbetale',
  placedBetsNoPlacedBet: 'Ingen placerede indsatser.',
  provablyFairContentCheckBtn: 'Tjek bevisbar retfærdig',
  provablyFairContentTitle: 'Beviselig Fair',
  provablyFairContentDesc: 'Fire side leads resultat kan bevises som retfærdigt. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Crash Rate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Crash rate uden x',
  provablyFairContentInputFieldsRoundHashTitle: 'Rund Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'f.eks. ',
  provablyFairContentInputFieldsSubmitBtn: 'Generer signatur',
  provablyFairContentSignature: 'Rund signatur',
  provablyFairContentErrorsCrashRate: 'Indtast venligst nummer i feltet for ulykkesfrekvens',
  resetPasswordTitle: 'Nulstille kodeord',
  resetPasswordNewPasswordTitle: 'nyt kodeord',
  resetPasswordNewPasswordErrorsRequired: '*adgangskode er påkrævet.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 tegn tilladt.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maksimalt 16 tegn tilladt.',
  resetPasswordNewPasswordErrorsPattern: '*Adgangskoden skal bestå af 8 til 16 alfanumeriske tegn og specialtegn. ',
  resetPasswordConfirmPasswordTitle: 'Bekræft kodeord',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Adgangskoderne stemmer ikke overens.',
  resetPasswordResetBtn: 'Nulstil',
  passwordResetSuccess: 'Adgangskoden blev ændret, log venligst ind for at nyde spil!',
  resetPasswordWentWrong: 'Noget gik galt!',
  levelGrand: 'GRAND',
  levelMajor: 'MAJOR',
  levelMinor: 'MINDRE',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Vind $1000 dagligt ',
  bannerContentContest: 'Konkurrencer nu ',
  bannerContentjoin: 'Deltag i vores daglige væddemålskonkurrence og bliv helten!  ',
  bannerContentPlayNow: 'Spil nu',
  bannerContentTitle: 'Vind Jackpot',
  bannerContentContent: 'Spil mere',
  bannerContentGameName: 'CRASH SPIL',
  bannerContentGameDesc: 'Spil mere',
  partners: 'Partnere',
  gamesGoldenDragon: 'Gyldne Drage',
  gamesCrashGame: 'Crash spil',
  gamesDice: 'Terning',
  gamesHilo: 'Hilo',
  allBetsTitle: 'Alle væddemål',
  allBetsBetId: 'Bet ID',
  allBetsUser: 'Bruger',
  allBetsPayout: 'Udbetaling',
  allBetsAmount: 'Beløb',
  allBetsTime: 'Tid',
  allBetsProfit: 'Profit',
  LoginwithOtp: 'Log ind med Otp',
  EnterLoginOtp: 'Indtast Login Otp',
  submit: 'Indsend',
  footerAboutUs: 'Om os',
  footerFaq: 'Ofte stillede spørgsmål',
  footerSitePolicy: 'Site Politik',
  footerResponsibleGambling: 'Ansvarligt spil',
  footerTerms: 'Vilkår og betingelser',
  signinTitle: 'Log på',
  signinUsername: 'Brugernavn / Email',
  signinPassword: 'Adgangskode',
  signinLoginBtn: 'Log på',
  signinSignup: 'Tilmelde!',
  signinForgetPassword: 'Glemt din adgangskode?',
  forgotPasswordMsg: 'Indtast den e-mailadresse, der er knyttet til din konto, og vi sender dig et link for at nulstille din adgangskode.',
  emailLinkMsg: 'Nulstil adgangskode-linket er blevet sendt til din registrerings-e-mail, klik venligst på e-mail-linket og indstil din nye adgangskode.',
  resetPasswardConfirm: 'Adgangskoden er blevet nulstillet',
  signinDontHaveAccount: 'Har du ikke en konto? ',
  signinCreateAccount: 'Opret konto',
  signinErrorsUserNameRequired: '*Indtast venligst brugernavn/e-mail.',
  signinErrorsUserNameMinLength: 'Minimum 4 tegn tilladt.',
  signinErrorsUserNameMaxLength: 'Maksimalt 20 tegn tilladt.',
  signinErrorsUserNamePattern: 'Indtast venligst et gyldigt brugernavn, kun bogstaver og tal er tilladt.',
  signinErrorsPasswordRequired: '*Adgangskode er påkrævet.',
  signinErrorsPasswordMinLength: 'Minimum 8 tegn tilladt.',
  signinErrorsPasswordMaxLength: 'Maksimalt 16 tegn tilladt.',
  signinErrorsPasswordPattern: '*Adgangskoden skal være på 8 til 16 alfanumeriske tegn og specialtegn. ',
  signinSuccessfullyLoggedIn: 'Loget ind.',
  signinResetMailSent: 'Nulstil adgangskode email sendt.',
  signinTryAgain: 'Noget gik galt! Prøv venligst igen.',
  signinResetPasswordUsernameErr: 'Indtast venligst brugernavn/e-mail for at nulstille adgangskoden.',
  signinResetMailSentSuccessContent: 'Vi har sendt dig et link til nulstilling af adgangskode på din e-mail. ',
  signupTitle: 'Tilmeld',
  signupUsername: 'Brugernavn',
  signupEmail: 'E-mail',
  signupTermsConditions: 'Betingelser',
  signupWalletConnect: 'Log ind med Wallet ',
  signupPassword: 'Adgangskode',
  signupEighteenPlus: 'Jeg er over 18 år og accepterer servicevilkårene',
  signupRegisterBtn: 'Tilmelde',
  signupCreateNewAccount: 'Opret ny konto',
  signupFullName: 'Fulde navn',
  signupReferralCode: 'Henvisningskode',
  signupConfirmPassword: 'Bekræft kodeord',
  signupUseSocialAccount: 'Log ind via sociale netværk',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Har du en konto? ',
  signupLoginBtn: 'Log på',
  signupErrorsUserNameRequired: '*Indtast venligst brugernavn.',
  signupErrorsUserNameMinLength: 'Minimum 4 tegn tilladt.',
  signupErrorsUserNameMaxLength: 'Højst 20 tegn tilladt.',
  signupErrorsUserNamePattern: 'Indtast venligst et gyldigt brugernavn, kun bogstaver og tal er tilladt.',
  signupErrorsFullNameRequired: '*Indtast venligst fulde navn.',
  signupErrorsFullNamePattern: 'Indtast venligst gyldigt fulde navn, kun bogstaver og tal er tilladt.',
  signupErrorsEmailRequired: '*Indtast venligst e-mail.',
  signupErrorsEmailMinLength: 'Minimum 4 tegn tilladt.',
  signupErrorsEmailMaxLength: 'Maksimalt 20 tegn tilladt.',
  signupErrorsEmailPattern: 'Indtast venligst gyldig e-mail.',
  signupErrorsPasswordRequired: '*Adgangskode er påkrævet.',
  signupErrorsPasswordMinLength: 'Minimum 8 tegn tilladt.',
  signupErrorsPasswordMaxLength: 'Maksimalt indskud 16 tegn tilladt.',
  signupErrorsPasswordPattern: '*Adgangskoden skal være 8 til 16 digi alfanumerisk (store og små bogstaver) og specialtegn. ',
  signupErrorsConfirmPasswordMatch: 'Adgangskode skal matche.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'f.eks. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Noget gik galt!',
  signupSignupGoogle: 'Tilmelding med Google lykkedes.',
  signupRegistered: 'Registreret med succes.',
  signupConfirmAge: 'Bekræft venligst, at du er fyldt 18 år.',
  signupSignupFacebook: 'Tilmelding med Facebook lykkedes.',
  signupCookiesConsent: 'Tillad venligst tredjepartscookies fra din browsers indstillinger for at bruge Socialt login.',
  signupRegisterEmailPopupContent: 'Vi har sendt dig en bekræftelses-e-mail. ',
  accountMenuDeposit: 'Depositum',
  accountMenuCashout: 'Udbetale',
  accountMenujackpotWinners: 'Jackpot vindere',
  accountMenuTransactions: 'Transaktioner',
  accountMenuPromotions: 'Kampagner',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Log ud',
  accountMenuSound: 'Lyd',
  accountMenuMusic: 'musik',
  accountMenuWithdraw: 'Træk tilbage',
  pageNotFound: 'Vi kan ikke finde den side, du leder efter!',
  error: 'Fejl',
  somethingWentWrong: 'Noget gik galt!',
  verifyEmailThankYou: 'Tak skal du have!',
  verifyEmailEmailIsVerified: 'Din e-mail er bekræftet. ',
  verifyEmailGoToLogin: 'Gå til login-siden',
  verifyEmailWentWrong: 'Noget gik galt!',
  verifyEmailTokenExpired: 'E-mail allerede bekræftet eller link udløbet, send venligst e-mail bekræftelseslink igen ved at angive din registrerede e-mail nedenfor. ',
  verifyEmailGoToHome: 'Gå til startsiden',
  verifyEmailSuccessMsg: 'E-mailen er blevet bekræftet.',
  bonusSectionSpanText: 'præsenterer de mest generøse og forskellige',
  bonusSectionBonuses: 'Bonusser',
  bonusSectionSubtitleText: 'Spil med fornøjelse og få yderligere belønninger under spillet. ',
  JoiningBonusTitle: 'Tilmeldingsbonus',
  JoiningBonusButton: 'Gør krav nu',
  JoiningBonusDesc: 'Sådanne bonusser tilskynder online casinospillere til at spille ved at bruge Bitcoin eller andre kryptoer. ',
  depositBonusTitle: 'Indbetalingsbonus',
  depositBonusButton: 'Depositum',
  depositBonusDesc: 'Sådanne bonusser tilskynder online casinospillere til at spille ved at bruge Bitcoin eller andre kryptoer. ',
  refferBonusTitle: 'Refer bonus',
  refferBonusButton: 'Depositum',
  refferBonusDesc: 'Sådanne bonusser tilskynder online casinospillere til at spille ved at bruge Bitcoin eller andre kryptoer. ',
  aboutSectionSpanText2: 'Onlinekasinoet giver dig mulighed for at spille dine yndlingsspil enten gratis eller for kryptovaluta. ',
  aboutSectionSpanText3: ' I denne virtuelle verden af ​​Bitcoin-gambling kan du nyde mange muligheder - spille dine yndlingskasinospil, få bonusser, deltage i konkurrencer og kampagner.',
  verifiedSectionTitle: 'Verificeret og licenseret software',
  verifiedSectionSpanText1: 'Vi kopierer eller adopterer ikke software fra andre websteder. ',
  faqSectionTitle: 'FAQ fra spillere',
  faqSectionForgetPasswordTitle: 'Jeg har glemt mit kodeord.',
  faqSectionForgetPasswordDesc: 'Du har ikke angivet en e-mailadresse til gendannelse til din konto. ',
  faqSectionMyDepositCreditedTitle: 'Mit indskud er ikke blevet krediteret.',
  faqSectionMyDepositCreditedDesc: ' Pladsholderindhold til denne harmonika, som er beregnet til at demonstrere klassen. ',
  SupportedCurrencyTitle: 'Understøttet valuta',
  SupportedCurrencySpanText1: 'Vi kopierer eller adopterer ikke software fra andre websteder. ',
  CarouselSectionTitle: 'HODL KRASH',
  CarouselSectionSubTitle: ' Spil flere flere spil og få en chance for at vinde. ',
  WalletSettingTitle: 'Indstilling af tegnebog',
  WalletSettingHideZero: 'Skjul nulsaldi',
  WalletSettingHideZeroInfo: 'Din nulsaldo vises ikke i din tegnebog',
  WalletSettingDisplayFiat: 'Vis krypto i fiat',
  WalletSettingDisplayFiatInfo: 'Alle væddemål',
  WalletSettingNoteForApprox: 'Bemærk venligst, at disse er valutatilnærmelser.',
  WalletSettingSave: 'Gemme',
  WalletSettingNoWalletFound: 'Ingen tegnebog fundet',
  jackpotWinnersNoWinnerAvailable: 'Ingen vindere tilgængelige',
  jackpotWinnersNew: 'Ny',
  jackpotWinnersHeader: 'Jackpot vinder',
  jackpotDetailsHeader: 'Jackpot detalje',
  jackpotDetailsCampaignPeriod: 'Kampagneperiode',
  jackpotDetailsFrom: 'Fra',
  jackpotDetailsTo: 'Til',
  jackpotDetailsBettingRule: 'Væddemålsregel',
  jackpotDetailsMinBetAmount: 'Minimum indsatsbeløb',
  jackpotDetailsMaxBetAmount: 'Max indsatsbeløb',
  jackpotDetailsBetPercentage: 'Indsatsprocent',
  jackpotDetailsWinningRuleHeader: 'Vinderregel',
  jackpotDetailsWinningRule: 'Placering af væddemål med højere indsatsbeløb vil øge chancerne for at vinde.',
  jackpotDetailsProfitRule: 'Hvis indsatsbeløbet er mellem minBetAmount og maxBetAmount, vil betPercentage% af indsatsbeløbet blive tilføjet jackpotbeløbet.',
  profileTitle: 'Personlige detaljer',
  profileFName: 'Fornavn',
  profileLname: 'Efternavn',
  profileUserName: 'Brugernavn',
  profileDob: 'Fødselsdato',
  profileEmail: 'Email adresse',
  profilePhone: 'Telefonnummer',
  profileChange: 'Lave om',
  profileSave: 'Gem ændring',
  profilePassword: 'Adgangskode',
  profileSubmit: 'Indsend',
  profileUploadProfile: 'Upload profil',
  profileCurrentPassword: 'Nuværende kodeord',
  profileNewPassword: 'nyt kodeord',
  profileConfirmPassword: 'Bekræft kodeord',
  profileScanQRCode: 'Scan QR-kode',
  profileEnterSecretCode: 'Indtast hemmelig kode',
  profileErrorsFirstNameRequired: 'Fornavn er påkrævet.',
  profileErrorsFirstNamePattern: 'Indtast venligst et gyldigt navn.',
  profileErrorsFirstNameMinLength: 'Minimum 3 tegn tilladt.',
  profileErrorsFirstNameMaxLength: 'Maksimalt 20 tegn tilladt.',
  profileErrorsLastNameRequired: 'Efternavn er påkrævet.',
  profileErrorsLastNamePattern: 'Indtast venligst et gyldigt navn.',
  profileErrorsLastNameMinLength: 'Minimum 3 tegn tilladt.',
  profileErrorsLastNameMaxLength: 'Højst 20 tegn tilladt.',
  profileErrorsChangePasswordOldRequired: 'Gammel adgangskode er påkrævet.',
  profileErrorsChangePasswordOldPattern: 'Indtast venligst gyldig adgangskode.',
  profileErrorsChangePasswordOldMinLength: 'Minimum 8 tegn tilladt.',
  profileErrorsChangePasswordOldMaxLength: 'Maksimalt 16 tegn tilladt.',
  profileErrorsChangePasswordNewRequired: 'Ny adgangskode er påkrævet.',
  profileErrorsChangePasswordNewPattern: 'Indtast venligst gyldig adgangskode.',
  profileErrorsChangePasswordNewMinLength: 'Minimum 8 tegn tilladt.',
  profileErrorsChangePasswordNewMaxLength: 'Maksimalt 16 tegn tilladt.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Adgangskoderne stemmer ikke overens.',
  profileErrorsChangePasswordNewPasswordMatched: 'Ny adgangskode kan ikke være det samme som den nuværende adgangskode.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Bekræft adgangskode er påkrævet.',
  profileErrorsUserNameRequired: 'Brugernavn er påkrævet.',
  profileErrorsUserNamePattern: 'Indtast venligst et gyldigt navn.',
  profileErrorsUserNameMinLength: 'Minimum 3 tegn tilladt.',
  profileErrorsUserNameMaxLength: 'Højst 20 tegn tilladt.',
  profileErrorsDobRequired: 'Fødselsdato er påkrævet.',
  profileErrorsEmailRequired: 'E-mail er påkrævet.',
  profileErrorsPostcodeRequired: 'Postnummer er påkrævet.',
  profileErrorsCityRequired: 'By er påkrævet.',
  profileErrorsCurrencyRequired: 'Valuta er påkrævet.',
  profileErrorsAddressRequired: 'Adresse er påkrævet.',
  profileErrorsCountryRequired: 'Land er påkrævet.',
  profileErrorsEmailPattern: 'Indtast venligst gyldig e-mail.',
  profileErrorsEmailMinLength: 'Minimum 3 tegn tilladt.',
  profileErrorsEmailMaxLength: 'Højst 20 tegn tilladt.',
  profileMessagesProfileUpdate: 'Profilen blev opdateret.',
  profileMessagesChangePassword: 'Adgangskoden blev opdateret.',
  profileProfilePicProfilePicUpload: 'Upload profilbillede',
  profileNoNewChanges: 'Profilen kunne ikke opdateres! ',
  profileLoggedInWithSocial: 'Du er logget ind med socialt login.',
  profileSideNavigatorSound: 'Lyd',
  profileSideNavigatorMusic: 'musik',
  profileSideNavigatorProfile: 'Min profil',
  profileSideNavigatorBetHistory: 'væddemålshistorie',
  profileSideNavigatorTransactions: 'Transaktioner',
  profileSideNavigatorDeposit: 'Depositum',
  profileSideNavigatorWithdraw: 'Træk tilbage',
  profileSideNavigatorGameLimits: 'Spilgrænser',
  profileSideNavigatorReferral: 'Henvisning',
  profileSideNavigatorChangePassword: 'Skift kodeord',
  profileSideNavigatorTermsConditions: 'Betingelser',
  profileSideNavigatorLogOut: 'Log ud',
  profileSideNavigatorProvablyFair: 'Beviselig Fair',
  WalletInfoSectionNotAvailable: 'Ikke tilgængelig',
  WalletInfoSectionRealBalance: 'Virkelig balance',
  GameLimitsMinimumBet: 'Minimum indsats',
  GameLimitsMaximumBet: 'Maksimal indsats',
  GameLimitsMaxWinFor1Bet: 'Maksimal gevinst for et bet',
  ProvablyFairSubTitle: 'Dette spil bruger Provably Fair teknologi til at bestemme spillets resultat. ',
  ProvablyFairMaximumBet: 'Maksimal indsats:',
  ProvablyFairMaxWinFor1Bet: 'Maksimal gevinst for et bet',
  promotionsTitle: 'Kampagner',
  promotionsNoLosingData: 'Beklager, ingen tabende bonuskampagner tilgængelige for ',
  promotionsNoDepositData: 'Beklager, ingen indskudsbonus kampagner tilgængelige for ',
  promotionsCurrency: ' betalingsmiddel.',
  promotionsViewBtn: 'Udsigt',
  promotionsClaimSuccess: 'Du har gjort krav på',
  promotionsAvailability: 'Tilgængelighed: ',
  promotionsAvailabilityTabLosing: 'Tabende bonus',
  promotionsAvailabilityTabDeposit: 'Indbetalingsbonus',
  promotionsDepositBonusTableCode: 'Kode',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Procent',
  promotionsDepositBonusTableMaxBonus: 'Max bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover multiplikator',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover pr. indsats',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Tid til at nå rollover-mål',
  promotionsBonusCode: 'BONUS KODE: ',
  promotionsLossesClaim: 'Tab kan kræves for (inden for kampagneperioden): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Procent',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Bonusstatus: ',
  promotionsBonusAmount: 'Bonusbeløb: ',
  promotionsRolloverTarget: 'Rollover-mål: ',
  promotionsRolloverAchieved: 'Rollover opnået: ',
  promotionsActivatebtn: 'AKTIVER',
  promotionsClaimBtn: 'PÅSTAND',
  promotionsCancelBtn: 'AFBESTILLE',
  promotionsBackBtn: 'TILBAGE',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Betingelser',
  promotionsClaimDepositBonusTitle: 'Trin til krav om indbetalingsbonus',
  promotionsClaimDepositBonusFirst: 'Aktiver bonuskode',
  promotionsClaimDepositBonusSecond: 'Indskudsbeløb til tegnebogen',
  promotionsClaimDepositBonusThird: 'Opnå rollover-mål for at få bonus',
  promotionsClaimLosingBonusTitle: 'Trin til at kræve tabende bonus',
  promotionsClaimLosingBonusFirst: 'Aktiver bonuskode',
  promotionsClaimLosingBonusSecond: 'Spil dine yndlingsspil',
  promotionsClaimLosingBonusThird: "Klik på 'Krav tab' for at kræve tab inden for kampagneperioden",
  promotionsWentWrong: 'Noget gik galt!',
  transactionTitle: 'Transaktioner',
  transactionBetId: 'Bet ID',
  transactionUser: 'Bruger',
  transactionPayout: 'Udbetaling',
  transactionAmount: 'Beløb',
  transactionProfit: 'Profit',
  transactionCashout: 'Udbetale',
  transactionBet: 'Vædde',
  transactionMultix: 'Multi.x',
  transactionWin: 'Vinde',
  transactionFairness: 'Retfærdighed',
  transactionReferFriend: 'Henvis en ven',
  transactionTotal: 'Samlede indsatser',
  transactionWins: 'Vinder',
  transactionRefresh: 'Opdater',
  transactionFilterTopMultipliers: 'Top multiplikatorer',
  transactionFilterHugeWins: 'Kæmpe sejre',
  transactionFilterBiggestWins: 'Største sejre',
  transactionFilterMultipliers: 'Multiplikatorer',
  transactionRealBalance: 'Virkelig balance',
  depositWithdrawTXDate: 'Dato',
  depositWithdrawTXAmount: 'Beløb',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transaktionstype',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Ingen tilgængelig data.',
  depositTitle: 'Depositum',
  depositHistory: 'Historie',
  depositSubTitle: 'Brugeren kan ikke indbetale eller hæve fiat-valuta. ',
  depositCopyNotSupported: 'Din browser understøtter ikke automatisk Kopier til udklipsholder. Prøv venligst at kopiere koden manuelt.',
  depositCopied: 'Adressen er kopieret.',
  depositAddressCreated: 'Adressen er oprettet.',
  depositGenerateAddress: 'Generer adresse',
  depositWarningText: 'Indbetal venligst IKKE via cross-chain overførsel',
  withdrawTitle: 'Træk tilbage',
  withdrawSubTitle: 'Brugeren kan ikke indbetale eller hæve fiat-valuta. ',
  withdrawNoteDesc: 'Sørg venligst for, at du IKKE indtaster BEP2, BEP20 (BSC) adresse som en LTC-udtræksadressetegnebog',
  withdrawNoteSublist: ' Du skal IKKE trække dig tilbage som krydskædeoverførsel',
  withdrawHistory: 'Historie',
  withdrawWithdrawBtn: 'Træk anmodning tilbage',
  withdrawFees: 'Udbetalingsgebyr {{fees}} {{token}}',
  withdrawWithdrawPending: 'Din anmodning om tilbagetrækning afventer. ',
  withdrawEnterFields: 'Indtast hævebeløb og din tegnebogsadresse.',
  withdrawSelectWallet: 'Vælg venligst tegnebog for at hæve.',
  withdrawAmountFieldTitle: 'Beløb (min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Din {{wallet.currency.code}} adresse',
  withdrawAddressFieldPlaceholder: 'Indtast din adresse',
  withdrawNoBalance: 'Ikke nok balance.',
  withdrawMinAmount: 'Indtast venligst beløb større end eller lig med {{min}} .',
  fiatCurrencyInfo: 'Brugeren kan ikke indbetale eller hæve fiat-valuta. ',
  paginationNextBtn: 'Næste',
  paginationPrevBtn: 'Tidligere',
  wentWrong: 'Noget gik galt.',
  referralTitle: 'Henvisning',
  referralSubTitle: 'Henvis dine venner',
  referralEarn: ' Tjen 150 INR hver',
  referralReferAndEarn: 'Henvis og optjen bonus for hver henvisning',
  referralReferPara: ' Hvis du henviser denne kode til en bruger',
  referralReferLink: 'Se link',
  referralCopyReferralCode: 'Kopiér henvisningskoden',
  referralTotalRewards: 'Samlet belønning',
  TwoWayAuthentication: 'Tovejsgodkendelse',
  LoginRequired: 'Login påkrævet!',
  BetPlacedSuccessfully: 'Indsatsen er placeret med succes',
  BetAddedInQueueSuccessfully: 'Indsats tilføjet i køen med succes',
  BetRemovedFromQueueSuccessfully: 'Indsats fjernet fra køen med succes',
  BetCancelledSuccessfully: 'Væddemålet blev annulleret',
  PlayerEscapedSuccessfully: 'Spilleren undslap med succes',
  CopiedSuccessfull: 'Kopieret vellykket',
  NotEnoughBalance: 'Ikke nok balance',
  AutoBetStarted: 'Auto bet startede ',
  AutoBetFinished: 'Auto bet afsluttet ',
  IdCopied: 'Id kopieret',
  usersConsent: 'Ved at gå ind på denne side bekræfter jeg, at jeg er 18 år gammel og har gennemgået, forstået og givet samtykke til',
  termsOfService: 'Servicevilkår',
  email: 'E-mail',
  playNow: 'Spil nu',
  orContinueWith: 'Eller fortsæt med',
  metamaskExtensionNotAvailable: 'Installer venligst metaMask-udvidelsen og opdater siden.',
  cashier: 'KASSERER',
  account: 'KONTO',
  hello: 'Hej',
  balance: 'Balance',
  referrals: 'Henvisninger',
  settings: 'Indstillinger',
  withdrawals: 'Udbetalinger',
  demoWallet: 'Demo tegnebog',
  myAccount: 'Min konto',
  wallet: 'Pung',
  user: 'Bruger',
  USD: 'USD',
  amountIsRequired: 'Beløb er påkrævet',
  minDepositAmount: 'Indskudsbeløbet skal være større end {{min}}.',
  maxDepositAmount: 'Indskudsbeløbet skal være mindre end {{max}}.',
  minWithdrawAmount: 'Udbetalingsbeløbet skal være større end {{min}}',
  maxWithdrawAmount: 'Udbetalt beløb skal være mindre end {{max}}',
  withdrawMustLessThanWallet: 'Udbetalingsbeløbet skal være mindre end beløbet i din tegnebog',
  accountTypeIsRequired: 'Kontotype er påkrævet.',
  mobileNumberLength: 'Mobilnummer skal være af {{length}} cifre',
  phoneIsRequired: 'Telefon er påkrævet',
  cpfError: 'CPF skal være af {{length}} tegn',
  cpfRequiredError: 'CPF er påkrævet',
  cnpjError: 'CNPJ skal være af {{length}} tegn',
  mainMenu: 'HOVEDMENU',
  casino: 'Kasino',
  sports: 'Sport',
  crypto: 'Krypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'OPtjen belønninger',
  rankUp: 'Rang op',
  inviteFriends: 'Inviter venner',
  partnerProgram: 'Partnerprogram',
  home: 'Hjem',
  sportsbook: 'Sportsbog',
  menu: 'Menu',
  liveBets: 'LIVEBETS',
  allBets: 'Alle væddemål',
  highRollers: 'High Rollers',
  luckyBets: 'Lucky Bets',
  trade: 'Handle',
  game: 'SPIL',
  player: 'SPILLER',
  time: 'TID',
  wager: 'VADDER',
  multiplierBan: 'MULTIPLIER',
  payoutText: 'UDBETALING',
  bannerHeadOne: 'Oplev ægte innovation med det højeste',
  bannerHeadTwo: 'belønningsprogram inden for branchen.',
  registerNow: 'Registrer nu',
  liveWins: 'LIVE VINDER',
  monthly: 'Månedligt',
  weekly: 'Ugentligt',
  daily: 'Dagligt',
  winner1: 'Vinder 1',
  winner2: 'Vinder 2',
  winner3: 'Vinder 3',
  spin: 'Spin',
  spinsWon: 'Spins Vundet',
  joinedOn: 'Tilmeldt den',
  wageringAmount: 'Spillebeløb',
  name: 'Navn',
  allGames: 'Alle spil',
  allSports: 'Al Sport',
  cryptoFutures: 'kryptofutures',
  tradeNow: 'Handl nu',
  allTables: 'Alle borde',
  nft: 'NFT',
  viewAllGames: 'Se alle spil',
  viewAll: 'Se alt',
  rewards: 'BELØNNINGER',
  profile: 'Profil',
  totalBets: 'Samlede indsatser',
  totalWagered: 'Samlet satset',
  joinDate: 'Tilmeldingsdato',
  displaySocialLinks: 'Vis linkede sociale konti',
  privateProfile: 'Privat profil',
  rank: 'Rang',
  winner: 'Vindere',
  nextRank: 'Næste rang',
  changeUsername: 'Skift brugernavn',
  newPassword: 'Nyt kodeord',
  changeName: 'Ændre navn',
  linkSocialAccount: 'Link social konto',
  privateProfileTooltip: 'Efter at have gjort din profil privat, vil andre brugere ikke se dit brugernavn sammen med væddemål, løb osv.',
  socialLinksTooltip: 'Indhold ikke tilgængeligt',
  rankTooltip: 'Indhold ikke tilgængeligt',
  balances: 'saldi',
  createReferralBalance: 'Opret henvisningsbalance',
  total: 'Total',
  switchBalance: 'Skift balance',
  mainAccount: 'Hovedkonto',
  tips: 'Tips',
  receivedAmount: 'Modtaget beløb',
  dateOrTime: 'Dato tid',
  view: 'Udsigt',
  allChains: 'Alle kæder',
  creditAmount: 'Kreditbeløb',
  setCustomReferralCode: 'Indstil tilpasset henvisningskode',
  changeEmail: 'Skift e-mail',
  newEmail: 'Ny email',
  createPassword: 'Opret adgangskode',
  newUsername: 'Nyt brugernavn',
  username: 'Brugernavn',
  changePassword: 'Skift kodeord',
  updateProfileMsg: 'Profilen blev opdateret',
  twoFactorAuthentication: 'to-faktor autentificering',
  twoFactorAuthenticationTitle: 'Det anbefales stærkt at bruge to-faktor-godkendelse, fordi det beskytter din konto med både din adgangskode og din telefon.',
  twoFactorAuthenticationSubTitle: 'En e-mail og adgangskode skal indstilles for at aktivere 2FA. ',
  enable2fa: 'AKTIVER 2FA',
  verifyIdentityByKyc: 'Bekræft din identitet (kyc)',
  oldPassword: 'Gammelt kodeord',
  live: 'Direkte',
  soccer: 'Fodbold',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ishokey',
  volleyball: 'Volleyball',
  tableTennis: 'Bordtennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boksning',
  myBets: 'Mine væddemål',
  favourites: 'Favoritter',
  americanFootball: 'amerikansk fodbold',
  buyCrypto: 'Køb krypto',
  oldEmail: 'Gammel e-mail',
  loggedInSuccess: 'Logget ind',
  loggedOutSuccess: 'Logget ud',
  wagerLimit: 'Indsatsgrænsen er indstillet med succes',
  lossLimit: 'Tabsgrænsen er indstillet med succes',
  accountDisable: 'Kontoen blev deaktiveret',
  sessionTimeout: 'Sessionstimeout blev indstillet',
  signup: 'Tilmelding lykkedes',
  depositLimit: 'Indbetalingsgrænsen er indstillet',
  tokenVerify: 'Token er blevet sendt til din e-mail, bekræft venligst',
  emailVerify: 'E-mail var blevet bekræftet',
  emailChangedSuccess: 'E-mail er blevet ændret',
  profileUpdatedSuccess: 'Profil opdateret',
  otpVerified: 'OTP bekræftet',
  twoFADeactivated: '2FA-godkendelse deaktiveret',
  internalServerError: 'Intern serverfejl',
  unAuthorized: 'Uberettiget',
  dataNotFound: 'Data ikke fundet',
  setReferralCode: 'Indstil henvisningskode...',
  ultimate: 'ULTIMAT',
  notFound: 'Ikke fundet',
  casinoTransactions: 'Kasinotransaktioner',
  sportsbookTransactions: 'Sportsbook-transaktioner',
  status: 'Status',
  success: 'Succes',
  failed: 'mislykkedes',
  pending: 'Verserende',
  date: 'Dato',
  to: 'Til',
  gameName: 'Spilnavn',
  transactionId: 'Transaktions ID',
  roundId: 'Runde ID',
  bet: 'Vædde',
  win: 'Vinde',
  transactionType: 'Transaktionstype',
  at: 'på',
  downloadAll: 'Download alle',
  gameText: 'Spil',
  betId: 'Bet ID',
  gameId: 'Spil ID',
  actionType: 'Handlingstype',
  searchGames: 'Søg spil',
  provider: 'Udbydere',
  search: 'Søg',
  sortBy: 'Sorter efter',
  popular: 'Populær',
  challengePool: 'Udfordringspuljen',
  loadMore: 'Indlæs mere',
  bitcoin: 'Bitcoin',
  chat: 'Snak',
  trades: 'Handler',
  battles: 'Kampe',
  loginToChat: 'Log ind for at chatte',
  rules: 'Regler',
  chatRule1: 'Undlad at chikanere eller fornærme andre brugere',
  chatRule2: 'Lad være med at tigge, bede om lån, triviaer eller tips',
  chatRule5: 'Annoncer ikke nogen form for handel, køb eller salg af tjenester',
  chatRule6: 'Del eller reklamer ikke for din henvisningskode',
  chatRule7: 'Spørg ikke om at blive medarbejder',
  chatRule8: 'kun engelsk',
  chatRule9: 'Respekter mods, administratorer og andre brugere',
  send: 'Sende',
  expand: 'Udvide',
  signIn: 'Log ind',
  cross: 'Kryds',
  collapse: 'Bryder sammen',
  emailPlaceholder: 'din e-mail@domæne.com',
  removeFromFavourite: 'Fjern fra favoritter',
  addToFavourite: 'Tilføj til favoritter',
  footerAboutSite: `Lad os tale lidt om videnskab? Cash Machine Casino's hjemmeside er en del af dig, da vi
alle består af de samme atomer. Din interaktion med det bekræfter denne forbindelse.`,
  footerRightsReserved: 'Сash Machine Casino alle rettigheder forbeholdes.',
  signupFirstName: 'Fornavn',
  signupAddress: 'Adresse',
  signupCity: 'By',
  signupPostcode: 'Postnummer ',
  signupCounty: 'Land',
  signupCurrency: 'betalingsmiddel',
  signupGender: 'Køn',
  signupMan: 'Mand',
  signupWomen: 'Kvinder',
  signupOther: 'Andet',
  signupLoginDetails: 'Log på detaljer',
  signupPersonalDetails: 'Personlige detaljer',
  signupConfirm: 'Bekræfte',
  signupPrivacyPolicy: 'Jeg har læst og accepteret fortrolighedspolitikken',
  signupTermAndConditions: 'Jeg accepterer behandlingen af ​​mine personoplysninger. ',
  signupNewsLetter: 'Jeg vil gerne modtage bonusser, kampagner, nyheder eller anden relevant information med jævne mellemrum via nyhedsbrev.',
  signupSms: 'Jeg vil gerne modtage bonusser, kampagner, nyheder eller anden relevant information med jævne mellemrum på SMS.',
  initialHeaderContent: 'Få fantastiske 100 %',
  secoundaryHeaderContent: 'Velkomstbonus op til $100',
  playNowButton: 'Spil nu',
  registerButtonContent: 'Tilmeld',
  results: 'Resultater',
  refresh: 'Opdater',
  cancel: 'Afbestille',
  select: 'Vælg',
  current: 'Nuværende',
  set: 'Sæt',
  remove: 'Fjerne',
  save: 'Gemme',
  not: 'ikke',
  before: 'Før',
  after: 'Efter',
  action: 'Handling',
  withdrawal: 'Tilbagetrækning',
  requested: 'Anmodet',
  withdraw: 'Træk tilbage',
  areYouSure: 'Er du sikker',
  yes: 'Ja',
  no: 'Ingen',
  cancelled: 'Aflyst',
  approved: 'godkendt',
  refunded: 'Refunderet',
  chargeback: 'Tilbageførsel',
  processedOn: 'Behandlet den',
  transaction: 'Transaktion',
  dateTime: 'Dato tid',
  more: 'Mere',
  fromDate: 'Fra dato',
  toDate: 'Til dato',
  percentage: 'Procent',
  info: 'Info',
  min: 'Min',
  max: 'Maks',
  change: 'Lave om',
  continueToSite: 'Fortsæt til webstedet',
  choose: 'Vælge',
  enterWithdrawalAmount: 'Vælg eller indtast dit udbetalingsbeløb',
  capDeposit: 'DEPOSITUM',
  capWithdrawal: 'TILBAGETRÆKNING',
  capAmount: 'BELØB',
  minimum: 'Minimum',
  required: 'Påkrævet',
  of: 'af',
  isRequired: 'er påkrævet',
  isAllowed: 'er tilladt',
  method: 'Metode',
  capWithdraw: 'TRÆK',
  showLess: 'Vis mindre',
  clearFilter: 'Ryd filter',
  quantity: 'Antal',
  reset: 'Nulstil',
  incorrect: 'Ukorrekt',
  load: 'belastning',
  applied: 'Anvendt',
  ok: 'Okay',
  list: 'Liste',
  all: 'Alle',
  favorite: 'Favorit',
  endPointNotFound: 'Der er noget galt med netværksforbindelsen',
  category: 'Kategori',
  emailUserNameRequired: 'E-mail/brugernavn påkrævet',
  emailUserNamePlaceHolder: 'Indtast din e-mail eller brugernavn',
  passwordRequired: 'Adgangskode påkrævet',
  accountFrozen: 'Din konto er frosset indtil',
  resendEmail: 'Send e-mail igen',
  resendLink: 'SEND LINK GEN',
  userLoggedOut: 'Logget ud',
  emailAlreadyRegistered: 'Denne email er allerede registreret',
  userNameAlreadyTaken: 'Dette brugernavn er allerede taget',
  fillAllFields: 'Udfyld alle felter, før du går videre til næste trin',
  pleaseAccept: 'Accepter venligst',
  acceptPrivacyPolicy: 'Fortrolighedspolitik',
  acceptTerms: 'Regler og vilkår',
  modeOfComm: ' og kommunikationsmåde',
  beforeNextStep: 'før du går videre til næste trin',
  userNamePlaceholder: 'Indtast brugernavn',
  min8Characters: 'Minimum 8 tegn',
  min1Number: 'Min 1 nummer',
  min1LowerCase: 'Min. 1 lille bogstav',
  min1UpperCase: 'Minimum 1 stort bogstav',
  min1SpecialChar: 'Min 1 specialtegn',
  passwordTip: 'Adgangskoden skal have mindst otte tegn med mindst et stort bogstav, et lille bogstav, et tal og et specialtegn',
  passwordPlaceholder: 'Indtast adgangskode',
  confirmPasswordPlaceholder: 'Indtast Bekræft adgangskode',
  acceptAll: 'Accepter alle',
  firstNamePlaceholder: 'Indtast fornavn',
  lastNamePlaceholder: 'Indtast efternavn',
  addressPlaceholder: 'Indtast adresse',
  cityPlaceholder: 'Indtast by',
  postCodePlaceholder: 'Indtast postnummer',
  phonePlaceholder: 'Indtast telefonnummer',
  invalidEmail: 'Ugyldig email',
  emailRequired: 'E-mail påkrævet',
  confirmPasswordRequired: 'Bekræft adgangskode påkrævet',
  firstNameRequired: 'Fornavn påkrævet',
  lastNameRequired: 'Efternavn påkrævet',
  phoneRequired: 'Telefon påkrævet',
  dateOfBirthRequired: 'Fødselsdato påkrævet',
  genderRequired: 'Køn påkrævet',
  userNameRequired: 'Brugernavn påkrævet',
  addressRequired: 'Adresse påkrævet',
  cityRequired: 'By påkrævet',
  postCodeRequired: 'Postnummer påkrævet',
  currencyCodeRequired: 'Valutakode påkrævet',
  countryRequired: 'Land påkrævet',
  matchPassword: 'Adgangskode skal matche',
  fName3Chars: 'Fornavn skal være på mindst 3 tegn',
  onlyAlphabet: 'Kun alfabeter tilladt',
  lName3Chars: 'Efternavn skal være på mindst 3 tegn',
  mustBe18: 'Du skal være fyldt 18 år for at blive tilmeldt',
  mustBeUnder200: 'Du skal være under 200 år for at blive registreret',
  validDOB: 'Indtast venligst en gyldig fødselsdato',
  max20Characters: 'Maksimalt 20 tegn tilladt',
  min3Characters: 'Minimum 3 tegn påkrævet',
  max100Characters: 'Maksimalt 100 tegn tilladt',
  max50Characters: 'Maksimalt 50 tegn tilladt',
  invalidNumber: 'Ugyldigt nummer',
  preferredLanguageRequired: 'Foretrukket sprog påkrævet',
  invalidPass: 'forkert kodeord',
  prevBtn: 'Tidligere',
  dayRequired: 'Dag er påkrævet',
  monthRequired: 'Måned er påkrævet',
  yearRequired: 'Årstal er påkrævet',
  uploadImage: 'Upload billede',
  removeImage: 'Fjern billede',
  preferredLanguage: 'foretrukne sprog',
  wantToChangePass: 'Vil du ændre adgangskode?',
  selectCountryCode: 'Vælg landekode',
  selectPreferredLanguage: 'Vælg Foretrukket sprog',
  fName50Chars: 'Fornavn skal være på højst 50 tegn',
  lName50Chars: 'Efternavn skal være på højst 50 tegn',
  invalidDate: 'Ugyldig dato',
  DOBEarlyThanToday: 'Fødselsdatoen skal være tidligere end i dag',
  enterValidPhone: 'Indtast venligst et gyldigt telefonnummer',
  accountStatus: 'Kontostatus',
  kycVerified: 'Din KYC var blevet bekræftet.',
  denied: 'Afvist',
  chooseFile: 'Vælg fil',
  upload: 'Upload',
  countryCantChange: 'Du er ved at påbegynde KYC-bekræftelse. ',
  proceed: 'Fortsæt',
  update: ' ',
  history: 'Historie',
  type: 'Type',
  amount: 'Beløb',
  forfeited: 'Fortabt',
  expired: 'Udløbet',
  completed: 'Afsluttet',
  zeroedOut: 'Nulstillet ud',
  active: 'Aktiv',
  lapsed: 'bortfaldet',
  yourActiveBonus: 'Dine aktive bonusser',
  currentActiveBonus: 'Aktuelle aktive bonusser',
  bonusStatus: 'Status',
  bonusWager: 'væddemål',
  bonusActivate: 'Aktiver',
  bonusForfeit: 'Bonus fortabt',
  notUsableBonus: 'Hvis ja, vil du ikke kunne bruge denne bonus igen.',
  bonusLapsed: 'Du havde ikke nok tab til at få cashback. ',
  inProcess: 'I gang',
  claiming: 'Hævde',
  loyaltyPopover: 'Disse loyalitetspoint kan bruges til at få cashback. ',
  loyaltyPage: 'Loyalitetsside',
  loyaltyPerCurrency: 'Loyalitet pr. valuta',
  maxLevelReached: 'Max niveau nået',
  pointsToReach: 'punkter at nå ',
  loyaltyBannerHeading: 'Du fortjener vores splinternye loyalitet',
  loyaltyBannerDesc: '100% velkomstbonus op til $200',
  loyaltyHeadingOne: 'Du bliver altid belønnet hos CashMachine',
  loyaltyHeadingOneDesc: 'Hos CashMachine tager vi loyalitet til et helt nyt niveau af spænding, og overøser dig med belønninger ved hvert drej og drejning! ',
  loyaltySubHeadOne: 'Optjening af loyalitetspoint',
  loyaltySubHeadOneDesc: 'For hver €10 i kontante væddemål placeret på vores casino, optjener du 1 loyalitetspoint. ',
  loyaltySubHeadTwo: 'Bestigning af loyalitetsstigen',
  loyaltySubHeadTwoDesc: 'Jo flere point du samler, jo højere stiger du gennem loyalitetsniveauerne og låser op for endnu flere fantastiske belønninger undervejs. ',
  loyaltySubHeadThree: 'Op i niveau, bliv belønnet ',
  loyaltySubHeadThreeDesc: 'Løft din spiloplevelse ved at gå videre gennem vores lokkende belønningsniveauer, hvor hvert niveau op låser op for et spændende udvalg af gratis spins for at maksimere dine chancer for at vinde. ',
  loyaltyTestimonialHeadOne: 'HOS CashMachine CASINO SIKKER VI DET',
  loyaltyTestimonialHeadTwo: 'Hvert spin tæller',
  loyaltyTestimonialDesc: 'Hvert spin, du tager på slots, er ikke kun for sjov, men bringer dig også tættere på nogle seriøst søde belønninger! ',
  loyaltyPoints: 'Points',
  headerLevel: 'niveau',
  loyaltyCashback: 'Penge tilbage',
  loyaltyBannerBtn: 'Tjek fremskridt',
  loyaltyHeadingTwo: 'Lås op for Ultimate Gaming Experience: CashMachine VIP-programmet',
  loyaltyHeadingTwoDesc: 'Når du når niveau 6 i vores loyalitetsprogram, låser du op for en verden af ​​eksklusive privilegier som medlem af det prestigefyldte CashMachine VIP-program. ',
  loyaltySubHeadFour: 'Højere ind- og udbetalingsgrænser',
  loyaltySubHeadFourDesc: 'Nyd fleksibiliteten ved øgede indbetalings- og udbetalingsgrænser som VIP-medlem.',
  loyaltySubHeadFive: 'Eksklusive bonusser og kampagner',
  loyaltySubHeadFiveDesc: 'Få adgang til specielle bonusser og kampagner skræddersyet til vores VIP-spillere',
  loyaltySubHeadSix: 'Personal Account Manager',
  loyaltySubHeadSixDesc: 'Modtag dedikeret support og personlig assistance fra din account manager.',
  loyaltySubHeadSeven: 'Hurtigere tilbagetrækningstider',
  loyaltySubHeadSevenDesc: 'Som VIP vil du nyde hurtige udbetalingstider, hvilket sikrer, at du kan få adgang til dine gevinster endnu hurtigere.',
  loyaltySubHeadEight: 'Månedlige Cashback-belønninger',
  loyaltySubHeadEightDesc: 'Boost din bankroll med generøse månedlige cashback-belønninger eksklusivt for vores VIP-medlemmer.',
  loyaltySubHeadNine: 'Invitationer til eksklusive begivenheder og turneringer',
  loyaltySubHeadNineDesc: "Få fingrene i eftertragtede invitationer til eksklusive begivenheder og turneringer, hvor du kan blande dig med andre VIP'er og konkurrere om utrolige præmier.",
  loyaltySubHeadTen: 'Fødselsdagsbonusser',
  loyaltySubHeadTenDesc: 'Fejr din specielle dag med stil med en dejlig fødselsdagsbonus til dig!',
  loyaltySubHeadEleven: 'Luksus gaver',
  loyaltySubHeadElevenDesc: 'Forkæl dig selv med et udsøgt udvalg af luksusgaver, der er håndplukket til at belønne vores mest værdsatte spillere.',
  loyaltyTableHeading: 'Oversigt over niveauer og belønninger',
  loyaltyTableHeaderOne: 'Loyalitetsniveau',
  loyaltyTableHeaderTwo: 'Loyalitetspoint',
  loyaltyTableHeaderThree: 'Daglig Cashback',
  promReadMore: 'Læs mere',
  currentPortalBlock: 'hvis du kun vil blokere din konto for denne portal.',
  allPortalBlock: 'at blokere din konto fra alle portaler.',
  limit24Reset: 'Når du har indstillet grænser for indsatser, tab, indbetalinger, vil de kunne redigeres og fjernes efter 24 timer, men grænserne kan sænkes med det samme.',
  enterAmount: 'Indtast beløb',
  limit: 'Begrænse',
  loss: 'Tab',
  takeABreak: 'Tag en pause',
  session: 'Session',
  selfExclusion: 'Selvudelukkelse',
  used: 'Brugt',
  edit: 'Redigere',
  updatedAt: 'Opdateret kl',
  cannotBeRemoved: 'Kan ikke fjernes før',
  timePeriod: 'Tidsperiode',
  custom: 'Brugerdefinerede',
  hours: 'Timer',
  days: 'Dage',
  timePeriodPlaceholder: 'Antal dage',
  months: 'måneder',
  permanent: 'Permanent',
  onlyNumbers: 'Kun tal tilladt',
  notNumbers: 'Tal ikke tilladt',
  userNameAllowed: 'Skal starte med alfabetet. ',
  timePeriodRequired: 'Tidsperiode påkrævet',
  cannotBeIncreased: 'Kan ikke øges før',
  amountGTZero: 'Beløbet skal være større end 0',
  amountEqualOrLess: 'Beløbet skal være lig med eller mindre end ugentligt og månedligt',
  amountInBetween: 'Beløbet skal ligge mellem dagligt og månedligt (inklusive)',
  amountEqualOIrGreater: 'Beløbet skal være lig med eller større end dagligt og månedligt',
  limitRemoved: 'Din følgende grænse vil blive fjernet.',
  limitSet24Hrs: 'Du er ved at sætte følgende grænse for din konto. ',
  takeABreakInfo: 'Du er ved at blokere adgangen til din konto i en forudbestemt periode. ',
  sessionLimitInfo: 'Du er ved at sætte følgende grænse for din konto. ',
  limitCantSetBefore: 'Din sessionsgrænse kan ikke indstilles før',
  selfExclusionInfo: 'Du er ved at blokere adgangen til din konto i en forudbestemt periode. ',
  quickSearch: 'Hurtig søgning',
  cash: 'Kontanter',
  nonCash: 'Ikke-kontant',
  userDetail: 'Brugerdetaljer',
  gameIdentifier: 'Spilidentifikator',
  rollback: 'Rul tilbage',
  rollbackBeforeBetWin: 'Tilbageføring før indsatsvind',
  freeSpins: 'Gratis spins',
  betInternal: 'Sats internt',
  winInternal: 'Vind internt',
  addMoney: 'Tilføj penge',
  removeMoney: 'Fjern penge',
  addMoneyInternal: 'Tilføj penge internt',
  removeMoneyInternal: 'Fjern penge internt',
  depositInternal: 'Indbetaling internt',
  withdrawInternal: 'Træk internt tilbage',
  promotionTitle: 'Kampagnetitel',
  cancelWithdrawRequest: 'Du ønsker at annullere denne anmodning',
  rowsPerPage: 'Rækker pr. side',
  availedBonus: 'Du har allerede en aktiv bonus. ',
  capForfeit: 'FORBAGET',
  itFirst: 'det først.',
  selectYourBonus: 'Vælg din bonus',
  skip: 'SPRINGE',
  with: 'med',
  without: 'uden',
  selectPaymentMethod: 'Vælg venligst din betalingsmetode',
  volatility: 'Volatilitet',
  paylines: 'Gevinstlinjer',
  theme: 'Tema',
  tryForFree: 'Prøv gratis',
  resultsFound: 'Resultater fundet',
  games: 'Spil',
  in: 'i',
  low: 'Lav',
  medium: 'Medium',
  high: 'Høj',
  'medium-high': 'Middel høj',
  'very-high': 'Meget høj',
  'low-medium': 'Lav Middel',
  fantasy: 'Fantasi',
  ancient_civilizations: 'Gamle civilisationer',
  fruits: 'Frugter',
  africa: 'Afrika',
  military: 'Militær',
  joker: 'Joker',
  asia: 'Asien',
  luxurylife: 'Luksus liv',
  underwater_world: 'Undervandsverden',
  book_off: 'Bookoff',
  western: 'Vestlig',
  retro: 'Retro',
  egypt: 'Egypten',
  party: 'Parti',
  st_patrick_day: 'St Patrick dag',
  space: 'Plads',
  easter: 'påske',
  girls: 'Piger',
  branded: 'Mærkemærket',
  x_mas_and_new_year: 'jul og nytår',
  horrors: 'Rædsler',
  other: 'Andet',
  loginFirst: 'Log venligst først ind for at tilføje spil i favorit',
  loginFirstPlay: 'Log venligst først ind for at spille dette spil',
  sport: 'Sport',
  pirates: 'Pirater',
  sweets: 'Søde sager',
  luxury_life: 'Luksus liv',
  st_valentines_day: 'St Valentinsdag',
  halloween: 'Halloween',
  food: 'Mad',
  lobby: 'lobby',
  favourite: 'Favorit',
  playNGO: 'Spil N Go',
  loyaltySystem: 'Loyalitetssystem',
  progressionSystem: 'Progressionssystem',
  startPoint: 'Startpunkt',
  endPoint: 'Slutpunkt',
  loyaltyBonus: 'Loyalitetsbonus',
  pageRemoved: 'Denne side eksisterer ikke eller blev fjernet',
  suggestToBackHome: 'Vi foreslår, at du vender tilbage til hjemmet',
  backToHome: 'Tilbage til hjemmet',
  goHome: 'Gå hjem',
  oopps: 'Ups',
  somethingNotWorking: 'Vi beklager, noget virker ikke her!',
  tournament: 'Turnering',
  none: 'Ingen',
  validOnDays: 'Gælder på dage',
  validTill: 'Gyldig til',
  yourRequest: 'Din anmodning',
  withdrawalRequestPending: 'Du har én udbetalingsanmodning i afventende tilstand. ',
  realBalance: 'Virkelig balance',
  bonusInCasino: 'Bonus i kasinoet',
  bonusSelected: 'Bonus valgt',
  payWith: 'Betal med',
  reached: 'Nået',
  receiptOfYour: 'Kvittering for din',
  capEmail: 'EMAIL',
  capCreditCard: 'KREDITKORTNUMMER',
  capExpiry: 'UDLØB',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY er ikke i korrekt format',
  min13Numbers: 'Minimum 13 numre påkrævet',
  max19Numbers: 'Maksimalt 19 numre tilladt',
  min3Numbers: 'Minimum 3 numre påkrævet',
  max4Numbers: 'Maksimalt 4 numre tilladt',
  Other: 'Andet',
  forfeit: 'Fortabt',
  withdrawRequestCreated: 'Tilbagetrækningsanmodning oprettet',
  noBetHistory: 'Ingen indsatshistorik fundet',
  noPromotions: 'Ingen kampagner fundet',
  noBonuses: 'Ingen bonusser fundet',
  noHistory: 'Ingen historie fundet',
  noWithdrawalHistory: 'Ingen tilbagetrækningshistorik fundet',
  noCMS: 'Intet CMS fundet',
  noProvidersFound: 'Ingen udbydere fundet',
  noDataFound: 'Ingen data fundet',
  countryBlocked: 'LAND BLOKERET',
  forbidden: 'FORBUDT',
  notAcceptingVisitors: 'Vi er dybt kede af dette, men shinywilds.com er ikke tilgængelig i dit land.',
  contactUsAt: 'Hvis du mener, at dette blev vist til dig ved en fejl, bedes du kontakte vores support via e-mail på',
  siteUnderMaintenance: 'Site under vedligeholdelse',
  weWillBeRightBack: 'Vi kommer straks tilbage!',
  serviceUnavailable: 'Vores service er midlertidigt utilgængelig. ',
  tryAgainLater: 'Prøv igen senere.',
  checkYourEmail: 'Tjek din e-mail',
  sentAVerification: 'Vi har sendt et bekræftelseslink',
  toYourEmail: 'til din e-mail.',
  backToLogin: 'Tilbage til login',
  goToLogin: 'Gå til Login',
  accountVerified: 'Konto bekræftet',
  yourAccountVerified: 'Din konto er blevet bekræftet',
  successfully: 'Succesfuldt',
  verificationFailed: 'Bekræftelse mislykkedes',
  emailFailedContact: 'Bekræftelse mislykkedes. Kontakt venligst, hvis dette sker konstant ',
  setNewPass: 'Indstil en ny adgangskode',
  passResetLink: 'Vi har sendt et link til nulstilling af adgangskode til',
  toYourAccount: 'Til din konto',
  sendPasswordResetLink: 'Anmod om at nulstille adgangskode',
  gamePlayWagerComplete: 'Tillykke, du har gennemført væddemål, og de vundne bonuspenge er større end det tilladte maksimale gevinstbeløb. ',
  real: 'Ægte',
  demo: 'Demo',
  money: 'Penge',
  formatRquired: '.png , .jpg, .jpeg-format påkrævet',
  chooseImage: 'Vælg venligst et billede',
  imageSize3MB: 'Indtast billede med en størrelse på mindre end 3 MB',
  noProfileImage: 'Intet profilbillede tilgængeligt at fjerne',
  limitSetSuccess: 'grænse indstillet med succes',
  accountDisabled: 'Kontoen blev deaktiveret',
  loginSuccess: 'Login lykkedes',
  signupSuccess: 'Tilmelding lykkedes',
  linkExpired: 'Linket er udløbet',
  linkSent: 'Link sendt',
  accAlreadyVerified: 'Konto allerede bekræftet. ',
  profileImage: 'Profilbillede',
  profileImageRemoved: 'Profilbilledet blev fjernet',
  docs: 'Dokumenter',
  updatedSuccess: 'Opdateret med succes',
  MATCH: 'MATCH',
  BALANCE: 'BALANCE',
  FREESPINS: 'GRATISSPINS',
  MATCH_1: 'MATCH',
  DEPOSIT: 'DEPOSITUM',
  WAGERING: 'VADNING',
  PROMOTION: 'FORFREMMELSE',
  CREDITCARD: 'KREDITKORT',
  WALLET: 'PUNG',
  CRYPTO: 'CRYPTO',
  OTHER: 'ANDET',
  INSTANTBANKING: 'ØJEBLIKKELIG BANK',
  cookieTitle: 'Cookies!',
  declineButton: 'Nedgang',
  acceptButton: 'Acceptere',
  cookieDeclinedMessage: 'For den bedst mulige oplevelse skal cookies accepteres for at fortsætte med at bruge denne hjemmeside. ',
  cookieDescription: 'Velkommen til Shinywilds.com! ',
  forfeitMessageFirst: 'Du har i øjeblikket følgende aktive bonus:',
  forfeitMessageSecond: 'Du skal miste denne bonus, før du kan gøre krav på en ny.',
  forfeitButton: 'Fortabt',
  pleaseVerifyFirstText: 'For at kunne anmode om en tilbagetrækning, skal vi først bekræfte din e-mailadresse. ',
  clickHere: 'Klik her',
  pleaseVerifyLastText: 'så vi kan sende dig bekræftelses-e-mailen igen og fortsætte.',
  pleseWait: 'Vent venligst på',
  toResend: 'for at sende linket igen',
  notVerified: 'Du skal bekræfte din e-mailadresse for at hæve penge',
  verification: 'Verifikation',
  UPLOAD_IMG_MSG: 'Træk og slip filer her, eller klik for at vælge filer',
  affiliate: 'Affiliate',
  affiliateDescription: ' Henvis vores brand og tjen penge ved at deltage i Cash Machine Affiliate Program! ',
  refferalTitle: 'Henvisningsprogram',
  refferalDescription: 'Du kan dele dette henvisningslink med andre og optjene en bonus, hvis brugeren vil registrere sig ved hjælp af dit givne link',
  affiliateButtonText: 'Bliv affiliate',
  affiliateLink: 'Affiliate link',
  affiliateStatus: 'Din affiliate-anmodning er sendt',
  currentStatus: 'og nuværende affiliate status er {{status}}',
  referralLink: 'Henvisningslink',
  copyTitleMessage: 'Kopier venligst dette link',
  copiedMessage: 'Linket er kopieret',
  searchedGame: 'Søgte spil',
  favoriteGame: 'Yndlingsspil',
  randomGames: 'Tilfældige spil',
  poolPrize: 'Præmiepulje',
  chooseYourWelcomeBonus: 'Vælg din velkomstbonus',
  useNow: 'Brug nu',
  readTheTerms: 'Læs vilkårene',
  proceedWithoutWelcomeBonus: 'Fortsæt uden velkomstbonus',
  doYouAlreadyHaveAccount: 'Har du allerede en konto? ',
  registerViaSocialNetworks: 'Tilmeld dig via sociale netværk',
  iconfirmIm18YearsOlder: 'Jeg bekræfter, at jeg er 18 år eller ældre',
  iAgreeWithTermsAndConditions: 'Jeg accepterer vilkår og betingelser',
  thankYouSuccessfullyRegisteringCashMachine: 'Tak fordi du har registreret dig på pengeautomaten.',
  verifyEmailMessage: 'Vi anmoder dig om at bekræfte din e-mail ved at klikke på bekræftelseslinket, der er sendt til din e-mail, for vellykket login på webstedet.',
  gotIt: 'Forstået',
  phonecodeRequired: 'Telefonkode er påkrævet',
  cpNotAllow: 'copy/paste er ikke tilladt',
  playFun: 'Spil sjov',
  playReal: 'Spil Real',
  yourRealBalance: 'Din reelle saldo er:',
  chooseYourPaymentMethod: 'Vælg din betalingsmetode',
  balanceHistory: 'Balance historie',
  liveChat: 'Live Chat',
  mail: 'Post',
  chooseEnterYourDepositAmounts: 'Vælg eller indtast dine indbetalingsbeløb',
  noBonusDataAvailable: 'Ingen tilgængelige bonusdata',
  nowTryPlayForRealBets: 'Prøv nu at spille for rigtige væddemål',
  registerAndPlayForRealBets: 'Registrer og spil for rigtige væddemål',
  tournamentDataNotAvailable: 'Turneringsdata er ikke tilgængelige',
  startDate: 'Start dato',
  endDate: 'Slutdato',
  merchant: 'Købmand',
  seachHere: 'Søg her',
  show: 'At vise',
  betResults: 'Væddemålsresultater:',
  noTransactionsFoundForselectedDate: 'Der blev ikke fundet nogen transaktioner for den valgte dato',
  loginError: 'Login fejl',
  errorMessageIncorrectlyAuthorization: 'E-mail/login eller adgangskode indtastet forkert Godkendelsesfejl.',
  profileSaved: 'Profil gemt',
  addInfo: 'Tilføj info',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Jeg har læst og accepterer betalingssystemernes begrænsninger*',
  paymentOptions: 'Betalingsmuligheder',
  language: 'Sprog',
  betTime: 'Indsatstid',
  noActiveBonusFound: 'Ingen aktiv bonus fundet',
  bonusType: 'Bonus type',
  requiredWageringAmount: 'Påkrævet indsatsbeløb',
  currentWageringAmount: 'Aktuelt indsatsbeløb',
  validUpto: 'Gyldig op til',
  bonusCode: 'Bonuskode',
  wageringProgressBar: 'Indsatslinje',
  noGamesFound: 'Ingen spil fundet',
  noteYouMayOptfreeSpin: 'Bemærk: Du kan vælge FREESPIN på et hvilket som helst af disse spil',
  viewOfferDetails: 'Se tilbudsdetaljer',
  viewFreespinGames: 'Se Freespin-spil',
  depositBonusPercentage: 'Indbetalingsbonus i procent: ',
  validity: 'Gyldighed',
  activated: 'Aktiveret',
  bonusName: 'Bonus navn',
  freeRounds: 'Gratis runder',
  wagering: 'væddemål',
  ended2: 'Afsluttet',
  searchBonusTitle: 'Søg efter bonustitel',
  claimed: 'Påstået',
  noBonusHistoryFoundselectedDates: 'Ingen bonushistorik fundet for udvalgte datoer',
  noBonusDetailsFound: 'Ingen bonusdetaljer fundet',
  enterCode: 'Indtast kode',
  transactionTime: 'Transaktionstid',
  credit: 'Kredit',
  debit: 'Debet',
  // bonusInfoOfHeading: 'Bonusser er ekstra betalinger, der belønner din præstation',
  bonusInfoOfHeading: 'Bonuser er ekstra belønninger, der anerkender din aktivitet og præstationer.',
  onlineCasino: 'online casino',
  isTheTop: ' er toppen ',
  bonusInfoPara0: 'Leder du efter de allerbedste casinospil online? ',
  tournamentsHistory: 'Turneringshistorie',

  loginWelcomeBonusInfo: `Cash Machine byder nye spillere velkommen med en velkomstbonus - 100% af
  indbetalingen + 100 freespins i spillet "Pat's Heroes" (Platipus).
  Det mindste indskudsbeløb er EUR 20 / ARS 900 / AUD 30 / CZK 500
  / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70
  / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,
  loginDepositBonusInfo: `Maksimalt bonusbeløb: 100 % af indbetalingsbeløbet, men ikke mere
  end EUR 100 / ARS 4000 / AUD 150 / CZK 2500 / DKK 750 / NOK 1000
  / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45.000 /
  TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `Gratis spins udstedes inden for 5 dage efter bonusaktiveringen,
  20 gratis spins dagligt. Alle midler vundet på gratis spins krediteres
  bonuskontoen og er tilgængelige for at spille/satse på
  bonus. Udbetaling er muligt efter at have satset X40. Bonusindsatstid
  er 7 dage`,
  loginMaximumBetLimitInfo: `Maksimal indsatsgrænse under indsats: EUR 1 / ARS 45 / AUD 1,5 / CZK
  25 / DKK 7 / NOK 10 / NZD 1,5 / PLN 5 / USD 1 / ZAR 15 / GEL 3,5 /
  KZT 450 / TJS 10 / UZS 10 000 / TRY 6`,

  bonusInfoPara1: ', med et stort udvalg af fantastiske spil og blændende specialtilbud. Vores spilleautomater kommer i en bred vifte af temaer, hvor progressive jackpot spilleautomater giver spillerne en lukrativ chance for at opnå en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet∂ og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for alvor.',
  bonusInfoPara2: 'Vores spilleautomater kommer i en bred vifte af temaer, hvor progressive jackpot spilleautomater tilbyder en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for alvor. Vores spilleautomater kommer i en lang række temaer, med progressive jackpot-spillemaskiner, der giver spillerne en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for alvor.',
  bonusInfoPara3: 'Vores spilleautomater kommer i en bred vifte af temaer, hvor progressive jackpot spilleautomater giver spillerne en lukrativ chance for at opnå en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan først øve dig i demotilstand for at få en fornemmelse af hvert spil, før du vælger at spille for alvor.',
  bonusInfoPara4: 'Vores spilleautomater kommer i en bred vifte af temaer, med progressive jackpot spilleautomater, der giver spillerne en lukrativ chance for at opnå en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for alvor. Vores spilleautomater kommer i en lang række temaer, med progressive jackpot-spillemaskiner, der giver spillerne en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og uddelt i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Bare rolig - du kan først øve dig i demotilstand for at få en fornemmelse af hvert spil, før du vælger at spille for alvor.',
  cashMachineFooterPara1: 'Leder du efter det bedste online casino? Velkommen til ',
  cashMachineFooterPara2: ` hvor vi
kombinerer spændende gameplay, generøse bonusser og eksklusive turneringer for at give
dig det bedste inden for online underholdning. Vil du ramme jackpotten på vores progressive
slots, konkurrere om præmier i turneringer eller prøve lykken med live dealer-spil? Vi har det
hele! Hvad gør os unikke? Hos `,
  cashMachineFooterPara3: `kan du vinde ikke kun pengepræmier, men
også rigtige fysiske belønninger! Vi er stolte af at blive spillernes valg verden over. Klar til at
udforske? Spil ethvert spil tilgængeligt i din region og nyd uovertruffen spænding. Oplev det
bedste, spil med de bedste — kun hos`,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: 'Live Casino, Slots og Drops og vinder casino-turneringer',
  tournamentDescTitle: 'Turneringer er en fantastisk mulighed for at konkurrere med andre spillere og vinde generøse præmier.',
  tournamentPara1: 'Leder du efter de allerbedste casinospil online? Så er du kommet til det rette sted.',
  tournamentPara2: ' med et stort udvalg af fantastiske spil og forbløffende specielle tilbud. Vores spilleautomater kommer i et bredt udvalg af temaer, med progressive jackpot-spil, der tilbyder spillere en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og givet i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Fortvivl ikke - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for rigtige penge.',
  tournamentPara3: 'Vores spilleautomater kommer i et bredt udvalg af temaer, med progressive jackpot-spil, der tilbyder spillere en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og givet i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Fortvivl ikke - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for rigtige penge. Vores spilleautomater kommer i et bredt udvalg af temaer, med progressive jackpot-spil, der tilbyder spillere en lukrativ chance for at score en stor gevinst. Vi tilbyder også spændende live dealer-spil, hvor du kan se dine kort blandet og givet i realtid! Er du i tvivl om, hvilket spil der er det rigtige for dig? Fortvivl ikke - du kan øve dig i demo-tilstand først for at få en fornemmelse af hvert spil, før du vælger at spille for rigtige penge.',
  'New Games': 'Nye spil',
  'Popular Games': 'Populære spil',
  'Games Of The Month': 'Månedens spil',
  Exclusive: 'Eksklusiv',
  'Bonus Buy': 'Bonus Køb',
  'Jackpot Games': 'Jackpot spil',
  lightMode: 'Lys tilstand',
  darkMode: 'Mørk tilstand',
  searchProvider: 'Søgeudbyder',
  tournamentHistoryDataNotAvailable: 'Turneringshistorikdata er ikke tilgængelige',
  viewMore: 'Se mere',
  description: 'Beskrivelse',
  codeCopied: 'Kode kopieret',
  tournamentGames: 'Turneringsspil',
  freeSpinGames: 'Gratis spin spil',
  promoCode: 'Kampagnekode',
  paymentNote: 'Bemærk: Luk ikke eller opdater vinduet, mens transaktionen behandles',
  liveGames: 'Live Spil',
  play: 'SPIL',
  demo1: 'DEMO',
  registration: 'REGISTRERING',
  confirmation: 'Bekræftelse',
  forfeitBonusMessage: 'Hvis du aktiverer denne bonus, vil enhver gammel aktiv bonus blive tabt.',

  bonusConfirmationMessage: 'Er du sikker på, at du vil aktivere denne bonus?',
  forfeitRewardMessage: 'Hvis du aktiverer denne belønning, vil enhver gammel aktiv bonus blive fortabt.',
  rewardConfirmationMessage: 'Er du sikker på, at du vil aktivere denne belønning?',
  reward: 'Belønne',
  purchasedReward: 'Købt belønning',
  noActiveRewardFound: 'Ingen aktiv belønning fundet',
  noRewardDetailsFound: 'Ingen belønning fundet',
  betDate: 'Bet Dato',
  shopDes: 'Butikken er stedet, hvor du kan bytte dine optjente point til forskellige belønninger, bonusser og præmier.',
  vipInfoHeading: 'VIP-bonusser er eksklusive tilbud til vores mest loyale spillere.',
  updatefooter:' leverer eGaming-tjenester og er officielt licenseret af Offshore Finance Authority i staten Anjouan under The Computer Gaming Licensing Act 007 af 2005. Denne hjemmeside ejes og drives af KeyPartnership B.V., (virksomhedsnummer: 166291), registreret og reguleret på Curaçao, med adresse Abraham de Veerstraat 1, Willemstad, Curaçao',
  loginFirstForRandom: 'Log venligst først ind for at se tilfældige spil',
  affiliateTitle: 'Affiliate program'

}
