import React from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CustomFormLabel,
  CustomTextField,
  CustomSelect,
  CustomSearchSelect
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import ErrorMessage from '../ErrorMessage'
import { AuthModalWrapper } from './style'
import AuthDialog from 'components/common-ui/AuthDialog/AuthDialog'
import { useDispatch, useSelector } from 'react-redux'
import { phoneCode } from 'utils/phoneCodes'
import { SocialLoginFormSchema } from './schema'
import { facebookSignup, googleSingup } from 'redux-thunk/thunk/auth.thunk'
import { SpinnerSVG } from 'assets/icons/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { setUserModal } from 'redux-thunk/redux/slices/auth.slice'

const SocialLoginForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const socialToken = params.get('token')
  const signInType = params.get('signInType')
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { currencyData } = useSelector((state) => state.general)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const { authModal } = useSelector((state) => state.auth)

  const signUpSuccess = () => {
    navigate('/')
    dispatch(setUserModal('deposit'))
  }

  const signUpfacebookSuccess = () =>{
    navigate('/')
    dispatch(setUserModal('deposit'))
  }

  const handleFormSubmit = async (values) => {
    const payload = {
      code: socialToken,
      dateOfBirth: values.dateOfBirth,
      currency: values.currency,
      country: values.country,
      mobile: values.mobile
    }
    if( signInType === "Google" ){
     dispatch(googleSingup({ payload, signUpSuccess ,isLogin: false}))
    }
    else if( signInType === "Facebook" ){
    dispatch(facebookSignup({ payload, signUpfacebookSuccess ,isLogin: false}))
    }
  }
  const formatCurrencyOptions = (currencies) => {
    return currencies?.map(currency => (
      <MenuItem key={currency.currencyId} value={currency.code}>
        {(currency.name)}
      </MenuItem>
    ))
  }

  return (
    <AuthDialog
      maxWidth='customModal_lg'
      dialogOpen={authModal}
      aria-labelledby='customized-dialog-title'
    >
      <AuthModalWrapper theme={theme}>

        <Box className='form-wrap'>
          <Formik
            initialValues={{ dateOfBirth: '', currency: '', country: '', mobile: '' }}
            validationSchema={SocialLoginFormSchema(t)}
            onSubmit={handleFormSubmit}
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>{t('profileDob')} *</CustomFormLabel>
                    <CustomTextField
                      fullWidth
                      type='date'
                      name='dateOfBirth'
                      maxDate={new Date()}
                      value={values.dateOfBirth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage component='p' name='dateOfBirth' className='error-msg' />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>{t('signupCurrency')} *</CustomFormLabel>
                    <CustomSelect
                      fullWidth
                      name='currency'
                      options={formatCurrencyOptions(currencyData?.currency)}
                      value={values.currency}
                      onChange={handleChange}
                    >
                      <MenuItem value='' disabled>{t('signupSelectCurrency')}</MenuItem>
                      {formatCurrencyOptions(currencyData?.currency)}
                    </CustomSelect>
                    <ErrorMessage component='p' name='currency' className='error-msg' />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>{t('signupCounty')} *</CustomFormLabel>
                    <CustomSearchSelect
                      fullWidth
                      type='text'
                      name='country'
                      value={phoneCode?.filter((item) => item?.code === values.phoneCode)?.[0]}
                      options={phoneCode}
                      getOptionLabel={(option) => option.name}
                      rennderOptions={(options) => (
                        <Box>{options.code}</Box>
                      )}
                      onChange={(e, phone) => {
                        setFieldValue('country', phone?.code)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <ErrorMessage component='p' name='country' className='error-msg' />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>{t('mobile')} *</CustomFormLabel>
                    <CustomTextField
                      fullWidth
                      type='text'
                      name='mobile'
                      placeholder='Enter mobile number'
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage component='p' name='mobile' className='error-msg' />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomPrimaryButton fullWidth type='submit' onClick={handleSubmit}>
                      {loading ? <SpinnerSVG style={{ fill: 'white' }} /> : t('Submit')}
                    </CustomPrimaryButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>

      </AuthModalWrapper>
    </AuthDialog>
  )
}

export default React.memo(SocialLoginForm)
