export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Tuleva',
  finished: 'Valmis',
  prizePool: 'Palkintopotti',
  joinNow: 'Liity nyt',
  joined: 'Liittyi',
  startsIn: 'Alkaa sisään',
  endsIn: 'Päättyy sisään',
  ended: 'Turnaus päättynyt',
  started: 'Turnaus alkanut',
  seeMore: 'Katso lisää',
  winPrizes: 'Voita jännittäviä palkintoja',
  leaderBoard: 'Tulostaulu',
  prize: 'Palkinto',
  aboutTournament: 'Tietoja Turnauksesta',
  congratulationsMessage: 'Bravo! ',
  payments: 'Maksu',
  tournaments: 'Turnaukset',
  shop: 'Myymälä',
  blog: 'Blogi',
  referral: 'Viittaus',
  contacts: 'Yhteystiedot',
  retry: 'Yritä uudelleen',
  incorrectCredsMessage: 'Käyttäjätunnuksesi tai salasanasi on väärä. ',
  bonuses: 'Bonus',
  depositBonus: 'Talletusbonus',
  voucher: 'Lahjakortti',
  gamlings: 'Uhkapelit',
  transactionHistory: 'Tapahtumahistoria',
  offers: 'Tarjoukset',
  editProfile: 'Muokkaa profiilia',
  continue: 'Jatkaa',
  apply: 'Käytä',
  male: 'Uros',
  female: 'Nainen',
  register: 'Rekisteröidy',
  login: 'Kirjaudu sisään',
  grand: 'Grand',
  major: 'Suuri',
  minor: 'Pieni',
  mini: 'Mini',
  crashGameTitle: 'Crash',
  betAmount: 'Panoksen määrä',
  profit: 'Voitto',
  payout: 'Tavoitemaksu',
  chance: 'Voita mahdollisuus',
  betBtn: 'Veto',
  autoCashout: 'Automaattinen nosto',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Panostettu',
  nothingFound: 'Mitään ei löytynyt',
  lost: 'Kadonnut',
  won: 'Voitti',
  streak: 'Viiva',
  highestWin: 'Korkein voitto',
  promotions: 'Kampanjat',
  latestPromotions: 'Uusimmat tarjoukset',
  sponsorships: 'Sponsorointi',
  highestLost: 'Korkein kadonnut',
  highestBet: 'Korkein veto',
  maxStreak: 'Max Streak',
  maxProfit: 'Suurin voitto: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Vetohistoriasi on tyhjä',
  messagesCopyNotSupported: 'Selaimesi ei tue automaattista kopiointia leikepöydälle. Yritä kopioida koodi manuaalisesti.',
  messagesCopied: 'Osoite kopioitu.',
  deposit: 'Tallettaa',
  statReset: 'Nollaa tilastot',
  rollOver: 'Kierähdä',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Takaisin',
  inputFieldBtnMax: 'max',
  buttonTextPlaceBet: 'Aseta veto',
  buttonTextCancelBet: 'Peruuta veto',
  buttonTextEscapeBet: 'Kotiuttaminen',
  buttonTextStartAutoBet: 'Käynnistä Autobet',
  buttonTextCancelAutoBet: 'Peruuta automaattinen veto',
  buttonTextLoading: 'Ladataan',
  tabsMyBets: 'Omat vedot',
  tabsDeposit: 'Tallettaa',
  tabsCashout: 'Kotiutukset',
  tabsAllbets: 'Kaikki vedot',
  tabsTopbets: 'Parhaat vedot',
  tabsBonus: 'Bonukset',
  tabsjackpot: 'Jättipotti',
  tabsBetHistory: 'Vedonlyöntihistoria',
  timerTextCurrentPayout: 'Nykyinen maksu',
  timerTextStarting: 'Alkaa sisään',
  timerTextSec: 's',
  timerTextFlewAway: 'Törmäsi',
  timerTextWait: 'Odota..',
  timerTextPreparingRound: 'Valmistelee kierrosta',
  timerTextLoadingRound: 'Latauskierros',
  comingSoon: 'Tulossa pian',
  labelsProfitOnWin: 'Voitto voitolla',
  inputsNoOfBets: 'Vetojen määrä',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'alkaa',
  inputsCancel: 'Peruuttaa',
  buttonsMin: 'Min',
  buttonsMax: 'Max',
  prediction: 'Ennustus',
  luckyNumber: 'Onnennumero',
  multiplier: 'Kerroin',
  chanceToWin: 'Mahdollisuus voittaa',
  bets: 'Vedot',
  depositBtn: 'Tallettaa',
  rollUnder: 'Roll Under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'Historia',
  navBarMyBets: 'Omat vedot',
  navBarAllBets: 'Kaikki vedot',
  navBarHighRollers: 'High Rollers',
  navBarRoundHistory: 'Pyöreä historia',
  navBarTotal: 'Panokset yhteensä',
  topHeaderBetId: 'Vedon tunnus',
  topHeaderUser: 'Käyttäjä',
  topHeaderPayout: 'Maksu',
  topHeaderAmount: 'Määrä',
  topHeaderTime: 'Aika',
  topHeaderProfit: 'Voitto',
  topHeaderRoundCrashed: 'Törmäsi',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Veto',
  topHeaderRoundProfit: 'Voitto',
  topHeaderRoundSignature: 'Allekirjoitus',
  roundHistoryTitle: 'PELITIEDOT',
  roundHistoryDescGame: 'Peli',
  roundHistoryDescCrashAt: 'Törmäsi klo:',
  roundHistoryDescDate: 'Päivämäärä :',
  roundHistoryDescBetHeader: 'Veto',
  roundHistoryTableTitlePlayer: 'PELAAJA',
  roundHistoryTableTitleBet: 'VETO',
  roundHistoryTableTitleCashedOut: 'POISTETTU',
  roundHistoryTableTitleProfit: 'VOITTO',
  roundHistoryTableTitleBetId: 'BET ID',
  roundHistoryMessagesNoBetHistory: 'Vetohistoriasi on tyhjä',
  placedBetsTabPlaced: 'Laitettu veto',
  placedBetsTabPrev: 'Edellinen veto',
  placedBetsTitleUser: 'Käyttäjä',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Veto',
  placedBetsTitleProfit: 'Voitto',
  placedBetsTitleCashout: 'Kotiuttaminen',
  placedBetsNoPlacedBet: 'Ei asetettuja vetoja.',
  provablyFairContentCheckBtn: 'Tarkista Provable Fair',
  provablyFairContentTitle: 'Todistettavasti reilua',
  provablyFairContentDesc: 'Neljän puolen johdon tulos voidaan todistaa oikeudenmukaiseksi. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Kaatumisprosentti',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Törmäysprosentti ilman x:ää',
  provablyFairContentInputFieldsRoundHashTitle: 'Pyöreä hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'esim. ',
  provablyFairContentInputFieldsSubmitBtn: 'Luo allekirjoitus',
  provablyFairContentSignature: 'Pyöreä allekirjoitus',
  provablyFairContentErrorsCrashRate: 'syötä numero kaatumisnopeuskenttään',
  resetPasswordTitle: 'Nollaa salasana',
  resetPasswordNewPasswordTitle: 'uusi salasana',
  resetPasswordNewPasswordErrorsRequired: '*salasana tarvitaan.',
  resetPasswordNewPasswordErrorsMinLength: 'Vähintään 8 merkkiä sallittu.',
  resetPasswordNewPasswordErrorsMaxLength: 'Enintään 16 merkkiä sallittu.',
  resetPasswordNewPasswordErrorsPattern: '*Salasanan tulee olla 8–16 aakkosnumeerista ja erikoismerkkiä. ',
  resetPasswordConfirmPasswordTitle: 'Vahvista salasana',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Salasanat eivät täsmää.',
  resetPasswordResetBtn: 'Nollaa',
  passwordResetSuccess: 'Salasanan vaihto onnistui, kirjaudu sisään nauttiaksesi pelaamisesta!',
  resetPasswordWentWrong: 'Jotain meni pieleen!',
  levelGrand: 'GRAND',
  levelMajor: 'SUURI',
  levelMinor: 'ALAKOHTAINEN',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Voita päivittäin 1000 dollaria ',
  bannerContentContest: 'Kilpailut nyt ',
  bannerContentjoin: 'Liity päivittäiseen panostuskilpailuumme ja tule sankariksi!  ',
  bannerContentPlayNow: 'Pelaa nyt',
  bannerContentTitle: 'Voita jättipotti',
  bannerContentContent: 'Leiki enemmän',
  bannerContentGameName: 'CRASH-PELI',
  bannerContentGameDesc: 'Leiki enemmän',
  partners: 'Yhteistyökumppanit',
  gamesGoldenDragon: 'kultainen lohikäärme',
  gamesCrashGame: 'Crash peli',
  gamesDice: 'Dice',
  gamesHilo: 'Hei',
  allBetsTitle: 'Kaikki vedot',
  allBetsBetId: 'Vedon tunnus',
  allBetsUser: 'Käyttäjä',
  allBetsPayout: 'Maksu',
  allBetsAmount: 'Määrä',
  allBetsTime: 'Aika',
  allBetsProfit: 'Voitto',
  LoginwithOtp: 'Kirjaudu sisään Otp:llä',
  EnterLoginOtp: 'Syötä sisäänkirjautumisotp',
  submit: 'Lähetä',
  footerAboutUs: 'Meistä',
  footerFaq: 'UKK',
  footerSitePolicy: 'Sivustokäytäntö',
  footerResponsibleGambling: 'Vastuullinen pelaaminen',
  footerTerms: 'Käyttöehdot',
  signinTitle: 'Kirjaudu sisään',
  signinUsername: 'Käyttäjätunnus / Sähköposti',
  signinPassword: 'Salasana',
  signinLoginBtn: 'Kirjaudu sisään',
  signinSignup: 'Kirjaudu!',
  signinForgetPassword: 'Unohditko salasanasi?',
  forgotPasswordMsg: 'Anna tiliisi liitetty sähköpostiosoite, niin lähetämme sinulle linkin salasanan vaihtamiseen.',
  emailLinkMsg: 'Salasanan palautus -linkki on lähetetty rekisteröitymissähköpostiisi, napsauta sähköpostilinkkiä ja aseta uusi salasanasi.',
  resetPasswardConfirm: 'Salasana on nollattu onnistuneesti',
  signinDontHaveAccount: 'Eikö sinulla ole tiliä? ',
  signinCreateAccount: 'Luo tili',
  signinErrorsUserNameRequired: '*Syötä käyttäjätunnus/sähköpostiosoite.',
  signinErrorsUserNameMinLength: 'Vähintään 4 merkkiä sallittu.',
  signinErrorsUserNameMaxLength: 'Enintään 20 merkkiä sallittu.',
  signinErrorsUserNamePattern: 'Anna kelvollinen käyttäjätunnus, vain kirjaimet ja numerot ovat sallittuja.',
  signinErrorsPasswordRequired: '*Salasana tarvitaan.',
  signinErrorsPasswordMinLength: 'Vähintään 8 merkkiä sallittu.',
  signinErrorsPasswordMaxLength: 'Enintään 16 merkkiä sallittu.',
  signinErrorsPasswordPattern: '*Salasanan tulee olla 8–16 aakkosnumeerista ja erikoismerkkiä. ',
  signinSuccessfullyLoggedIn: 'Sisäänkirjautuminen onnistui.',
  signinResetMailSent: 'Salasanan nollaussähköposti lähetetty.',
  signinTryAgain: 'Jokin meni pieleen. Yritä uudelleen.',
  signinResetPasswordUsernameErr: 'Anna käyttäjänimi/sähköpostiosoite nollataksesi salasanan.',
  signinResetMailSentSuccessContent: 'Lähetimme sinulle sähköpostiisi salasanan vaihtolinkin. ',
  signupTitle: 'Rekisteröidy',
  signupUsername: 'Käyttäjätunnus',
  signupEmail: 'Sähköposti',
  signupTermsConditions: 'Ehdot',
  signupWalletConnect: 'Kirjaudu sisään Walletilla ',
  signupPassword: 'Salasana',
  signupEighteenPlus: 'Olen yli 18-vuotias ja hyväksyn käyttöehdot',
  signupRegisterBtn: 'Kirjaudu',
  signupCreateNewAccount: 'Luo uusi tili',
  signupFullName: 'Koko nimi',
  signupReferralCode: 'viitekoodi',
  signupConfirmPassword: 'Vahvista salasana',
  signupUseSocialAccount: 'Kirjaudu sisään sosiaalisten verkostojen kautta',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'On tili? ',
  signupLoginBtn: 'Kirjaudu sisään',
  signupErrorsUserNameRequired: '*Syötä käyttäjätunnus.',
  signupErrorsUserNameMinLength: 'Vähintään 4 merkkiä sallittu.',
  signupErrorsUserNameMaxLength: 'Enintään 20 merkkiä sallittu.',
  signupErrorsUserNamePattern: 'Anna kelvollinen käyttäjätunnus, vain kirjaimet ja numerot ovat sallittuja.',
  signupErrorsFullNameRequired: '*Syötä koko nimi.',
  signupErrorsFullNamePattern: 'Anna kelvollinen koko nimi, vain kirjaimet ja numerot ovat sallittuja.',
  signupErrorsEmailRequired: '*Syötä sähköpostiosoite.',
  signupErrorsEmailMinLength: 'Vähintään 4 merkkiä sallittu.',
  signupErrorsEmailMaxLength: 'Enintään 20 merkkiä sallittu.',
  signupErrorsEmailPattern: 'Anna kelvollinen sähköpostiosoite.',
  signupErrorsPasswordRequired: '*Salasana tarvitaan.',
  signupErrorsPasswordMinLength: 'Vähintään 8 merkkiä sallittu.',
  signupErrorsPasswordMaxLength: 'Enimmäistalletus16 merkkiä sallittu.',
  signupErrorsPasswordPattern: '*Salasanan tulee olla 8-16 digi aakkosnumeerista (isot ja pienet kirjaimet) ja erikoismerkkejä. ',
  signupErrorsConfirmPasswordMatch: 'Salasanan pitää olla sama.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'esim. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Jotain meni pieleen!',
  signupSignupGoogle: 'Rekisteröityminen Googleen onnistui.',
  signupRegistered: 'Rekisteröinti onnistui.',
  signupConfirmAge: 'Vahvista, että olet 18-vuotias.',
  signupSignupFacebook: 'Rekisteröidy Facebookissa onnistuneesti.',
  signupCookiesConsent: 'Salli kolmannen osapuolen evästeet selaimesi asetuksista käyttääksesi sosiaalista kirjautumista.',
  signupRegisterEmailPopupContent: 'Lähetimme sinulle vahvistussähköpostin. ',
  accountMenuDeposit: 'Tallettaa',
  accountMenuCashout: 'Kotiuttaminen',
  accountMenujackpotWinners: 'Jackpotin voittajat',
  accountMenuTransactions: 'Tapahtumat',
  accountMenuPromotions: 'Kampanjat',
  accountMenuProfile: 'Profiili',
  accountMenuLogout: 'Kirjautua ulos',
  accountMenuSound: 'Ääni',
  accountMenuMusic: 'Musiikki',
  accountMenuWithdraw: 'Peruuttaa',
  pageNotFound: 'Emme löydä etsimääsi sivua!',
  error: 'Virhe',
  somethingWentWrong: 'Jotain meni pieleen!',
  verifyEmailThankYou: 'Kiitos!',
  verifyEmailEmailIsVerified: 'Sähköpostisi on vahvistettu. ',
  verifyEmailGoToLogin: 'Siirry kirjautumissivulle',
  verifyEmailWentWrong: 'Jotain meni pieleen!',
  verifyEmailTokenExpired: 'Sähköposti on jo vahvistettu tai linkki vanhentunut, lähetä sähköpostin vahvistuslinkki uudelleen antamalla rekisteröity sähköpostiosoitteesi alle. ',
  verifyEmailGoToHome: 'Mene kotisivulle',
  verifyEmailSuccessMsg: 'Sähköposti vahvistettu onnistuneesti.',
  bonusSectionSpanText: 'esittelee anteliaisimmat ja monipuolisimmat',
  bonusSectionBonuses: 'Bonukset',
  bonusSectionSubtitleText: 'Pelaa ilolla ja hanki lisäpalkintoja pelin aikana. ',
  JoiningBonusTitle: 'Bonukseen liittyminen',
  JoiningBonusButton: 'Lunasta nyt',
  JoiningBonusDesc: 'Tällaiset bonukset rohkaisevat online-kasinon pelaajia pelaamaan Bitcoinilla tai muilla kryptoilla. ',
  depositBonusTitle: 'Talletusbonus',
  depositBonusButton: 'Tallettaa',
  depositBonusDesc: 'Tällaiset bonukset rohkaisevat online-kasinon pelaajia pelaamaan Bitcoinilla tai muilla kryptoilla. ',
  refferBonusTitle: 'Reffer Bonus',
  refferBonusButton: 'Tallettaa',
  refferBonusDesc: 'Tällaiset bonukset rohkaisevat online-kasinon pelaajia pelaamaan Bitcoinilla tai muilla kryptoilla. ',
  aboutSectionSpanText2: 'Online-kasinolla voit pelata suosikkipelejäsi joko ilmaiseksi tai kryptovaluutoilla. ',
  aboutSectionSpanText3: ' Tässä Bitcoin-uhkapelien virtuaalimaailmassa voit nauttia monista mahdollisuuksista – pelata suosikkikasinopelejäsi, saada bonuksia, osallistua kilpailuihin ja promootioihin.',
  verifiedSectionTitle: 'Varmennettu ja lisensoitu ohjelmisto',
  verifiedSectionSpanText1: 'Emme kopioi tai ota käyttöön ohjelmistoja muilta verkkosivustoilta. ',
  faqSectionTitle: 'FAQ pelaajilta',
  faqSectionForgetPasswordTitle: 'Unohdin salasanani.',
  faqSectionForgetPasswordDesc: 'Et antanut tilillesi palauttamisen sähköpostiosoitetta. ',
  faqSectionMyDepositCreditedTitle: 'Talletustani ei ole hyvitetty.',
  faqSectionMyDepositCreditedDesc: ' Paikkamerkkisisältö tälle haitarille, joka on tarkoitettu havainnollistamaan luokkaa. ',
  SupportedCurrencyTitle: 'Tuettu valuutta',
  SupportedCurrencySpanText1: 'Emme kopioi tai ota käyttöön ohjelmistoja muilta verkkosivustoilta. ',
  CarouselSectionTitle: 'HODL CRASH',
  CarouselSectionSubTitle: ' Pelaa enemmän pelejä ja saat mahdollisuuden voittaa. ',
  WalletSettingTitle: 'Lompakon asetukset',
  WalletSettingHideZero: 'Piilota nollasaldo',
  WalletSettingHideZeroInfo: 'Nollasaldosi ei näy lompakossasi',
  WalletSettingDisplayFiat: 'Näytä krypto fiatissa',
  WalletSettingDisplayFiatInfo: 'Kaikki vedot',
  WalletSettingNoteForApprox: 'Huomaa, että nämä ovat likimääräisiä valuuttoja.',
  WalletSettingSave: 'Tallentaa',
  WalletSettingNoWalletFound: 'Lompakkoa ei löytynyt',
  jackpotWinnersNoWinnerAvailable: 'Ei voittajia saatavilla',
  jackpotWinnersNew: 'Uusi',
  jackpotWinnersHeader: 'Jackpotin voittaja',
  jackpotDetailsHeader: 'Jackpotin yksityiskohta',
  jackpotDetailsCampaignPeriod: 'Kampanjan kausi',
  jackpotDetailsFrom: 'From',
  jackpotDetailsTo: 'Vastaanottaja',
  jackpotDetailsBettingRule: 'Vedonlyöntisääntö',
  jackpotDetailsMinBetAmount: 'Minimipanos',
  jackpotDetailsMaxBetAmount: 'Maksimipanos',
  jackpotDetailsBetPercentage: 'Panosprosentti',
  jackpotDetailsWinningRuleHeader: 'Voittosäännöt',
  jackpotDetailsWinningRule: 'Vedon asettaminen suuremmilla panoksilla lisää voittomahdollisuuksia.',
  jackpotDetailsProfitRule: 'Jos panoksen määrä on minBetAmount ja maxBetAmount välillä, betPercentage% vedon määrästä lisätään jättipotin määrään.',
  profileTitle: 'Henkilökohtaiset tiedot',
  profileFName: 'Etunimi',
  profileLname: 'Sukunimi',
  profileUserName: 'Käyttäjänimi',
  profileDob: 'Syntymäaika',
  profileEmail: 'Sähköpostiosoite',
  profilePhone: 'Puhelinnumero',
  profileChange: 'Muuttaa',
  profileSave: 'Tallenna muutos',
  profilePassword: 'Salasana',
  profileSubmit: 'Lähetä',
  profileUploadProfile: 'Lataa profiili',
  profileCurrentPassword: 'Nykyinen salasana',
  profileNewPassword: 'uusi salasana',
  profileConfirmPassword: 'Vahvista salasana',
  profileScanQRCode: 'Skannaa QR-koodi',
  profileEnterSecretCode: 'Syötä salainen koodi',
  profileErrorsFirstNameRequired: 'Etunimi vaaditaan.',
  profileErrorsFirstNamePattern: 'Anna kelvollinen nimi.',
  profileErrorsFirstNameMinLength: 'Vähintään 3 merkkiä sallittu.',
  profileErrorsFirstNameMaxLength: 'Enintään 20 merkkiä sallittu.',
  profileErrorsLastNameRequired: 'Sukunimi vaaditaan.',
  profileErrorsLastNamePattern: 'Anna kelvollinen nimi.',
  profileErrorsLastNameMinLength: 'Vähintään 3 merkkiä sallittu.',
  profileErrorsLastNameMaxLength: 'Enintään 20 merkkiä sallittu.',
  profileErrorsChangePasswordOldRequired: 'Vanha salasana vaaditaan.',
  profileErrorsChangePasswordOldPattern: 'Anna kelvollinen salasana.',
  profileErrorsChangePasswordOldMinLength: 'Vähintään 8 merkkiä sallittu.',
  profileErrorsChangePasswordOldMaxLength: 'Enintään 16 merkkiä sallittu.',
  profileErrorsChangePasswordNewRequired: 'Uusi salasana vaaditaan.',
  profileErrorsChangePasswordNewPattern: 'Anna kelvollinen salasana.',
  profileErrorsChangePasswordNewMinLength: 'Vähintään 8 merkkiä sallittu.',
  profileErrorsChangePasswordNewMaxLength: 'Enintään 16 merkkiä sallittu.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Salasanat eivät täsmää.',
  profileErrorsChangePasswordNewPasswordMatched: 'Uusi salasana ei voi olla sama kuin nykyinen salasana.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Vahvista salasana vaaditaan.',
  profileErrorsUserNameRequired: 'Käyttäjätunnus vaaditaan.',
  profileErrorsUserNamePattern: 'Anna kelvollinen nimi.',
  profileErrorsUserNameMinLength: 'Vähintään 3 merkkiä sallittu.',
  profileErrorsUserNameMaxLength: 'Enintään 20 merkkiä sallittu.',
  profileErrorsDobRequired: 'Syntymäaika vaaditaan.',
  profileErrorsEmailRequired: 'Sähköposti vaaditaan.',
  profileErrorsPostcodeRequired: 'Postinumero vaaditaan.',
  profileErrorsCityRequired: 'Kaupunki vaaditaan.',
  profileErrorsCurrencyRequired: 'Valuutta vaaditaan.',
  profileErrorsAddressRequired: 'Osoite vaaditaan.',
  profileErrorsCountryRequired: 'Maa on pakollinen.',
  profileErrorsEmailPattern: 'Anna kelvollinen sähköpostiosoite.',
  profileErrorsEmailMinLength: 'Vähintään 3 merkkiä sallittu.',
  profileErrorsEmailMaxLength: 'Enintään 20 merkkiä sallittu.',
  profileMessagesProfileUpdate: 'Profiilin päivitys onnistui.',
  profileMessagesChangePassword: 'Salasana päivitetty onnistuneesti.',
  profileProfilePicProfilePicUpload: 'Lataa profiilikuva',
  profileNoNewChanges: 'Profiilia ei voitu päivittää! ',
  profileLoggedInWithSocial: 'Olet kirjautunut sisään sosiaalisella kirjautumisella.',
  profileSideNavigatorSound: 'Ääni',
  profileSideNavigatorMusic: 'Musiikki',
  profileSideNavigatorProfile: 'Profiilini',
  profileSideNavigatorBetHistory: 'Vedonlyöntihistoria',
  profileSideNavigatorTransactions: 'Tapahtumat',
  profileSideNavigatorDeposit: 'Tallettaa',
  profileSideNavigatorWithdraw: 'Peruuttaa',
  profileSideNavigatorGameLimits: 'Pelin rajoitukset',
  profileSideNavigatorReferral: 'Viittaus',
  profileSideNavigatorChangePassword: 'Vaihda salasana',
  profileSideNavigatorTermsConditions: 'Ehdot',
  profileSideNavigatorLogOut: 'Kirjautua ulos',
  profileSideNavigatorProvablyFair: 'Todistettavasti reilua',
  WalletInfoSectionNotAvailable: 'Ei saatavilla',
  WalletInfoSectionRealBalance: 'Todellinen tasapaino',
  GameLimitsMinimumBet: 'Minimipanos',
  GameLimitsMaximumBet: 'Maksimipanos',
  GameLimitsMaxWinFor1Bet: 'Maksimivoitto yhdellä vedolla',
  ProvablyFairSubTitle: 'Tämä peli käyttää Provably Fair -tekniikkaa pelin tuloksen määrittämiseen. ',
  ProvablyFairMaximumBet: 'Maksimipanos:',
  ProvablyFairMaxWinFor1Bet: 'Maksimivoitto yhdellä vedolla',
  promotionsTitle: 'Kampanjat',
  promotionsNoLosingData: 'Anteeksi, ei Losing-bonustarjouksia saatavilla ',
  promotionsNoDepositData: 'Anteeksi, ei talletusbonuskampanjat saatavilla ',
  promotionsCurrency: ' valuutta.',
  promotionsViewBtn: 'Näytä',
  promotionsClaimSuccess: 'Olet onnistuneesti vaatinut',
  promotionsAvailability: 'Saatavuus: ',
  promotionsAvailabilityTabLosing: 'Bonuksen menetys',
  promotionsAvailabilityTabDeposit: 'Talletusbonus',
  promotionsDepositBonusTableCode: 'Koodi',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Prosenttiosuus',
  promotionsDepositBonusTableMaxBonus: 'Max bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover-kerroin',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover vetoa kohden',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Aika saavuttaa kierrätystavoite',
  promotionsBonusCode: 'BONUS KOODI: ',
  promotionsLossesClaim: 'Tappioita voi hakea (kampanjan aikana): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Prosenttiosuus',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Bonuksen tila: ',
  promotionsBonusAmount: 'Bonuksen määrä: ',
  promotionsRolloverTarget: 'Kierrätyskohde: ',
  promotionsRolloverAchieved: 'Kierros saavutettu: ',
  promotionsActivatebtn: 'AKTIVOIDA',
  promotionsClaimBtn: 'VAATIMUS',
  promotionsCancelBtn: 'PERUUTTAA',
  promotionsBackBtn: 'TAKAISIN',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Ehdot',
  promotionsClaimDepositBonusTitle: 'Talletusbonuksen lunastamisen vaiheet',
  promotionsClaimDepositBonusFirst: 'Aktivoi bonuskoodi',
  promotionsClaimDepositBonusSecond: 'Talletussumma lompakkoon',
  promotionsClaimDepositBonusThird: 'Saavuta bonuksen lunastamistavoite',
  promotionsClaimLosingBonusTitle: 'Menetysbonuksen lunastamisen vaiheet',
  promotionsClaimLosingBonusFirst: 'Aktivoi bonuskoodi',
  promotionsClaimLosingBonusSecond: 'Pelaa suosikkipelejäsi',
  promotionsClaimLosingBonusThird: "Napsauta 'Hae tappiot' vaatiaksesi tappioita kampanjajakson aikana",
  promotionsWentWrong: 'Jotain meni pieleen!',
  transactionTitle: 'Tapahtumat',
  transactionBetId: 'Vedon tunnus',
  transactionUser: 'Käyttäjä',
  transactionPayout: 'Maksu',
  transactionAmount: 'Määrä',
  transactionProfit: 'Voitto',
  transactionCashout: 'Kotiuttaminen',
  transactionBet: 'Veto',
  transactionMultix: 'Multi.x',
  transactionWin: 'Voittaa',
  transactionFairness: 'Oikeudenmukaisuus',
  transactionReferFriend: 'Suosittele ystävää',
  transactionTotal: 'Panokset yhteensä',
  transactionWins: 'Voittoja',
  transactionRefresh: 'virkistää',
  transactionFilterTopMultipliers: 'Parhaat kertoimet',
  transactionFilterHugeWins: 'Valtavat voitot',
  transactionFilterBiggestWins: 'Suurimmat voitot',
  transactionFilterMultipliers: 'Kertoimet',
  transactionRealBalance: 'Todellinen tasapaino',
  depositWithdrawTXDate: 'Päivämäärä',
  depositWithdrawTXAmount: 'Määrä',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Maksutavan tyyppi',
  depositWithdrawTXStatus: 'Tila',
  depositWithdrawTXNoData: 'Tietoja ei ole saatavilla.',
  depositTitle: 'Tallettaa',
  depositHistory: 'Historia',
  depositSubTitle: 'Käyttäjä ei voi tallettaa tai nostaa fiat-valuuttaa. ',
  depositCopyNotSupported: 'Selaimesi ei tue automaattista kopiointia leikepöydälle. Yritä kopioida koodi manuaalisesti.',
  depositCopied: 'Osoite kopioitu.',
  depositAddressCreated: 'Osoitteen luominen onnistui.',
  depositGenerateAddress: 'Luo osoite',
  depositWarningText: 'ÄLÄ talleta ketjujen välisellä siirrolla',
  withdrawTitle: 'Peruuttaa',
  withdrawSubTitle: 'Käyttäjä ei voi tallettaa tai nostaa fiat-valuuttaa. ',
  withdrawNoteDesc: 'Varmista, että ET syötä BEP2-, BEP20 (BSC) -osoitetta LTC:n nosto-osoitteeksi',
  withdrawNoteSublist: ' ÄLÄ peruuta ketjujen välisenä siirtona',
  withdrawHistory: 'Historia',
  withdrawWithdrawBtn: 'Takaisinvetopyyntö',
  withdrawFees: 'Kotiutusmaksu {{fees}} {{token}}',
  withdrawWithdrawPending: 'Peruuttamispyyntösi on vireillä. ',
  withdrawEnterFields: 'Syötä nostosumma ja lompakkosi osoite.',
  withdrawSelectWallet: 'Valitse nostettava lompakko.',
  withdrawAmountFieldTitle: 'Summa (vähintään 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Sinun {{wallet.currency.code}} osoite',
  withdrawAddressFieldPlaceholder: 'Kirjoita osoitteesi',
  withdrawNoBalance: 'Ei tarpeeksi tasapainoa.',
  withdrawMinAmount: 'Anna summa, joka on suurempi tai yhtä suuri kuin {{min}} .',
  fiatCurrencyInfo: 'Käyttäjä ei voi tallettaa tai nostaa fiat-valuuttaa. ',
  paginationNextBtn: 'Seuraava',
  paginationPrevBtn: 'Edellinen',
  wentWrong: 'Jotain meni pieleen.',
  referralTitle: 'Viittaus',
  referralSubTitle: 'Suosittele ystäviäsi',
  referralEarn: ' Ansaitse 150 ₹ kukin',
  referralReferAndEarn: 'Suosittele ja ansaitse bonus jokaisesta viittauksesta',
  referralReferPara: ' Jos viittaat tähän koodiin jollekin käyttäjälle',
  referralReferLink: 'Katso linkki',
  referralCopyReferralCode: 'Kopioi viittauskoodi',
  referralTotalRewards: 'Kokonaispalkinto',
  TwoWayAuthentication: 'Kaksisuuntainen todennus',
  LoginRequired: 'Sisäänkirjautuminen vaaditaan!',
  BetPlacedSuccessfully: 'Panos asetettu onnistuneesti',
  BetAddedInQueueSuccessfully: 'Panos lisätty jonoon onnistuneesti',
  BetRemovedFromQueueSuccessfully: 'Vedon poistaminen jonosta onnistui',
  BetCancelledSuccessfully: 'Veto peruutettu onnistuneesti',
  PlayerEscapedSuccessfully: 'Pelaaja pakeni onnistuneesti',
  CopiedSuccessfull: 'Kopiointi onnistui',
  NotEnoughBalance: 'Ei tarpeeksi tasapainoa',
  AutoBetStarted: 'Automaattinen veto alkoi ',
  AutoBetFinished: 'Automaattinen veto päättynyt ',
  IdCopied: 'Id kopioitu',
  usersConsent: 'Siirrymällä tälle sivustolle vahvistan, että olen 18-vuotias ja olen tarkistanut, ymmärtänyt ja hyväksynyt',
  termsOfService: 'Käyttöehdot',
  email: 'Sähköposti',
  playNow: 'Pelaa nyt',
  orContinueWith: 'Tai Jatka',
  metamaskExtensionNotAvailable: 'Asenna metaMask-laajennus ja päivitä sivu.',
  cashier: 'KASA',
  account: 'TILI',
  hello: 'Hei',
  balance: 'Saldo',
  referrals: 'Viittaukset',
  settings: 'asetukset',
  withdrawals: 'Kotiutukset',
  demoWallet: 'Demo-lompakko',
  myAccount: 'Tilini',
  wallet: 'Lompakko',
  user: 'Käyttäjä',
  USD: 'USD',
  amountIsRequired: 'Määrä vaaditaan',
  minDepositAmount: 'Talletussumman on oltava suurempi kuin {{min}}.',
  maxDepositAmount: 'Talletussumman on oltava pienempi kuin {{max}}.',
  minWithdrawAmount: 'Nostosumman on oltava suurempi kuin {{min}}',
  maxWithdrawAmount: 'Nostettavan summan on oltava pienempi kuin {{max}}',
  withdrawMustLessThanWallet: 'Nostosumman tulee olla pienempi kuin lompakkosi summa',
  accountTypeIsRequired: 'Tilityyppi vaaditaan.',
  mobileNumberLength: 'Matkapuhelinnumeron on oltava {{length}} numeroita',
  phoneIsRequired: 'Puhelin vaaditaan',
  cpfError: 'CPF:n on oltava {{length}} hahmoja',
  cpfRequiredError: 'CPF vaaditaan',
  cnpjError: 'CNPJ:n on oltava {{length}} hahmoja',
  mainMenu: 'PÄÄVALIKKO',
  casino: 'Kasino',
  sports: 'Urheilu',
  crypto: 'Krypto',
  NFT: 'NFT',
  poker: 'Pokeri',
  earnRewards: 'Ansaitse PALKIOT',
  rankUp: 'Sijoitus ylös',
  inviteFriends: 'Kutsua ystäviä',
  partnerProgram: 'Kumppaniohjelma',
  home: 'Koti',
  sportsbook: 'Urheiluvedonlyönti',
  menu: 'Valikko',
  liveBets: 'LIVE-VEDOT',
  allBets: 'Kaikki vedot',
  highRollers: 'High Rollers',
  luckyBets: 'Onnelliset vedot',
  trade: 'Käydä kauppaa',
  game: 'PELI',
  player: 'PELAAJA',
  time: 'AIKA',
  wager: 'LYÖDÄ VETOA',
  multiplierBan: 'KERTOJA',
  payoutText: 'MAKSUT',
  bannerHeadOne: 'Koe todellinen innovaatio korkeimmalla',
  bannerHeadTwo: 'palkitsemisohjelma alalla.',
  registerNow: 'Rekisteröidy nyt',
  liveWins: 'LIVE VOITTAA',
  monthly: 'Kuukausittain',
  weekly: 'Viikoittain',
  daily: 'Päivittäin',
  winner1: 'Voittaja 1',
  winner2: 'Voittaja 2',
  winner3: 'Voittaja 3',
  spin: 'Pyöräytys',
  spinsWon: 'Voitetut pyöräytykset',
  joinedOn: 'Liittynyt',
  wageringAmount: 'Panostusmäärä',
  name: 'Nimi',
  allGames: 'Kaikki pelit',
  allSports: 'Kaikki urheilu',
  cryptoFutures: 'kryptofutuurit',
  tradeNow: 'Tee kauppaa nyt',
  allTables: 'Kaikki taulukot',
  nft: 'NFT',
  viewAllGames: 'Katso kaikki pelit',
  viewAll: 'Näytä kaikki',
  rewards: 'PALKKIOT',
  profile: 'Profiili',
  totalBets: 'Panokset yhteensä',
  totalWagered: 'Panostettu yhteensä',
  joinDate: 'Liittymispäivä',
  displaySocialLinks: 'Näytä linkitetyt sosiaaliset tilit',
  privateProfile: 'Yksityinen profiili',
  rank: 'Sijoitus',
  winner: 'Voittajat',
  nextRank: 'Seuraava sijoitus',
  changeUsername: 'Vaihda käyttäjätunnus',
  newPassword: 'Uusi salasana',
  changeName: 'Vaihtaa nimeä',
  linkSocialAccount: 'Linkitä sosiaalinen tili',
  privateProfileTooltip: 'Kun profiilisi on muutettu yksityiseksi, muut käyttäjät eivät näe käyttäjätunnustasi vetojen, kilpailujen jne. rinnalla.',
  socialLinksTooltip: 'Sisältöä ei ole saatavilla',
  rankTooltip: 'Sisältöä ei ole saatavilla',
  balances: 'saldot',
  createReferralBalance: 'Luo viittaussaldo',
  total: 'Kaikki yhteensä',
  switchBalance: 'Vaihda tasapainoa',
  mainAccount: 'Päätili',
  tips: 'Vinkkejä',
  receivedAmount: 'Vastaanotettu summa',
  dateOrTime: 'Treffiaika',
  view: 'Näytä',
  allChains: 'Kaikki ketjut',
  creditAmount: 'Luottomäärä',
  setCustomReferralCode: 'Aseta mukautettu viittauskoodi',
  changeEmail: 'Vaihda sähköposti',
  newEmail: 'Uusi sähköposti',
  createPassword: 'Luo salasana',
  newUsername: 'Uusi käyttäjätunnus',
  username: 'Käyttäjätunnus',
  changePassword: 'Vaihda salasana',
  updateProfileMsg: 'Profiilin päivitys onnistui',
  twoFactorAuthentication: 'kaksivaiheinen todennus',
  twoFactorAuthenticationTitle: 'Kaksivaiheisen todennuksen käyttäminen on erittäin suositeltavaa, koska se suojaa tiliäsi sekä salasanallasi että puhelimellasi.',
  twoFactorAuthenticationSubTitle: 'Sähköposti ja salasana on asetettava 2FA:n aktivoimiseksi. ',
  enable2fa: 'KÄYTÖSSÄ 2FA',
  verifyIdentityByKyc: 'Vahvista henkilöllisyytesi (kyc)',
  oldPassword: 'Vanha salasana',
  live: 'Elää',
  soccer: 'Jalkapallo',
  tennis: 'Tennis',
  fifa: 'FIFA',
  basketball: 'Koripallo',
  iceHockey: 'Ice Hokey',
  volleyball: 'Lentopallo',
  tableTennis: 'Pöytätennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Nyrkkeily',
  myBets: 'Omat vedot',
  favourites: 'Suosikit',
  americanFootball: 'Amerikkalainen jalkapallo',
  buyCrypto: 'Osta Crypto',
  oldEmail: 'Vanha Sähköposti',
  loggedInSuccess: 'Sisäänkirjautuminen onnistui',
  loggedOutSuccess: 'Uloskirjautuminen onnistui',
  wagerLimit: 'Panosraja asetettu onnistuneesti',
  lossLimit: 'Tappioraja asetettu onnistuneesti',
  accountDisable: 'Tili poistettu onnistuneesti',
  sessionTimeout: 'Istunnon aikakatkaisu asetettu onnistuneesti',
  signup: 'Ilmoittautuminen onnistui',
  depositLimit: 'Talletusraja asetettu onnistuneesti',
  tokenVerify: 'Token on lähetetty sähköpostiisi, vahvista',
  emailVerify: 'Sähköposti oli vahvistettu',
  emailChangedSuccess: 'Sähköpostin vaihtaminen onnistui',
  profileUpdatedSuccess: 'profiili päivitetty',
  otpVerified: 'OTP vahvistettu',
  twoFADeactivated: '2FA-todennus poistettu käytöstä',
  internalServerError: 'Sisäinen palvelinvirhe',
  unAuthorized: 'Luvaton',
  dataNotFound: 'Tietoja ei löydy',
  setReferralCode: 'Aseta viittauskoodi...',
  ultimate: 'PERIMMÄINEN',
  notFound: 'Ei löydetty',
  casinoTransactions: 'Kasinon tapahtumat',
  sportsbookTransactions: 'Urheiluvedonlyöntitapahtumat',
  status: 'Tila',
  success: 'Menestys',
  failed: 'Epäonnistui',
  pending: 'Odottaa',
  date: 'Päivämäärä',
  to: 'Vastaanottaja',
  gameName: 'Pelin nimi',
  transactionId: 'Tapahtumatunnus',
  roundId: 'Pyöreä tunnus',
  bet: 'Veto',
  win: 'Voittaa',
  transactionType: 'Maksutavan tyyppi',
  at: 'klo',
  downloadAll: 'Lataa kaikki',
  gameText: 'Peli',
  betId: 'Vedon tunnus',
  gameId: 'Pelin tunnus',
  actionType: 'Toimintotyyppi',
  searchGames: 'Etsi pelejä',
  provider: 'Palveluntarjoajat',
  search: 'Hae',
  sortBy: 'Järjestä',
  popular: 'Suosittu',
  challengePool: 'Challenge Pool',
  loadMore: 'Lataa lisää',
  bitcoin: 'Bitcoin',
  chat: 'Chat',
  trades: 'Kaupat',
  battles: 'Taistelut',
  loginToChat: 'Kirjaudu sisään chattiin',
  rules: 'säännöt',
  chatRule1: 'Älä häiritse tai loukkaa muita käyttäjiä',
  chatRule2: 'Älä kerjää, pyydä lainaa, triviaa tai vinkkejä',
  chatRule5: 'Älä mainosta minkäänlaista kauppaa, ostoa tai myyntiä',
  chatRule6: 'Älä jaa tai mainosta viittauskoodiasi',
  chatRule7: 'Älä pyydä tulla henkilökunnan jäseneksi',
  chatRule8: 'vain englanniksi',
  chatRule9: 'Kunnioita modeja, järjestelmänvalvojia ja muita käyttäjiä',
  send: 'Lähettää',
  expand: 'Laajentaa',
  signIn: 'Kirjaudu sisään',
  cross: 'Ylittää',
  collapse: 'Romahdus',
  emailPlaceholder: 'sähköpostisi@verkkotunnus.com',
  removeFromFavourite: 'Poista suosikeista',
  addToFavourite: 'Lisää suosikkeihin',
  footerAboutSite: `Puhutaanko hieman tieteestä? Cash Machine Casinon verkkosivusto on osa sinua, sillä me
kaikki koostumme samoista atomeista. Vuorovaikutuksesi sen kanssa vahvistaa tämän
yhteyden.`,
  footerRightsReserved: 'Сash Machine Casino kaikki oikeudet pidätetään',
  signupFirstName: 'Etunimi',
  signupAddress: 'Osoite',
  signupCity: 'Kaupunki',
  signupPostcode: 'Postinumero ',
  signupCounty: 'Maa',
  signupCurrency: 'Valuutta',
  signupGender: 'Sukupuoli',
  signupMan: 'Mies',
  signupWomen: 'Naiset',
  signupOther: 'muu',
  signupLoginDetails: 'Kirjaudu sisään Tiedot',
  signupPersonalDetails: 'Henkilökohtaiset tiedot',
  signupConfirm: 'Vahvistaa',
  signupPrivacyPolicy: 'Olen lukenut ja hyväksynyt tietosuojakäytännön',
  signupTermAndConditions: 'Hyväksyn henkilötietojeni käsittelyn. ',
  signupNewsLetter: 'Haluan saada bonuksia, tarjouksia, uutisia tai muuta asiaankuuluvaa tietoa säännöllisesti uutiskirjeellä.',
  signupSms: 'Haluan saada bonuksia, tarjouksia, uutisia tai muuta asiaankuuluvaa tietoa säännöllisesti tekstiviestillä.',
  initialHeaderContent: 'Hanki hämmästyttävä 100 %',
  secoundaryHeaderContent: 'Tervetuliaisbonus $100 asti',
  playNowButton: 'Pelaa nyt',
  registerButtonContent: 'Rekisteröidy',
  results: 'Tulokset',
  refresh: 'virkistää',
  cancel: 'Peruuttaa',
  select: 'Valitse',
  current: 'Nykyinen',
  set: 'Aseta',
  remove: 'Poista',
  save: 'Tallentaa',
  not: 'ei',
  before: 'Ennen',
  after: 'Jälkeen',
  action: 'Toiminta',
  withdrawal: 'Nosto',
  requested: 'Pyydetty',
  withdraw: 'Peruuttaa',
  areYouSure: 'Oletko varma',
  yes: 'Joo',
  no: 'Ei',
  cancelled: 'Peruutettu',
  approved: 'Hyväksytty',
  refunded: 'Hyvitetty',
  chargeback: 'Takaisinveloitus',
  processedOn: 'Käsitelty',
  transaction: 'Tapahtuma',
  dateTime: 'Treffiaika',
  more: 'Lisää',
  fromDate: 'Päivämäärästä',
  toDate: 'Tähän mennessä',
  percentage: 'Prosenttiosuus',
  info: 'Tiedot',
  min: 'Min',
  max: 'Max',
  change: 'Muuttaa',
  continueToSite: 'Jatka sivustolle',
  choose: 'Valita',
  enterWithdrawalAmount: 'Valitse tai syötä nostosummasi',
  capDeposit: 'TALLETTAA',
  capWithdrawal: 'PERUUTUMINEN',
  capAmount: 'MÄÄRÄ',
  minimum: 'Minimi',
  required: 'Edellytetään',
  of: '/',
  isRequired: 'vaaditaan',
  isAllowed: 'on sallittu',
  method: 'Menetelmä',
  capWithdraw: 'PERUUTTAA',
  showLess: 'Näytä vähemmän',
  clearFilter: 'Tyhjennä suodatin',
  quantity: 'Määrä',
  reset: 'Nollaa',
  incorrect: 'väärä',
  load: 'Ladata',
  applied: 'Sovellettu',
  ok: 'OK',
  list: 'Lista',
  all: 'Kaikki',
  favorite: 'Suosikki',
  endPointNotFound: 'Verkkoyhteydessä on jotain vialla',
  category: 'Kategoria',
  emailUserNameRequired: 'Sähköpostiosoite/käyttäjätunnus vaaditaan',
  emailUserNamePlaceHolder: 'Kirjoita sähköpostiosoitteesi tai käyttäjätunnuksesi',
  passwordRequired: 'Salasana vaaditaan',
  accountFrozen: 'Tilisi on jäädytetty asti',
  resendEmail: 'Lähetä uudelleen sähköposti',
  resendLink: 'LÄHETÄ LINKKI UUDELLEEN',
  userLoggedOut: 'Uloskirjautuminen onnistui',
  emailAlreadyRegistered: 'Tämä sähköpostiosoite on jo rekisteröity',
  userNameAlreadyTaken: 'Tämä käyttäjänimi on jo varattu',
  fillAllFields: 'Täytä kaikki kentät ennen kuin siirryt seuraavaan vaiheeseen',
  pleaseAccept: 'Ole hyvä ja hyväksy',
  acceptPrivacyPolicy: 'Tietosuojakäytäntö',
  acceptTerms: 'Käyttöehdot',
  modeOfComm: ' ja viestintätapa',
  beforeNextStep: 'ennen kuin siirryt seuraavaan vaiheeseen',
  userNamePlaceholder: 'Syötä käyttäjätunnus',
  min8Characters: 'Vähintään 8 merkkiä',
  min1Number: 'Vähintään 1 numero',
  min1LowerCase: 'Vähintään 1 pieni kirjain',
  min1UpperCase: 'Vähintään 1 iso kirjain',
  min1SpecialChar: 'Vähintään 1 erikoismerkki',
  passwordTip: 'Salasanassa on oltava vähintään kahdeksan merkkiä, joissa on vähintään yksi iso kirjain, yksi pieni kirjain, yksi numero ja yksi erikoismerkki',
  passwordPlaceholder: 'Kirjoita salasana',
  confirmPasswordPlaceholder: 'Anna Vahvista salasana',
  acceptAll: 'Hyväksy kaikki',
  firstNamePlaceholder: 'Anna etunimi',
  lastNamePlaceholder: 'Anna sukunimi',
  addressPlaceholder: 'Anna osoite',
  cityPlaceholder: 'Anna kaupunki',
  postCodePlaceholder: 'Anna postinumero',
  phonePlaceholder: 'Anna puhelinnumero',
  invalidEmail: 'Virheellinen sähköpostiosoite',
  emailRequired: 'Sähköposti vaaditaan',
  confirmPasswordRequired: 'Vahvista salasana vaaditaan',
  firstNameRequired: 'Etunimi vaaditaan',
  lastNameRequired: 'Sukunimi vaaditaan',
  phoneRequired: 'Puhelin vaaditaan',
  dateOfBirthRequired: 'Syntymäaika vaaditaan',
  genderRequired: 'Sukupuoli Pakollinen',
  userNameRequired: 'Käyttäjätunnus vaaditaan',
  addressRequired: 'Osoite vaaditaan',
  cityRequired: 'Pakollinen kaupunki',
  postCodeRequired: 'Postinumero vaaditaan',
  currencyCodeRequired: 'Valuuttakoodi vaaditaan',
  countryRequired: 'Maa Pakollinen',
  matchPassword: 'Salasanan pitää olla sama',
  fName3Chars: 'Etunimessä on oltava vähintään 3 merkkiä',
  onlyAlphabet: 'Vain aakkoset sallittu',
  lName3Chars: 'Sukunimessä on oltava vähintään 3 merkkiä',
  mustBe18: 'Sinun tulee olla 18-vuotias rekisteröityäksesi',
  mustBeUnder200: 'Sinun tulee olla alle 200-vuotias rekisteröityäksesi',
  validDOB: 'Anna kelvollinen syntymäaika',
  max20Characters: 'Enintään 20 merkkiä sallittu',
  min3Characters: 'Vähintään 3 merkkiä vaaditaan',
  max100Characters: 'Enintään 100 merkkiä sallittu',
  max50Characters: 'Enintään 50 merkkiä sallittu',
  invalidNumber: 'Väärä numero',
  preferredLanguageRequired: 'Ensisijainen kieli vaaditaan',
  invalidPass: 'väärä salasana',
  prevBtn: 'Edellinen',
  dayRequired: 'Päivä vaaditaan',
  monthRequired: 'Kuukausi vaaditaan',
  yearRequired: 'Vuosi vaaditaan',
  uploadImage: 'Lataa kuva',
  removeImage: 'Poista kuva',
  preferredLanguage: 'Ensisijainen kieli',
  wantToChangePass: 'Haluatko vaihtaa salasanan?',
  selectCountryCode: 'Valitse maakoodi',
  selectPreferredLanguage: 'Valitse Ensisijainen kieli',
  fName50Chars: 'Etunimessä saa olla enintään 50 merkkiä',
  lName50Chars: 'Sukunimessä saa olla enintään 50 merkkiä',
  invalidDate: 'Väärä päivämäärä',
  DOBEarlyThanToday: 'Syntymäajan on oltava tätä päivää aikaisemmin',
  enterValidPhone: 'Anna kelvollinen puhelinnumero',
  accountStatus: 'Tilin tila',
  kycVerified: 'KYC:si varmistus onnistui.',
  denied: 'Kielletty',
  chooseFile: 'Valitse tiedosto',
  upload: 'Lataa',
  countryCantChange: 'Olet käynnistämässä KYC-vahvistuksen. ',
  proceed: 'Edetä',
  update: ' ',
  history: 'Historia',
  type: 'Tyyppi',
  amount: 'Määrä',
  forfeited: 'Menetetty',
  expired: 'Vanhentunut',
  completed: 'Valmis',
  zeroedOut: 'Nollattu pois',
  active: 'Aktiivinen',
  lapsed: 'Vanhentunut',
  yourActiveBonus: 'Aktiiviset bonuksesi',
  currentActiveBonus: 'Nykyiset aktiiviset bonukset',
  bonusStatus: 'Tila',
  bonusWager: 'Lyödä vetoa',
  bonusActivate: 'Aktivoida',
  bonusForfeit: 'Bonuksen menetys',
  notUsableBonus: 'Jos kyllä, et voi käyttää tätä bonusta uudelleen.',
  bonusLapsed: 'Sinulla ei ollut tarpeeksi tappioita saadaksesi cashbackia. ',
  inProcess: 'Työn alla',
  claiming: 'Väitetään',
  loyaltyPopover: 'Näitä uskollisuuspisteitä voidaan käyttää cashbackin saamiseen. ',
  loyaltyPage: 'Kanta-asiakassivu',
  loyaltyPerCurrency: 'Uskollisuus valuuttakohtaisesti',
  maxLevelReached: 'Maksimitaso saavutettu',
  pointsToReach: 'tavoittavia pisteitä ',
  loyaltyBannerHeading: 'Ansaitset upouuden uskollisuutemme',
  loyaltyBannerDesc: '100 % tervetuliaisbonus 200 dollariin asti',
  loyaltyHeadingOne: 'Sinut palkitaan aina CashMachinessa',
  loyaltyHeadingOneDesc: 'CashMachinessa nostamme uskollisuuden aivan uudelle jännityksen tasolle ja tarjoamme sinulle palkintoja joka käänteessä! ',
  loyaltySubHeadOne: 'Ansaitse uskollisuuspisteitä',
  loyaltySubHeadOneDesc: 'Ansaitset 1 uskollisuuspisteen jokaista 10 € käteisvetoa kohden kasinollemme. ',
  loyaltySubHeadTwo: 'Uskollisuusportaiden kiipeäminen',
  loyaltySubHeadTwoDesc: 'Mitä enemmän pisteitä keräät, sitä korkeammalle nouset uskollisuustasoilla ja saat matkan varrella vieläkin upeampia palkintoja. ',
  loyaltySubHeadThree: 'Nouse tasolle, saat palkinnon ',
  loyaltySubHeadThreeDesc: 'Paranna pelikokemustasi etenemällä houkuttelevien palkintotasojemme läpi, joissa jokainen taso ylöspäin avaa innostavan valikoiman ilmaiskierroksia, jotka maksimoivat voittomahdollisuutesi. ',
  loyaltyTestimonialHeadOne: 'VARMISTAMME TÄMÄN CashMachine CASINOLLA',
  loyaltyTestimonialHeadTwo: 'Jokaisella pyöräytyksellä on merkitystä',
  loyaltyTestimonialDesc: 'Jokainen kolikkopelin pyöräytys ei ole vain huvin vuoksi, vaan se vie sinut myös lähemmäksi todella makeita palkintoja! ',
  loyaltyPoints: 'Pisteet',
  headerLevel: 'taso',
  loyaltyCashback: 'Vaihtorahat',
  loyaltyBannerBtn: 'Tarkista edistyminen',
  loyaltyHeadingTwo: 'Avaa Ultimate Gaming Experience: CashMachine VIP -ohjelma',
  loyaltyHeadingTwoDesc: 'Kun saavutat kanta-asiakasohjelmamme tason 6, pääset nauttimaan ainutlaatuisista etuoikeuksista arvostetun CashMachine VIP -ohjelman jäsenenä. ',
  loyaltySubHeadFour: 'Korkeammat talletus- ja kotiutusrajat',
  loyaltySubHeadFourDesc: 'Nauti kasvaneiden talletus- ja kotiutusrajojen joustavuudesta VIP-jäsenenä.',
  loyaltySubHeadFive: 'Eksklusiiviset bonukset ja promootiot',
  loyaltySubHeadFiveDesc: 'Pääset VIP-pelaajillemme räätälöityihin erikoisbonuksiin ja kampanjoihin',
  loyaltySubHeadSix: 'Henkilökohtainen tilivastaava',
  loyaltySubHeadSixDesc: 'Saat omistettua tukea ja henkilökohtaista apua tilivastaavaltasi.',
  loyaltySubHeadSeven: 'Nopeammat nostoajat',
  loyaltySubHeadSevenDesc: 'VIP-jäsenenä voit nauttia nopeutuneista kotiutusajoista, mikä varmistaa, että voit käyttää voittojasi entistä nopeammin.',
  loyaltySubHeadEight: 'Kuukausittaiset Cashback-palkinnot',
  loyaltySubHeadEightDesc: 'Kasvata pelikassaasi runsailla kuukausittaisilla cashback-palkinnoilla, jotka on tarkoitettu vain VIP-jäsenillemme.',
  loyaltySubHeadNine: 'Kutsut eksklusiivisiin tapahtumiin ja turnauksiin',
  loyaltySubHeadNineDesc: 'Hanki käsiisi haluttuja kutsuja eksklusiivisiin tapahtumiin ja turnauksiin, joissa voit seurustella muiden VIP-jäsenten kanssa ja kilpailla uskomattomista palkinnoista.',
  loyaltySubHeadTen: 'Syntymäpäiväbonukset',
  loyaltySubHeadTenDesc: 'Juhlista erityistä päivääsi tyylikkäästi ihastuttavalla syntymäpäiväbonuksella juuri sinulle!',
  loyaltySubHeadEleven: 'Luksuslahjat',
  loyaltySubHeadElevenDesc: 'Nauti upeasta valikoimasta luksuslahjoja, jotka on valittu palkitsemaan arvostetuimpia pelaajiamme.',
  loyaltyTableHeading: 'Tasojen ja palkkioiden yleiskatsaus',
  loyaltyTableHeaderOne: 'Uskollisuustaso',
  loyaltyTableHeaderTwo: 'Uskollisuuspisteet',
  loyaltyTableHeaderThree: 'Päivittäinen Cashback',
  promReadMore: 'Lue lisää',
  currentPortalBlock: 'jos haluat estää tilisi vain tälle portaalille.',
  allPortalBlock: 'estääksesi tilisi kaikista portaaleista.',
  limit24Reset: 'Kun asetat panos-, tappio- ja talletusrajat, se on muokattavissa ja poistettavissa 24 tunnin kuluttua, mutta rajoja voidaan pienentää välittömästi.',
  enterAmount: 'Syötä summa',
  limit: 'Raja',
  loss: 'Menetys',
  takeABreak: 'Pidä tauko',
  session: 'Istunto',
  selfExclusion: 'Itsestäänsulkeminen',
  used: 'Käytetty',
  edit: 'Muokata',
  updatedAt: 'Päivitetty klo',
  cannotBeRemoved: 'Ei voida poistaa ennen',
  timePeriod: 'Ajanjakso',
  custom: 'Mukautettu',
  hours: 'Tuntia',
  days: 'päivää',
  timePeriodPlaceholder: 'Päivien määrä',
  months: 'Kuukaudet',
  permanent: 'Pysyvä',
  onlyNumbers: 'Vain numerot sallittu',
  notNumbers: 'Numerot eivät ole sallittuja',
  userNameAllowed: 'On aloitettava aakkosilla. ',
  timePeriodRequired: 'Vaadittu aikajakso',
  cannotBeIncreased: 'Ei voi lisätä ennen',
  amountGTZero: 'Summan tulee olla suurempi kuin 0',
  amountEqualOrLess: 'Summan tulee olla yhtä suuri tai pienempi kuin viikoittain ja kuukausittain',
  amountInBetween: 'Summan tulee olla päivittäisen ja kuukauden välillä (mukaan lukien)',
  amountEqualOIrGreater: 'Summan tulee olla yhtä suuri tai suurempi kuin päivittäin ja kuukausittain',
  limitRemoved: 'Seuraava rajasi poistetaan.',
  limitSet24Hrs: 'Olet asettamassa tilillesi seuraavan rajan. ',
  takeABreakInfo: 'Olet aikeissa estää pääsyn tiliisi ennalta määritetyksi ajaksi. ',
  sessionLimitInfo: 'Olet asettamassa tilillesi seuraavan rajan. ',
  limitCantSetBefore: 'Istuntorajaa ei voi asettaa aikaisemmin',
  selfExclusionInfo: 'Olet aikeissa estää pääsyn tiliisi ennalta määritetyksi ajaksi. ',
  quickSearch: 'Pikahaku',
  cash: 'Käteinen raha',
  nonCash: 'Ei käteistä',
  userDetail: 'Käyttäjätiedot',
  gameIdentifier: 'Pelin tunniste',
  rollback: 'Palautus',
  rollbackBeforeBetWin: 'Palautus ennen vedon voittoa',
  freeSpins: 'Ilmaiskierrokset',
  betInternal: 'Sisäinen veto',
  winInternal: 'Voita sisäinen',
  addMoney: 'Lisää rahaa',
  removeMoney: 'Poista rahaa',
  addMoneyInternal: 'Lisää rahaa sisäisesti',
  removeMoneyInternal: 'Poista sisäinen raha',
  depositInternal: 'Sisäinen talletus',
  withdrawInternal: 'Nosta sisäinen',
  promotionTitle: 'Promootio otsikko',
  cancelWithdrawRequest: 'Haluat peruuttaa tämän pyynnön',
  rowsPerPage: 'Rivejä sivulla',
  availedBonus: 'Sinulla on jo aktiivinen bonus. ',
  capForfeit: 'MENETTÄÄ',
  itFirst: 'se ensin.',
  selectYourBonus: 'Valitse bonuksesi',
  skip: 'OHITA',
  with: 'kanssa',
  without: 'ilman',
  selectPaymentMethod: 'Valitse maksutapasi',
  volatility: 'Volatiliteetti',
  paylines: 'Voittolinjat',
  theme: 'Teema',
  tryForFree: 'Kokeile ilmaiseksi',
  resultsFound: 'Tulokset löydetty',
  games: 'Pelit',
  in: 'sisään',
  low: 'Matala',
  medium: 'Keskikokoinen',
  high: 'Korkea',
  'medium-high': 'Keskikorkea',
  'very-high': 'Erittäin korkea',
  'low-medium': 'Matala Keskitaso',
  fantasy: 'Fantasia',
  ancient_civilizations: 'Muinaiset sivilisaatiot',
  fruits: 'Hedelmät',
  africa: 'Afrikka',
  military: 'Sotilaallinen',
  joker: 'Jokeri',
  asia: 'Aasia',
  luxurylife: 'Luksuselämää',
  underwater_world: 'Vedenalainen maailma',
  book_off: 'Varaus',
  western: 'Läntinen',
  retro: 'Retro',
  egypt: 'Egypti',
  party: 'Juhla',
  st_patrick_day: 'Pyhän Patrickin päivä',
  space: 'Avaruus',
  easter: 'pääsiäinen',
  girls: 'Tytöt',
  branded: 'Tuotemerkki',
  x_mas_and_new_year: 'joulu ja uusi vuosi',
  horrors: 'Kauhuja',
  other: 'muu',
  loginFirst: 'Kirjaudu ensin lisätäksesi pelin suosikkeihin',
  loginFirstPlay: 'Kirjaudu ensin pelataksesi tätä peliä',
  sport: 'Urheilu',
  pirates: 'Merirosvot',
  sweets: 'Makeiset',
  luxury_life: 'Luksuselämää',
  st_valentines_day: 'Ystävänpäivä',
  halloween: 'Halloween',
  food: 'Ruokaa',
  lobby: 'aula',
  favourite: 'Suosikki',
  playNGO: 'Pelaa N Go',
  loyaltySystem: 'Uskollisuusjärjestelmä',
  progressionSystem: 'Edistysjärjestelmä',
  startPoint: 'Lähtöpiste',
  endPoint: 'Päätepiste',
  loyaltyBonus: 'Uskollisuus Bonus',
  pageRemoved: 'Tätä sivua ei ole olemassa tai se on poistettu',
  suggestToBackHome: 'Suosittelemme palaamaan kotiin',
  backToHome: 'Takaisin kotiin',
  goHome: 'Mene kotiin',
  oopps: 'Hups',
  somethingNotWorking: 'Pahoittelemme, jokin ei toimi täällä!',
  tournament: 'Turnaus',
  none: 'Ei mitään',
  validOnDays: 'Voimassa päivinä',
  validTill: 'Voimassa',
  yourRequest: 'Sinun pyyntösi',
  withdrawalRequestPending: 'Sinulla on yksi nostopyyntö odottavassa tilassa. ',
  realBalance: 'Todellinen tasapaino',
  bonusInCasino: 'Bonus kasinolla',
  bonusSelected: 'Bonus valittu',
  payWith: 'Maksaa',
  reached: 'Saavuttanut',
  receiptOfYour: 'Kuitti sinun',
  capEmail: 'EMAIL',
  capCreditCard: 'LUOTTOKORTIN NUMERO',
  capExpiry: 'PÄÄTTYY',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY ei ole oikeassa muodossa',
  min13Numbers: 'Vähintään 13 numeroa vaaditaan',
  max19Numbers: 'Enintään 19 numeroa sallittu',
  min3Numbers: 'Vähintään 3 numeroa vaaditaan',
  max4Numbers: 'Enintään 4 numeroa sallittu',
  Other: 'Muut',
  forfeit: 'Menettää',
  withdrawRequestCreated: 'Peruutuspyyntö luotu onnistuneesti',
  noBetHistory: 'Vetohistoriaa ei löytynyt',
  noPromotions: 'Kampanjoita ei löytynyt',
  noBonuses: 'Bonuksia ei löytynyt',
  noHistory: 'Historiaa ei löydy',
  noWithdrawalHistory: 'Nostohistoriaa ei löytynyt',
  noCMS: 'CMS:ää ei löydy',
  noProvidersFound: 'Palveluntarjoajia ei löytynyt',
  noDataFound: 'Dataa ei löytynyt',
  countryBlocked: 'MAA ESTETTY',
  forbidden: 'KIELLETTY',
  notAcceptingVisitors: 'Olemme syvästi pahoillamme tästä, mutta shinywilds.com ei ole saatavilla maassasi.',
  contactUsAt: 'Jos uskot, että tämä näytettiin sinulle erehdyksessä, ota yhteyttä tukeemme sähköpostitse osoitteessa',
  siteUnderMaintenance: 'Sivusto ylläpidossa',
  weWillBeRightBack: 'Tulemme heti takaisin!',
  serviceUnavailable: 'Palvelumme on tilapäisesti poissa käytöstä. ',
  tryAgainLater: 'Yritä uudelleen myöhemmin.',
  checkYourEmail: 'Tarkista sähköpostisi',
  sentAVerification: 'Olemme lähettäneet vahvistuslinkin',
  toYourEmail: 'sähköpostiisi.',
  backToLogin: 'Takaisin sisäänkirjautumiseen',
  goToLogin: 'Siirry sisäänkirjautumiseen',
  accountVerified: 'Tili vahvistettu',
  yourAccountVerified: 'Tilisi on vahvistettu',
  successfully: 'Onnistuneesti',
  verificationFailed: 'Vahvistus epäonnistui',
  emailFailedContact: 'Vahvistus epäonnistui. Jos näin tapahtuu jatkuvasti, ota yhteyttä ',
  setNewPass: 'Aseta uusi salasana',
  passResetLink: 'Olemme lähettäneet salasanan palautuslinkin osoitteeseen',
  toYourAccount: 'Omalle tilillesi',
  sendPasswordResetLink: 'Pyydä nollaamaan salasana',
  gamePlayWagerComplete: 'Onnittelut, olet onnistuneesti suorittanut kierrätyksen ja voitettu bonusraha on suurempi kuin sallittu enimmäisvoittosumma. ',
  real: 'Todellinen',
  demo: 'Demo',
  money: 'Raha',
  formatRquired: 'Vaaditaan .png-, .jpg- ja .jpeg-muoto',
  chooseImage: 'Ole hyvä ja valitse kuva',
  imageSize3MB: 'Anna kuva, jonka koko on alle 3 Mt',
  noProfileImage: 'Profiilikuvaa ei ole poistettavissa',
  limitSetSuccess: 'raja asetettu onnistuneesti',
  accountDisabled: 'Tili poistettu käytöstä onnistuneesti',
  loginSuccess: 'Kirjautuminen onnistui',
  signupSuccess: 'Ilmoittautuminen onnistui',
  linkExpired: 'Linkki vanhentunut',
  linkSent: 'Linkki lähetetty',
  accAlreadyVerified: 'Tili on jo vahvistettu. ',
  profileImage: 'Profiilikuva',
  profileImageRemoved: 'Profiilikuvan poistaminen onnistui',
  docs: 'Asiakirjat',
  updatedSuccess: 'päivitetty onnistuneesti',
  MATCH: 'OTTELU',
  BALANCE: 'SALDO',
  FREESPINS: 'ILMAISKIERROKSIA',
  MATCH_1: 'OTTELU',
  DEPOSIT: 'TALLETTAA',
  WAGERING: 'PYÖRÄYS',
  PROMOTION: 'EDISTÄMINEN',
  CREDITCARD: 'LUOTTOKORTTI',
  WALLET: 'LOMPAKKO',
  CRYPTO: 'KRYPTO',
  OTHER: 'MUUTA',
  INSTANTBANKING: 'PIKAPANKKIT',
  cookieTitle: 'Keksit!',
  declineButton: 'Hylkää',
  acceptButton: 'Hyväksyä',
  cookieDeclinedMessage: 'Parhaan mahdollisen käyttökokemuksen saamiseksi evästeet on hyväksyttävä, jotta voit jatkaa tämän verkkosivuston käyttöä. ',
  cookieDescription: 'Tervetuloa Shinywilds.com-sivustolle! ',
  forfeitMessageFirst: 'Sinulla on tällä hetkellä seuraava aktiivinen bonus:',
  forfeitMessageSecond: 'Sinun on luovuttava tämä bonus ennen kuin voit lunastaa uuden.',
  forfeitButton: 'Menettää',
  pleaseVerifyFirstText: 'Jotta voimme pyytää kotiutusta, meidän on ensin vahvistettava sähköpostiosoitteesi. ',
  clickHere: 'Klikkaa tästä',
  pleaseVerifyLastText: 'jotta voimme lähettää sinulle vahvistussähköpostin uudelleen ja jatkaa.',
  pleseWait: 'Odota hetki',
  toResend: 'lähettääksesi linkin uudelleen',
  notVerified: 'Sinun on vahvistettava sähköpostiosoitteesi nostaaksesi rahaa',
  verification: 'Todentaminen',
  UPLOAD_IMG_MSG: 'Vedä ja pudota tiedostoja tähän tai valitse tiedostot napsauttamalla',
  affiliate: 'Yhteistyökumppani',
  affiliateTitle: 'Kumppanuusohjelma',
  affiliateDescription: ' Suosittele brändiämme ja ansaitse rahaa osallistumalla käteisautomaatin kumppaniohjelmaan! ',
  refferalTitle: 'Suositusohjelma',
  refferalDescription: 'Voit jakaa tämän viittauslinkin muille ja ansaita bonusta, jos käyttäjä rekisteröityy antamaasi linkin kautta',
  affiliateButtonText: 'Ryhdy kumppaniksi',
  affiliateLink: 'Affiliate-linkki',
  affiliateStatus: 'Affiliate-pyyntösi on lähetetty onnistuneesti',
  currentStatus: 'ja nykyinen Affiliate-status on {{status}}',
  referralLink: 'Viittauslinkki',
  copyTitleMessage: 'Kopioi tämä linkki',
  copiedMessage: 'Linkki kopioitu',
  searchedGame: 'Haettu peli',
  favoriteGame: 'Suosikkipelit',
  randomGames: 'Satunnaiset pelit',
  poolPrize: 'Palkintopotti',
  chooseYourWelcomeBonus: 'Valitse tervetuliaisbonuksesi',
  useNow: 'Käytä nyt',
  readTheTerms: 'Lue ehdot',
  proceedWithoutWelcomeBonus: 'Jatka ilman tervetuliaisbonusta',
  doYouAlreadyHaveAccount: 'Onko sinulla jo tili? ',
  registerViaSocialNetworks: 'Rekisteröidy sosiaalisten verkostojen kautta',
  iconfirmIm18YearsOlder: 'Vahvistan olevani 18-vuotias tai vanhempi',
  iAgreeWithTermsAndConditions: 'Hyväksyn käyttöehdot',
  thankYouSuccessfullyRegisteringCashMachine: 'Kiitos onnistuneesta rekisteröitymisestä kassakoneeseen.',
  verifyEmailMessage: 'Pyydämme sinua vahvistamaan sähköpostiosoitteesi napsauttamalla sähköpostiisi lähetettyä vahvistuslinkkiä, jotta kirjautuminen onnistuu sivustolle.',
  gotIt: 'Sain sen',
  phonecodeRequired: 'Puhelinkoodi vaaditaan',
  cpNotAllow: 'kopiointi/liittäminen ei ole sallittua',
  playFun: 'Pelaa hauskaa',
  playReal: 'Pelaa Realia',
  yourRealBalance: 'Todellinen saldosi on:',
  chooseYourPaymentMethod: 'Valitse maksutapa',
  balanceHistory: 'Tasapainohistoria',
  liveChat: 'Live Chat',
  mail: 'Mail',
  chooseEnterYourDepositAmounts: 'Valitse tai syötä talletussummasi',
  noBonusDataAvailable: 'Bonustietoja ei ole saatavilla',
  nowTryPlayForRealBets: 'Yritä nyt pelata oikeilla panoksilla',
  registerAndPlayForRealBets: 'Rekisteröidy ja pelaa oikeilla vedoilla',
  tournamentDataNotAvailable: 'Turnaustietoja ei ole saatavilla',
  startDate: 'Aloituspäivämäärä',
  endDate: 'Päättymispäivä',
  merchant: 'Kauppias',
  seachHere: 'Etsi täältä',
  show: 'Näytä',
  betResults: 'Vedon tulokset:',
  noTransactionsFoundForselectedDate: 'Valitulle päivälle ei löytynyt tapahtumia',
  loginError: 'Kirjautumisvirhe',
  errorMessageIncorrectlyAuthorization: 'Sähköpostiosoite/kirjautumistunnus tai salasana syötetty väärin Valtuutusvirhe.',
  profileSaved: 'Profiili tallennettu',
  addInfo: 'Lisää tietoja',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Olen lukenut ja hyväksyn maksujärjestelmien rajoitukset*',
  paymentOptions: 'Maksuvaihtoehdot',
  language: 'Kieli',
  betTime: 'Vedonlyöntiaika',
  noActiveBonusFound: 'Aktiivista bonusta ei löytynyt',
  bonusType: 'Bonustyyppi',
  requiredWageringAmount: 'Vaadittu kierrätyssumma',
  currentWageringAmount: 'Nykyinen panostussumma',
  validUpto: 'Voimassa',
  bonusCode: 'Bonus koodi',
  wageringProgressBar: 'Panostuksen edistymispalkki',
  noGamesFound: 'Pelejä ei löytynyt',
  noteYouMayOptfreeSpin: 'Huomautus: Voit valita FREESPINin missä tahansa näistä peleistä',
  viewOfferDetails: 'Katso tarjouksen tiedot',
  viewFreespinGames: 'Katso Freespin-pelejä',
  depositBonusPercentage: 'Talletusbonusprosentti: ',
  validity: 'Voimassaolo',
  activated: 'Aktivoitu',
  bonusName: 'Bonuksen nimi',
  freeRounds: 'Ilmaiset kierrokset',
  wagering: 'Panostaminen',
  ended2: 'Päättyi',
  searchBonusTitle: 'Hae bonusotsikon perusteella',
  claimed: 'Väitetty',
  noBonusHistoryFoundselectedDates: 'Valituille päivämäärille ei löytynyt bonushistoriaa',
  noBonusDetailsFound: 'Bonustietoja ei löytynyt',
  enterCode: 'Syötä koodi',
  transactionTime: 'Kaupan aika',
  credit: 'Luotto',
  debit: 'Veloittaa',
  // bonusInfoOfHeading: 'Bonukset ovat ylimääräisiä maksuja, jotka palkitsevat suoritussi',
  bonusInfoOfHeading: 'Bonukset ovat lisäpalkintoja, jotka tunnustavat toimintasi ja saavutuksesi.',
  onlineCasino: 'online-kasino',
  isTheTop: ' on huippu ',
  bonusInfoPara0: 'Etsitkö parhaita kasinopelejä verkossa? ',
  tournamentsHistory: 'Turnausten historia',

  loginWelcomeBonusInfo: `Cash Machine toivottaa uudet pelaajat tervetulleiksi tervetuliaisbonuksella - 100 %
  talletus + 100 ilmaiskierrosta pelissä "Pat’s Heroes" (Platipus).
  Minimitalletussumma on 20 euroa / 900 ARS / 30 AUD / 500 CZK
  / 150 DKK / 200 NOK / 30 NZD / 80 PLN / 20 USD / 300 ZAR / 70 GEL
  / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,
  loginDepositBonusInfo: `Maksimibonus: 100 % talletussummasta, mutta ei enempää
  yli 100 EUR / 4 000 ARS / 150 AUD / 2 500 CZK / 750 DKK / 1 000 NOK
  / 150 NZD / 400 PLN / 100 USD / 1500 ZAR / 350 GEL / 45 000 KZT /
  TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `Ilmaispyöräytykset myönnetään 5 päivän sisällä bonuksen aktivoinnista,
  20 ilmaiskierrosta päivittäin. Kaikki ilmaiskierroksilla voitetut varat hyvitetään
  bonustilille ja ovat käytettävissä pelaamiseen/panostamiseen
  bonus. Nosto käytettävissä X40 panostuksen jälkeen. Bonuksen kierrätysaika
  on 7 päivää`,
  loginMaximumBetLimitInfo: `Maksimipanosraja kierrätyksen aikana: 1 EUR / ARS 45 / AUD 1,5 / CZK
  25 / DKK 7 / NOK 10 / NZD 1,5 / PLN 5 / USD 1 / ZAR 15 / GEL 3,5 /
  KZT 450 / TJS 10 / UZS 10 000 / TRY 6`,

  bonusInfoPara1: ', jossa on laaja valikoima upeita pelejä ja häikäiseviä erikoistarjouksia. Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat pelaajille tuottoisen mahdollisuuden tehdä suuri voitto. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna∂ ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi.',
  bonusInfoPara2: 'Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat tuottoisen mahdollisuuden ison voiton saavuttamiseen. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi. Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat pelaajille tuottoisen mahdollisuuden tehdä suuri voitto. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi.',
  bonusInfoPara3: 'Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat pelaajille tuottoisen mahdollisuuden tehdä suuri voitto. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi.',
  bonusInfoPara4: 'Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat pelaajille tuottoisen mahdollisuuden tehdä suuri voitto. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi. Kolikkopeleissämme on monenlaisia teemoja, ja progressiiviset jättipottipelit tarjoavat pelaajille tuottoisen mahdollisuuden tehdä suuri voitto. Tarjoamme myös jännittäviä live-jakajapelejä, joissa näet korttisi sekoitettuna ja jaettuna reaaliajassa! Etkö ole varma, mikä peli sopii sinulle? Älä huoli – voit harjoitella ensin demotilassa saadaksesi tunteen jokaisesta pelistä, ennen kuin päätät pelata tosissasi.',
  cashMachineFooterPara1: 'Etsitkö parasta nettikasinoa? Tervetuloa ',
  cashMachineFooterPara2: ` jossa yhdistämme jännittävän
pelattavuuden, anteliaat bonukset ja eksklusiiviset turnaukset tarjotaksemme sinulle parasta
online-viihdettä. Haluatko voittaa jättipotin progressiivisista kolikkopeleistämme, kilpailla
palkinnoista turnauksissa tai kokeilla onneasi live-jakajapeleissä? Meillä on kaikki! Mikä
tekee meistä ainutlaatuisia? `,
  cashMachineFooterPara3: `voit voittaa paitsi käteispalkintoja myös
todellisia fyysisiä palkintoja! Olemme ylpeitä siitä, että olemme pelaajien valinta ympäri
maailmaa. Valmiina tutkimaan? Pelaa mitä tahansa alueellasi saatavilla olevaa peliä ja nauti
vertaansa vailla olevasta jännityksestä. Kokeile parasta, pelaa parhaiden kanssa – vain `,
  cashMachineTitle1: 'CASH MACHINEen',
  cashMachineTitle2: 'CASH MACHINElla',
  cashMachineTitle3: 'CASH MACHINE CASINOlla!',
  // tournamentDescTitle: ' Live Casino, Slots ja Drops and Wins -kasinoturnaukset',
  tournamentDescTitle: 'Turnaukset ovat loistava tilaisuus kilpailla muiden pelaajien kanssa ja voittaa runsaita palkintoja.',
  tournamentPara1: 'Etsitkö parhaita kasinopelejä verkossa? Silloin olet tullut oikeaan paikkaan.',
  tournamentPara2: 'fantastisten pelien ja upeiden erikoistarjousten laajalla valikoimalla. Kolikkopelimme tulevat laajassa teemavalikoimassa, ja progressiiviset jättipottikolikkopelit tarjoavat pelaajille mahdollisuuden voittaa suuria voittoja. Tarjoamme myös jännittäviä live-jakajapelejä, joissa voit nähdä korttisi sekoitettuna ja jaettuna reaaliajassa! Et varma, mikä peli sopii sinulle? Älä huoli - voit harjoitella ensin demo-tilassa saadaksesi tuntuman jokaiseen peliin ennen kuin päätät pelata oikealla rahalla.',
  tournamentPara3: 'Kolikkopelimme tulevat laajassa valikoimassa teemoja, ja progressiiviset jättipottikolikkopelit tarjoavat pelaajille mahdollisuuden voittaa suuria voittoja. Tarjoamme myös jännittäviä live-jakajapelejä, joissa voit nähdä korttisi sekoitettuna ja jaettuna reaaliajassa! Et varma, mikä peli sopii sinulle? Älä huoli - voit harjoitella ensin demo-tilassa saadaksesi tuntuman jokaiseen peliin ennen kuin päätät pelata oikealla rahalla. Kolikkopelimme tulevat laajassa valikoimassa teemoja, ja progressiiviset jättipottikolikkopelit tarjoavat pelaajille mahdollisuuden voittaa suuria voittoja. Tarjoamme myös jännittäviä live-jakajapelejä, joissa voit nähdä korttisi sekoitettuna ja jaettuna reaaliajassa! Et varma, mikä peli sopii sinulle? Älä huoli - voit harjoitella ensin demo-tilassa saadaksesi tuntuman jokaiseen peliin ennen kuin päätät pelata oikealla rahalla.',
  'New Games': 'Uudet pelit',
  'Popular Games': 'Suositut pelit',
  'Games Of The Month': 'Kuukauden pelit',
  Exclusive: 'Yksinomainen',
  'Bonus Buy': 'Bonusosto',
  'Jackpot Games': 'Jackpot-pelit',
  lightMode: 'Valotila',
  darkMode: 'Tumma tila',
  searchProvider: 'Hakupalveluntarjoaja',
  tournamentHistoryDataNotAvailable: 'Turnaushistoriatietoja ei ole saatavilla',
  viewMore: 'Katso lisää',
  description: 'Kuvaus',
  codeCopied: 'Koodi kopioitu',
  tournamentGames: 'Turnauspelit',
  freeSpinGames: 'Ilmaiset pyörityspelit',
  promoCode: 'Tarjouskoodi',
  paymentNote: 'Huomautus: Älä sulje tai päivitä ikkunaa, kun tapahtumaa käsitellään',
  liveGames: 'Live-pelit',
  play: 'PELAA',
  demo1: 'DEMO',
  registration: 'REKISTERÖINTI',
  confirmation: 'Vahvistus',
  forfeitBonusMessage: 'Jos aktivoit tämän bonuksen, kaikki vanhat aktiiviset bonukset menetetään.',
  bonusConfirmationMessage: 'Oletko varma, että haluat aktivoida tämän bonuksen?',

  forfeitRewardMessage: 'Jos aktivoit tämän palkinnon, kaikki vanhat aktiiviset bonukset menetetään.',
  rewardConfirmationMessage: 'Haluatko varmasti aktivoida tämän palkinnon?',
  reward: 'Palkkio',
  purchasedReward: 'Ostettu palkinto',
  noActiveRewardFound: 'Aktiivista palkintoa ei löytynyt',
  noRewardDetailsFound: 'Palkintoa ei löytynyt',
  betDate: 'Vedon päivämäärä',
  shopDes: 'Kaupassa voit vaihtaa keräämäsi pisteet erilaisiin palkintoihin, bonuksiin ja palkintoihin.',
  vipInfoHeading: 'VIP-bonukset ovat eksklusiivisia tarjouksia kaikista uskollisimmille pelaajillemme.',
  updatefooter:' tarjoaa eGaming-palveluita ja on virallisesti lisensoinut Anjouanin osavaltion Offshore Finance Authorityn vuoden 2005 Computer Gaming Licensing Actin 007 mukaisesti. Tämän verkkosivuston omistaa ja sitä ylläpitää KeyPartnership B.V., (yritysnumero: 166291), joka on rekisteröity ja säännelty Curaçaossa, jonka osoite on Abraham de Veerstraat 1, Willemstad, Curaçao',
  loginFirstForRandom: 'Kirjaudu ensin nähdäksesi satunnaiset pelit'
}
