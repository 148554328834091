
import { Box, styled } from '@mui/material'

export const MobileMenuWrapper = styled(Box)(({ theme }) => {
  return {
    all: 'unset',
    '& .mobile-menu-btn': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      minWidth:theme.spacing(3.5),
      color: theme.palette.warning.dark,
      fill: theme.palette.warning.dark,
      padding: theme.spacing(0.25),
      '& svg': {
        width: theme.spacing(2),
        height: theme.spacing(2)
      },
    },

    '& .wallet-btn-wrapper': {
      display: 'flex',

    },

    '& .mobile-menu-wrap': {
      width: '100%',
      height: '100dvh',
      background: `url("${theme.themeImages.layoutBgImage}") ${theme.palette.solidColor.layoutBg}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundAttachment: 'fixed',
      position: 'fixed',
      top: theme.spacing(0),
      right: theme.spacing(0),
      '& .mobile-menu-box': {
        zIndex: '0',
        position: 'relative',
        '& .mobile-header': {
          height: theme.spacing(4.375),
          background: theme.palette.header.main,
          borderBottom: `3px solid ${theme.palette.header.borderMain}`,
          backdropFilter: 'blur(4px)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: theme.spacing(0, 1.25)
        },
        '& .mobile-header .auth-btn-wrap': {
          '& .MuiButtonBase-root': {
            minWidth: theme.spacing(8.125)
          }
        },
        '& .mobile-body': {
          zIndex: '-1',
          position: 'relative',
          '& .header-box': {
            height: theme.spacing(3.25),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing(1.25),
            zIndex: '-1',
            padding: theme.spacing(0.75, 1),
            position: 'relative'
          },
          '& .mobile-navigation-wrap': {
            height: `calc(100dvh - ${theme.spacing(10)})`,
            overflowY: 'auto',
            '& .mobile-navigation-box': {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: theme.spacing(0.75)
            }
          }
        },
        '& .auth-btn-wrap': {
          height: theme.spacing(4.375),
          position: 'absolute',
          top: theme.spacing(0),
          left: '50%',
          transform: 'translateX(-50%)',
          '& .MuiButtonBase-root': {
            minWidth: theme.spacing(8.125)
          }
        },
        '& .navigation-box': {
          flexGrow: 'unset',
          '& .wallet-balance-wrap': {
            height: theme.spacing(4.375),
            justifyContent: 'center',
            padding: theme.spacing(0, 1.25),
            position: 'absolute',
            top: theme.spacing(0),
            left: '50%',
            transform: 'translateX(-50%)',
            '& .wallet-box': {
              minWidth: theme.spacing(3),
              height: `${theme.spacing(1.5)} !important`,
              flex: 'none',
              padding: theme.spacing(0.5, 0.5),
              '& svg': {
                width: theme.spacing(1.5),
                height: theme.spacing(1.5),
                fill: theme.palette.warning.main,
                marginRight: 'auto'
              },
              '& .MuiTypography-root': {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.formInput.contrastTextWhite,
                fontSize: theme.spacing(1),
                fontWeight: '500',
                lineHeight: theme.spacing(1)
              }
            }
          },
          '& .settings-btn': {
            position: 'absolute',
            top: theme.spacing(5.407),
            right: theme.spacing(6.625)
          }
        },
        '& .back-btn': {
          fontFamily: 'Poppins, sans-serif',
          color: theme.palette.typographyText.textMain,
          fill: theme.palette.warning.dark,
          fontSize: theme.spacing(1),
          fontWeight: '500',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          padding: theme.spacing(0),
          marginRight: 'auto',
          '& svg': {
            width: theme.spacing(1.25),
            height: theme.spacing(1.25)
          }
        },
        '& .theme-toggle-btn': {
          position: 'absolute',
          top: '50%',
          right: theme.spacing(-0.25),
          transform: 'translateY(-50%) scale(0.673)'
        }
      }
    }
  }
})
