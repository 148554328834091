export default {
  404: '404',
  vip: 'Vip',
  upcoming: 'Upcoming',
  finished: 'Finished',
  prizePool: 'Prize Pool',
  joinNow: 'Join Now',
  joined: 'Joined',
  startsIn: 'Starts In',
  endsIn: 'Ends In',
  ended: 'Tournament Ended',
  started: 'Tournament Started',
  seeMore: 'See More',
  winPrizes: 'Win exciting Prizes',
  leaderBoard: 'LeaderBoard',
  prize: 'Prize',
  aboutTournament: 'About Tournament',
  congratulationsMessage: 'Bravo! Congratulations on winning this tournament!!',
  payments: 'Payment',
  tournaments: 'Tournaments',
  shop: 'Shop',
  blog: 'Blog',
  referral: 'Referral',
  contacts: 'Contacts',
  retry: 'Retry',
  incorrectCredsMessage: 'Your username or password is incorrect. Please try again!!!',
  bonuses: 'Bonus',
  depositBonus: 'Deposit Bonus',
  voucher: 'Voucher',
  gamlings: 'Gamblings',
  transactionHistory: 'Transactions History',
  offers: 'Offers',
  editProfile: 'Edit Profile',
  continue: 'Continue',
  apply: 'Apply',
  male: 'Male',
  female: 'Female',
  register: 'Register',
  login: 'Login',
  grand: 'Grand',
  major: 'Major',
  minor: 'Minor',
  mini: 'Mini',
  crashGameTitle: 'Crash',
  betAmount: 'Bet Amount',
  profit: 'Profit',
  payout: 'Target Payout',
  chance: 'Win Chance',
  betBtn: 'Bet',
  autoCashout: 'Auto Cashout',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Wagered',
  nothingFound: 'Nothing Found',
  lost: 'Lost',
  won: 'Won',
  streak: 'Streak',
  highestWin: 'Highest Win',
  promotions: 'Promotions',
  latestPromotions: 'Latest Promotions',
  sponsorships: 'Sponsorships',
  highestLost: 'Highest Lost',
  highestBet: 'Highest Bet',
  maxStreak: 'Max Streak',
  maxProfit: 'Max Profit: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Your bets history is empty',
  messagesCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.Please try to copy the code manually.',
  messagesCopied: 'Address copied.',
  deposit: 'Deposit',
  statReset: 'Reset stats',
  rollOver: 'Roll Over',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Back',
  inputFieldBtnMax: 'max',
  buttonTextPlaceBet: 'Place Bet',
  buttonTextCancelBet: 'Cancel Bet',
  buttonTextEscapeBet: 'Cashout',
  buttonTextStartAutoBet: 'Start Autobet',
  buttonTextCancelAutoBet: 'Cancel Autobet',
  buttonTextLoading: 'Loading',
  tabsMyBets: 'My Bets',
  tabsDeposit: 'Deposit',
  tabsCashout: 'Cashouts',
  tabsAllbets: 'All Bets',
  tabsTopbets: 'Top Bets',
  tabsBonus: 'Bonuses',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Bets History',
  timerTextCurrentPayout: 'Current payout',
  timerTextStarting: 'Starting in',
  timerTextSec: 's',
  timerTextFlewAway: 'Crashed',
  timerTextWait: 'Please wait..',
  timerTextPreparingRound: 'Preparing round',
  timerTextLoadingRound: 'Loading Round',
  comingSoon: 'Coming Soon',
  labelsProfitOnWin: 'Profit on win',
  inputsNoOfBets: 'Number of bets',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Cancel',
  buttonsMin: 'Min',
  buttonsMax: 'Max',
  prediction: 'Prediction',
  luckyNumber: 'Lucky Number',
  multiplier: 'Multiplier',
  chanceToWin: 'Chance to win',
  bets: 'Bets',
  depositBtn: 'Deposit',
  rollUnder: 'Roll Under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'History',
  navBarMyBets: 'My Bets',
  navBarAllBets: 'All Bets',
  navBarHighRollers: 'High Rollers',
  navBarRoundHistory: 'Round History',
  navBarTotal: 'Total Bets',
  topHeaderBetId: 'Bet ID',
  topHeaderUser: 'User',
  topHeaderPayout: 'Payout',
  topHeaderAmount: 'Amount',
  topHeaderTime: 'Time',
  topHeaderProfit: 'Profit',
  topHeaderRoundCrashed: 'Crashed',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Bet',
  topHeaderRoundProfit: 'Profit',
  topHeaderRoundSignature: 'Signature',
  roundHistoryTitle: 'GAME INFORMATION',
  roundHistoryDescGame: 'Game #',
  roundHistoryDescCrashAt: 'Crashed At:',
  roundHistoryDescDate: 'Date :',
  roundHistoryDescBetHeader: 'Bet#',
  roundHistoryTableTitlePlayer: 'PLAYER',
  roundHistoryTableTitleBet: 'BET',
  roundHistoryTableTitleCashedOut: 'CASHED OUT',
  roundHistoryTableTitleProfit: 'PROFIT',
  roundHistoryTableTitleBetId: 'BET ID',
  roundHistoryMessagesNoBetHistory: 'Your bets history is empty',
  placedBetsTabPlaced: 'Placed Bet',
  placedBetsTabPrev: 'Previous Bet',
  placedBetsTitleUser: 'User',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Bet',
  placedBetsTitleProfit: 'Profit',
  placedBetsTitleCashout: 'Cashout',
  placedBetsNoPlacedBet: 'No placed Bets.',
  provablyFairContentCheckBtn: 'Check Provable Fair',
  provablyFairContentTitle: 'Provably Fair',
  provablyFairContentDesc: 'Four side lead’s outcome can be proven as fair. Here you can verify the game hashes and calculate the results.',
  provablyFairContentInputFieldsCrashRateTitle: 'Crash Rate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Crash rate without x',
  provablyFairContentInputFieldsRoundHashTitle: 'Round Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'e.g. 0xaaaaaa',
  provablyFairContentInputFieldsSubmitBtn: 'Generate Signature',
  provablyFairContentSignature: 'Round Signature',
  provablyFairContentErrorsCrashRate: 'please input  number in the crash rate field',
  resetPasswordTitle: 'Reset Password',
  resetPasswordNewPasswordTitle: 'New Password',
  resetPasswordNewPasswordErrorsRequired: '*password is required.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 characters allowed.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximum 16 characters allowed.',
  resetPasswordNewPasswordErrorsPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  resetPasswordConfirmPasswordTitle: 'Confirm Password',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwords do not match.',
  resetPasswordResetBtn: 'Reset',
  passwordResetSuccess: 'Password changed successfull, please login to enjoy gaming!',
  resetPasswordWentWrong: 'Something went wrong!',
  levelGrand: 'GRAND',
  levelMajor: 'MAJOR',
  levelMinor: 'MINOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Win Daily $1000 ',
  bannerContentContest: 'Contests now ',
  bannerContentjoin: 'Join our daily wagering contest and become the Hero! higher your position in the ranking when the bigger your prize is! ',
  bannerContentPlayNow: 'Play Now',
  bannerContentTitle: 'Win Jackpot',
  bannerContentContent: 'Play more & more games and get a chance to win jackpot. The more you wager the highest chance of your for jackpot winning.',
  bannerContentGameName: 'CRASH GAME',
  bannerContentGameDesc: 'Play more & more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  partners: 'Partners',
  gamesGoldenDragon: 'Golden Dragon',
  gamesCrashGame: 'Crash Game',
  gamesDice: 'Dice',
  gamesHilo: 'Hilo',
  allBetsTitle: 'All Bets',
  allBetsBetId: 'Bet ID',
  allBetsUser: 'User',
  allBetsPayout: 'Payout',
  allBetsAmount: 'Amount',
  allBetsTime: 'Time',
  allBetsProfit: 'Profit',
  LoginwithOtp: 'Login with Otp',
  EnterLoginOtp: 'Enter Login Otp',
  submit: 'Submit',
  footerAboutUs: 'About Us',
  footerFaq: 'FAQs',
  footerSitePolicy: 'Site Policy',
  footerResponsibleGambling: 'Responsible Gambling',
  footerTerms: 'Terms and Conditions',
  signinTitle: 'Login',
  signinUsername: 'Username / Email',
  signinPassword: 'Password',
  signinLoginBtn: 'Login',
  signinSignup: 'Sign Up!',
  signinForgetPassword: 'Forgot your Password?',
  forgotPasswordMsg: 'Enter the email address associated with your account and we will send you a link to reset your password.',
  emailLinkMsg: 'Reset password link has been sent to your register email please click on email link and set your new password.',
  resetPasswardConfirm: 'Password has been reset successfully',
  signinDontHaveAccount: 'Don’t have an account? ',
  signinCreateAccount: 'Create account',
  signinErrorsUserNameRequired: '*Please enter Username/Email.',
  signinErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signinErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signinErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signinErrorsPasswordRequired: '*Password is required.',
  signinErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signinErrorsPasswordMaxLength: 'Maximum 16 characters allowed.',
  signinErrorsPasswordPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  signinSuccessfullyLoggedIn: 'Successfully logged in.',
  signinResetMailSent: 'Reset password email sent.',
  signinTryAgain: 'Something went wrong!, Please try again.',
  signinResetPasswordUsernameErr: 'Please enter username/email to reset password.',
  signinResetMailSentSuccessContent: 'We sent you a reset password link on your email. Please check your email and reset your password.',
  signupTitle: 'Register',
  signupUsername: 'Username',
  signupEmail: 'Email',
  signupTermsConditions: 'Terms & Conditions',
  signupWalletConnect: 'Login with Wallet ',
  signupPassword: 'Password',
  signupEighteenPlus: 'I’m over 18 years of age and I agree to the Terms of Service',
  signupRegisterBtn: 'SignUp',
  signupCreateNewAccount: 'Create New Account',
  signupFullName: 'Full Name',
  signupReferralCode: 'Referral Code',
  signupConfirmPassword: 'Confirm Password',
  signupUseSocialAccount: 'Login via social networks',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Have an account? ',
  signupLoginBtn: 'Login',
  signupErrorsUserNameRequired: '*Please enter username.',
  signupErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signupErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signupErrorsFullNameRequired: '*Please enter full name.',
  signupErrorsFullNamePattern: 'Please enter valid full name, only letters and numbers are allowed.',
  signupErrorsEmailRequired: '*Please enter email.',
  signupErrorsEmailMinLength: 'Minimum 4 characters allowed.',
  signupErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsEmailPattern: 'Please enter valid email.',
  signupErrorsPasswordRequired: '*Password is required.',
  signupErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signupErrorsPasswordMaxLength: 'Maximum Deposit16 characters allowed.',
  signupErrorsPasswordPattern: '*Password should be 8 to 16 digi alphanumeric(upper and lower case) and special characters. It is case-sensitive.',
  signupErrorsConfirmPasswordMatch: 'Password must match.',
  signupPlaceholderUserName: 'Min. 4 character and only number & letters',
  signupPlaceholderEmail: 'e.g. abc@gmail.com',
  signupPlaceholderPassword: 'Min. 8 character with a number or symbol',
  signupSomethingWentWrong: 'Something went wrong!',
  signupSignupGoogle: 'Successfully signup with Google.',
  signupRegistered: 'Successfully registered.',
  signupConfirmAge: 'Please confirm you are 18 years old.',
  signupSignupFacebook: 'Successfully signup with Facebook.',
  signupCookiesConsent: 'Please allow third party cookies from your browser’s settings to use Social login.',
  signupRegisterEmailPopupContent: 'We sent you a verification email. Please check your email and verify your account.',
  accountMenuDeposit: 'Deposit',
  accountMenuCashout: 'Cashout',
  accountMenujackpotWinners: 'Jackpot Winners',
  accountMenuTransactions: 'Transactions',
  accountMenuPromotions: 'Promotions',
  accountMenuProfile: 'Profile',
  accountMenuLogout: 'Logout',
  accountMenuSound: 'Sound',
  accountMenuMusic: 'Music',
  accountMenuWithdraw: 'Withdraw',
  pageNotFound: 'We can’t find the page you are looking for!',
  error: 'Error',
  somethingWentWrong: 'Something went wrong!',
  verifyEmailThankYou: 'Thank You!',
  verifyEmailEmailIsVerified: 'Your email is verified. Please login to enjoy Gaming!',
  verifyEmailGoToLogin: 'Go to Login page',
  verifyEmailWentWrong: 'Something went wrong!',
  verifyEmailTokenExpired: 'Email already verified or link expired please resend email verification link by providing your registered email below. If this is happening consistently, Please contact support@casinogaming.com',
  verifyEmailGoToHome: 'Go to Home page',
  verifyEmailSuccessMsg: 'Email successfully verified.',
  bonusSectionSpanText: 'presents the most generous and various',
  bonusSectionBonuses: 'Bonuses',
  bonusSectionSubtitleText: 'Play with pleasure and get additional rewards during the game. Choose the most attractive bonuses or use them all at once.',
  JoiningBonusTitle: 'Joining Bonus',
  JoiningBonusButton: 'Claim Now',
  JoiningBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  depositBonusTitle: 'Deposit Bonus',
  depositBonusButton: 'Deposit',
  depositBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  refferBonusTitle: 'Reffer Bonus',
  refferBonusButton: 'Deposit',
  refferBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  aboutSectionSpanText2: 'The online casino allows you to play your favorite games either for free or for cryptocurrency. Also, the player gets a lot of additional bonuses and gifts. Rewards are granted for creating an account, for a successful game, and for upgrading your account/character.',
  aboutSectionSpanText3: ' In this virtual world of Bitcoin gambling, you can enjoy many opportunities — playing your favorite casino games, getting bonuses, participating in contests and promotions.',
  verifiedSectionTitle: 'Verified and Licensed Software',
  verifiedSectionSpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  faqSectionTitle: 'FAQ from Players',
  faqSectionForgetPasswordTitle: 'I forgot my password.',
  faqSectionForgetPasswordDesc: 'You did not provide a recovery email address for your account. Unfortunately it is not possible to reset an account’s password if you did not provide a recovery email address for it.',
  faqSectionMyDepositCreditedTitle: 'My deposit hasn’t been credited.',
  faqSectionMyDepositCreditedDesc: ' Placeholder content for this accordion, which is intended to demonstrate the class. This is the second item’s accordion body. Let’s imagine this being filled with some actual content.',
  SupportedCurrencyTitle: 'Supported Currency',
  SupportedCurrencySpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  CarouselSectionTitle: 'HODL CRASH',
  CarouselSectionSubTitle: ' Play more more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  WalletSettingTitle: 'Wallet Setting',
  WalletSettingHideZero: 'Hide zero balances',
  WalletSettingHideZeroInfo: 'Your zero balance won’t appear in your wallet',
  WalletSettingDisplayFiat: 'Display crypto in fiat',
  WalletSettingDisplayFiatInfo: 'All bets & transactions will be settled in the crypto equivalent',
  WalletSettingNoteForApprox: 'Please note that these are currency approximations.',
  WalletSettingSave: 'Save',
  WalletSettingNoWalletFound: 'No wallet Found',
  jackpotWinnersNoWinnerAvailable: 'No winners Available',
  jackpotWinnersNew: 'New',
  jackpotWinnersHeader: 'Jackpot Winner',
  jackpotDetailsHeader: 'Jackpot detail',
  jackpotDetailsCampaignPeriod: 'Campaign Period',
  jackpotDetailsFrom: 'From',
  jackpotDetailsTo: 'To',
  jackpotDetailsBettingRule: 'Betting Rule',
  jackpotDetailsMinBetAmount: 'Min bet amount',
  jackpotDetailsMaxBetAmount: 'Max bet amount',
  jackpotDetailsBetPercentage: 'Bet percentage',
  jackpotDetailsWinningRuleHeader: 'Winning Rule',
  jackpotDetailsWinningRule: 'Placing bets with higher bet amount will increase chances of winning.',
  jackpotDetailsProfitRule: 'If bet amount is between minBetAmount and maxBetAmount then betPercentage% of bet amount will be added to jackpot amount.',
  profileTitle: 'Personal Details',
  profileFName: 'First Name',
  profileLname: 'Last Name',
  profileUserName: 'User Name',
  profileDob: 'Date of birth',
  profileEmail: 'Email Address',
  profilePhone: 'Phone Number',
  profileChange: 'Change',
  profileSave: 'Save Change',
  profilePassword: 'Password',
  profileSubmit: 'Submit',
  profileUploadProfile: 'Upload Profile',
  profileCurrentPassword: 'Current Password',
  profileNewPassword: 'New Password',
  profileConfirmPassword: 'Confirm Password',
  profileScanQRCode: 'Scan QR Code',
  profileEnterSecretCode: 'Enter Secret Code',
  profileErrorsFirstNameRequired: 'First name is required.',
  profileErrorsFirstNamePattern: 'Please enter valid name.',
  profileErrorsFirstNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsFirstNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsLastNameRequired: 'Last name is required.',
  profileErrorsLastNamePattern: 'Please enter valid name.',
  profileErrorsLastNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsLastNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsChangePasswordOldRequired: 'Old password is required.',
  profileErrorsChangePasswordOldPattern: 'Please enter valid password.',
  profileErrorsChangePasswordOldMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordOldMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewRequired: 'New password is required.',
  profileErrorsChangePasswordNewPattern: 'Please enter valid password.',
  profileErrorsChangePasswordNewMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordNewMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Passwords do not match.',
  profileErrorsChangePasswordNewPasswordMatched: 'New password can not be the same as current password.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Confirm password is required.',
  profileErrorsUserNameRequired: 'User name is required.',
  profileErrorsUserNamePattern: 'Please enter valid name.',
  profileErrorsUserNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsDobRequired: 'Date of birth is required.',
  profileErrorsEmailRequired: 'Email is required.',
  profileErrorsPostcodeRequired: 'Postcode is required.',
  profileErrorsCityRequired: 'City is required.',
  profileErrorsCurrencyRequired: 'Currency is required.',
  profileErrorsAddressRequired: 'Address is required.',
  profileErrorsCountryRequired: 'Country is required.',
  profileErrorsEmailPattern: 'Please enter valid email.',
  profileErrorsEmailMinLength: 'Minimum 3 characters allowed.',
  profileErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  profileMessagesProfileUpdate: 'Profile updated successfully.',
  profileMessagesChangePassword: 'Password updated successfully.',
  profileProfilePicProfilePicUpload: 'Upload profile image',
  profileNoNewChanges: 'Couldn’t update profile! No new changes.',
  profileLoggedInWithSocial: 'You have logged in with social login.',
  profileSideNavigatorSound: 'Sound',
  profileSideNavigatorMusic: 'Music',
  profileSideNavigatorProfile: 'My Profile',
  profileSideNavigatorBetHistory: 'Bet History',
  profileSideNavigatorTransactions: 'Transactions',
  profileSideNavigatorDeposit: 'Deposit',
  profileSideNavigatorWithdraw: 'Withdraw',
  profileSideNavigatorGameLimits: 'Game Limits',
  profileSideNavigatorReferral: 'Referral',
  profileSideNavigatorChangePassword: 'Change Password',
  profileSideNavigatorTermsConditions: 'Terms & Conditions',
  profileSideNavigatorLogOut: 'Log Out',
  profileSideNavigatorProvablyFair: 'Provably Fair',
  WalletInfoSectionNotAvailable: 'Not Available',
  WalletInfoSectionRealBalance: 'Real Balance',
  GameLimitsMinimumBet: 'Minimum bet',
  GameLimitsMaximumBet: 'Maximum bet',
  GameLimitsMaxWinFor1Bet: 'Maximum win fir one bet',
  ProvablyFairSubTitle: 'This game uses Provably Fair technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.',
  ProvablyFairMaximumBet: 'Maximum bet:',
  ProvablyFairMaxWinFor1Bet: 'Maximum win fir one bet',
  promotionsTitle: 'Promotions',
  promotionsNoLosingData: 'Sorry, No Losing bonus promotions available for ',
  promotionsNoDepositData: 'Sorry, No Deposit bonus promotions available for ',
  promotionsCurrency: ' currency.',
  promotionsViewBtn: 'View',
  promotionsClaimSuccess: 'You have successfully claimed',
  promotionsAvailability: 'Availability: ',
  promotionsAvailabilityTabLosing: 'Losing bonus',
  promotionsAvailabilityTabDeposit: 'Deposit bonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. Deposit',
  promotionsDepositBonusTablePercentage: 'Percentage',
  promotionsDepositBonusTableMaxBonus: 'Max Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover multiplier',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover per bet',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Time to achieve rollover target',
  promotionsBonusCode: 'Bonus Code',
  promotionsLossesClaim: 'Losses can be claimed for(within campaign period): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. losing amount',
  promotionsBonusStatus: 'Bonus Status: ',
  promotionsBonusAmount: 'Bonus Amount: ',
  promotionsRolloverTarget: 'Rollover target: ',
  promotionsRolloverAchieved: 'Rollover achieved: ',
  promotionsActivatebtn: 'ACTIVATE',
  promotionsClaimBtn: 'CLAIM',
  promotionsCancelBtn: 'CANCEL',
  promotionsBackBtn: 'BACK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Terms & Conditions',
  promotionsClaimDepositBonusTitle: 'Steps for claiming deposit bonus',
  promotionsClaimDepositBonusFirst: 'Activate Bonus code',
  promotionsClaimDepositBonusSecond: 'Deposit amount to wallet',
  promotionsClaimDepositBonusThird: 'Achieve rollover target for claiming bonus',
  promotionsClaimLosingBonusTitle: 'Steps for claiming losing bonus',
  promotionsClaimLosingBonusFirst: 'Activate Bonus code',
  promotionsClaimLosingBonusSecond: 'Play your favorite games',
  promotionsClaimLosingBonusThird: 'Click ’Claim losses’ to claim losses within campaign period',
  promotionsWentWrong: 'Something went wrong!',
  transactionTitle: 'Transactions',
  transactionBetId: 'Bet ID',
  transactionUser: 'User',
  transactionPayout: 'Payout',
  transactionAmount: 'Amount',
  transactionProfit: 'Profit',
  transactionCashout: 'Cashout',
  transactionBet: 'Bet',
  transactionMultix: 'Multi.x',
  transactionWin: 'Win',
  transactionFairness: 'Fairness',
  transactionReferFriend: 'Refer a Friend',
  transactionTotal: 'Total Bets',
  transactionWins: 'Wins',
  transactionRefresh: 'Refresh',
  transactionFilterTopMultipliers: 'Top Multipliers',
  transactionFilterHugeWins: 'Huge Wins',
  transactionFilterBiggestWins: 'Biggest Wins',
  transactionFilterMultipliers: 'Multipliers',
  transactionRealBalance: 'Real Balance',
  depositWithdrawTXDate: 'Date',
  depositWithdrawTXAmount: 'Amount',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transaction type',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'No data available.',
  depositTitle: 'Deposit',
  depositHistory: 'History',
  depositSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  depositCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.Please try to copy the code manually.',
  depositCopied: 'Address copied.',
  depositAddressCreated: 'Address created successfully.',
  depositGenerateAddress: 'Generate address',
  depositWarningText: 'Please do NOT deposit via cross-chain transfer',
  withdrawTitle: 'Withdraw',
  withdrawSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  withdrawNoteDesc: 'Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a LTC withdraw address wallets',
  withdrawNoteSublist: ' Please do NOT withdraw as cross-chain transfer',
  withdrawHistory: 'History',
  withdrawWithdrawBtn: 'Withdraw Request',
  withdrawFees: 'Withdrawal fee {{fees}} {{token}}',
  withdrawWithdrawPending: 'Your withdraw request is pending. Admin will Appprove it soon.',
  withdrawEnterFields: 'Enter withdraw amount and your wallet address.',
  withdrawSelectWallet: 'Please select wallet to withdraw.',
  withdrawAmountFieldTitle: 'Amount (Min. 0.03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Your {{wallet.currency.code}} address',
  withdrawAddressFieldPlaceholder: 'Type your address',
  withdrawNoBalance: 'Not enough balance.',
  withdrawMinAmount: 'Please enter amount greater than or equal to {{min}} .',
  fiatCurrencyInfo: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  paginationNextBtn: 'Next',
  paginationPrevBtn: 'Previous',
  wentWrong: 'Something went wrong.',
  referralTitle: 'Referral',
  referralSubTitle: 'Refer your Friends',
  referralEarn: ' Earn ₹150 each',
  referralReferAndEarn: 'Refer and earn bonus for each referral',
  referralReferPara: ' If you refer this code to any user & user signup with your referral code then you will get 100% bonus in your account',
  referralReferLink: 'Refer Link',
  referralCopyReferralCode: 'Copy Referral Code',
  referralTotalRewards: 'Total Reward',
  TwoWayAuthentication: 'Two Way Authentication',
  LoginRequired: 'Login required!',
  BetPlacedSuccessfully: 'Bet Placed  successfully',
  BetAddedInQueueSuccessfully: 'Bet added in queue successfully',
  BetRemovedFromQueueSuccessfully: 'Bet removed from queue successfully',
  BetCancelledSuccessfully: 'Bet cancelled  successfully',
  PlayerEscapedSuccessfully: 'Player escaped  successfully',
  CopiedSuccessfull: 'Copied successfull',
  NotEnoughBalance: 'Not enough balance',
  AutoBetStarted: 'Auto bet started ',
  AutoBetFinished: 'Auto bet finished ',
  IdCopied: 'Id copied',
  usersConsent: 'By accessing this site, I confirm that I am 18 years old, and have reviewed,  understood and consented to the',
  termsOfService: 'Terms of Service',
  email: 'Email',
  playNow: 'Play Now',
  orContinueWith: 'Or Continue With',
  metamaskExtensionNotAvailable: 'Please install the metaMask extension and refresh the page.',
  cashier: 'CASHIER',
  account: 'ACCOUNT',
  hello: 'Hello',
  balance: 'Balance',
  referrals: 'Referrals',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  demoWallet: 'Demo Wallet',
  myAccount: 'My Account',
  wallet: 'Wallet',
  user: 'User',
  USD: 'USD',
  amountIsRequired: 'Amount is required',
  minDepositAmount: 'Deposit amount must be greater than {{min}}.',
  maxDepositAmount: 'Deposit amount must be less than {{max}}.',
  minWithdrawAmount: 'Withdraw amount must be greater than {{min}}',
  maxWithdrawAmount: 'Withdraw amount must be less than {{max}}',
  withdrawMustLessThanWallet: 'Withdraw amount must be less than your wallet amount',
  accountTypeIsRequired: 'Account Type is required.',
  mobileNumberLength: 'Mobile number must be of {{length}} digits',
  phoneIsRequired: 'Phone is required',
  cpfError: 'CPF must be of {{length}} characters',
  cpfRequiredError: 'CPF is required',
  cnpjError: 'CNPJ must be of {{length}} characters',
  mainMenu: 'MAIN MENU',
  casino: 'Casino',
  sports: 'Sports',
  crypto: 'Crypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'EARN REWARDS',
  rankUp: 'Rank Up',
  inviteFriends: 'Invite Friends',
  partnerProgram: 'Partner Program',
  home: 'Home',
  sportsbook: 'Sportsbook',
  menu: 'Menu',
  liveBets: 'LIVE BETS',
  allBets: 'All bets',
  highRollers: 'High Rollers',
  luckyBets: 'Lucky Bets',
  trade: 'Trade',
  game: 'GAME',
  player: 'PLAYER',
  time: 'TIME',
  wager: 'WAGER',
  multiplierBan: 'MULTIPLIER',
  payoutText: 'PAYOUT',
  bannerHeadOne: 'Experience true innovation with the highest',
  bannerHeadTwo: 'rewards program within the industry.',
  registerNow: 'Register now',
  liveWins: 'LIVE WINS',
  monthly: 'Monthly',
  weekly: 'Weekly',
  daily: 'Daily',
  winner1: 'Winner 1',
  winner2: 'Winner 2',
  winner3: 'Winner 3',
  spin: 'Spin',
  spinsWon: 'Spins Won',
  joinedOn: 'Joined on',
  wageringAmount: 'Wagering Amount',
  name: 'Name',
  allGames: 'All Games',
  allSports: 'All Sports',
  cryptoFutures: 'crypto futures',
  tradeNow: 'Trade Now',
  allTables: 'All Tables',
  nft: 'NFT',
  viewAllGames: 'See All Games',
  viewAll: 'See All',
  rewards: 'REWARDS',
  profile: 'Profile',
  totalBets: 'Total Bets',
  totalWagered: 'Total Wagered',
  joinDate: 'Join Date',
  displaySocialLinks: 'Display Linked Social Accounts',
  privateProfile: 'Private Profile',
  rank: 'Rank',
  winner: 'Winners',
  nextRank: 'Next Rank',
  changeUsername: 'Change username',
  newPassword: 'New password',
  changeName: 'Change name',
  linkSocialAccount: 'Link social account',
  privateProfileTooltip: "After making your profile private, other users won't see your user name alongside bets, races, etc.",
  socialLinksTooltip: 'Content not available',
  rankTooltip: 'Content not available',
  balances: 'balances',
  createReferralBalance: 'Create referral balance',
  total: 'Total',
  switchBalance: 'Switch Balance',
  mainAccount: 'Main Account',
  tips: 'Tips',
  receivedAmount: 'Received amount',
  dateOrTime: 'Date/Time',
  view: 'View',
  allChains: 'All Chains',
  creditAmount: 'Credit amount',
  setCustomReferralCode: 'Set custom referrals code',
  changeEmail: 'Change email',
  newEmail: 'New email',
  createPassword: 'Create password',
  newUsername: 'New username',
  username: 'Username',
  changePassword: 'Change password',
  updateProfileMsg: 'Profile updated successfully',
  twoFactorAuthentication: 'two-factor authentication',
  twoFactorAuthenticationTitle: 'Using two-factor authentication is highly recommended because it protects your account with both your password and your phone.',
  twoFactorAuthenticationSubTitle: 'An email and password must be set to activate 2FA. While 2FA is enabled, you will not be able to login via Steam.',
  enable2fa: 'ENABLE 2FA',
  verifyIdentityByKyc: 'Verify your identity (kyc)',
  oldPassword: 'Old password',
  live: 'Live',
  soccer: 'Soccer',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ice Hokey',
  volleyball: 'Volleyball',
  tableTennis: 'Table Tennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boxing',
  myBets: 'My Bets',
  favourites: 'Favourites',
  americanFootball: 'American Football',
  buyCrypto: 'Buy Crypto',
  oldEmail: 'Old Email',
  loggedInSuccess: 'Logged in successfully',
  loggedOutSuccess: 'Logged out successfully',
  wagerLimit: 'Wager limit set successfully',
  lossLimit: 'Loss limit set successfully',
  accountDisable: 'Account disabled successfully',
  sessionTimeout: 'Session timeout set successfully',
  signup: 'Signup successfully',
  depositLimit: 'Deposit limit set successfully',
  tokenVerify: 'Token has been sent to your Email, please verify',
  emailVerify: 'Email has been verified',
  emailChangedSuccess: 'Email has been changed successfully',
  profileUpdatedSuccess: 'Profile updated',
  otpVerified: 'OTP verified',
  twoFADeactivated: '2FA Authentication deactivated',
  internalServerError: 'Internal server error',
  unAuthorized: 'Unauthorized',
  dataNotFound: 'Data Not Found',
  setReferralCode: 'Set referral code...',
  ultimate: 'ULTIMATE',
  notFound: 'Not Found',
  casinoTransactions: 'Casino Transactions',
  sportsbookTransactions: 'Sportsbook Transactions',
  status: 'Status',
  success: 'Success',
  failed: 'Failed',
  pending: 'Pending',
  date: 'Date',
  to: 'To',
  gameName: 'Game Name',
  transactionId: 'Transaction Id',
  roundId: 'Round ID',
  bet: 'Bet',
  win: 'Win',
  transactionType: 'Transaction Type',
  at: 'at',
  downloadAll: 'Download All',
  gameText: 'Game',
  betId: 'Bet ID',
  gameId: 'Game ID',
  actionType: 'Action Type',
  searchGames: 'Search Games',
  provider: 'Providers',
  search: 'Search',
  sortBy: 'Sort by',
  popular: 'Popular',
  challengePool: 'Challenge Pool',
  loadMore: 'Load More',
  bitcoin: 'Bitcoin',
  chat: 'Chat',
  trades: 'Trades',
  battles: 'Battles',
  loginToChat: 'Login to chat',
  rules: 'Rules',
  chatRule1: 'Do not harass or insult other users',
  chatRule2: 'Do not beg, ask for loans, trivias or tips',
  chatRule5: 'Do not advertise any form of trading, buying or selling services',
  chatRule6: 'Do not share or advertise your referral code',
  chatRule7: 'Do not ask to become a staff member',
  chatRule8: 'English only',
  chatRule9: 'Respect mods, admins and other users',
  send: 'Send',
  expand: 'Expand',
  signIn: 'Sign In',
  cross: 'Cross',
  collapse: 'Collapse',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'Remove from favourites',
  addToFavourite: 'Add to favourites',
  footerAboutSite: "Let's talk a bit about science? Cash Machine Casino website is a part of you, as we all consist of the same atoms. Your interaction with it confirms this connection.",
  footerRightsReserved: 'Сash Machine Casino all rights reserved.',
  signupFirstName: 'First Name',
  signupAddress: 'Address',
  signupCity: 'City',
  signupPostcode: 'Postcode ',
  signupCounty: 'Country',
  signupCurrency: 'Currency',
  signupGender: 'Gender',
  signupMan: 'Man',
  signupWomen: 'Women',
  signupOther: 'Other',
  signupLoginDetails: 'Login in Details',
  signupPersonalDetails: 'Personal Details',
  signupConfirm: 'Confirm',
  signupPrivacyPolicy: 'I have read and accepted the Privacy Policy',
  signupTermAndConditions: 'I agree with the processing of my personal data. I am above 18 years of age and have read and accepted all Terms and Conditions.',
  signupNewsLetter: 'I would like to receive bonuses, promotions, news or other relevant information periodically by News Letter.',
  signupSms: 'I would like to receive bonuses, promotions, news or other relevant information periodically by SMS.',
  initialHeaderContent: 'Get an amazing 100%',
  secoundaryHeaderContent: 'Welcome bonus upto $100',
  playNowButton: 'Play Now',
  registerButtonContent: 'Register',
  results: 'Results',
  refresh: 'Refresh',
  cancel: 'Cancel',
  select: 'Select',
  current: 'Current',
  set: 'Set',
  remove: 'Remove',
  save: 'Save',
  not: 'not',
  before: 'Before',
  after: 'After',
  action: 'Action',
  withdrawal: 'Withdrawal',
  requested: 'Requested',
  withdraw: 'Withdraw',
  areYouSure: 'Are You Sure',
  yes: 'Yes',
  no: 'No',
  cancelled: 'Cancelled',
  approved: 'Approved',
  refunded: 'Refunded',
  chargeback: 'Chargeback',
  processedOn: 'Processed On',
  transaction: 'Transaction',
  dateTime: 'Date/Time',
  more: 'More',
  fromDate: 'From Date',
  toDate: 'To Date',
  percentage: 'Percentage',
  info: 'Info',
  min: 'Min',
  max: 'Max',
  change: 'Change',
  continueToSite: 'Continue To Site',
  choose: 'Choose',
  enterWithdrawalAmount: 'Choose or enter your Withdrawal Amount',
  capDeposit: 'DEPOSIT',
  capWithdrawal: 'WITHDRAWAL',
  capAmount: 'AMOUNT',
  minimum: 'Minimum',
  required: 'Required',
  of: 'of',
  isRequired: 'is Required',
  isAllowed: 'isAllowed',
  method: 'Method',
  capWithdraw: 'WITHDRAW',
  showLess: 'Show Less',
  clearFilter: 'Clear Filter',
  quantity: 'Quantity',
  reset: 'Reset',
  incorrect: 'Incorrect',
  load: 'Load',
  applied: 'Applied',
  ok: 'OK',
  list: 'List',
  all: 'All',
  favorite: 'Favorite',
  endPointNotFound: 'Something is wrong with the network connection',
  category: 'Category',
  emailUserNameRequired: 'Email/Username Required',
  emailUserNamePlaceHolder: 'Enter your Email or Username',
  passwordRequired: 'Password Required',
  accountFrozen: 'Your account is frozen till',
  resendEmail: 'Resend Email',
  resendLink: 'RESEND LINK',
  userLoggedOut: 'Logged Out Successfully',
  emailAlreadyRegistered: 'This email is already registered',
  userNameAlreadyTaken: 'This username is already taken',
  fillAllFields: 'Fill all fields before moving to the next step',
  pleaseAccept: 'Please accept',
  acceptPrivacyPolicy: 'Privacy Policy',
  acceptTerms: 'Terms and Condition',
  modeOfComm: ' and mode of communication',
  beforeNextStep: 'before moving to the next step',
  userNamePlaceholder: 'Enter Username',
  min8Characters: 'Min 8 characters',
  min1Number: 'Min 1 Number',
  min1LowerCase: 'Min 1 lower case character',
  min1UpperCase: 'Min 1 upper case character',
  min1SpecialChar: 'Min 1 special character',
  passwordTip: 'Password must have at least eight characters with at least one uppercase letter, one lowercase letter, one number and one special character',
  passwordPlaceholder: 'Enter Password',
  confirmPasswordPlaceholder: 'Enter Confirm Password',
  acceptAll: 'Accept All',
  firstNamePlaceholder: 'Enter First Name',
  lastNamePlaceholder: 'Enter Last Name',
  addressPlaceholder: 'Enter Address',
  cityPlaceholder: 'Enter City',
  postCodePlaceholder: 'Enter Post Code',
  phonePlaceholder: 'Enter Phone Number',
  invalidEmail: 'InvalidEmail',
  emailRequired: 'Email Required',
  confirmPasswordRequired: 'Confirm Password Required',
  firstNameRequired: 'First Name Required',
  lastNameRequired: 'Last Name Required',
  phoneRequired: 'Phone Required',
  dateOfBirthRequired: 'Date Of Birth Required',
  genderRequired: 'Gender Required',
  userNameRequired: 'UserName Required',
  addressRequired: 'Address Required',
  cityRequired: 'City Required',
  postCodeRequired: 'Post Code Required',
  currencyCodeRequired: 'Currency Code Required',
  countryRequired: 'Country Required',
  matchPassword: 'Password must match',
  fName3Chars: 'First Name must be atleast 3 characters',
  onlyAlphabet: 'Only Alphabets allowed',
  lName3Chars: 'Last Name must be atleast 3 characters',
  mustBe18: 'You Must be 18 Year old to get registered',
  mustBeUnder200: 'You Must be under 200 Year old to get registered',
  validDOB: 'Please enter a valid Date of Birth',
  max20Characters: 'Maximum 20 Characters Allowed',
  min3Characters: 'Minimum 3 Characters Required',
  max100Characters: 'Maximum 100 Characters Allowed',
  max50Characters: 'Maximum 50 Characters Allowed',
  invalidNumber: 'Invalid Number',
  preferredLanguageRequired: 'Preferred Language Required',
  invalidPass: 'Invalid Password',
  prevBtn: 'Previous',
  dayRequired: 'Day is required',
  monthRequired: 'Month is required',
  yearRequired: 'Year is required',
  uploadImage: 'Upload Image',
  removeImage: 'Remove Image',
  preferredLanguage: 'Preferred Language',
  wantToChangePass: 'Do you want to Change Password ?',
  selectCountryCode: 'Select Country Code',
  selectPreferredLanguage: 'Select Preferred Language',
  fName50Chars: 'First Name must be atmost 50 characters',
  lName50Chars: 'Last Name must be atmost 50 characters',
  invalidDate: 'Invalid Date',
  DOBEarlyThanToday: 'Date Of Birth Must be Earlier than Today',
  enterValidPhone: 'Please enter valid phone number',
  accountStatus: 'Account Status',
  kycVerified: 'Your KYC had been verified successfully.',
  denied: 'Denied',
  chooseFile: 'Choose File',
  upload: 'Upload',
  countryCantChange: "You are about to initiate KYC Verification. Confirm Your country before proceeding. Once the KYC has been initiated, you won't be able update your Country.",
  proceed: 'Proceed',
  update: ' ',
  history: 'History',
  type: 'Type',
  amount: 'Amount',
  forfeited: 'Forfeited',
  expired: 'Expired',
  completed: 'Completed',
  zeroedOut: 'Zeroed Out',
  active: 'Active',
  lapsed: 'Lapsed',
  yourActiveBonus: 'Your Active Bonuses',
  currentActiveBonus: 'Current Active Bonuses',
  bonusStatus: 'Status',
  bonusWager: 'Wager',
  bonusActivate: 'Activate',
  bonusForfeit: 'Bonus Forfeit',
  notUsableBonus: 'If yes, you wont be able to use this bonus again.',
  bonusLapsed: 'You didnt have enough losses to get cashback. Therefore, bonus lapsed.',
  inProcess: 'In-Process',
  claiming: 'Claiming',
  loyaltyPopover: 'These Loyalty Points can be used for getting cashback. Find out more on our',
  loyaltyPage: 'Loyalty Page',
  loyaltyPerCurrency: 'Loyalty per currency',
  maxLevelReached: 'Max Level Reached',
  pointsToReach: 'points to reach ',
  loyaltyBannerHeading: 'You Deserve Our Brand-New Loyalty',
  loyaltyBannerDesc: '100% Welcome Bonus Upto $200',
  loyaltyHeadingOne: 'You are Always Rewarded at CashMachine',
  loyaltyHeadingOneDesc: "At CashMachine, we take loyalty to a whole new level of excitement, showering you with rewards at every twist and turn! Our thrilling loyalty program gives you points for each daring bet you place at our casino. These points propel you through our dazzling loyalty tiers, unlocking an array of incredible benefits that rain down upon you daily, weekly, and monthly. Now, let's dive into the exhilarating details of how it all works! CashMachine is thrilled to present our captivating loyalty program, featuring 10 unique levels that offer an array of enticing benefits for our cherished players. Here's a quick guide to help you understand and navigate your way to even greater rewards:",
  loyaltySubHeadOne: 'Earning Loyalty Points',
  loyaltySubHeadOneDesc: "For every €10 in cash bets placed at our casino, you'll earn 1 loyalty point. As soon as you earn points by placing bets on any of our exciting games, you'll be assigned to one of our 10 loyalty levels. Each level is designed to reward you with increasingly enticing benefits as you progress.",
  loyaltySubHeadTwo: 'Climbing the Loyalty Ladder',
  loyaltySubHeadTwoDesc: "The more points you accumulate, the higher you'll ascend through the loyalty levels, unlocking even more fantastic rewards along the way. Plus, you'll earn daily cashback based on your current level. The higher your loyalty level, the bigger the cashback percentage you'll receive daily.",
  loyaltySubHeadThree: 'Level up, get rewarded ',
  loyaltySubHeadThreeDesc: 'Elevate your gaming experience by progressing through our enticing reward tiers, where each level up unlocks an exhilarating array of free spins to maximize your chances of winning. As you climb higher, delight in the satisfaction of receiving instant cash rewards, a testament to your dedication and skill. Additionally, relish the benefits of our increasingly generous cashback offers, designed to make your journey even more rewarding, providing a safety net for your bets and an incentive to keep pushing forward.',
  loyaltyTestimonialHeadOne: 'AT CashMachine CASINO WE MAKE SURE THAT',
  loyaltyTestimonialHeadTwo: 'Every Spin Counts',
  loyaltyTestimonialDesc: "Every spin you take at the slots ain't just for fun, but also gets you closer to some seriously sweet rewards! Rack up those spins, and watch as the prizes and Cashbacks get bigger and better. It's a win-win, making your time at the casino a total blast, while you rake in those perks. So, spin away and let the good times roll!",
  loyaltyPoints: 'Points',
  headerLevel: 'level',
  loyaltyCashback: 'Cashback',
  loyaltyBannerBtn: 'Check Progress',
  loyaltyHeadingTwo: 'Unlock the Ultimate Gaming Experience: CashMachine VIP Program',
  loyaltyHeadingTwoDesc: "Once you reach Level 6 in our loyalty program, you'll unlock a world of exclusive privileges as a member of the prestigious CashMachine VIP Program. This elite club is designed to offer a superior gaming experience and pamper you with exceptional perks and benefits, including in examples below.",
  loyaltySubHeadFour: 'Higher Deposit and Withdrawal Limits',
  loyaltySubHeadFourDesc: 'Enjoy the flexibility of increased deposit and withdrawal limits as a VIP member.',
  loyaltySubHeadFive: 'Exclusive Bonuses and Promotions',
  loyaltySubHeadFiveDesc: 'Gain access to special bonuses and promotions tailor-made for our VIP players',
  loyaltySubHeadSix: 'Personal Account Manager',
  loyaltySubHeadSixDesc: 'Receive dedicated support and personalised assistance from your account manager.',
  loyaltySubHeadSeven: 'Faster Withdrawal Times',
  loyaltySubHeadSevenDesc: "As a VIP, you'll enjoy expedited withdrawal times, ensuring you can access your winnings even quicker.",
  loyaltySubHeadEight: 'Monthly Cashback Rewards',
  loyaltySubHeadEightDesc: 'Boost your bankroll with generous monthly cashback rewards exclusively for our VIP members.',
  loyaltySubHeadNine: 'Invitations to Exclusive Events and Tournaments',
  loyaltySubHeadNineDesc: 'Get your hands on coveted invites to exclusive events and tournaments, where you can mingle with fellow VIPs and compete for incredible prizes.',
  loyaltySubHeadTen: 'Birthday Bonuses',
  loyaltySubHeadTenDesc: 'Celebrate your special day in style with a delightful birthday bonus just for you!',
  loyaltySubHeadEleven: 'Luxury Gifts',
  loyaltySubHeadElevenDesc: 'Indulge in an exquisite selection of luxury gifts handpicked to reward our most valued players.',
  loyaltyTableHeading: 'Levels and Rewards Overview',
  loyaltyTableHeaderOne: 'Loyalty Level',
  loyaltyTableHeaderTwo: 'Loyalty Points',
  loyaltyTableHeaderThree: 'Daily Cashback',
  promReadMore: 'Read More',
  currentPortalBlock: 'if you want to block your account only for this portal.',
  allPortalBlock: 'to block your account from all portals.',
  limit24Reset: 'Once you set Wager, Loss, Deposit limits then it will be editable and removeable after 24hrs but limits can be decreased immediately.',
  enterAmount: 'Enter Amount',
  limit: 'Limit',
  loss: 'Loss',
  takeABreak: 'Take A Break',
  session: 'Session',
  selfExclusion: 'Self Exclusion',
  used: 'Used',
  edit: 'Edit',
  updatedAt: 'Updated At',
  cannotBeRemoved: 'Can not be removed before',
  timePeriod: 'Time Period',
  custom: 'Custom',
  hours: 'Hours',
  days: 'Days',
  timePeriodPlaceholder: 'Number of Days',
  months: 'Months',
  permanent: 'Permanent',
  onlyNumbers: 'Only Numbers allowed',
  notNumbers: 'Numbers Not Allowed',
  userNameAllowed: 'Must Start with Alphabet. No Spaces allowed.',
  timePeriodRequired: 'Time Period Required',
  cannotBeIncreased: 'Can not be increased before',
  amountGTZero: 'Amount should be greater than 0',
  amountEqualOrLess: 'Amount should be equal or less than weekly and monthly',
  amountInBetween: 'Amount should be in between daily and monthly(inclusive)',
  amountEqualOIrGreater: 'Amount should be equal or greater than daily and monthly',
  limitRemoved: 'Your following limit will be removed.',
  limitSet24Hrs: 'You are about to set the following limit to your account. Please note, that in case you want to change the limit, you can do that after 24hrs.',
  takeABreakInfo: 'You are about to block the access to your account for a preset period of time. Please know that once you use the option, you will not be able to log in to your account to change the block period. If you would like to choose a different block period or extend the existing one, please contact us via Live Support.',
  sessionLimitInfo: 'You are about to set the following limit to your account. Please note, that in case you want to change the limit, it will take 24 hours for the limit to be changed after you set another limit amount.',
  limitCantSetBefore: 'Your session limit can not be set before',
  selfExclusionInfo: 'You are about to block the access to your account for a preset period of time. Please know that once you use the option, you will not be able to log in to your account to change the block period. If you would like to choose a different block period or extend the existing one, please contact us via Live Support.',
  quickSearch: 'Quick Search',
  cash: 'Cash',
  nonCash: 'Non-Cash',
  userDetail: 'User Detail',
  gameIdentifier: 'Game Identifier',
  rollback: 'Rollback',
  rollbackBeforeBetWin: 'Rollback Before Bet Win',
  freeSpins: 'Free Spins',
  betInternal: 'Bet Internal',
  winInternal: 'Win Internal',
  addMoney: 'Add Money',
  removeMoney: 'Remove Money',
  addMoneyInternal: 'Add Money Internal',
  removeMoneyInternal: 'Remove Money Internal',
  depositInternal: 'Deposit Internal',
  withdrawInternal: 'Withdraw Internal',
  promotionTitle: 'Promotion Title',
  cancelWithdrawRequest: 'You want to Cancel this request',
  rowsPerPage: 'Rows Per Page',
  availedBonus: 'You already have an active bonus. To continue',
  capForfeit: 'FORFEIT',
  itFirst: 'it first.',
  selectYourBonus: 'Select Your Bonus',
  skip: 'SKIP',
  with: 'with',
  without: 'without',
  selectPaymentMethod: 'Please choose your Payment Method',
  volatility: 'Volatility',
  paylines: 'Paylines',
  theme: 'Theme',
  tryForFree: 'Try for Free',
  resultsFound: 'Results Found',
  games: 'Games',
  in: 'in',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  'medium-high': 'Medium High',
  'very-high': 'Very High',
  'low-medium': 'Low Medium',
  fantasy: 'Fantasy',
  ancient_civilizations: 'Ancient Civilizations',
  fruits: 'Fruits',
  africa: 'Africa',
  military: 'Military',
  joker: 'Joker',
  asia: 'Asia',
  luxurylife: 'Luxury Life',
  underwater_world: 'Underwater World',
  book_off: 'Bookoff',
  western: 'Western',
  retro: 'Retro',
  egypt: 'Egypt',
  party: 'Party',
  st_patrick_day: 'St Patrick Day',
  space: 'Space',
  easter: 'Easter',
  girls: 'Girls',
  branded: 'Branded',
  x_mas_and_new_year: 'Xmas and New Year',
  horrors: 'Horrors',
  other: 'Other',
  loginFirst: 'Please Login First To Add Game In Favorite',
  loginFirstPlay: 'Please Login First To Play this Game',
  sport: 'Sport',
  pirates: 'Pirates',
  sweets: 'Sweets',
  luxury_life: 'Luxury Life',
  st_valentines_day: 'St Valentines Day',
  halloween: 'Halloween',
  food: 'Food',
  lobby: 'lobby',
  favourite: 'Favourite',
  playNGO: 'Play N Go',
  loyaltySystem: 'Loyalty System',
  progressionSystem: 'Progression system',
  startPoint: 'Start Point',
  endPoint: 'End Point',
  loyaltyBonus: 'Loyalty Bonus',
  pageRemoved: 'This Page does not exist or was removed',
  suggestToBackHome: 'We suggest you back to home',
  backToHome: 'Back To Home',
  goHome: 'Go Home',
  oopps: 'Oopps',
  somethingNotWorking: 'We’re sorry, something is not working here!',
  tournament: 'Tournament',
  none: 'None',
  validOnDays: 'Valid On Days',
  validTill: 'Valid Till',
  yourRequest: 'Your Request',
  withdrawalRequestPending: 'You have one withdrawal request in pending state. To activate a bonus you need to',
  realBalance: 'Real Balance',
  bonusInCasino: 'Bonus In Casino',
  bonusSelected: 'Bonus Selected',
  payWith: 'Pay With',
  reached: 'Reached',
  receiptOfYour: 'Receipt of your',
  capEmail: 'EMAIL',
  capCreditCard: 'CREDITCARD NUMBER',
  capExpiry: 'EXPIRY',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY is not in correct format',
  min13Numbers: 'Minimum 13 Numbers Required',
  max19Numbers: 'Maximum 19 Numbers Allowed',
  min3Numbers: 'Minimum 3 Numbers Required',
  max4Numbers: 'Maximum 4 Numbers Allowed',
  Other: 'Other',
  forfeit: 'Forfeit',
  withdrawRequestCreated: 'Withdraw Request Created Successfully',
  noBetHistory: 'No Bet History Found',
  noPromotions: 'No Promotions Found',
  noBonuses: 'No Bonuses Found',
  noHistory: 'No History Found',
  noWithdrawalHistory: 'No Withdrawal History Found',
  noCMS: 'No CMS Found',
  noProvidersFound: 'No Providers Found',
  noDataFound: 'No Data Found',
  countryBlocked: 'COUNTRY BLOCKED',
  forbidden: 'FORBIDDEN',
  notAcceptingVisitors: 'We are deeply sorry about this, but shinywilds.com is not available in your country.',
  contactUsAt: 'If you think this was shown to you by error, please contact our support via email on',
  siteUnderMaintenance: 'Site Under Maintenance',
  weWillBeRightBack: 'We will be right back!',
  serviceUnavailable: 'Our service is temporarily unavailable. We are currently working to restore it.',
  tryAgainLater: 'Please try again later.',
  checkYourEmail: 'Check your email',
  sentAVerification: 'We’ve sent a verification link',
  toYourEmail: 'to your email.',
  backToLogin: 'Back To Login',
  goToLogin: 'Go To Login',
  accountVerified: 'Account Verified',
  yourAccountVerified: 'Your Account has been verified',
  successfully: 'Successfully',
  verificationFailed: 'Verification failed',
  emailFailedContact: 'Verification Failed.If this is consistently happening, Please contact ',
  setNewPass: 'Set a new password',
  passResetLink: 'We’ve sent a password reset link to',
  toYourAccount: 'To Your Account',
  sendPasswordResetLink: 'Reqest to Reset Password',
  gamePlayWagerComplete: 'Congratulation, you have successfully completed wagering and bonus money won is greater than max win amount allowed. So, crediting max win amount to wallet',
  real: 'Real',
  demo: 'Demo',
  money: 'Money',
  formatRquired: '.png , .jpg, .jpeg format required',
  chooseImage: 'Please Choose an image',
  imageSize3MB: 'Enter Image Having Size Less than 3 MB',
  noProfileImage: 'No Profile Image available to remove',
  limitSetSuccess: 'limit set successfully',
  accountDisabled: 'Account Disabled Successfully',
  loginSuccess: 'Login Successful',
  signupSuccess: 'Sign Up Successful',
  linkExpired: 'Link Expired',
  linkSent: 'Link Sent',
  accAlreadyVerified: 'Account Already Verified. Please Login',
  profileImage: 'Profile Image',
  profileImageRemoved: 'Profile Image Removed Successfully',
  docs: 'Documents',
  updatedSuccess: 'Updated Successfully',
  MATCH: 'MATCH',
  BALANCE: 'BALANCE',
  FREESPINS: 'FREESPINS',
  MATCH_1: 'MATCH',
  DEPOSIT: 'DEPOSIT',
  WAGERING: 'WAGERING',
  PROMOTION: 'PROMOTION',
  CREDITCARD: 'CREDIT CARD',
  WALLET: 'WALLET',
  CRYPTO: 'CRYPTO',
  OTHER: 'OTHER',
  INSTANTBANKING: 'INSTANT BANKING',
  cookieTitle: 'Cookies!',
  declineButton: 'Decline',
  acceptButton: 'Accept',
  cookieDeclinedMessage: 'For the best possible experience, cookies must be accepted to continue using this website. Please accept the cookies if you want to proceed.',
  cookieDescription: "Welcome to Shinywilds.com! We use cookies to optimise your user experience. By clicking 'Accept,' you agree to their use. You can opt-out by clicking 'Decline.",
  forfeitMessageFirst: 'You currently have the following active bonus:',
  forfeitMessageSecond: 'You need to forfeit this bonus before claiming a new one.',
  forfeitButton: 'Forfeit',
  pleaseVerifyFirstText: "In order to be able to request a withdrawal, we first need to verify your email address. If you haven't received your a verification email before, please",
  clickHere: 'Click Here',
  pleaseVerifyLastText: 'so we can re-send you the verification email and proceed.',
  pleseWait: 'Please wait for',
  toResend: 'to resend the link',
  notVerified: 'You must verify your email address to withdraw money',
  verification: 'Verification',
  UPLOAD_IMG_MSG: 'Drag n drop files here, or click to select files',
  affiliate: 'Affiliate',
  affiliateTitle: 'Affiliate Program',
  affiliateDescription: ' Refer our brand and earn money through participating in Cash Machine Affiliate Program! You will receive a commission for users who register and play on Cash Machine through your links.  Being a Cash Machine affiliate means you will earn commission through this program.',
  refferalTitle: 'Referral Progam',
  refferalDescription: 'You can share this referral link to others and earn some bonus if user will register using your given link',
  affiliateButtonText: 'Become Affiliate',
  affiliateLink: 'Affiliate Link',
  affiliateStatus: 'Your Affiliate request is sent succesfully',
  currentStatus: 'and curent Affiliate status is {{status}}',
  referralLink: 'Referral Link',
  copyTitleMessage: 'Please copy this link',
  copiedMessage: 'Link copied',
  searchedGame: 'Searched Game',
  favoriteGame: 'Favorite Games',
  randomGames: 'Random Games',
  poolPrize: 'Prize Pool',
  chooseYourWelcomeBonus: 'Choose your welcome bonus',
  useNow: 'Use Now',
  readTheTerms: 'Read the terms',
  proceedWithoutWelcomeBonus: 'Proceed Without Welcome Bonus',
  doYouAlreadyHaveAccount: 'Do you already have an account? ',
  registerViaSocialNetworks: 'Register via social networks',
  iconfirmIm18YearsOlder: 'I confirm that I’m 18 years or older',
  iAgreeWithTermsAndConditions: 'I agree with Terms and Conditions',
  thankYouSuccessfullyRegisteringCashMachine: 'Thank you for successfully registering on Cash Machine.',
  verifyEmailMessage: 'We request you to verify your email by clicking on the verification link sent to your email for successful login on the site.',
  gotIt: 'Got It',
  phonecodeRequired: 'Phone code is required',
  cpNotAllow: 'copy/paste is not allow',
  playFun: 'Play Fun',
  playReal: 'Play Real',
  yourRealBalance: 'Your Real Balance is:',
  chooseYourPaymentMethod: 'Choose your Payment Method',
  balanceHistory: 'Balance History',
  liveChat: 'Live Chat',
  mail: 'Mail',
  chooseEnterYourDepositAmounts: 'Choose or enter your deposit amounts',
  noBonusDataAvailable: 'No bonus data available',
  nowTryPlayForRealBets: 'Now try to play for real bets',
  registerAndPlayForRealBets: 'Register and play for real bets',
  tournamentDataNotAvailable: 'Tournament data not available',
  startDate: 'Start date',
  endDate: 'End date',
  merchant: 'Merchant',
  seachHere: 'Search here',
  show: 'Show',
  betResults: 'Bet results:',
  noTransactionsFoundForselectedDate: 'No Transactions found for selected date',
  loginError: 'Login Error',
  errorMessageIncorrectlyAuthorization: 'Email / login or password entered incorrectly Authorization error.',
  profileSaved: 'Profile Saved',
  addInfo: 'Add Info',
  iHaveReadAndAgreePaymentSystemRestrictions: 'I have read and agree with the payment systems restrictions*',
  paymentOptions: 'Payment Options',
  language: 'Language',
  betTime: 'Bet Time',
  noActiveBonusFound: 'No active bonus found',
  bonusType: 'Bonus Type',
  requiredWageringAmount: 'Required Wagering Amount',
  currentWageringAmount: 'Current Wagering Amount',
  validUpto: 'Valid Upto',
  bonusCode: 'BonusCode',
  wageringProgressBar: 'Wagering Progress Bar',
  noGamesFound: 'No games found',
  noteYouMayOptfreeSpin: 'Note: You may opt for FREESPIN on any one of these games',
  viewOfferDetails: 'View offer details',
  viewFreespinGames: 'View Freespin Games',
  depositBonusPercentage: 'Deposit Bonus Percentage: ',
  validity: 'Validity',
  activated: 'Activated',
  bonusName: 'Bonus Name',
  freeRounds: 'Free Rounds',
  wagering: 'Wagering',
  ended2: 'Ended',
  searchBonusTitle: 'Search by bonus title',
  claimed: 'Claimed',
  noBonusHistoryFoundselectedDates: 'No Bonus history found for selected dates',
  noBonusDetailsFound: 'No Bonus Details found',
  enterCode: 'Enter code',
  transactionTime: 'Transaction Time',
  credit: 'Credit',
  debit: 'Debit',
  // bonusInfoOfHeading: 'Bonuses are extra payments that reward your performance',
  bonusInfoOfHeading: 'Bonuses are additional rewards that recognize your activity and achievements.',
  onlineCasino: 'online casino',
  isTheTop: ' is the top ',
  tournamentsHistory: 'Tournaments history',
  bonusInfoPara0: 'Looking for the very best casino games online? Then you’ve come to the right place.',
  loginWelcomeBonusInfo: 'Cash Machine welcomes new players with a welcome bonus - 100% of  the Deposit + 100 freespins in the game "Pat"s Heroes" (Platipus).  The minimum Deposit amount is EUR 20 / ARS 900 / AUD 30 / CZK 500  / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70  / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130',
  loginDepositBonusInfo: 'Maximum bonus amount: 100% of the Deposit amount, but not more  than EUR 100 / ARS 4000 / AUD 150 / CZK 2500 / DKK 750 / NOK 1000  / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45 000 /  TJS 1000 / UZS 1 000 000 / TRY 600',
  loginFreespinBonusInfo: 'Free spins are isssued within 5 days since the bonus activation,  20 free spins daily. All funds won on free spins are credited to  the bonus account and are available for playing/wagering the  bonus. Withdraw available after wagering X40. Bonus wagering time  is 7 days.',
  loginMaximumBetLimitInfo: 'Maximum bet limit during wagering: EUR 1 / ARS 45 / AUD 1.5 / CZK  25 / DKK 7 / NOK 10 / NZD 1.5 / PLN 5 / USD 1 / ZAR 15 / GEL 3.5 /  KZT 450 / TJS 10 / UZS 10 000 / TRY 6',
  bonusInfoPara1: ' , with a great range of fantastic games and dazzling special offers. Our slot games come in a wide variety of themes, with progressive jackpot slot games offering players a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled∂ and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of  each game before you choose to play for real.',
  bonusInfoPara2: 'Our slot games come in a wide variety of themes, with progressive jackpot slot games offering a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of each game before you choose to play for real.Our slot games come in a wide variety of themes, with progressive jackpot slot games offering players a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of each game before you choose to play for real.',
  bonusInfoPara3: 'Our slot games come in a wide variety of themes, with progressive jackpot slot games offering players a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of each game before you choose to play for real.',
  bonusInfoPara4: 'Our slot games come in a wide variety of themes, with progressive jackpot slot games offering players a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of each game before you choose to play for real.Our slot games come in a wide variety of themes, with progressive jackpot slot games offering players a lucrative chance to score a big win. We also offer exciting live dealer games where you can see your cards shuffled and dealt in real time! Unsure of which game is right for you? Don’t worry - you can practice in demo mode first to get a feel of each game before you choose to play for real.',
  cashMachineFooterPara1: 'Looking for the very best casino games online? Then you’ve come to the right place.',
  cashMachineFooterPara2: 'with a great range of fantastic  games and dazzling special offers. Our slot games come in a wide  variety of themes, with progressive jackpot slot games offering  players a lucrative chance to score a big win. We also offer  exciting live dealer games where you can see your cards shuffled and  dealt in real time! Unsure of which game is right for you? Don"t  worry - you can practice in demo mode first to get a feel of each  game before you choose to play for real.',
  // tournamentDescTitle: ' Live Casino, Slots and Drops and wins casino Tournaments',
  tournamentDescTitle: 'Tournaments are a great opportunity to compete with other players and win generous prizes.',
  tournamentPara1: 'Looking for the very best casino games online? Then you’ve come to the right place.',
  tournamentPara2: 'with a great range of fantastic  games and dazzling special offers. Our slot games come in a wide  variety of themes, with progressive jackpot slot games offering  players a lucrative chance to score a big win. We also offer  exciting live dealer games where you can see your cards shuffled  and dealt in real time! Unsure of which game is right for you?  Don"t worry - you can practice in demo mode first to get a feel of  each game before you choose to play for real.',
  tournamentPara3: ' Our slot games come in a wide variety of themes, with progressive  jackpot slot games offering players a lucrative chance to score a  big win. We also offer exciting live dealer games where you can  see your cards shuffled and dealt in real time! Unsure of which  game is right for you? Don"t worry - you can practice in demo mode  first to get a feel of each game before you choose to play for  real.Our slot games come in a wide variety of themes, with  progressive jackpot slot games offering players a lucrative chance  to score a big win. We also offer exciting live dealer games where  you can see your cards shuffled and dealt in real time! Unsure of  which game is right for you? Don"t worry - you can practice in  demo mode first to get a feel of each game before you choose to  play for real.',
  'New Games': 'New Games',
  'Popular Games': 'Popular Games',
  'Games Of The Month': 'Games Of The Month',
  Exclusive: 'Exclusive',
  'Bonus Buy': 'Bonus Buy',
  'Jackpot Games': 'Jackpot Games',
  lightMode: 'Light Mode',
  darkMode: 'Dark Mode',
  searchProvider: 'Search Provider',
  tournamentHistoryDataNotAvailable: 'Tournament history data not available',
  viewMore: 'View More',
  description: 'Description',
  codeCopied: 'Code Copied',
  tournamentGames: 'Tournament Games',
  freeSpinGames: 'Free Spin Games',
  promoCode: 'Promo Code',
  paymentNote: 'Note: Please do not close or refresh window while transaction is being processed',
  liveGames: 'Live Games',
  play: 'PLAY',
  demo1: 'DEMO',
  registration: 'REGISTRATION',
  confirmation: 'Confirmation',
  forfeitBonusMessage: 'If you activate this bonus, any old active bonus will get forfeited.',

  bonusConfirmationMessage: 'Are you sure you want to activate this bonus?',
  forfeitRewardMessage: 'If you activate this reward, any old active bonus will get forfeited.',
  rewardConfirmationMessage: 'Are you sure you want to activate this reward?',
  reward: 'Reward',
  purchasedReward: 'Purchased Reward',
  noActiveRewardFound: 'No active reward found',
  noRewardDetailsFound: 'No reward found',
  betDate: 'Bet Date',
  shopDes: 'The shop is where you can exchange your accumulated points for various rewards, bonuses, and prizes.',
  vipInfoHeading: 'VIP bonuses are exclusive offers for our most loyal players.',
  updatefooter: ' provides eGaming services and is officially licensed by the Offshore Finance Authority of The State of Anjouan under The Computer Gaming Licensing Act 007 of 2005. This website is owned and operated by KeyPartnership B.V., (company number: 166291), registered and regulated in Curaçao, with its address at Abraham de Veerstraat 1, Willemstad, Curaçao',
  loginFirstForRandom: 'Please Login First To See Random Games'
}
