import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import { FooterWrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { getAllSiteinfo } from "redux-thunk/thunk/gameSetting";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AffiliateModal from "pages/AffiliateModal/index";
import ReferralModal from "pages/ReferralModal/index";
import { setUserModal } from "redux-thunk/redux/slices/auth.slice";
const { REACT_APP_S3_URL } = process.env;

export default function Footer () {
  const theme = useTheme();
  const [isAffiliateModalOpen, setAffiliateModalOpen] = useState(false);
  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const { cmsData } = useSelector((state) => state?.gameSetting);
  const { selectedLanguage } = useSelector((state) => state.language);
  const { sponsorData } = useSelector((state) => state.general);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSiteinfo());
  }, [dispatch]);

  // const handleLicenseShow = () => {
  //   window.open('https://ch-machine-dev-storage.s3.amazonaws.com/1728047116431_Screenshot_from_2024-10-04_18-35-02.png')
  // }

  const handlePaymentRedirect = () => {
    dispatch(setUserModal("deposit"));
  }

  useEffect(() => {
    // Create script for licensing logo
    const script = document.createElement('script');
    script.src = "https://6ce7ad35-9c34-4197-88aa-0ce8bd4b2f3e.snippet.anjouangaming.org/anj-seal.js";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to head and initialize after load
    script.onload = () => {
      if (window.anj_6ce7ad35_9c34_4197_88aa_0ce8bd4b2f3e) {
        window.anj_6ce7ad35_9c34_4197_88aa_0ce8bd4b2f3e.init();
      }
    };
    document.head.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.head.removeChild(script);
    };

  }, []);

  return (
    <>
      <FooterWrapper theme={theme}>
        {isAffiliateModalOpen && (
          <AffiliateModal
            dialogOpen={isAffiliateModalOpen}
            handleClose={() => setAffiliateModalOpen(false)}
          />
        )}
        {isReferralModalOpen && (
          <ReferralModal
            dialogOpen={isReferralModalOpen}
            handleClose={() => setReferralModalOpen(false)}
          />
        )}

        <Grid className="footer-inner">
          <Box className="update-footer-text">
            <Typography>Cash-machine-casino.com</Typography>
            <Typography>
              {t('updatefooter')}
            </Typography>
          </Box>

          <Box className="footer-wrap">
            <Grid className="footer-grid-wrap">
              <Grid item className="footer-links-container">
                <Box className="footer-links-box">
                  {cmsData?.map((data, i) => (
                    <Link
                      key={i}
                      to={`/cms/${data.slug}`}
                      target="_blank"
                      className="link-item"
                    >
                      {data.title?.[selectedLanguage?.code.toUpperCase()] ||
                        data.title?.EN ||
                        ""}
                    </Link>
                  ))}
                  <Link
                    onClick={() => setAffiliateModalOpen(true)}
                    className="link-item"
                  >
                    {t("affiliate")}
                  </Link>
                  {/* <Link onClick={() => setReferralModalOpen(true)} className='link-item'>{t('referral')}</Link> */}
                </Box>
                <Box className="img-box">
                  {/* <img
                  src="https://ch-machine-dev-storage.s3.amazonaws.com/1728039595059_anjouan-logo.png"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLicenseShow(true)}
                  loading="lazy"
                  alt="img"
                /> */}
                  <div
                    id="anj-6ce7ad35-9c34-4197-88aa-0ce8bd4b2f3e"
                    data-anj-seal-id="6ce7ad35-9c34-4197-88aa-0ce8bd4b2f3e"
                    data-anj-image-size="128"
                    data-anj-image-type="basic-small"
                  />
                  <img
                    src="/assets/images/stock-images/18-plus.png"
                    loading="lazy"
                    alt="img"
                  />
                </Box>
              </Grid>

              <Grid item className="footer-logos-container">
                {sponsorData?.rows?.filter(
                  (sponsor) => sponsor?.category === "payment"
                )?.length && (
                    <Box className="payment-options-box">
                      <Typography variant="subtitle1" className="title-text">
                        {t("paymentOptions")}
                      </Typography>
                      <Box className="logos-box">
                        {sponsorData?.rows
                          ?.filter((sponsor) => sponsor?.category === "payment")
                          ?.map((sponsor) => (
                            <div
                              onClick={handlePaymentRedirect}
                              // target="_blank"
                              style={{ cursor: "pointer" }}
                              className="logo-item cursor-pointer"
                              key={sponsor.id}
                            >
                              <img
                                src={`${REACT_APP_S3_URL}/${sponsor?.imageUrl}`}
                                loading="lazy"
                                alt="sponsor"
                                key={sponsor.id}
                              />
                            </div>
                          ))}
                        {/* <img src='/assets/images/stock-images/payment-options-logos/paymsystem_visa.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_mastercard.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_postepay.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_footer_interac.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_paysafecard.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_banktransfer.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_skrill.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_klarna.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_mifinity.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_footer_astropay.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_bitcoin.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_litecoin.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_neteller.png' loading='lazy' alt='Payment Option Logo' /> */}
                      </Box>
                    </Box>
                  )}

                {sponsorData?.rows?.filter(
                  (sponsor) => sponsor?.category === "sponser"
                )?.length && (
                    <Box className="providers-box">
                      <Box className="logos-box">
                        {sponsorData?.rows
                          ?.filter((sponsor) => sponsor?.category === "sponser")
                          ?.map((sponsor) => (
                            <Link
                              to={sponsor?.redirectUrl}
                              target="_blank"
                              className="logo-item"
                              key={sponsor.id}
                            >
                              <img
                                src={`${REACT_APP_S3_URL}/${sponsor?.imageUrl}`}
                                loading="lazy"
                                alt="sponsor"
                                key={sponsor.id}
                              />
                            </Link>
                          ))}
                        {/* <img src='/assets/images/stock-images/providers-logos/clash-of-slots-black.png' loading='lazy' alt='Payment Option Logo' /> */}
                        {/* <img src='/assets/images/stock-images/providers-logos/sigma.png' loading='lazy' alt='Payment Option Logo' /> */}
                        {/* <img src='/assets/images/stock-images/providers-logos/top10casinos.png' loading='lazy' alt='Payment Option Logo' /> */}
                        {/* <img src='/assets/images/stock-images/providers-logos/casino-en-ligne.png' loading='lazy' alt='Payment Option Logo' /> */}
                        {/* <img src='/assets/images/stock-images/providers-logos/ask-gamblers-logo.png' loading='lazy' alt='Payment Option Logo' /> */}
                        {/* <img src='/assets/images/stock-images/providers-logos/bonkku-logo.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/italiancasino-logo.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/new-casino.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/casino-on-line.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/casinolandia.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/betrugstest.png' loading='lazy' alt='Payment Option Logo' /> */}
                      </Box>
                    </Box>
                  )}
                <Box className="footer-text">
                  2023-2024 &#169; {t("footerRightsReserved")}
                  <br />
                  {t("footerAboutSite")}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </FooterWrapper>
    </>
  );
}
