import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  FormGroup,
  FormControlLabel,
  IconButton,
  Slide,
  Typography
} from '@mui/material'
import { DarkLightSwitch } from '../style'
import { MobileMenuWrapper } from './style'
import {
  ArrowLeftIcon,
  CoinVerticalIcon,
  CurrencyEurIcon,
  HamburgerIcon,
  SettingIcon
} from 'assets/icons/index'
import {
  CustomAnimatedButton,
  CustomIconButton,
  CustomMainButton
} from 'components/common-ui/CustomButton/CustomButton'
import MyAccount from 'components/layout/MyAccount/index'
import { useDispatch, useSelector } from 'react-redux'
import AuthModal from 'components/layout/AuthContentSection/AuthModal'
import { setAuthModal, setUserModal } from 'redux-thunk/redux/slices/auth.slice'
import { useTranslation } from 'react-i18next'
import SideNavBar from 'components/layout/SideNavBar/index'
import { setShowSideNav } from 'redux-thunk/redux/slices/settings.slice'
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet-icon.svg'

const MobileMenu = ({ toggleTheme }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { isAuthenticated, isDarkMode } = useSelector((state) => state.gameSetting)
  const { showSideNav } = useSelector((state) => state.settings)
  const { userWallet } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [myAccountDialog, setMyAccountDialog] = useState(false)
  const { authModal } = useSelector((state) => state.auth)

  const handleChange = () => {
    dispatch(setShowSideNav(!showSideNav))
  }

  const userElement = (
    <Box className='navigation-box'>
      {/* <CustomIconButton>
        <GiftIcon />
      </CustomIconButton> */}

      <Box className='wallet-balance-wrap'>
        <Box className='wallet-box'>
          <CurrencyEurIcon />
          <Typography variant='h6'>{userWallet?.amount}</Typography>
        </Box>
        <Box className='wallet-box'>
          <Typography variant='h6'>+{userWallet?.nonCashAmount}</Typography>
        </Box>
        <Box className='wallet-box'>
          <CoinVerticalIcon />
          <Typography variant='h6'>{userWallet?.loyaltyPoint}</Typography>
        </Box>
      </Box>

      <CustomIconButton
        className='settings-btn'
        onClick={() => {
          setMyAccountDialog('account')
        }}
      >
        <SettingIcon />
      </CustomIconButton>
    </Box>
  )

  const loginElement = (
    <Box className='auth-btn-wrap'>
      <CustomMainButton
        onClick={() => {
          dispatch(setAuthModal('login'))
        }}
      >
        {t('login')}
      </CustomMainButton>
      <CustomMainButton
        onClick={() => {
          dispatch(setAuthModal('signup'))
        }}
      >
        {t('registration')}
      </CustomMainButton>
    </Box>
  )

  return (
    <>
      <MobileMenuWrapper theme={theme}>

        <Box className="wallet-btn-wrapper">
          <CustomAnimatedButton className="mobile-menu-btn"
            onClick={() => {
              if (isAuthenticated)
                dispatch(setUserModal('deposit'))
              else
                dispatch(setAuthModal('login'));
            }}>
            <WalletIcon className='wallet-icon' />
          </CustomAnimatedButton>

          <IconButton className='mobile-menu-btn' onClick={handleChange}>
            <HamburgerIcon />
          </IconButton>
        </Box>

        <Slide direction='left' in={showSideNav}>
          <Box className='mobile-menu-wrap'>
            <Box className='mobile-menu-box'>
              <Box className='mobile-header'>
                {isAuthenticated ? userElement : loginElement}
              </Box>

              <Box className='mobile-body'>
                <Box className='header-box'>
                  <Button startIcon={<ArrowLeftIcon />} className='back-btn' onClick={handleChange}>
                    Back
                  </Button>

                  <FormGroup className='theme-toggle-btn'>
                    <FormControlLabel
                      sx={{ margin: '0' }}
                      control={<DarkLightSwitch content={isDarkMode ? t('lightMode') : t('darkMode')} onChange={toggleTheme} />}
                    // label="MUI switch"
                    />
                  </FormGroup>
                </Box>

                <SideNavBar slideOpen={showSideNav} closeSlide={() => dispatch(setShowSideNav(false))} />
              </Box>
            </Box>
          </Box>
        </Slide>
      </MobileMenuWrapper>

      <MyAccount
        dialogOpen={myAccountDialog}
        handleClose={() => setMyAccountDialog(false)}
        setDialogOpen={setMyAccountDialog}
      />
      <AuthModal
        dialogOpen={authModal}
        handleClose={() => dispatch(setAuthModal(''))}
      />
    </>
  )
}

export default React.memo(MobileMenu)
